//
// Borders
// --------------------------------------------------
$border-direction-list: top bottom right left;

// Add values here for border colors that you want to exist
$border-colors: (
   ($default-background-color, 'default'),
   ($primary, 'primary'),
   ($secondary, 'secondary'),
   ($tertiary, 'tertiary'),
   ($quaternary, 'quaternary'),
   ($success, 'success'),
   ($danger, 'danger'),
   ($warning, 'warning'),
   ($info, 'info'),
   ($light, 'light'),
   ($dark, 'dark')
);

// Misc
.border-radius-circle {
    border-radius: 50%;
}
.thick-border {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    transition: all ease .5s;
    background-color: rgba(0,0,0,0.125);
}
.border-dashed {
    border: $content-block-border;
    border-style: dashed;
}
.no-border-radius {
    border-radius: 0px !important;
}

// Trianglular defaults
.triangle {
    width: 0;
    height: 0;
    position: absolute;
    border-width: 55px;
    border-style: solid
}
.triangle-top-left,.triangle-top-right {
    top: 0;
    border-top-color: $default-background-color;
    border-bottom: none
}
.triangle-bottom-left,.triangle-bottom-right {
    bottom: 0;
    border-bottom-color: $default-background-color;
    border-top: none
}
.triangle .triangle-icon {
    color: $default-text-color;
    position: absolute;
}

// Full borders
@include border("");
@include border-radius-classes("");

@each $colorset in $border-colors {
  @include border-colors("", nth($colorset, 1), nth($colorset, 2));
}

// Single directional borders
@each $border-direction in $border-direction-list {
    @include border($border-direction);
    @include border-radius-classes($border-direction);

    @each $colorset in $border-colors {
      @include border-colors("", nth($colorset, 1), nth($colorset, 2));
    }
}


// Bi directional borders & triangles
@each $border-direction in $border-direction-list {
    $i: index($border-direction-list, $border-direction);
    @if ($i <= 2) {
       @each $border-direction-bi in $border-direction-list {
            $ib: index($border-direction-list, $border-direction-bi);
            @if ($ib >= 3) {
                @include border-radius-classes($border-direction + '-' + $border-direction-bi);
                @include border-triangle($border-direction, $border-direction-bi);
            }
       }
    }
}
