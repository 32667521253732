// Dots to indicate activity status (on Operator Active Pathways List)
.status-dots
{
	span
	{
		display: inline-block;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-right: 5px;

		i
		{
			padding: 0;
		}

		&.bg-success-muted i
		{
			color: $success-text-color !important;
		}

		&.available, &.locked
		{
			background: #f6f6f6;
			color: darken(#f6f6f6, 20%);
		}
	}
}
