.radio {
    margin-bottom:7px;
    span.radio-widget-input {
        position: relative;
        display: flex;
        padding: 5px;
        margin-right: 7px;
        align-self: stretch;
        justify-content: center;
        align-items: center;
        input[type=radio]{
            position:relative !important;
            margin:0 !important;   
            top:0;     
        }
    } 
}