// Beamer (3rd party plugin that needs styling) - by Robbie, for TC-19702
// Beamer docs: https://www.getbeamer.com/docs

// Overwrites the default counter icon styles
// .beamer_icon.active, #beamerIcon.active {
//     background-color: $tertiary !important;
//     animation: none !important;
//     width: auto;
//     padding: 0px 6px;
//     border-radius: .9375rem;
// }

.romeo .beamer_icon.active, #beamerIcon.active {
    background-color: $romeo-secondary !important;
    animation: none !important;
    width: auto;
    padding: 0px 6px;
    border-radius: .9375rem;
}