// header.login-header {
// 	text-align:center;	
// 	color:$login-primary-text-color-mobile;
// 	font-size:$login-image-text-font-size;
// 	position:relative;
// 	background:transparent;
// 	background-size: cover;
// 	margin-bottom: 20px;
// 	display: block;
// 	@media(min-width: $screen-md){
// 		height:100vh;
// 		padding-left:100px;	
// 		text-align:left;
// 		margin-left: -15px;
// 		margin-right: -15px;		
// 		padding:0 5vh;
// 		background:$login-background-desktop;
// 		color:$login-primary-text-color-desktop;
// 		position:absolute;
// 		background-size: cover;
// 		margin-bottom: 0px;
// 		display: block;
// 	}	
// 	@media(min-width: $screen-lg){
// 		padding-left:115px;
// 	}
// 	.login-content-container {
// 	    display: inline-block;
// 	    background: $login-content-container-background;
// 	    padding: $login-content-container-padding;
// 	    opacity: $login-content-container-opacity;	
// 	    color:$login-content-container-color;
//         display:$login-content-container-mobile-display;
//         display:none;
// 	    @media(min-width: $screen-md){
// 	    	display:block;
// 	    	max-width: 400px;
//             display:$login-content-container-desktop-display;
// 	    }
// 	    @media(min-width: $screen-lg){
// 	    	max-width: 600px;
// 	    }	    
// 	    h1 {
// 	    	color:$login-content-container-heading-color;
// 		}
// 		a {
// 			color: $login-content-container-a-color-mobile;
// 			@media(min-width: $screen-md){
// 				color:$login-content-container-a-color-desktop;
// 			}			
// 		}
// 	}
// 	h1 {
// 		@include remConversion(font-size, $login-heading-primary-font-size);
// 		margin-bottom:10px;
// 		color:$login-primary-text-color-mobile;
// 		font-family:$login-heading-font, "Arial","Open Sans";
// 		@media(min-width: $screen-md){
// 			color:$login-primary-text-color-desktop;
// 			@include remConversion(font-size, $login-heading-primary-font-size);
// 		}
// 	}
// 	p {
// 		margin: 0 0 10px;
// 		max-width:100%;
// 		text-align:left;
// 		font-size:$login-content-container-font-size;
// 		@media(min-width: $screen-md){
// 			width:700px;
// 		}
// 	}	
// 	img.site-logo {
// 		z-index: 999999;
// 		width: auto;
// 		position: relative;
// 		text-align: center;
// 		margin: $logo-mobile-top-margin auto 0vh;
// 		transition:none;
// 		height:$login-mobile-logo-size;
// 		background:$login-logo-mobile-background;
// 		padding:$login-logo-mobile-padding;
// 		max-width: 100%;
//         display: $login-logo-mobile-display;
// 		@media(min-width: $screen-md){
// 			margin-top: $logo-desktop-top-margin;
// 			width: auto;
// 			height:$login-desktop-logo-size;
// 			margin-bottom: 3vh;	
// 			background:$login-logo-desktop-background;
// 			padding:$login-logo-desktop-padding;
//             display: $login-logo-desktop-display;
// 		}
// 		&:after {
// 			background:red;
// 			position:absolute;
// 			content:"";
// 		}		
// 	}	
// 	.login-video-container, .video-overlay {
// 		display:none;
// 	}
// }

// .login-video.login {
//     .body {
// 		min-height: calc(100vh - 80px);
// 		padding-bottom: 0px;
// 		font-size:$login-font-text-size;
// 		.side-section {
// 		    padding: 0;
// 		    overflow-x: hidden;
// 		    font-size:$login-font-text-size;
// 		    z-index: 999;
// 		}		
//     }
// 	.video-overlay {
// 		position:fixed;
// 	    height: 100%;
// 	    left: 0px;
// 	    right: 0px;
// 	    top: 0px;
// 	    bottom: 0px;	
// 	    background: $login-video-overlay-background-mobile;
// 	    opacity: $login-video-overlay-opacity-mobile;
// 	    overflow: hidden;
// 	    display:block;
// 	    @media(min-width: $screen-md){
// 	    	width: calc(100% - 15px);
// 	    	position:absolute;
// 	    	opacity: $login-video-overlay-opacity-desktop;
// 	    	background: $login-video-overlay-background-desktop;
// 	    }
// 	}    
// 	header.login-header {
// 		@media(min-width: $screen-md){
// 			height:auto;
// 			background:none;
// 			width:auto;
// 			padding:0;
// 			position:relative;
// 			height: 100vh;
// 			margin-left: -30px;
// 			padding-right:15px;
// 		}
// 		.login-video-container {
// 		    position: fixed;
// 		    top: 0px;
// 		    left: 0px;
// 		    right: 0px;
// 		    bottom: 0px;	
// 		    display:block;
// 		    @media(min-width: $screen-md){
// 				position:relative;
// 				height:100%;
// 				overflow:hidden;
// 		    }		
// 			audio, canvas, progress, video {
// 				height:100%;
// 				object-fit: fill;
// 			}		
// 		}
// 		.login-logo-container {
// 			@media(min-width: $screen-md){
// 			    z-index: 99999;
// 			    position: absolute;
// 			    top: 0px;
// 			    padding-left: 115px;			
// 			}
// 		}
// 		.login-content-container {
// 		    display: inline-block;
// 		    top: 0;
// 		    position: absolute;
// 		    margin-top: $login-video-content-margin-top;
// 		    margin-left: 115px;
// 		    background: $login-video-content-container-background;
// 		    padding: $login-video-content-container-padding;
// 		    opacity: $login-video-content-container-opacity;	
// 		}		
// 	}
// 	.footer, footer {
// 	    z-index: 999;
// 	    margin: 20px auto 0;
// 		@media(min-width: $screen-md){	    	
//     		margin: 3px -45px;
//     	}
// 	}
// }