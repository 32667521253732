.widget-chat {
    .widget-chat-row {
        display:flex;
        margin-bottom:20px;
        .widget-chat-icon {
            i {
                @include remConversion(font-size,2);
                margin-right: 7px;            
            }
        }
        .widget-chat-message {
            background: darken($content-block-background-color, 5%);
            padding: 8px 12px;
            border-left: 5px solid $tertiary;   
            position:relative; 
            .triangle {
                left: -14px;
                border-width: 14px;
                top: 12px;
                border-top-color: #2AB69D;
                border-bottom-color:transparent;
                right: 0;
                border-left-color: transparent;
                border-right: none;                                
            }        
        }
        &.widget-chat-message-reply {
            text-align:right;
            .widget-chat-icon {
                order:3;
                i {
                    margin:0 0 0 12px;
                }
            }
            .widget-chat-message {
                border-left:none;
                border-right:5px solid $quaternary; 
                .triangle {
                    right: -14px;
                    border-width: 14px;
                    top: 12px;
                    border: 14px solid $quaternary;
                    left:initial;
                    border-left: none;
                    border-bottom-color: transparent;
                    border-right-color: transparent;                               
                }                 
            }
        }
    }
}