.risk-history-indicator {
    height: 15px;
    width: 15px;
    border-radius: 50%;

    &.current {
        height: 100%;
        border-radius: 20px;
    }
}

//AB For Casebooks 23/7/20 TC-20519 but kept generic
// commenting out as this branch is merged in with 20404 which needs to go live before this casebook stuff.

/*#casebook-items-filters-container {
	a:hover {
		text-decoration: none;
	}
	background: #ccc !important;
	border: none;
}

.input-group {
	&.locked {
		input {
			// height: 42px;
			border-left: none;
			background-color: $palette-grey-50;
		}

		.input-group-addon {

			&:hover {
				text-decoration: none;
			}

			&:first-of-type {
				background-color: $palette-grey-50;
				i {
					color: $palette-grey-600;
					&:hover {
						// color: green;
					}
				}
			}
			&:last-of-type {
				background-color: #ccc;
			}
		}

		#casebook-items-filters-heading {
			&:hover {
				text-decoration: none;
			}
		}
	}
}  */