// /////////////////////////////////////
// // Inpsired by: https://codepen.io/jo-asakura/pen/stFHi?editors=1100
// //
// // Required markup:
// //
// // <div class="pie-wrapper progress-{1-100}">
// //   <div class="label">
// //     <div class="inner">
// //       <span>{1-100}<span class="smaller">%</span></span>
// //     </div>
// //   </div>
// //   <div class="pie">
// //     <div class="left-side half-circle"></div>
// //     <div class="right-side half-circle"></div>
// //   </div>
// // </div>


// $size: 1em;

// @mixin size($h, $w) {
//   height: $h;
//   width: $w;
// }

// @mixin draw-progress($progress) {
//   .pie {
//     .left-side {
//       $rotate: $progress * 3.6;
//       transform: rotate(#{$rotate}deg);
//       // transition: all 0.5s;
//     }
//   }

//   @if ($progress > 50) {
//     .pie {
//       clip: rect(auto, auto, auto, auto);

//       .right-side {
//         transform: rotate(180deg);
//         // transition: all 0.5s;
//       }
//     }
//   }

//   @if ($progress <=  50) {
//     .pie {
//       .right-side {
//         display: none;
//       }
//     }
//   }
// }

// .pie-wrapper {
//   @include size($size, $size);
//   margin: auto;
//   font-size: 8em;
//   position: relative;
//   background: transparent !important;

//   .label {
//     $font-size: $size / 4;
//     $font-size-redo: $size * 4;

//     background: black;
//     border-radius: 50%;
//     bottom: $font-size-redo / 10;
//     color: #ecf0f1;
//     cursor: default;
//     display: block;
//     font-size: $font-size;
//     left: $font-size-redo / 10;
//     line-height: $font-size-redo * .65;
//     position: absolute;
//     right: $font-size-redo / 10;
//     text-align: center;
//     top: $font-size-redo / 10;
//     z-index: 3;

//     .smaller {
//       font-size: .45em;
//       padding-bottom: 20px;
//     }

//     .inner
//     {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);

//       i, span
//       {
//         font-size: 1em;
//         padding: 0;
//       }

//       span
//       {
//         font-size: 0.8em;
//       }

//       .completion
//       {
//         font-size: 12px;
//       }
//     }
//   }

//   .shadow {
//     @include size(100%, 100%);
//     border: $size / 10 solid;
//     border-radius: 50%;
//   }

//   .pie {
//     @include size(100%, 100%);
//     clip: rect(0, $size, $size, $size / 2);
//     left: 0;
//     position: absolute;
//     top: 0;
//     z-index: 2;

//     .half-circle {
//       @include size(100%, 100%);
//       transform: rotate(0deg);
//       border: $size / 9 solid;
//       border-radius: 50%;
//       clip: rect(0, $size / 2, $size, 0);
//       left: 0;
//       position: absolute;
//       top: 0;
//       transition: all 0.5s;
//     }
//   }

//   &::after
//   {
//     content: '';
//     @include size(100%, 100%);
//     border: $size / 10 solid;
//     border-radius: 50%;
//     position: absolute;
//     left: 0;
//     top: 0;
//     z-index: 1;
//   }

//   @for $i from 1 through $percentages {
//     &.progress-#{$i} {@include draw-progress($i);}
//   }

//   $colors: (
//     ($primary-background-color, 'primary', 30, 60)
//     ($secondary-background-color, 'secondary', 20, 45)
//     ($tertiary-background-color, 'tertiary', 20, 45)
//     ($quaternary-background-color, 'quaternary', 15, 30)
//     ($success-background-color, 'success', 15, 30)
//     ($info-background-color, 'waiting', 15, 30)
//     ($danger-background-color, 'reject', 15, 30)
//   );

//   @each $color-set in $colors {
//     $color: nth($color-set, 1);
//     $name: nth($color-set, 2);
//     $loading-bar-bg-lighten: nth($color-set, 3);
//     $loading-bar-content-lighten: nth($color-set, 4);

//     &.color-#{$name} {
//       .label {
//         background-color: $color;
//       }

//       .pie .half-circle, &.progress-100::after {
//         border-color: lighten($color, $loading-bar-bg-lighten);
//       }

//       &::after {
//         border-color: lighten($color, $loading-bar-content-lighten);
//       }
//     }
//   }

//   &.color-white {
//     .label {
//       background-color: transparent;
//     }

//     .pie .half-circle, &.progress-100::after {
//       border-color: #ffffff;
//     }

//     &::after {
//       border-color: rgba(255,255,255,0.5);
//     }
//   }
// }
