.btn-flip {
	-webkit-transition: -webkit-transform 1s;
	-moz-transition: -moz-transform 1s;
	-o-transition: -o-transform 1s;
	transition: transform 1s;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
	.btn-clear:hover {
		background:transparent !important;
		color:initial !important;
	}
	&.flipped {
	  -webkit-transform: rotateY( 180deg );
	     -moz-transform: rotateY( 180deg );
	       -o-transform: rotateY( 180deg );
						transform: rotateY( 180deg );
						
		.button1 {
			opacity:0;
		}
	}	
	a, button {
	    -webkit-backface-visibility: hidden;
	    -moz-backface-visibility: hidden;
	    -ms-backface-visibility: hidden;
	    backface-visibility: hidden;
	    -webkit-transition: -webkit-transform 1s;
	    -moz-transition: -moz-transform 1s;
	    -o-transition: -o-transform 1s;
	    transition: transform 1s;
	    -webkit-transform-style: preserve-3d;
	    -moz-transform-style: preserve-3d;
	    -o-transform-style: preserve-3d;
	    transform-style: preserve-3d; 
	}	
	.button2 {
	  -webkit-transform: rotateY( 180deg );
	     -moz-transform: rotateY( 180deg );
	       -o-transform: rotateY( 180deg );
	          transform: rotateY( 180deg );
	          position:absolute;
	          top:0;
	}	
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.btn-flip {
		transition: none !important;
		-webkit-transition: none !important;
		-moz-transition: none !important;
		  -o-transition: none !important;	
		  display:block;
		  .btn-clear:hover {
			background:transparent !important;
			color:inherit !important;
		}		  
		&.flipped {
			-webkit-transform: none !important;
			-moz-transform: none !important;
			  -o-transform: none !important;
			  transform: none !important;	
			  transition: none !important;
			  -webkit-transition: none !important;
			  -moz-transition: none !important;
				-o-transition: none !important;				  		
			a,button {
				display:none !important;
				-webkit-transform: none !important;
				-moz-transform: none !important ;
				  -o-transform: none !important;
				  transform: none !important;
				  transition: none !important;
				  -webkit-transition: none !important;
				  -moz-transition: none !important;
					-o-transition: none !important;					  
			}
			.button2 {
				display:block !important;
				-webkit-transform: none !important;
				-moz-transform: none !important;
				  -o-transform: none !important;
				  transform: none !important;
				  transition: none !important;
				  -webkit-transition: none !important;
				  -moz-transition: none !important;
					-o-transition: none !important;					  
				  position:relative;
				  top:auto;
			}
		}	
	}	
}
