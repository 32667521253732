$flexDimensionsPx:('5', '10', '20', '25', '50', '75', '100', '150', '200', '250', '500');
$flexDimensionsPerc:('5', '10', '20', '25', '50', '75', '100');
$flexIncrements:('1', '2', '3', '4', '5');

// Breakpoints only
.d-flex {
	@include flexbox;
}
.d-flex-inline {
	@include flexboxInline;
}
.d-flex-wrap {
	@include flex-wrap(wrap);
}
.d-flex-important {
	@include flexboxImportant;
}
.d-align-items-center {
	@include align-items(center);
}
.d-align-items-start {
	@include align-items(flex-start);
}
.d-flex-align-items-start {
	@include align-items(flex-start);
}
.d-align-items-end {
	@include align-items(flex-end);
}
.d-flex-align-items-end {
	@include align-items(flex-end);
}
.d-align-items-stretch {
	@include align-items(stretch);
}
.d-flex-align-items-stretch {
	@include align-items(stretch);
}
.d-flex-wrap-center {
	@include flexbox;
	@include flex-flow(wrap);
    @include justify-content(center);
}
.d-justify-content-center {
	@include justify-content (center);
}
.d-align-self-flex-start {
	@include align-self(flex-start);
}
.d-align-self-flex-end {
	@include align-self(flex-end);
}
.d-justify-content-flex-start {
	@include justify-content (flex-start);
}
.d-justify-content-flex-end {
	@include justify-content (flex-end);
}
.d-justify-content-space-evenly {
	@include justify-content (space-evenly);
}
.d-justify-content-space-between {
	@include justify-content(space-between);
}
.d-flex-direction-row {
	@include flex-direction(row);
}
.d-flex-direction-column {
	@include flex-direction(column);
}
.d-flex-direction-row-reverse {
	@include flex-direction(row-reverse);
}
.d-flex-direction-column-reverse {
	@include flex-direction(column-reverse);
}
.d-align-self-stretch {
	@include align-self(stretch);
}
.d-align-self-center {
	@include align-self(center);
}
.d-align-content-flex-start {
	@include align-content(flex-start);
}
.d-align-content-flex-end {
	@include align-content(flex-end);
}
.d-align-content-flex-start {
	@include align-content(flex-start);
}
.d-align-content-center{
	@include align-content(center);
}
.d-align-content-space-between {
	@include align-content(space-between);
}
.d-align-content-space-around {
	@include align-content(space-around);
}
.d-align-content-space-evenly {
	@include align-content(space-evenly);
}
.d-align-content-stretch {
	@include align-content(stretch);
}
.d-align-content-start {
	@include align-content(start);
}
.d-align-content-end {
	@include align-content(end);
}
.d-align-content-flex-baseline {
	@include align-content(baseline);
}


@each $breakpoint, $size in $grid-breakpoints {
	@media(min-width: $size){
		.d-flex-#{$breakpoint} {
			@include flexbox;
		}
		.d-flex-inline-#{$breakpoint} {
			@include flexboxInline;
		}		
		.d-flex-wrap-#{$breakpoint} {
			@include flex-wrap(wrap);
		}		
		.d-flex-important-#{$breakpoint} {
			@include flexboxImportant;
		}	
		.d-align-items-center-#{$breakpoint} {
			@include align-items(center);
		}	
		.d-align-items-start-#{$breakpoint} {
			@include align-items(flex-start);
		}		
		.d-flex-align-items-start-#{$breakpoint} {
			@include align-items(flex-start);
		}
		.d-align-items-end-#{$breakpoint} {
			@include align-items(flex-end);
		}		
		.d-flex-align-items-end-#{$breakpoint} {
			@include align-items(flex-end);
		}
		.d-align-items-stretch-#{$breakpoint} {
			@include align-items(stretch);
		}		
		.d-flex-align-items-stretch-#{$breakpoint} {
			@include align-items(stretch);
		}
		.d-flex-wrap-center-#{$breakpoint} {
			@include flexbox;
			@include flex-flow(wrap);
			@include justify-content(center);
		}
		.d-justify-content-center-#{$breakpoint} {
			@include justify-content (center);
		}
		.d-align-self-flex-end-#{$breakpoint} {
			@include align-self (flex-end);
		}
		.d-justify-content-flex-start-#{$breakpoint} {
			@include justify-content (flex-start);
		}
		.d-align-self-flex-start-#{$breakpoint} {
			@include align-self(flex-start);
		}	
		.d-justify-content-flex-end-#{$breakpoint} {
			@include justify-content (flex-end);
		}
		.d-justify-content-space-evenly-#{$breakpoint} {
			@include justify-content (space-evenly);
		}
		.d-justify-content-space-between-#{$breakpoint} {
			@include justify-content(space-between);
		}		
		.d-flex-direction-row-#{$breakpoint} {
			@include flex-direction(row);
		}
		.d-flex-direction-column-#{$breakpoint} {
			@include flex-direction(column);
		}
		.d-flex-direction-row-reverse-#{$breakpoint} {
			@include flex-direction(row-reverse);
		}
		.d-flex-direction-column-reverse-#{$breakpoint} {
			@include flex-direction(column-reverse);
		}		
		.d-align-self-stretch-#{$breakpoint} {
			@include align-self(stretch);
		}
		.d-align-self-center-#{$breakpoint} {
			@include align-self(center);
		}		
		.d-align-content-flex-start-#{$breakpoint} {
			@include align-content(flex-start);
		}
		.d-align-content-flex-end-#{$breakpoint} {
			@include align-content(flex-end);
		}
		.d-align-content-flex-start-#{$breakpoint} {
			@include align-content(flex-start);
		}
		.d-align-content-center-#{$breakpoint} {
			@include align-content(center);
		}
		.d-align-content-space-between-#{$breakpoint} {
			@include align-content(space-between);
		}
		.d-align-content-space-around-#{$breakpoint} {
			@include align-content(space-around);
		}
		.d-align-content-space-evenly-#{$breakpoint} {
			@include align-content(space-evenly);
		}
		.d-align-content-stretch-#{$breakpoint} {
			@include align-content(stretch);
		}
		.d-align-content-start-#{$breakpoint} {
			@include align-content(start);
		}
		.d-align-content-end-#{$breakpoint} {
			@include align-content(end);
		}
		.d-align-content-flex-baseline-#{$breakpoint} {
			@include align-content(baseline);
		}						
	}
}	

// Dimension (px) & BreakPoints
@each $dimension in $flexDimensionsPx {
	.d-flex-w-#{$dimension} {
		@include flex(0 0 #{$dimension}px);
	}
	@each $breakpoint, $size in $grid-breakpoints {
		@media(min-width: $size){
			.d-flex-w-#{$dimension}-#{$breakpoint}
			{
				@include flex(0 0 #{$dimension}px);
			}
		}
	}	
}

// Dimension (%) & BreakPoints
@each $dimension in $flexDimensionsPerc {
	.d-flex-basis-#{$dimension} {
		@include flex-basis('#{$dimension}%');
	}	
	@each $breakpoint, $size in $grid-breakpoints {
		@media(min-width: $size){
			.d-flex-basis-#{$dimension}-#{$breakpoint} {
				@include flex-basis('#{$dimension}%')
			}			
		}
	}	
}

// Increments & Breakpoints
@each $increment in $flexIncrements {
	.d-flex-#{$increment} {
		@include flex(#{$increment} !important);
	}	
	.d-order-#{$increment} {
		@include order(#{$increment})
	}	
	.d-flex-grow-#{$increment} {
		@include flex-grow(#{$increment});
	}
	.flex#{$increment} {
		@include flex(#{$increment});
	}	
	@each $breakpoint, $size in $grid-breakpoints {
		@media(min-width: $size){
			.d-flex-#{$increment}-#{$breakpoint} {
				@include flex(#{$increment} !important);
			}	
			.d-order-#{$increment}-#{$breakpoint} {
				@include order(#{$increment})
			}	
			.d-flex-grow-#{$increment}-#{$breakpoint} {
				@include flex-grow(#{$increment});
			}
			.flex#{$increment}-#{$breakpoint} {
				@include flex(#{$increment});
			}						
		}
	}	
}

// Misc
.d-flex-item, .flex-item {
	flex: 1 0 auto;
	margin-right:20px;
	&:last-child {
		margin-right:0px;
	}
}
.d-flex-wrap-center > .widget-block {
	@include flex(0 1 490px)
}
.d-flex-list {
	@include flexbox;
	@include flex-wrap(wrap);
    @include justify-content(flex-start);
    list-style: none;
	padding: 0;
	> div, span, p, li {
		flex: 1 0 340px;
		@include flex(1 0 340px)
	}
	> .custom-text {
		min-width:300px;
	}
}
.d-flex-list-small {
	@include flexbox;
	@include flex-wrap(wrap);
    @include justify-content(flex-start);
    list-style: none;
	padding: 0;
	> div, span, p, li {
		@include flex(1 0 250px)
	}
}