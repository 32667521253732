// DASHBOARD 
//
.dashboard-content {
    margin-bottom:3rem; // adding for when the dashboard is lacking content (i.e. no sections)

    // SECTIONS 
    > section {
        &:nth-child(odd) { // alt row colors    section-overlap section-overlap-double
            background-color: #eee;
        }
        &:nth-child(3) {  // 3 section that needs to underlap the custom section
            margin-top:-2rem;
            > .container {
                padding-top:5rem;
            }     
        }
        .container,
        .container-fluid {
            padding-top:3rem;
            padding-bottom:3rem;
            .dash-card-count-1 {
                .dashboard-feed:first-child {
                    margin-top: 1.5rem;
                    @include media-breakpoint-up(lg) {
                        margin-top:0;
                    }
                }
            }
            .dash-card-count-2 {
                .dashboard-feed:first-child {
                    margin-top: 1.5rem;
                    margin-bottom:1.5rem;
                    @include media-breakpoint-up(lg) {
                        margin-top: -7rem;
                    }
                }
            }
            .dashboard-feed,
            .dashboard-favs {
                .btn {
                    margin-bottom:.75rem;
                }
            }
            .section-btn {
                display:inline-block;
                margin: 3rem 0 0 0;
                text-decoration: none;
                background:$romeo-secondary;
                @include color-yiq($romeo-secondary);
                &:hover:not(.disabled), &:focus:not(.disabled) {
                    background:darken($romeo-secondary, 15%) !important;
                }  
            }
            &.dash-jobs {
                .section-btn {
                    margin: 1.5rem 0 0 0;
                }
            }
        }                         
    }
    // first 3 sections at the top of the dashboard!  header=circles, custom=custom content and feed, first=first section after custom
    .dashboard-header { // grey header with the circles
        > .container {
            padding-top:2.2rem;
            padding-bottom:2.2rem;  // for the very top section on mobile (cta's have margin-bottom, so countering for that
            @include media-breakpoint-up(sm) {
                padding-bottom:5rem;
            }
        }        
    }
    .dashboard-custom { // custom content and feed section
        margin-top:-2rem;
        .container {
            padding-top:0;    
            padding-bottom:0;     
        }
    }

    // buttons :: make bigger on the dashboard only
    .btn {
        font-size: 1rem;
    }  

    // this is needed to get the boxes to spread evenly.... had to take out of the _main.scss as it courses so many issues on tablets.
    .row {
        display:flex;
        flex-wrap: wrap;
    }    
      
}


//headings 
.dash-h2 {
    text-align:center;
    margin:0 0 3rem 0;
    text-transform: uppercase;
    font-weight:700;
    color:$palette-grey-700;
    font-size: 2rem;
    }


// CTA deck (a copy of bootstrap 4 card-deck)
.dash-cta-deck {    
    display: flex;
    flex-direction: column;
    justify-content: left;  
    flex-flow:wrap;
    @include media-breakpoint-up(sm) {
        justify-content: space-around; //space-around rather than space-evenly due to IE
    }
    .dash-cta-link {
        display: block;
        height: 64px;
        width: 64px;
        position: relative;  
        margin:0 15px 40px 15px;
        @include media-breakpoint-up(sm) {
            margin:0;
        }
        &:after, &:before { //.dash-cta-inner, .dash-cta-outer
            display: block;
            height: 64px;
            width: 64px;
            position: absolute;
            top: calc(50% - 32px);
            left: calc(50% - 32px);
            border-radius: 100%;
            content:" ";
        }
        &:before { //dash-cta-outer       
            background: white;
            border: 1px solid $romeo-primary;
        }
        &:after {//dash-cta-inner
            transition: all .2s ease;        
            background-color: $romeo-primary; 
        }
        &:hover, &:active, &:focus {
            &:after { //dash-cta-inner
                transform: scale(0.85);
            }
        }
        .dash-cta-title {
            display:block;
            color:$romeo-primary;
            font-size: .74rem;
            position: absolute;
            bottom: -21px;
            left: -5%;
            text-align: center;
            width: 110%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .dash-cta-icon {
            position: absolute;
            display: block;
            color: #fff;
            font-size: 30px;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 2;
            z-index: 1;
        }
        &.dash-cta-mail {
            .dash-cta-icon {
                padding-top:2px;
                padding-right: 0;
            }
        }
        &.dash-cta-appointments {
            .dash-cta-icon {
                padding-top:3px;
            }
        }
        &.dash-cta-pathways {
            .fa-stack-1 {
                font-size: 15px;
                padding-top:17px;
            }
            .fa-stack-2 {
                padding-top:2px;  //because of rotate, this is padding right
            }
        }
        .dash-cta-badge { //fyi, using .badge-secondary so it makes the text the right colour
            position: absolute;
            top: -4px;
            padding: 5px 8px;
            right: -7px;
            font-size: 85%;
            z-index: 3;
            box-shadow: $romeo-box-shadow;
            display: block;
            background:$romeo-secondary;
            @include color-yiq($romeo-secondary);
        }
    }
}


// cards 
.dashboard-content {
    .card {
        h1.dash-card-header {
            font-size:1.4rem;
            font-weight: 500;
            border-bottom: none;    
            padding: 1.25rem 1.25rem 0 1.25rem;
            margin:0;
            color:$romeo-primary;
        }
        h2.dash-card-header {
            font-size:1.2rem;
            font-weight: 500;
            padding: 0.85rem 1.25rem;  
            margin:0;
            color:$default-text-color;  
        }
    }
}
    


// feed/notifications
.dash-feed {
    margin-top:-.4rem;
    margin-bottom:0;
    li {
        padding-top:0.75rem;
        padding-bottom:0.75rem;
        border-top:1px solid #ddd;
        &:first-child {
            padding-top:0;
            border-top:none;
        }
        &:last-child {
            padding-bottom:0;
        }
    }
    a.feed-link {
        text-decoration: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .feed-item {
            order: 1;
            flex-grow: 2;
            .feed-type {    
                color:$default-text-color;
                font-size:.8rem;
                font-weight:500;        
                display:block;
                margin-bottom:3px;
            }
            .feed-title {
                font-weight:600;
                font-size:.95rem;
                color:$romeo-primary;
                margin-bottom:3px;
                display:block;
            }
            .feed-details {
                font-size:.8rem;
                color:#666;
                display:block;
            }
        }
        .feed-icon { // upcoming bookings, icon
            color:$romeo-secondary;
            font-size: 2.2em;
            padding:0 0 0 1rem;
            order: 2;
        }
        .feed-image { // shortlisted job, company logo
            order:2;
            width:25%;
            max-width:100px;
        }
        &:hover, &:active, &:focus {
            .feed-title {
                color:$romeo-secondary;
            }
        }
    }
    a.feed-link-more {
        color: #666;
        font-size:.8rem;
        strong {
            color:$romeo-primary;
            font-size:1rem;
            font-weight:700;
        }
        &:hover, &:active, &:focus {
            color:$romeo-secondary;
            strong {
                color:$romeo-secondary;
            }
        }
    }
    &.dash-feed-empty {  // if there aren't any bookings or favourties, text and links appear
        a.feed-link-more {
            padding: 1rem 0;
            border-top:1px solid #ddd;
            strong {
                font-size: 1rem;
                font-weight: 600;
            }
            &:last-child {
                padding-bottom:0;
            }
        }
        p {
            margin-bottom:1.5rem; // pushing the content down so it fills the boxes nicely
        } 
    }
}


// general layout stuff
.dash-jobs,
.dash-events,
.dash-placements,
.dash-pathways {
    .card {
        margin-bottom:1.5rem;
        height:auto;
        .card-footer {
            .btn {
                display:block;
                margin:0 auto;
            }
        }
    }
    .col-md-12.col-lg-6,
    .col-sm-12.col-lg-6,
    .col-sm-12.col-lg-4 {
        width:100%; // without this the cards don't go full width on small
    }
    @include media-breakpoint-up(lg) {
        .col-sm-12.col-lg-6,
        .col-md-12.col-lg-6 {
            max-width:50%; // hacky hack to get the cards working the same as BS4
            margin-bottom:1.5rem; // margin here rather than card because that messes with the flex height stuff
        }
        .col-sm-12.col-lg-4 {
            width: 33.33333333%;
            margin-bottom:1.5rem;
        }
        .card {
            margin-bottom:0;
            height:100%;
        }
    }
}

// jobs and events shared
.dash-jobs,
.dash-events {
    a.jobs-link,
    .events-link {
        text-decoration: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .jobs-item,
        .events-item {
            order: 1;
            flex-grow: 2;
            h3 {
                font-weight:500;
                font-size:1.2rem;
                color:$romeo-primary;
                margin:0 0 1.2rem 0;
                display:block;
            }
            span {    
                color:$default-text-color;
                display:block;
            }
            .jobs-employer,
            .events-date {    
                color:$default-text-color;
                font-weight:500;        
                display:block;
                margin-bottom:.25rem;
            }
            
            .jobs-details,
            .events-details {
                color:$default-text-color;
                display:block;
            }
        }               
    }
}

// jobs
.dash-jobs {
    a.jobs-link {
        .jobs-closing {
            color:$romeo-secondary;
            margin-top:.4rem;
            font-size:85%;
        }
        .jobs-image {
            padding:0 0 0 1rem;
            order: 2;
            max-width:150px;
            @include media-breakpoint-up(lg) {
               // width:35%; leaving out for now
            }
        } 
        .jobs-item span.label {
            display: inline-block;
        }
        .jobs-more-icon {
            position:absolute;
            bottom:10px;
            right:10px;
            color:$romeo-primary;
            font-size:1.1rem;
        }
        &:hover, &:active, &:focus {
            .jobs-item {
                h3 {
                    color:$romeo-secondary;
                }
            }
            .jobs-more-icon {
                color:$romeo-secondary;
            }
        }
    }
}


// events
.dash-events {
    .card {
        background:$romeo-primary;
        .card-body {
            background:transparent; //overriding a helpers thing!
        }
    }
    a.events-link {
        align-items: stretch;
        height: 100%;
        .events-item {
            padding-bottom: 2.4rem;
            order:2;
            h3,
            .events-date,
            .events-location,
            .events-datedetails,
            .events-more {
                color:white;
            }
            h3 {
                margin-bottom:0.5rem;
            }
            .events-location {
                opacity: .7;
            }
            .events-datedetails {
                margin: .4rem 0;
                font-size: .75rem;
            }
            .events-more {
                position: absolute;
                bottom: 1.25rem;
                font-size: 0.9rem;
                line-height: 1rem;
                width: calc(100% - 135px);
                background: rgba(255, 255, 255, 0.12);
                margin: -1.25rem;
                padding: 0.7rem 1.25rem;
                left: 155px; //not sure why I need this, must be a bs3 thing (not needed in foundation)
                .events-icon {
                    float:right;
                    margin-left: .3rem;
                    font-size: 1.1rem;
                }
            }
        }
        .events-date {
            display: flex; // so the date centers vertically
            align-items: center;
            order:1;
            flex-grow:1;
            background:white;
            margin:-1.25rem 1.25rem -1.25rem -1.25rem;
            padding:1.3rem;
            min-width:135px;
            max-width:135px;
            border:1px solid $romeo-primary;
            span {
                text-align: center;
                display:block;
                width: 100%;
            }
            .day {
                font-size: 1.5rem;
                font-weight: 600;
                color:$romeo-secondary;    
            }
            .weekday,
            .monthyear,
            .time {
                color:$default-text-color;
                font-size:.85rem;
            }
            .time {
                color:#666;
            }
        }
        &:hover, &:active, &:focus {
            .events-more {
                background:$romeo-secondary;
            }
        }
    }
}

// placements
.dash-placements {
    .row {
        justify-content: center;
    }  
    .card {
        .card-body {
            position:relative; // for the links at the bottom to line up.
            }
        a {
            text-decoration: none;
            color:$default-text-color;
            
            .placements-header { // using class bg-primary because it figures out the right text colour
                font-weight: 500;
                font-size: 1.2rem;
                margin:-1.25rem -1.25rem 0 -1.25rem; //bleading the heading/bg colour
                display: block;
                padding: 1.15rem 1.25rem 2.4rem;   
                background:$romeo-primary;   
                @include color-yiq($romeo-primary);      
                @include media-breakpoint-up(lg) {
                    min-height: 113px;
                    }
                }
            &.placements-link:nth-of-type(2) { // link to more  (used to be .placements-jobs (a <span> within the <a>) had to change so the focus outline worked 
                display:block;
                border-top: $romeo-hr;
                padding-top:.75rem;
                font-size: .9rem;
                margin-top: 1rem;
                color:$default-text-color;
                position: absolute;
                bottom: 20px;
                width:calc(100% - 2.5rem); // removing the extra padding on card-body and details
                line-height: 1rem; // making the text line up with the icon
                padding-right: 1.5rem; // making room for the arrow (for when it's long text)
                .placements-icon {
                    float: right;
                    margin-left:.3rem;
                    font-size:1.1rem;
                    color:$romeo-secondary;
                    margin-right:-1.5rem; // moving arrow inside padding above
                }
                strong {
                    font-size:1rem;
                    color:$romeo-secondary;
                }
            }
            &:hover, &:active, &:focus {
                .placements-jobs {
                    color:$romeo-secondary;
                }
            }
        } 
        .placements-details {
            display:block;
            background: #fff;
            padding:.85rem .85rem 3.65rem .85rem;
            margin-top:-1.25rem;
            position: relative;
            a {
                text-decoration: underline;
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
            @include media-breakpoint-up(lg) {
                .placements-desc {
                    height: 250px;
                    overflow: hidden;
                    display: block;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 50px;
                        bottom: 30px;
                        left: 0;
                        background-image: linear-gradient(to top, #fff, 80%, rgba(255,255,255,0));
                    }
                }
            }
        }       
    }
}

// logos (dashboard)
.dash-organisations {
    .logos {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .item {
            flex: 1 1 auto; 
            margin: 0 30px;
            text-align: center;
            display:none;
            img {
                width:100%;
                max-width: 180px;
            }
            &:nth-child(1), &:nth-child(2) {
                display:block;
            }
            @include media-breakpoint-up(md) {
                &:nth-child(3) {
                    display:block;
                }
            }
            @include media-breakpoint-up(lg) {
                &:nth-child(4) {
                    display:block;
                }
            }
            @include media-breakpoint-up(xl) {
                &:nth-child(5) {
                    display:block;
                }
            }
        }
    }
}


// publications (dashboard)
.dash-publications {
    .publications {
        .item {
            img {
                max-height:250px;
            }
        }
    }
}

// Pathways (dashboard)
.dash-pathways {
    .row {
        justify-content: center;
    }    
    .card {
        overflow: hidden;
        padding-bottom: 2.95rem;
        a {
            text-decoration: none;
            display: flex;
            &:hover, &:active, &:focus {
                .pathways-more {
                    color:$romeo-secondary;
                }
            }
        }
    }
    .pathways-image {
        position: relative;
        overflow: hidden;
        width: 150px;
        height: 150px;
        background-size: cover;
        background-position: center;
    }
    .pathways-title {
        font-size: 1.2rem;
        font-weight: 500;
    }
    .pathways-percentage {
        position: absolute;
        height: 120px;
        width: 120px;
        bottom: -70px;
        right: -70px;
        transform: rotate(-45deg);
        display: flex;
        justify-content: center;
        padding-top:5px;
    }
    .pathways-status {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 85%;
        font-weight: normal;
        padding: 0.4em .6em .45em .6em;
    }
    .pathways-more { //continue link
        border-top: $romeo-hr;
        display:block;
        font-size: 0.9rem;
        color:$default-text-color;
        position: absolute;
        bottom: 15px;
        left:1.25rem;
        width:calc(100% - 2.5rem); // removing the extra padding on card-body
        line-height: 1rem; // making the text line up with the icon
        padding: .75rem 0 0 0;
        .pathways-icon {
            float: right;
            font-size:1.2rem;
            color:$romeo-secondary;
        }
    }
    @include media-breakpoint-up(lg) {
        .card a {
            display: block;
            height:100%;
        }
        .pathways-image {
           width: 100%;
           height: 150px;
        }
    }
}


// CUSTOM HEADER
// Ang trying to recreate the bootstrap4 rows/cols for section at the top of the dashboard
// It gets complicated because all 3 boxes need to fill the space available, regardless of how much or little content each box has

.flex-dash-row {  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    flex: 1 0 auto;
    margin-left:-15px;
    margin-right:-15px;
    .flex-dash-col { 
        display: flex;
        padding-right: 15px;
        padding-left: 15px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex: 1 1 100%;
        @include media-breakpoint-up(lg) {
            flex: 1;  // don't really understand, but without this it makes the cards go full-width
        }
        .flex-dash-row { // crazy nesting, sorry! 
            margin-left: -15px; //Using minus margins makes it work, switching off padding makes the cards not be full width
            margin-right: -15px;
            .flex-dash-col {
                flex-basis: 100%;
            }
        }
    }
    @include media-breakpoint-up(lg) { //custom content
        .flex-dash-col-left {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .flex-dash-col-right { //feed
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
    }
    .flex-dash-card {
        min-height: 150px;
        flex: 1 0 auto;
    }
    @include media-breakpoint-up(lg) {
        .dash-card-custom {
            .card-body {
                max-height: 435px;
                overflow-x: auto;
            }
        }
    }
}



// CUSTOM PANE CONTENT - DEFAULT!
// targetjobs default content - updated by Ang 11/21 - see TC-23318 for more details

.custom-content-default { 
    height: 100%;
    width: 100%;
    display: flex;  /* flex so the image sticks to the bottom */
    flex-direction: column;
    justify-content: space-between;
    margin: -4.4rem 0 0 0;
    z-index: 1;
    background-color: #fff;
    padding: 1rem 0 0 0;
    min-height: 340px;
    @media (max-width:  425px) { 
        margin: -3rem 0 0 0; 
        padding: 0;
    }
    @include media-breakpoint-up(sm) {
        padding: 2rem 1rem 1rem 1rem;
    }
    @include media-breakpoint-up(md) {
        min-height: 460px;
    }
    @include media-breakpoint-up(lg) {
        // position:absolute;
        // top:0;
        // left:0;
        // margin:0;
        padding: 2.5rem 2rem 2rem 2rem;
    }
    .custom-heading, .custom-details {
        font-size: 1.5rem; 
        line-height: 1.4;
        font-weight: 800;   
        @include media-breakpoint-up(md) {
            font-size: 2rem; 
        }    
    }
    .custom-details {
        font-size: 1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.3rem; 
        }    
    }       
}

// CUSTOM PANE CONTENT !
// fancy dashboard content for the clients to use.  See TC-23426 for more details

.custom-content-client {
    .cp-btn {
        display: block;
        text-decoration: none !important;
        background-color:$romeo-primary;
        color:color-yiq-r($romeo-primary);
        &.cp-btn-secondary {
            background-color:$romeo-secondary;
            color:color-yiq-r($romeo-secondary);
            &:hover {
                background-color:$romeo-primary;
                color:color-yiq-r($romeo-primary);
                }
            }
        &:hover {
            background-color:$romeo-secondary;
            color:color-yiq-r($romeo-secondary);
            }
        } 
    .cp-link {
        text-decoration:none;
        position: relative;
        &:hover {
            color:$romeo-primary;
            .text-primary {
                color:$romeo-secondary !important;
                }
            .text-secondary {
                color:$romeo-primary !important;
                }
            }
        img, span {
            position:relative;  /* adding so that the span block can sit ontop of the image */
            }
        }
    .cp-link span {
        display: block;
        }
    .cp-link-overlay .cp-overlay-hover {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;  
        transition: .5s ease;
        background-color: $romeo-primary;
        opacity: 0;
        }
    .cp-link-overlay-secondary .cp-overlay-hover {
        background-color:$romeo-secondary;
        }
    a.cp-link:hover .cp-overlay-hover, a.cp-link:focus .cp-overlay-hover {
        opacity:.5;
        }   
    }

