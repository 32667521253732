.module-check-in {
	font-family:$font-family-base;
	min-height:100vh;
	background: $swipe-background;
	.navbar-collapse {
		display: flex;  // added to overrule  .collapse:not(.show) {display:none}
		}
	.navbar {
		min-height:auto;
		position:relative;
		}
	ul.nav.navbar-nav {
		display: flex;
	    justify-content: space-between;
	    content: close-quote;		
	    &:before, &:after {
	    	display: none;  			
	    }	
	}
	.content-body {
		font-size: 1rem;
        text-align: left;
		margin-top: 30px;
		padding: 30px;
		margin-bottom:70px;
		background: #fff;

		ol {
			display: inline-block;
            text-align: left;
            margin-top: 2rem;
		}
	}
	#swipeLoginForm {
		margin-top: 2rem;  

		button {
			&[name="_eventId"],
			&#cancelLoginButton {
				margin-top: 1rem;
			}
		}
	}
	.navbar {
		background: $swipe-header-bg;	
		border-bottom: 1px solid $gray-300;	
		> .container-fluid {
			padding:0px;
		}

		li.logo {
			margin-top: 0px;
		    padding-top: 0px;			
		}
		li {
			margin:0;
			padding:0;
		    display: flex;
		    flex-direction: column;
		    justify-content: center;			
			a {
				background:transparent;
			}
			&:hover, &:focus {
				background:transparent;
			}
		} 
		.navbar-brand {
		    display: block;
		    position: relative;
		    top: 0px;
		    right: 0px;
		    left: 0px;
		    padding: 25px 0;
		    &:hover, &:focus {
		    	background:transparent;
		    }
		}
	}
	.navbar-nav>li:last-child {
		margin-right:15px;
	}	
	.navbar-default {
		margin-top:0px;
		z-index: 20;
	}
	#logout-link { // reset link in header
	    font-size: 1.5rem;	
	    color:$swipe-header-text-color; 
		padding:15px 0;
	    &:hover, &:focus {
	    	background:transparent;
	    }   
	    i {
	    	font-size: inherit;
	    	margin-right:0px;
	    }
	}	
	.homepage {
		background: $swipe-body-bg; //main bg image
		background-size:cover;
		.homepage-content-container {
			display:flex;
			justify-content:center;
			.homepage-content {  // this is the 'tap here' block
				color: $swipe-content-text-color;
				background: $swipe-content-bg;
				padding: 20px;
				border-radius:10px;
				width:auto;
				text-align:center;
				h1 {
					color: $swipe-content-text-color;
					@include remConversion(font-size, 2rem);
					text-align:center;
				}
				p {
					@include remConversion(font-size, 1.3rem);
					text-align:center;
				}			
			}
		}
	}
	.form-control {
	    font-size: 18px;
	    height: 60px;
	    padding: 4px 13px;
	    margin-top: 0px;
	}	
	footer.footer {
		padding-top:10px;
		border:none;
		.container {
			position: relative;	
			display: flex;
    		justify-content: space-between;	
			align-items: center;
			&:after, &:before {
				display: none;
			}
		}	
		p {
			margin:0;
			font-size: .8rem;
			font-weight: normal;
		}	
		.groupgtilink img {
			width: 85px;
		}
	}
	.btn-lg {
    	padding: 25px 50px;
		border-radius: .9375rem;
	}
}