.romeo {

    // THIS IS ALL WORK IN PROGRESS!!!!!

    &.unauthorised {

        .content-body {
            @media(max-width: $screen-md){
                padding-left:2rem;
                padding-right:2rem;
            }   
        }

        // hacky hack to replace the banner image.  to be moved or removed
        .main-banner-outer {
            background-image: url('https://groupgti.com/sites/groupgti.com/files/public/homepage-panel-all-clear-new_large.png') !important;
            background-color: #062f4f;
            background-size: 1004px;
            background-repeat: repeat;
            background-position: 0 -284px;
            .main-banner-brand {
                display:none;
            }
            .main-banner-txt {
                padding:0;
                margin-top: -15px;
            }
        }

        // main-header (tc logo, service, cta's)
        .main-header {
            
            .navbar-cta {                
                .cta-logout,
                .cta-mail,
                .cta-user {
                    display:none;
                }
            }
            .form-inline { // maybe not the best approach to hide this, but meh!
                display:none !important;
            }

        }

        // main-nav
        .main-navbar-container {
            display:none;
        }


        // LOGIN PAGE!
        &.login {
            background-color:#062f4f !important;
            background-image: url('../../../../../../img/link-login-bg.png') !important;
            background-size: cover;
            background-position: 262px -152px;

            .main-header {
                background-color: white;
            }
            .main-banner-outer {
                display:none;
            }
            .content-body {
                background:transparent;
            }
        }
        // login forms - probably shouldn't be in here!
        .form-group-fancy {

            display: flex;
            flex-wrap: nowrap;
            align-items: baseline;

            label {
                margin-right:.9rem;
                font-size:1.1rem;
            }
            
            input.form-control {
                display:inline-block;
                box-shadow: none;
                border-top: none;
                border-right: none;
                border-left: none;
                border-bottom: 1px solid #ccc;
                padding-left: 0;
                border-radius: 0;
                font-size: 1rem;
                width:100%; // adding width because of crazy styling in custom css (input:-webkit-autofill), this must of been to do with the animating fields
            }

        } 

    }



    // THIS IS RELATING TO THE FORM - NEEDS MOVING!
    .section-title {
        &:before {
            display:none;
        }
    }

}