.widget-profile {
	h2 {
		border:none;
		font-size:26px;
		padding:0px;
		margin:0;
		span {
			font-family: "Proxima N W01 Light";
			font-size: 16px;		
		}
	}
	.widget-profile-logo {
		img {
			height: auto;
			width: 100%;
			padding:20px;
		}
		border:$content-block-border;
	}
	.widget-profile-items-container {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		width: 100%;
		flex-wrap:wrap;
		.widget-profile-items {
			display:flex;
			flex-grow:1;
			flex-direction:column;
			padding:0;
			margin-top:15px;
			border-right:1px solid #ccc;
			padding:0 20px;
			margin-bottom:0px;
			&:first-child {
				padding-left:0px;
			}			
			&:last-child {
				border:none;
				padding-right:0px;
			}
			.widget-profile-item {
				display:flex;
				justify-content:space-between;
				flex-direction:row;
				margin-bottom:7px;
				div {
					text-align:right;
					padding-left:10px;
				}
				label {
					padding:0px;
				}
				&:last-child {
					margin-bottom:0px;
				}
			}
		}
	}
}
.widget-block-profile {
	margin:0 -15px;
	border:none;
}