// .banner-profile-image {
//     background-size:cover;
//     height: 200px;   
//     @media(min-width:$screen-md){
//         height:500px;
//     }     
// }

// /***** FOR CUSTOM BANNER PRIMARILY IMAGES*****/

// .custom-banner-1 {
//     background: $custom-banner-1-background;
//     color: $custom-banner-1-text-color;
//     h1,h2,h3 {
//         color: $custom-banner-1-text-color;
//         letter-spacing: $custom-banner-1-letter-spacing;
//         font-weight: $custom-banner-1-font-weight;            
//     }       
// } 
// .custom-banner-2 {
//     background: $custom-banner-2-background;
//     color: $custom-banner-2-text-color;
//     h1,h2,h3 {
//         color: $custom-banner-2-text-color;
//         letter-spacing: $custom-banner-2-letter-spacing;
//         font-weight: $custom-banner-2-font-weight;            
//     }       
// } 