.widget-button-container {
	display:flex;
	justify-content: flex-end;
	@include remConversion(margin-top, 1.250);
	> a {
		@include remConversion(margin-left, 0.813);
		> i {
			padding-right:0rem;
		}		
	}
	> button {
		@include remConversion(margin-top, 0.75);
	}
}
.widget-secondary-description {
    font-style:italic;
    @include remConversion(font-size, multiply-rem($heading-secondary-font-size, $heading-small-percentage));
    margin-left:2px;
    color: lighten($text-font-color, 40%)
}
.widget-min-width {
    min-width:700px;
    overflow:scroll;
}
.widget-shadow {
    box-shadow: 1px 1px 6px -1px #000;
}

.dropdown.convert {
	margin:-7px 7px;
	.dropdown-menu {
	    position: absolute;
	    top: 35px;	
	    right:auto;   // changed from 0 due to this: TC-28176
	    left:auto;
	}
}
// .dropdown {
// 	a {
// 		&:active, &:hover, &:focus {
// 			text-decoration: underline;
// 		}
// 	}
// 	.dropdown-menu {
// 		a {
// 			color: $text-font-color;
// 			text-decoration: none;
// 		}
// 	}
// }
.widget-tabs-chevron {
	a._content-block-toggle {
		order: 3;
	}
}
.widget-container-image > img {
	max-width:100%;
}