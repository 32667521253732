.widget-pricing-simple {
	@extend .widget-block;
	padding:0px;
    display: flex;
    flex-direction: column;	
    margin-right:15px;
    margin-left:15px;    
	h2 {
		border-bottom:none;
		text-transform: initial;
		text-align:center;
		padding-top: 15px;
	    padding-bottom: 15px;
	    margin-bottom: 0px;		
	    text-transform:uppercase;
	}
	.widget-pricing-simple-cost {
		padding:60px 20px;
		text-align:center;
		@include remConversion(font-size, 3rem);
		font-weight:bold;
		background-image:url("../img/careers.jpg") !important;
		background-size: cover !important;
	    background-blend-mode: multiply;
	    &.image1 {
	    	background-image: url("https://image.freepik.com/free-photo/two-young-businessmen-with-a-report_1098-3513.jpg") !important;
	    }	
	    &.image2 {
	    	background-image: url("https://fthmb.tqn.com/1VKxgPavs2Q2qHS0zB4O8TqkCvc=/5616x3744/filters:no_upscale()/about/young-businessmen-and-businesswoman-smiling-and-cheering-109377964-56febb183df78c7d9e3de5db.jpg") !important;
	    }		
	    &.image3 {
	    	background-image: url("https://www.edgehill.ac.uk/news/files/2013/04/ProspDay4_0017.jpg") !important;
	    }		
	    &.image4 {
	    	background-image: url("http://freightbrain.co.uk/wp-content/uploads/2017/02/Graduation-Students2.jpg") !important;
	    }		            	
	}
	.widget-pricing-simple-details {
		margin-top:5px;
		ul {
			margin:0;
			padding:0;;
			list-style:none;
			li {
				border-bottom: $input-border;
				padding: 10px 20px;
				&:before {
				    content: "\f0da";
				    font-family: FontAwesome;	
				    padding-right:10px;				
				}
			}
		}
	}
	.widget-pricing-simple-button-container {
		padding-bottom:30px;
	    padding-top: 30px;
	    flex: 1;
	    justify-content: center;
	    align-items: flex-end;
	    display: flex;
	    flex-direction: row;		
	}
}