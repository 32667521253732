/******* COMPASS *********/

@import "../../node_modules/compass-sass-mixins/lib/compass/css3";
@import "../../node_modules/compass-sass-mixins/lib/compass/utilities";

/******* FontAwesome 5 *********/

$fa-font-path:        "fonts";


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-50:  #F9FAFA !default;
$gray-100: #f1f2f4 !default;
$gray-200: #e3e5e8 !default;
$gray-300: #d4d7dc !default;
$gray-400: #abb1ba !default;
$gray-500: #8f97a3 !default;
$gray-600: #737d8c !default;
$gray-700: #5c6670 !default;
$gray-800: #454c54 !default;
$gray-900: #2e3338 !default;
$gray-1000:#17191C !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "50": $gray-50,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
    "1000": $gray-1000
  ),
  $grays
);

$primary:         #017EB4 !default; // tc:green ##016275
$primary-muted:   #CCE5F0 !default;
$secondary:       #021273 !default;
$secondary-muted: #CCCFE3 !default;
$tertiary:        #3E1C95 !default; 
$tertiary-muted:  #D8D1E9 !default;
$quaternary:      #9F1143 !default;
$quaternary-muted:#EBCFD9 !default;
$success:         #08855F !default;
$success-muted:   #DFF9EF !default;
$info:            #027BC7 !default;
$info-muted:      #E0F1FE !default;
$warning:         #B75906 !default;
$warning-muted:   #FEEFC6 !default;
$danger:          #D92D20 !default;
$danger-muted:    #FEE4E2 !default; 
$light:             $gray-100 !default;
$dark:              $gray-800 !default;
$default:           $gray-300 !default;

$inferno-primary:         #4E21FF !default;
$inferno-primary-hover:   #3716BE !default;
$inferno-secondary:       #c10649 !default;
$inferno-secondary-hover: #ff2372 !default;
$inferno-warning:         #ffc107 !default;
$inferno-warning-200:     #fff3cd !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":          $primary,
    "primary-muted":    $primary-muted,
    "secondary":        $secondary,
    "secondary-muted":  $secondary-muted,
    "tertiary":         $tertiary,
    "tertiary-muted":   $tertiary-muted,
    "quaternary":       $quaternary,
    "quaternary-muted": $quaternary-muted,
    "success":          $success,
    "success-muted":    $success-muted,
    "info":             $info,
    "info-muted":       $info-muted,
    "warning":          $warning,
    "warning-muted":    $warning-muted,
    "danger":           $danger,
    "danger-muted":     $danger-muted,
    "light":            $light,
    "dark":             $dark,
    "default":          $default,
    "inferno-primary":        $inferno-primary,
    "inferno-secondary":      $inferno-secondary,
    "inferno-primary-hover":  $inferno-primary-hover,
    "inferno-secondary-hover":$inferno-secondary-hover,
    "inferno-warning":        $inferno-warning,
    "inferno-warning-200":    $inferno-warning-200,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;

// bloody text colours - needs removing!
$primary-text-color:      $white;
$primary-icon-color:      $white;
$secondary-text-color:    $white;
$secondary-icon-color:    $white;
$tertiary-text-color:     $white;
$tertiary-icon-color:     $white;
$quaternary-text-color:   $white;
$quaternary-icon-color:   $white;
$success-text-color:      $white;
$success-icon-color:      $white;
$success-muted-text-color:$black;
$success-muted-icon-color:$black;
$info-text-color:         $white;
$info-icon-color:         $white;
$info-muted-text-color:   $black;
$info-muted-icon-color:   $black;
$warning-text-color:      $white;
$warning-icon-color:      $white;
$warning-muted-text-color:$black;
$warning-muted-icon-color:$black;
$danger-text-color:       $white;
$danger-icon-color:       $white;
$danger-muted-text-color: $black;
$danger-muted-icon-color: $black;
$light-text-color:        $black;
$light-icon-color:        $black;
$dark-text-color:         $white;
$dark-icon-color:         $white;
$white-text-color:        $black;
$white-icon-color:        $black;
$text-muted:              $gray-700 !default;

// Options :: Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                false !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

$transition-base:             all .2s ease !default;

// Spacing :: Control the default styling of most Bootstrap elements by modifying these variables. Mostly focused on spacing. You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// Grid breakpoints :: Define the minimum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$screen-xs: 0px !default;
$screen-sm: 576px !default;
$screen-md: 768px !default;
$screen-lg: 992px !default;
$screen-xl: 1200px !default;

// switching from $breakpoints to grid-breakpoints
$grid-breakpoints:(
    'xs': $screen-xs,
    'sm': $screen-sm,
    'md': $screen-md,
    'lg': $screen-lg,
    'xl': $screen-xl
);
// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Components :: Define common padding and border radius sizes and more.
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;
$border-width:                1px !default;
$border-color:                $gray-300 !default;
$border-radius:               .5rem !default;
$border-radius-lg:            1rem !default;
$border-radius-sm:            .2rem !default;
$rounded-pill:                50rem !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$component-active-color:      $white !default;
$component-active-bg:         $primary !default;
$caret-width:                 .4em !default;
$caret-vertical-align:        $caret-width * .45 !default;
$caret-spacing:               $caret-width * .85 !default;
$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;
$hr-border-width:             2px !default;
$hr-border-color:             $gray-100 !default;

// Z-index master list :: Warning: Avoid customizing these values. They're used for a bird's eye view of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

// Body :: Page and content wrappers.
$body-bg:                      #fff;
$body-color:                    $gray-1000 !default;

// Links :: Style anchor elements.
$link-color:                  $primary !default;
$link-decoration:             none !default;
$link-hover-color:            $secondary !default;
$link-hover-decoration:       underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Typography :: Font, line-height, and color for body text, headings, and more.
$font-family-base:            'Poppins', sans-serif !default;
$font-family-sans-serif:      $font-family-base !default;
$font-family-monospace:       SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !default;
$font-size-base:              .9rem !default;
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          800 !default;
$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.4 !default;
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;
$dt-font-weight:              $font-weight-bold !default;
$paragraph-margin-bottom:     .65rem !default;
$small-font-size:             85% !default;
$tranlsation-case:            lowercase !default;

// Header :: Logo, parition, search, notifications and user.
$header-brand-bg:               $white !default; // #217dbb bg for toggle and logo
$header-bg:                     #fff !default; // bg for parition, search, user
$header-toggle-color:           $gray-800 !default; // inside brand bg
$header-toggle-hover-color:     $gray-800 !default; 
$header-input-bg:               $gray-100 !default; //search input bg  #ffffff30
$header-input-color:            $gray-800 !default; //search input text colour
$header-a-color:                $gray-800 !default; // partition and user dropdown text 
$header-a-hover-color:          $primary !default;
$header-icon-color:             $gray-700 !default; // profile and beamer icons
$header-box-shadow:             0 0 0 1px #14141f0d, 0 1px 3px 0 #14141f26 !default;
$header-height:                 116px !default;  // medium and below screens.   These variables are used to control margins on other elements!
$header-height-lg:              62px !default;  // large screens
$header-logo-height:            50px !default; 

// Navigation :: Widths and colours for the sidebar nav.
$nav-bg:                        $gray-900 !default;
$nav-dropdown-bg:               $gray-800 !default;
$nav-width:                     220px !default; //width of the standard sidebar
$nav-mini-width:                48px !default; //width of the mini/toggled sidebar
$nav-border-right:              1px solid $gray-200 !default;
$nav-a-font-size:               .9rem !default;
$nav-a-color:                   $gray-100 !default; //$gray-100
$nav-a-hover-bg:                $primary !default; //$header-brand-bg
$nav-a-hover-color:             $white !default; // needs replacing with color-yiq 
// Navs :: bs4 defaults
$nav-divider-color:                 $gray-200 !default;
$nav-divider-margin-y:              $spacer * .5 !default;

// Sub sub navigation :: Widths and colours for the 'work with' navbar.
$nav-sub-bg:                    $white !default;
$nav-sub-border:                1px solid #e1dede !default;  // top and bottom border
$nav-sub-a-font-size:           .85rem !default;
$nav-sub-a-color:               $gray-800 !default;
$nav-sub-a-hover-color:         $primary !default;

// tables :: header bgs and link colours
$table-link-color:              $link-color !default;
$table-link-decoration:         none !default;
$table-link-hover-color:        $link-hover-color !default;
$table-link-hover-decoration:   underline !default;
$table-hover-bg:                $gray-100 !default;

// Login/unauth pages :: covering the pages that aren't yet angular.
$login-background-mobile:       #fff !default;
$login-background-desktop:      url("/styles/dynamic/image/400200/cover.png") no-repeat right top/cover !default; //also used for swipecard
$login-header-bg:               #fff !default;
$login-footer-bg:               #fff !default;
$login-content-bg:              #fff !default;

// Swipecard :: 
$swipe-body-bg:                 $primary !default;
$swipe-background:              $gray-200 !default;  // bg behind the login form
$swipe-header-bg:               #fff !default;
$swipe-header-text-color:       $gray-800 !default;
$swipe-content-bg:              #000 !default; // 'tap here' block
$swipe-content-text-color:      #fff !default; 

// Tables :: Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;
$table-caption-color:         $text-muted !default;
$table-head-bg:               $gray-200 !default;
$table-head-color:            $gray-700 !default;
$table-th-font-weight:        null !default;

// Buttons :: For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y:                 .3rem  !default;
$btn-padding-x:                 .7rem !default;
$btn-font-size:                 .8rem !default;
$btn-line-height:               1.5 !default;
$btn-white-space:               null !default; // Set to `nowrap` to prevent text wrapping
$btn-border-width:              1 !default;   // needed for outline btns
$btn-font-weight:               500 !default;
$btn-box-shadow:                inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-disabled-opacity:          .65 !default;
$btn-active-box-shadow:         inset 0 3px 5px rgba($black, .125) !default;
$btn-link-disabled-color:       $gray-600 !default;
$btn-block-spacing-y:           .5rem !default;
$btn-border-radius:             $border-radius-sm !default;
$btn-transition:                color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Dropdowns :: Dropdown menu container and contents.
$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                0 !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                .85rem !default;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius-sm !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:               $gray-200 !default;
$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;
$dropdown-link-color:               $gray-900 !default;
$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:            $gray-200 !default;
$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $primary !default;
$dropdown-link-disabled-color:      $gray-500 !default;
$dropdown-item-padding-y:           .4rem !default;
$dropdown-item-padding-x:           .75rem !default;
$dropdown-header-color:             $gray-600 !default;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;

// Buttons + Forms :: shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;
$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;
$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      .75rem !default; // ang changed from $font-size-sm (probably a bad idea!)
$input-btn-line-height-sm:    $line-height-sm !default;
$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    $line-height-lg !default;
$input-btn-border-width:      $border-width !default;

// Buttons :: for each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping
$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;
$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;
$btn-border-width:            $input-btn-border-width !default;
$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;
$btn-link-disabled-color:     $gray-600 !default;
$btn-block-spacing-y:         .5rem !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Forms :: bs4 defaults
$label-margin-bottom:                   .5rem !default;
$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;
$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;
$input-line-height-sm:                  $input-btn-line-height-sm !default;
$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;
$input-line-height-lg:                  $input-btn-line-height-lg !default;
$input-bg:                              $white !default;
$input-disabled-bg:                     $gray-200 !default;
$input-color:                           $gray-700 !default;
$input-border-color:                    $gray-300 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;
$input-border-radius:                   .25rem !default;
$input-border-radius-lg:                $border-radius-lg !default;
$input-border-radius-sm:                $border-radius-sm !default;
$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              lighten($component-active-bg, 25%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;
$input-placeholder-color:               $gray-700 !default;
$input-plaintext-color:                 $body-color !default;
$input-height-border:                   $input-border-width * 2 !default;
$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5) !default;
$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;
$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$form-text-margin-top:                  .25rem !default;
$form-check-input-gutter:               1.25rem !default;
$form-check-input-margin-y:             .2rem !default;
$form-check-input-margin-x:             .25rem !default;
$form-check-inline-margin-x:            .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;
$form-grid-gutter-width:                10px !default;
$form-group-margin-bottom:              1rem !default;
$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-200 !default;
$input-group-addon-border-color:        $input-border-color !default;
$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$custom-control-gutter:                 .5rem !default;
$custom-control-spacer-x:               1rem !default;
$custom-control-cursor:                 null !default;
$custom-control-indicator-size:         1rem !default;
$custom-control-indicator-bg:           $input-bg !default;
$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-700 !default;
$custom-control-indicator-border-width: $input-border-width !default;
$custom-control-label-color:            null !default;
$custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
$custom-control-label-disabled-color:           $gray-600 !default;
$custom-control-indicator-checked-color:        $component-active-color !default;
$custom-control-indicator-checked-bg:           $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg:  rgba($primary, .5) !default;
$custom-control-indicator-checked-box-shadow:   null !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;
$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color:   $input-focus-border-color !default;
$custom-control-indicator-active-color:         $component-active-color !default;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow:    null !default;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;
$custom-checkbox-indicator-border-radius:       $border-radius !default;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow:   null !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;
$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;
$custom-switch-width:                     $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius: $custom-control-indicator-size * .5 !default;
$custom-switch-indicator-size:          subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;
$custom-select-padding-y:               $input-padding-y !default;
$custom-select-padding-x:               $input-padding-x !default;
$custom-select-font-family:         $input-font-family !default;
$custom-select-font-size:           $input-font-size !default;
$custom-select-height:              $input-height !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight !default;
$custom-select-line-height:         $input-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $gray-200 !default;
$custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-800 !default;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background:          escape-svg($custom-select-indicator) right $custom-select-padding-x center / $custom-select-bg-size no-repeat !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;
$custom-select-border-width:        $input-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       $border-radius !default;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;
$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-width:         $input-focus-width !default;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color !default;
$custom-select-padding-y-sm:        $input-padding-y-sm !default;
$custom-select-padding-x-sm:        $input-padding-x-sm !default;
$custom-select-font-size-sm:        $input-font-size-sm !default;
$custom-select-height-sm:           $input-height-sm !default;
$custom-select-padding-y-lg:        $input-padding-y-lg !default;
$custom-select-padding-x-lg:        $input-padding-x-lg !default;
$custom-select-font-size-lg:        $input-font-size-lg !default;
$custom-select-height-lg:           $input-height-lg !default;
$custom-range-track-width:          100% !default;
$custom-range-track-height:         .5rem !default;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             $gray-300 !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;
$custom-range-thumb-width:                   1rem !default;
$custom-range-thumb-height:                  $custom-range-thumb-width !default;
$custom-range-thumb-bg:                      $component-active-bg !default;
$custom-range-thumb-border:                  0 !default;
$custom-range-thumb-border-radius:           1rem !default;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg:             $gray-500 !default;
$custom-file-height:                $input-height !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-focus-box-shadow !default;
$custom-file-disabled-bg:           $input-disabled-bg !default;
$custom-file-padding-y:             $input-padding-y !default;
$custom-file-padding-x:             $input-padding-x !default;
$custom-file-line-height:           $input-line-height !default;
$custom-file-font-family:           $input-font-family !default;
$custom-file-font-weight:           $input-font-weight !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;

// Form validation :: BS4 default
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $small-font-size !default;
$form-feedback-valid-color:         $success !default; //theme-color("success") ;
$form-feedback-invalid-color:       $danger !default; //theme-color("danger")
$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
$form-validation-states: () !default;

$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);

// Badges :: bs4 defaults
$badge-font-size:                   75% !default;
$badge-font-weight:                 $font-weight-semibold !default;
$badge-padding-y:                   .25rem !default;
$badge-padding-x:                   .75rem !default;
$badge-border-radius:               $border-radius !default;
$badge-transition:                  $btn-transition !default;
$badge-focus-width:                 $input-btn-focus-width !default;
$badge-pill-padding-x:              .65rem !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          .5rem !default;

// Modals :: bs4 defaults
$modal-inner-padding:                   1rem !default; // Padding applied to the modal body
$modal-footer-margin-between:           .5rem !default; // Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-dialog-margin:                   .5rem !default;
$modal-dialog-margin-y-sm-up:           1.75rem !default;
$modal-title-line-height:               $line-height-base !default;
$modal-content-color:                   null !default;
$modal-content-bg:                      $white !default;
$modal-content-border-color:            rgba($black, .05) !default;
$modal-content-border-width:            $border-width !default;
$modal-content-border-radius:           $border-radius !default;
$modal-content-inner-border-radius:     subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:         rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0, rgba(0, 0, 0, 0.14) 0px 2px 6px 0px !default;
$modal-content-box-shadow-sm-up:        $modal-content-box-shadow-xs;
$modal-backdrop-bg:                     $black !default;
$modal-backdrop-opacity:                .5 !default;
$modal-header-border-color:             $border-color !default;
$modal-footer-border-color:             $modal-header-border-color !default;
$modal-header-border-width:             0 !default;
$modal-footer-border-width:             $modal-header-border-width !default;
$modal-header-padding-y:                1rem !default;
$modal-header-padding-x:                1rem !default;
$modal-header-padding:                  $modal-header-padding-y $modal-header-padding-x 0.5rem $modal-header-padding-x !default; // Keep this for backwards compatibility
$modal-xl:                              1140px !default;
$modal-lg:                              900px !default;
$modal-md:                              600px !default;
$modal-sm:                              300px !default;
$modal-fade-transform:                  translate(0, -50px) !default;
$modal-show-transform:                  none !default;
$modal-transition:                      transform .3s ease-out !default;
$modal-scale-transform:                 scale(1.02) !default;

// nav tabs/pills - bs4 defaults
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            .3rem !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $primary !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;

// Tooltips
$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 .25rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 !default;
$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;
// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
$form-feedback-tooltip-line-height:   $line-height-base !default;
$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// Scheduler/calendar - status colours
$calSessionTextColor:     #fff !default;
$calSessionTextColorDark: $gray-1000 !default;
$calSessionCreated:       $gray-200 !default;
$calSessionBooked:        $success !default;
$calSessionMissed:        $danger !default;
$calSessionArrived:       $tertiary !default; // #905cad
$calSessionAttended:      $primary !default;  // primary
// muted colours for calendar
$calSessionBookedMuted:   $success-muted !default;
$calSessionMissedMuted:   $danger-muted !default;
$calSessionArrivedMuted:  $tertiary-muted !default; 
$calSessionAttendedMuted: $primary-muted !default; 


/** ANG - variables that are NEEDED (referenced all over the place **/
$content-background: #fff  !default;  //used a lot in the variables below
$container-background: #fff  !default; 
$a-tag-color: $primary !default;
$a-tag-color-hover: $secondary !default;
$header-item-text-color:#000 !default; // needed for swipe
$widget-a-tag-color: $body-color !default;
$content-font-weight:300;
$text-font: $font-family-base !default;
$text-font-size: $font-size-base !default;
$text-font-color:$body-color !default;
$input-font-size: $font-size-base !default;

/******* BACKGROUND & CONTENT *********/

$content-padding-top-mobile: 30px !default;
$content-padding-right-mobile: 15px !default;
$content-padding-bottom-mobile: 15px !default;
$content-padding-left-mobile: 15px !default;
$content-padding-top-desktop: 30px !default;
$content-padding-right-desktop: 15px !default;
$content-padding-bottom-desktop: 15px !default;
$content-padding-left-desktop: 15px !default;
$content-background-image-size:cover !default;
$content-padding-mobile: $content-padding-top-mobile $content-padding-right-mobile $content-padding-bottom-mobile $content-padding-left-mobile !default;
$content-padding-desktop: $content-padding-top-desktop $content-padding-right-desktop $content-padding-bottom-desktop $content-padding-left-desktop !default;

/******* SPACING - MARGINS & PADDING ******/

$xxs-size: 0.125rem !default;
$xxs-vertical: 0.125rem !default;
$xxs-horizontal: 0.3125rem !default;

$xs-size: 0.3125rem !default;
$xs-vertical: 0.125rem !default;
$xs-horizontal: 0.3125rem !default;

$sm-size: 0.625rem !default;
$sm-vertical: 0.1875rem !default;
$sm-horizontal: 0.4375rem !default;

$reg-size: 0.9375rem !default;
$reg-vertical: 0.3125rem !default; 
$reg-horizontal: 0.5rem !default;

$md-size: 1.25rem !default;
$md-vertical: 0.5rem !default;
$md-horizontal: 0.625rem !default;

$lg-size: 1.5625rem !default;
$lg-vertical: 0.625rem !default;
$lg-horizontal: 0.875rem !default;

$xl-size: 1.875rem !default;
$xl-vertical: 0.75rem !default;
$xl-horizontal: 0.875rem !default;

$xxl-size: 2.1875rem !default;
$xxl-vertical: 0.9375rem !default;
$xxl-horizontal: 1.125rem !default;

$xxxl-size: 2.5rem !default;
$xxxl-vertical: 1.125rem !default;
$xxxl-horizontal: 1.325rem !default;

$xxxxl-size: 2.8125rem !default;
$xxxxl-vertical: 1.2255rem !default;
$xxxxl-horizontal: 1.525rem !default;

$xxxxxl-size: 3.438rem !default;
$xxxxxl-vertical: 1.5255rem !default;
$xxxxxl-horizontal: 1.525rem !default;

$card-size: 1.25rem !default;
$card-vertical: .75rem !default;
$card-horizontal: 1.25rem !default;

$xxxxxxl-size: 4.688rem !default;
$xxxxxxl-vertical: 2.5255rem !default;
$xxxxxxl-horizontal: 2.525rem!default;

$xxxxxxxl-size: 6.25rem !default;
$xxxxxxxl-vertical: 3.5255rem !default;
$xxxxxxxl-horizontal: 3.525rem !default;

$xxxxxxxxl-size: 9.375rem !default;
$xxxxxxxxl-vertical: 4.5255rem !default;
$xxxxxxxxl-horizontal: 4.525rem !default;


/******* HEADINGS *******/

$heading-font: $font-family-base !default;
$heading-small-percentage: 0.75 !default; // This number is multiplied my the heading font size to create the child "small" tag & class

$heading-primary-font: $heading-font !default;
$heading-primary-font-size:1.3125rem !default;
$heading-primary-margin: 0 0 $md-size 0 !default;
$heading-primary-font-transform:Initial !default;
$heading-primary-font-weight:600 !default;

$heading-secondary-font: $heading-font !default;
$heading-secondary-margin: $sm-size 0 $reg-size 0 !default;
$heading-secondary-font-size:1.125rem !default;
$heading-secondary-font-transform:Initial !default;
$heading-secondary-font-weight:600 !default;

$heading-tertiary-font: $heading-font !default;
$heading-tertiary-font-size:0.9375rem !default;
$heading-tertiary-margin: $sm-size 0 $reg-size 0 !default;
$heading-tertiary-font-transform:Initial !default;
$heading-tertiary-font-weight:600 !default;

$heading-quaternary-font: $font-family-base !default;
$heading-quaternary-font-size: 0.875rem !default;
$heading-quaternary-margin: 0 !default;
$heading-quaternary-font-transform:Initial !default;
$heading-quaternary-font-weight:600 !default;

$heading-quinary-font: $font-family-base !default;
$heading-quinary-font-size:0.75rem !default;
$heading-quinary-margin: 0 !default;
$heading-quinary-font-transform:Initial !default;
$heading-quinary-font-weight:600 !default;

$heading-senary-font: $font-family-base !default;
$heading-senary-font-size: 0.6875rem !default;
$heading-senary-margin: 0 !default;
$heading-senary-font-transform:Initial !default;
$heading-senary-font-weight:600 !default;

/******* MAIN THEME COLOURS *******/

$black: #000 !default;
$white: #fff !default;

$default-background-color: #CCCCCC !default;
$default-text-color: #17191c !default;
$default-icon-color: lighten($default-background-color, 5%) !default;

$basic-background-color: transparent !default;
$basic-text-color: #fff !default;
$danger-muted-icon-color: darken($basic-background-color, 5%) !default;

// old school! 
$text-root-font-size: 16px !default;  
$bootstrap-size: 15px / $text-root-font-size !default;
$bootstrap-vertical: 15px / $text-root-font-size !default;
$bootstrap-horizontal: 15px / $text-root-font-size !default;
$bootstrap-size2x: (15px / $text-root-font-size) * 2 !default;
$bootstrap-vertical2x: (15px / $text-root-font-size) * 2 !default;
$bootstrap-horizontal2x: (15px / $text-root-font-size) * 2 !default;

/******* CUSTOM COLORS ********/

$custom-background-color-1: #333 !default;
$custom-text-color-1: #333 !default;
$custom-icon-color-1: #333 !default;

$custom-background-color-2: #333 !default;
$custom-text-color-2: #333 !default;
$custom-icon-color-2: #333 !default;

$custom-background-color-3: #333 !default;
$custom-text-color-3: #333 !default;
$custom-icon-color-3: #333 !default;

$custom-background-color-4: #333 !default;
$custom-text-color-4: #333 !default;
$custom-icon-color-4: #333 !default;

$custom-background-color-5: #333 !default;
$custom-text-color-5: #333 !default;
$custom-icon-color-5: #333 !default;

$custom-background-color-6: #333 !default;
$custom-text-color-6: #333 !default;
$custom-icon-color-6: #333 !default;

$custom-background-color-7: #333 !default;
$custom-text-color-7: #333 !default;
$custom-icon-color-7: #333 !default;

$custom-background-color-8: #333 !default;
$custom-text-color-8: #333 !default;
$custom-icon-color-8: #333 !default;

$custom-background-color-9: #333 !default;
$custom-text-color-9: #333 !default;
$custom-icon-color-9: #333 !default;

$custom-background-color-10: #333 !default;
$custom-text-color-10: #333 !default;
$custom-icon-color-10: #333 !default;

$custom-background-color-11: #333 !default;
$custom-text-color-11: #333 !default;
$custom-icon-color-11: #333 !default;

$custom-background-color-12: #333 !default;
$custom-text-color-12: #333 !default;
$custom-icon-color-12: #333 !default;

$custom-background-color-13: #333 !default;
$custom-text-color-13: #333 !default;
$custom-icon-color-13: #333 !default;

$custom-background-color-14: #333 !default;
$custom-text-color-14: #333 !default;
$custom-icon-color-14: #333 !default;

$custom-background-color-15: #333 !default;
$custom-text-color-15: #333 !default;
$custom-icon-color-15: #333 !default;

$custom-background-color-16: #333 !default;
$custom-text-color-16: #333 !default;
$custom-icon-color-16: #333 !default;

$custom-background-color-17: #333 !default;
$custom-text-color-17: #333 !default;
$custom-icon-color-17: #333 !default;

$custom-background-color-18: #333 !default;
$custom-text-color-18: #333 !default;
$custom-icon-color-18: #333 !default;

$custom-background-color-19: #333 !default;
$custom-text-color-19: #333 !default;
$custom-icon-color-19: #333 !default;

$custom-background-color-20: #333 !default;
$custom-text-color-20: #333 !default;
$custom-icon-color-20: #333 !default;

/******* INPUTS ********/

$input-background-mobile: transparent !default;
$input-background-desktop: darken($content-background, 5%) !default;
$input-border-radius: 0.9375rem !default;
$input-animation-form-control-background: transparent !default; // Background for form-control on the slide right input animation 
$input-border: 0.0625rem solid darken($content-background, 15%) !default;
//$input-placeholder-color: darken($content-background, 50%) !default;

/** checkboxes/radios **/
$input-check-background-color: $primary !default;
$input-check-border: 1px solid $primary !default;
$input-check-inactive-background-color: #fff !default;
$input-check-inactive-border: 1px solid #ccc !default;
$input-check-hover-background-color: #fff !default;
$input-check-hover-border: 1px solid #666 !default;
$input-check-text-color: #fff !default;      
$input-check-icon-color: #fff !default;  


/******* BUTTONS ********/
$button-border-radius: $input-border-radius !default;
$button-pricing-title-font-size: 1.8 !default;
$button-font-size:0.9 !default;

/******* PILLS ********/
$pill-border-radius: $input-border-radius !default;
$pill-background-color: $primary !default;
$pill-text-color: $primary-text-color !default;  

/******* BADGES ********/
// $badge-font-size:0.8 !default;
// $badge-backgound-color: $primary !default;
// $badge-text-color: $primary-text-color !default;
// $badge-border-radius: $input-border-radius !default;
// $badge-color: $primary-text-color;

/******* LABELS *******/

$label-success-color:$success !default;
// $label-text-color:$success-text-color !default;

/******* FORM-WIZARD *******/

$form-wizard-inactive-background-color:#3B5055 !default;
$form-wizard-color: darken($form-wizard-inactive-background-color, 20%) !default !default;
$form-wizard-background: $form-wizard-inactive-background-color !default;

/******* ITEM-LINE-CENTER *******/

$item-line-center-mobile: #fff !default;
$item-line-center-desktop: $body-color !default;


/******* CONTENT BLOCK & WIDGETS *******/

$content-block-background-color: #fff !default;
$content-block-border: 0.0625rem solid darken($content-block-background-color, 15%) !default;
$content-block-text-color: $body-color !default;

$widget-primary-background: $primary !default;
$widget-secondary-background: $secondary !default;
$widget-tertiary-background: $tertiary !default;
$widget-quaternary-background: $quaternary !default;

$widget-primary-color: $primary-text-color !default;
$widget-secondary-color: $secondary-text-color !default;
$widget-tertiary-color: $tertiary-text-color !default;
$widget-quaternary-color: $quaternary-text-color !default;

/******* PROFILE COLUMN ********/

$profile-column-border: $input-border !default;

/****** SIDE MENU *******/

$side-menu-body-background:#938F8F !default;
$side-menu-navigation-background: $content-background !default;

/******* TABLE *******/

$table-header-background-color: $secondary !default;
$table-header-text-color: $secondary !default;  //$secondary-text-color

$table-header-results-background-color: darken($table-header-background-color, 20%) !default;
$table-header-results-text-color: $table-header-text-color !default;

$table-header-sidebar-background-color: $table-header-results-background-color !default;
$table-header-sidebar-text-color: $table-header-results-text-color !default;

$table-sidebar-heading-background-color: $table-header-results-background-color !default;
$table-sidebar-heading-text-color: $table-header-results-text-color !default;

$table-achor-link-color: $a-tag-color !default;
$table-border: $input-border !default;

$sidebar-background: darken($content-block-background-color, 5%) !default;
$sidebar-list-group-background: darken($sidebar-background, 10%) !default;
$sidebar-heading-secondary-font-size: $text-font-size !default;
$side-bar-label: 0.6875rem !default;
$sidebar-background:darken($content-background,7%) !default;

/******* SEARCH FILTER BLOCK ******/

$search-filter-container-background: darken($content-background, 5%) !default;
$search-filter-container-text-size: 0.8rem !default;

$search-filter-container-collapse-background-color: $content-block-text-color !default;
$search-filter-container-collapse-text-color: $content-block-background-color !default;

$search-filter-container-viewmore-background-color:$search-filter-container-collapse-background-color !default;
$search-filter-container-viewmore-text-color: $search-filter-container-collapse-text-color !default;

$search-filter-container-tab-border-color: $primary !default;
$search-filter-container-tab-mobile-menu-background-color: $primary !default;
$search-filter-container-tab-mobile-menu-text-color: $primary-text-color !default;
$search-filter-container-tab-mobile-menu-background-active-color: lighten($search-filter-container-tab-mobile-menu-background-color, 10%) !default;
$search-filter-container-tab-mobile-menu-text-active-color: $primary-text-color !default;

$search-results-pagination-item-background-color: transparent !default;
$search-results-pagination-item-text-color: $body-color !default;
$search-results-pagination-item-border-color: $primary !default;
$search-results-pagination-item-border-radius: $input-border-radius !default;
$search-results-pagination-item-hover-background-color: lighten($primary, 5%) !default;
$search-results-pagination-item-hover-text-color: $primary !default; //$primary-text-color
$search-results-pagination-item-hover-border-color: darken($primary, 5%) !default;
$search-results-pagination-item-active-background-color: darken($primary, 5%) !default;
$search-results-pagination-item-active-text-color: $primary !default;  //$primary-text-color
$search-results-pagination-item-active-border-color: darken($primary, 5%) !default;

/******* TC Specific *******/

$sub-heading-text-color: $primary !default;

/***** DIVIDER ******/

$divider-background-color: darken($content-background, 20%) !default;

/***** CUSTOM TCL LOGIN PAGE *****/

$login-background-body-desktop: url("https://images1.groupgti.com/themes/employerlink/background.jpg") !default;
$login-background-mobile: url("https://images1.groupgti.com/themes/employerlink/background.jpg") !default;
$login-side-background: transparent !default;
$login-button-simple-square: #3B5055 !default;
$login-price-colour: #ED1D25 !default;
$login-button-simple-square-login: $login-button-simple-square !default;
$login-logo-custom-size: 100px !default;

/***** PATHWAYS *****/

$pathways-type-colour-placement: #A61E0C;
$pathways-type-colour-award: #016275;
$pathways-type-colour-assessment: #062f4f;
$pathways-type-colour-basic: #395f6f;
$pathways-type-colour-support: #821358;
$pathways-type-colour-learning:#2178AE;
$pathways-type-colour-skills:#005F74;

/***** PRE REGISTRATION *****/

$pre-registration-main: #333 !default;
$pre-registration-text: #333 !default;
$pre-registration-header-background:transparent !default;
$pre-registration-footer-background:transparent !default;
$pre-registration-pagination: darken($pre-registration-main, 20%) !default;
$pre-registration-background: url("https://media.gettyimages.com/photos/silhouette-of-a-man-and-digital-tablet-with-galaxy-overlaid-picture-id491748268") !default;
$pre-registration-background-size: cover !important !default;
$pre-registration-background-position: 350px 0px !default;
$pre-registration-logo-size:100px !default;
$pre-registration-background-repeat: no-repeat !default;
$pre-registration-border-bottom: none !default;
$pre-registration-margin: 40px 0 0 -40px !default;


/****** ROMEO VARIABLES ******/
// do not use $romeo-primary or $romeo-secondary in here as it wont pick up the theme colours (it will use the colours in here).

// greys from the gti branding
$palette-grey-1000: #17191c !default;
$palette-grey-900: #2e3338 !default;
$palette-grey-800: #454c54 !default;
$palette-grey-700: #5c6470 !default;
$palette-grey-600: #737d8c !default;
$palette-grey-500: #8f97a3 !default;
$palette-grey-400: #abb1ba !default;
$palette-grey-300: #c7cbd1 !default;
$palette-grey-200: #e3e5e8 !default;
$palette-grey-100: #f1f2f4 !default;
$palette-grey-50: #f9fafa !default;

$gray-500: #adb5bd !default;   // to be phased out
$gray-box-shadow-nav: #383737 !default;

// base colours
$romeo-primary: $primary !default;
$romeo-secondary: $secondary !default;
$romeo-tertiary: $tertiary !default;
$romeo-quaternary: $quaternary !default;
$romeo-success: $success !default;
$romeo-danger: $danger !default;
$romeo-warning: $warning !default;
$romeo-info: $info !default;
$romeo-dark: $dark !default;
$romeo-light: $light !default;

$romeo-body-bg: $white !default;
$romeo-body-color: $default-text-color !default;  
$romeo-grid-gutter-width: 30px !default; 
$romeo-table-header-bg-color: $romeo-secondary !default;
$romeo-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.3) !default;
$romeo-box-shadow-light: 0px 1px 4px rgba(0, 0, 0, 0.16) !default;
$romeo-hr: 1px solid #dcdcdc !default;

// card standouts
$romeo-card-border-standout-color: $romeo-primary !default;
$romeo-corner-standout-width: 55px !default; // warning, changing this will throw out positioning of anything inside the standout
$romeo-corner-standout-color: $romeo-primary !default;

// header (with TC logo), navbar ctas, service selector and navbar toggle
$romeo-main-header-bg: #fff !default;
$romeo-main-header-icon-color: $default-text-color !default;   // changed from $romeo-primary because it wasn't working

// header banner 
$romeo-main-banner-bg-position: center !default; 
$romeo-main-banner-text-color: #fff !default;
$romeo-main-banner-text-shadow: 0px 1px 1px rgba(0,0,0,0.4) !default;

// header client logo
$romeo-main-banner-brand-bg: $white !default; 
$romeo-main-banner-brand-border-color: $white !default;
$romeo-main-banner-brand-box-shadow: 0px 1px 1px rgba(0,0,0,0.4) !default;
$romeo-main-banner-brand-padding: 0 0 !default;  // use this to control the size of the logo 

// client logo, when the logo is white, use a bg colour (ideally primary/secondary)
$romeo-logo-bg-color: transparent !default;
$romeo-link-clientlogo-bg-color: #fff !default; // Link > choose audience 

// navigation
$romeo-nav-items: $default-text-color !default;
$romeo-nav-items-hover: $romeo-primary !default;

//link colours
$romeo-link-color: $romeo-body-color !default;
$romeo-link-hover-color: $primary !default;
$romeo-link-decoration: underline !default;
$romeo-link-hover-decoration: none !default;

// font weights
$romeo-font-weight-lighter: 300 !default;
$romeo-font-weight-light: 400 !default;
$romeo-font-weight-normal: 500 !default;
$romeo-font-weight-semibold: 600 !default;
$romeo-font-weight-bold: 700 !default;
$romeo-font-weight-bolder: 800 !default;

// footer
$romeo-footer-bg: #fff !default;
$romeo-footer-text-color: $gray-1000 !default;
$romeo-footer-nav-items: $gray-1000 !default;
$romeo-footer-nav-items-hover: $primary !default; 