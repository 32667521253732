//////////////////////////////////////////////
// Flow view
// Originating in the Pathways module, this view allows for a 'flow' of flow-level containers.
// These flow-levels hold flow-items, and those flow-items can contain flow-icons of various types.
//////////////////////////////////////////////

.flow-view
{
	.round-button
	{
		box-shadow: 0px 1px 2px #555555;
		cursor: pointer;
		transition: all 0.25s;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&.small-rb
		{
			height: 30px;
			width: 30px;

			i
			{
				font-size: 14px;
			}
		}

		&.light-rb
		{
			background: #ffffff;
			color: #565656;
		}

		&.grey-rb
		{
			background: #f6f6f6;
			color: #b3b3b3;
		}

		&:hover, &:focus
		{
			box-shadow: 0px 1px 12px #555555;
		}

		&.disabled
		{
			box-shadow: none;

			&:hover, &:focus
			{
				box-shadow: none;
				cursor: default;
			}
		}
	}

  .flow-level
  {
    display: inline-block;
    width: 100%;
    position: relative;
		@extend .top-padding-xxxl;
		@extend .bottom-padding-xxxl;

		.top-right-rb
		{
			position: absolute;
      top: 10px;
      right: 10px;
		}

		.top-left-rb
		{
			position: absolute;
      top: 10px;
      left: 10px;
		}

		.bottom-center-rb
		{
			position: absolute;
			bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
		}

		.bottom-center-right-rb
		{
			@extend .bottom-center-rb;
			bottom: -15px;
			margin-left: 55px;
		}

		.bottom-center-righter-rb
		{
			@extend .bottom-center-rb;
			bottom: -15px;
			margin-left: 100px;
		}

		.bottom-center-left-rb
		{
			@extend .bottom-center-rb;
			bottom: -15px;
			margin-left: -55px;
		}

		.bottom-center-lefter-rb
		{
			@extend .bottom-center-rb;
			bottom: -15px;
			margin-left: -100px;
		}

		.next-level-rb
		{
			background: #f6f6f6;
			color: #b3b3b3;
		}

    &:nth-child(even)
    {
			background: #f6f6f6;

      .next-level-rb
      {
        background-color: #ffffff;
      }

      .flow-item.selected
      {
        @media(min-width: $screen-md)
        {
          background-color: #e3e3e3;
          box-shadow: inset 0 0 10px #d6d6d6;
        }
      }
    }

		&:first-child
		{
			.hide-in-first-level
			{
				display: none !important;
			}
		}

		&:last-child
		{
			.hide-in-last-level
			{
				display: none !important;
			}
		}
  }

  .flow-item
  {
    display: inline-block;
    width: 50%;
    max-width: 200px;
    min-width: 125px;
    vertical-align: top;
    padding: 15px 10px;
    margin: 5px 0;
    position: relative;
    border-radius: 3px;
		box-shadow: none;

		&.flow-control-item
		{
			.round-button
			{
				margin: 12.5px auto;
			}
		}

    .flow-item-controls
    {
			position: absolute;
			height: 85px;
			width: 125px;
			margin: auto;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;

      i
      {
        font-size: 20px;
      }

      .flow-icon-inner-icon
      {
        position: absolute;
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i
        {
          font-size: 14px;
          padding: 0;
        }

				&.flow-icon-inner-icon-top-right
				{
					top: 0;
					right: 0;
				}

        &.flow-icon-inner-icon-top-left
        {
					top: 0;
          left: 0;
        }

        &.flow-icon-inner-icon-bottom-left
        {
          left: 0;
					bottom: 0;
        }

				&.flow-icon-inner-icon-bottom-right
				{
					right: 0;
					bottom: 0;
				}
      }
    }

    .flow-item-title
    {
      display: inline-block;
      margin: 10px 0 0;
    }

    .flow-item-subtitle
    {
      font-size: 12px;
      margin-top: 5px;

      i
      {
        font-size: 10px;
      }
    }
  }

	&.flow-read-only
	{
		.flow-level
		{
			.flow-item
			{
				cursor: pointer;

				&.locked
				{
					opacity: 0.35;
					cursor: default;
				}

				&.selected
				{
					@media(min-width: $screen-md)
					{
						background-color: #f3f3f3;
						box-shadow: inset 0 0 10px #e0e0e0;
					}
				}

				.round-button
				{
					box-shadow: none;

					&:hover, &:focus
					{
						box-shadow: none;
						cursor: default;
					}
				}

				.flow-icon-inner-icon
				{
					i
					{
						font-size: 18px;
					}
				}
			}
		}
	}
}
