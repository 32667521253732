//
// Spacings mixin
// --------------------------------------------------

@mixin spacing($title, $size, $vertical, $horizontal){
	.minus-margin-#{$title} {
		@include remConversionImportant(margin, -$vertical, -$horizontal);
	}
	.minus-top-margin-#{$title}{
		margin-top: -$size !important;
		@include remConversionImportant(margin-top, -$size);
	}	
	.minus-right-margin-#{$title}{
		@include remConversionImportant(margin-right, -$size);
	}	
	.minus-bottom-margin-#{$title}{
		@include remConversionImportant(margin-bottom, -$size);
	}	
	.minus-left-margin-#{$title}{
		@include remConversionImportant(margin-left, -$size);
	}		


	.margin-#{$title} {
		@include remConversionImportant(margin, $vertical, $horizontal);
	}
	.top-margin-#{$title}{
		@include remConversionImportant(margin-top, $size);
	}
	.right-margin-#{$title}{
		@include remConversionImportant(margin-right, $size);
	}	
	.bottom-margin-#{$title}{
		@include remConversionImportant(margin-bottom, $size);
	}	
	.left-margin-#{$title}{
		@include remConversionImportant(margin-left, $size);
	}		

	.padding-#{$title} {
		@include remConversionImportant(padding, $vertical, $horizontal);
	}			
	.top-padding-#{$title}{
		@include remConversionImportant(padding-top, $size);
	}
	.right-padding-#{$title}{
		@include remConversionImportant(padding-right, $size);
	}	
	.bottom-padding-#{$title}{
		@include remConversionImportant(padding-bottom, $size);
	}	
	.left-padding-#{$title}{
		@include remConversionImportant(padding-left, $size);
	}			
}

@mixin spacingNoTitle($size, $vertical, $horizontal) {
	.minus-margin {
		@include remConversionImportant(margin, -$vertical, -$horizontal);
	}
	.minus-top-margin{
		margin-top: -$size !important;
		@include remConversionImportant(margin-top, -$size);
	}	
	.minus-right-margin{
		@include remConversionImportant(margin-right, -$size);
	}	
	.minus-bottom-margin{
		@include remConversionImportant(margin-bottom, -$size);
	}	
	.minus-left-margin{
		@include remConversionImportant(margin-left, -$size);
	}		


	.margin {
		@include remConversionImportant(margin, $vertical, $horizontal);
	}
	.top-margin{
		@include remConversionImportant(margin-top, $size);
	}
	.right-margin{
		@include remConversionImportant(margin-right, $size);
	}	
	.bottom-margin{
		@include remConversionImportant(margin-bottom, $size);
	}	
	.left-margin{
		@include remConversionImportant(margin-left, $size);
	}		

	.padding {
		@include remConversionImportant(padding, $vertical, $horizontal);
	}			
	.top-padding{
		@include remConversionImportant(padding-top, $size);
	}
	.right-padding{
		@include remConversionImportant(padding-right, $size);
	}	
	.bottom-padding{
		@include remConversionImportant(padding-bottom, $size);
	}	
	.left-padding{
		@include remConversionImportant(padding-left, $size);
	}			
}