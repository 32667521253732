// Robbie: Styles for a mock filter section.
// Most likely will be delete once new filters are added.
.mock-filter-group
{
  .mock-title
  {
    background: #666;
  }

  .mock-item
  {
    background: #999;
    width: calc(100% - 30px);
  }
}
