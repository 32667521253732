.page-operator {

    .breadcrumb-outer {
        background-color: #e9ecef; // !!
        font-size:.8rem;    
        ol.breadcrumb {
            padding:.55rem 1rem;
            margin:0;
            .breadcrumb-item.active {
                color:$gray-700;
                }
            button {
                padding:0;
                }
            .fa-external-link { // quick hack until they are removed from the code!
                display:none;
                }
            }
        }
    
    .breadcrumb {  // needed for the breadcrumb on the placements > scheme page
        background:none;
        padding:0;
        }

    }
