.dz-default.dz-message {
    display:none;
}
.dropzone-content.dz-default.dz-message {
    display:block;
}
.dropzone.dz-started .dropzone-content.dz-default.dz-message {
    display:none;
}
.dropzone {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include align-content(center);
    border: 2px dotted #ccc;
    min-height:250px;
}