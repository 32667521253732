// ed = employer dashboard

.romeo {

    .dashboard-header.employer {
        cursor: default;

        .container {
            padding-bottom: 2rem;

            @media (min-width: $screen-sm) {
                padding-bottom: 3rem;
            }

            @media (min-width: $screen-md) {
                padding-bottom: 3.5rem;
            }

            .dash-cta-deck {
                -ms-flex-pack: justify !important;
                justify-content: space-between !important;

                .ed-type-card {
                    width: 100%;

                    @media (min-width: $screen-sm) {
                        // width: inherit;
                        width: min-content;
                        margin-right: 15px;
                    }

                    .info-list-item {
                        -ms-flex-align: start;
                        align-items: flex-start;
                        -ms-flex-direction: row;
                        flex-direction: row;
                        display: -ms-flexbox;
                        display: flex;

                        >div {
                            margin-top: 1rem;
                        }

                    }

                    @media (min-width: $screen-md) {
                        // max-width: 31%;

                        .info-list-item {
                            > div {
                                max-width: 6rem;
                            }
                        }
                    }

                    .ed-text-light-grey {
                        color: #a2a2a2;
                    }

                    .ed-text-grey {
                        color: #808080;
                    }

                    .card-header {
                        padding-bottom: 1.7rem !important;
                        padding-top: 1rem !important;

                        @media (min-width: $screen-sm) {
                            padding-bottom: 1.4rem !important;
                            padding-top: 0.4rem !important;
                        }
                    }

                    .card-body {
                        border-top: 1px solid rgba(0, 0, 0, 0.125);
                    }

                    .ed-disabled {
                        opacity: 0.2;
                        pointer-events: none;
                        cursor: default;
                    }

                    .no-content {
                        max-width: none !important;
                        position: relative;
                        top: -0.2rem;

                        @media (min-width: $screen-sm) and (max-width: 991px) {
                            max-width: 11rem !important;
                        }
                    }

                    .info-list-item-highlight {
                        min-height: 8rem;
                        max-height: 5rem;

                        .info-list-text {
                            @media (min-width: $screen-sm) {
                                max-width: 7rem;
                            }
                        }

                        .feed-link-more {
                            margin-top: 0.5rem;
                        }

                        div {
                            div {
                                @media (min-width: $screen-md) and (max-width: 1110px) {
                                    max-width: 110px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }

                    // AB restrict long words otherwise will break layout
                    // TODO a js condition fix needs to be implemented to adjust the right panel if there are two lines
                    @media (min-width: $screen-sm) {

                        h2,
                        .feed-title,
                        .no-content {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            line-height: 2.4rem;
                            max-height: 4.2rem;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            max-width: 14rem;
                            word-break: break-all;
                        }

                        .feed-title {
                            max-width: 9rem;
                            max-height: 2.5rem;
                        }

                        .no-content {
                            line-height: 1.3rem;
                            -webkit-line-clamp: 4;
                            max-height: 10rem;
                            word-break: break-word; 
                        }

                    }

                    @media (min-width: $screen-md) and (max-width: 1000px) {
                        .no-content {
                            max-height: 6.4rem;
                        }
                    }

                    @media (min-width: 1100px) {

                        .info-list-item > div:not(.no-content) {
                            max-width: 10rem;
                        }

                    }
                }
            }
        }
    }

    .dashboard-custom.employer {
        cursor: default;

        .dashboard-feed {

            @media (min-width: $screen-md) {
                &:first-child {
                    margin-top: -17.9rem !important;
                }
            }

            .ed-employer-details {

                .ed-company-logo {
                    width: 9rem;
                    height: 7rem;
                }

                dt {
                    width: 4rem;
                }

                >div:first-of-type {
                    min-height: 7rem;

                    span {
                        width: 100%;
                    }
                }

                h2 {
                    padding-top: 1rem !important;
                    margin-bottom: 3.3rem;
                }

                .card-body {

                    ul {
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        li {
                            width: 100%;

                            &:first-of-type {
                                position: relative;
                                top: -2rem;

                                div:first-of-type {
                                    max-width: 14rem;
                                    display: flex;
                                }
                            }

                            &:not(:first-child) {
                                padding-top: 1.25rem;
                                margin-top: auto;
                            }

                            &:last-of-type {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .help-panel {

            .card-body { 

                ul {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    li {
                        width: 100%;

                        &:first-of-type {
                            max-height: 15rem;
                            overflow-y: auto;
                            margin-bottom: 1rem;
                        }

                        &:not(:first-child) {
                            padding-top: 1.25rem;
                            margin-top: auto;
                        }

                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .dash-shared-cv-books.employer,
    .dash-helpful-resources.employer,
    .dash-job-list.employer {

        .container-fluid {
            padding-top: 6rem;
            padding-bottom: 6rem;

            .dash-h2 {
                text-align: left;
            }

            .ed-cvbook-left {
                background: right center no-repeat url("https://images1.groupgti.com/themes/targetconnect/employer-dashboard/cvbook-left.png");
                background-size: 13rem;
                min-height: 15rem;
            }

            .ed-cvbook-right {
                background: left center no-repeat url("https://images1.groupgti.com/themes/targetconnect/employer-dashboard/cvbook-right.png");
                background-size: 13rem;
                min-height: 15rem;
            }

            .ed-cv-book {

                padding-left: 0;
                padding-right: 0;

                .card {
                    height: 20rem;

                    @media (min-width: $screen-md) {
                        height: 21rem;
                    }

                    .card-header h2 {
                        font-size: 1.2rem !important;
                        font-weight: 600 !important;

                        padding-bottom: 4rem;
                        max-width: 50%;

                        @media (min-width: $screen-sm) {
                            padding-bottom: 0;
                            max-width: inherit;
                        }
                    }

                    .ed-cv-book-number {
                        top: -0.5rem;
                    }
                }
            }

            // just for IE11 on SM as just bloats out
            .ie11-fix {
                display: block;

                @media (min-width: $screen-md) {
                    display: flex;
                }
            }

            .dash-feed {
                li:first-of-type {
                    border-top: none;
                }
            }

        }
    }
}







// BS4 classes

.d-inline-flex {
    display: inline-flex !important;
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}



.w-25 {
    width: 25%;
}

.w-60 {
    width: 60%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}

.position-relative {
    position: relative !important;
}

/****** EMPLOYER CUSTOM PANE CONTENT ******/
.dashboard-content .employer-default-custom-content {
    h1.card-header::after {
        content: "";
        background-color: $romeo-secondary;
        height: 85px;
        width: 85px;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    
    ul li {
        list-style: none;
    
        &::before {
            content: "\25ef";
            color: $romeo-secondary;
            font-weight: bold;
            display: inline-block;
            width: 1.7em;
            margin-left: -2em;
            font-size: 1em;
        }
    }
}

_:-ms-fullscreen, .romeo .dashboard-header.employer .container .dash-cta-deck .ed-type-card {
    @media (min-width: $screen-sm) {
        // AB allow elipsis to work on IE
        white-space: nowrap;
    }

    @media (min-width: $screen-sm) {
        width: 31% !important;
    }
    
    @media (min-width: $screen-md) {
        // AB ie11 fix as it panics and makes the layout look weird if this class is applied
        // max-width: inherit !important; 

        // AB stop text flowing to two lines and break layout
        .feed-title {
            max-height: 3rem;
        }
    }
}

@supports (-ms-ime-align:auto) {
    /* Edge 16+ CSS */ 

    .ed-type-card {
        @media (min-width: $screen-sm) {
            width: 31% !important;
        }
    }
}




// helpful resources phase 2

/*
.dash-placements {
    .card {
        height: auto;
    }
}

.fa-caret-down:before {
    content: "\f0d7";
    font-size: 1rem;
    position: relative;
    top: -0.3rem;
}

.far {
    font-weight: 300;
}
*/