// Button variants - BS4!!!!
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
    color: color-yiq($background) !important; /** having to add important here for romeo - a.link colour was overwriting **/
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);
  
    @include hover() {
      color: color-yiq($hover-background);
      @include gradient-bg($hover-background);
      border-color: $hover-border;
    }
  
    &:focus,
    &.focus {
      color: color-yiq($hover-background);
      @include gradient-bg($hover-background);
      border-color: $hover-border;
      @if $enable-shadows {
        @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      }
    }
  
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      color: color-yiq($background);
      background-color: $background;
      border-color: $border;
      // Remove CSS gradients if they're enabled
      @if $enable-gradients {
        background-image: none;
      }
    }
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: color-yiq($active-background);
      background-color: $active-background;
      @if $enable-gradients {
        background-image: none; // Remove the gradient for the pressed/active state
      }
      border-color: $active-border;
  
      &:focus {
        @if $enable-shadows and $btn-active-box-shadow != none {
          @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
        }
      }
    }
  }
  
  @mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $gray-1000;
    border-color: $color;
    background-color: #fff;
    border-width: 2px;
  
    @include hover() {
      color: $gray-1000 !important;
      background-color: $gray-200;
      border-color: $active-border;
      text-decoration: none !important;
    }
  
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  
    &.disabled,
    &:disabled {
      color: $color;
      background-color: transparent;
    }
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: color-yiq($active-background);
      background-color: $active-background;
      border-color: $active-border;
  
      &:focus {
        @if $enable-shadows and $btn-active-box-shadow != none {
          @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
        }
      }
    }
  }
  
  // Button sizes
  @mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    @include font-size($font-size);
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    @include border-radius($border-radius, 0);
  }
  



//  OLD BS3 !   ang removed, but keeping btn-clear.   BUTTONSTYLE = being used on romeo! 
// Buttons mixin
// --------------------------------------------------

@mixin buttonStyle($suffix, $background, $text){
    .input-group-btn:last-child {
        >.btn-#{$suffix} {
            height: 100%;
            border: 1px solid $background;
            font-size: 0.9rem;
            cursor: pointer;
        }    
    }         
    .btn-#{$suffix} {
        background: $background !important;
        color: $text !important;
        border:none;
        .fa:first-of-type {
            color:lighten($text, 55%);
        }
        &:hover:not:disabled, &:focus:not:disabled,
        &:hover:not(.disabled), &:focus:not(.disabled) {
            color: $text !important;
            background:darken($background, 20%) !important;
        }  
        &.selected:not(.disabled) {
            color: $text !important;
            background:darken($background, 20%) !important;
        }           
        &:focus {
            box-shadow: 0 0 0 2px rgba($background,.5);
            background:green;
        }      
        &.disabled, &.disabled:focus, &.disabled:active {
            box-shadow: none;
            background: $background;
            color: $text;            
        }     
    }                   
}
@mixin buttonClear($suffix, $background, $text){  
    @if $suffix == '' {
        .btn-clear {
            background: transparent !important;
            color: $background !important;
            border: 1px solid $background;
            .fa:first-of-type {
                color:$background !important;
            }
            &:hover:not(.disabled), &:focus:not(.disabled) {
                background:lighten($background, 75%) !important;
            }   
            &:focus {
                box-shadow: 0 0 0 2px rgba(lighten($background, 75%),.5)
            }    
            &.disabled:focus {
                box-shadow: none;
            }            
        }            
    } @else {
        .btn-clear-#{$suffix}, .btn-#{$suffix}-clear {
            background: transparent !important;
            color: $background !important;
            border: 1px solid $background !important;
            .fa:first-of-type {
                color:$background !important;
            }
            &:hover:not(.disabled), &:focus:not(.disabled) {
                color: $text !important;
                background:$background !important;
                .fa {
                    color:$text !important;
                }
            }   
            &:focus {
                box-shadow: 0 0 0 2px rgba($background,.5);
            }      
            &.disabled:focus {
                box-shadow: none;
            }            
        }
    }
}