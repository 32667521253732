.widget-simple-action-icon {
	border: $input-border;
	position: relative;
    overflow: hidden;
    padding: 20px;
    margin-bottom:20px;
    display:flex;
    background:$content-block-background-color;
	.widget-simple-action-content {
	    padding-left: 20px;
	    display: flex;
	    align-items: flex-start;
	    flex-direction: column;
	}
	.widget-simple-action-background {
		order:-5;
		display:flex;
		justify-content:center;
		align-items:center;
		height:65px;
		width:65px;
		border-radius:50%;
		flex: 0 0 65px;
		i {
			@include remConversion(font-size, 2rem);
			padding:0;
			margin:0;
		}
		&.primary-background {
			i {
				color:$primary-text-color !important;
			}
		}	
		&.secondary-background {
			i {
				color:$secondary-text-color !important;
			} 
		}	
		&.tertiary-background {
			i {
				color:$tertiary-text-color !important;
			}
		}	
		&.quaternary-background {
			i {
				color:$quaternary-text-color !important;
			}
		}				
	}			
	h2 {
		@include remConversion(font-size, 1.6rem);
		margin-bottom:0rem;
		margin-top:0;
	}
	p {
		color: $text-font-color;
	}
	a {
		color:inherit;
	}
	a:hover {
		text-decoration:underline;
	}
}