// search lists

.romeo {

    // kinda a copy of .list-group from BS4.  Decided against using .list-group because it adds a lot of unnecessary styles (borders, rounded corners etc). But there is a lot of cool stuff we can copy
    .info-list-group {  
        margin-top:-1rem; // removing the extra space made from the item padding

        .info-list-item {
            position: relative;
            border-bottom: 1px solid #ddd;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding:0 0 1rem 0; // to get the spacing right when the columns wrap onto multi lines - adding padding-bottom here, padding-top added to the info-list-column
            &:last-child {
                border-bottom:none;
            }
            .info-list-column {
                padding: 1rem 0 0 0; //adding spacing here for when columns wrap
            }
            // variations of the list items
            &.info-list-item-compact {
                padding:0 0 .45rem 0;
                .info-list-column {
                    padding: .45rem 0 0 0;
                }
                &:last-child {
                    padding:0;
                }
            }
            &.info-list-item-highlight {
                background: #f5f5f5;
                margin-left: -1.25rem;
                margin-right: -1.25rem;
                margin-top: -1.25rem;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }

            .info-list-text {
                display:block;
                margin-bottom:.25rem;
                &:last-child {
                    margin-bottom:0;
                }
            }

            // adding specifics for lists (rather than global styles) because it gives us more control - css change rather than code change
            .info-list-text-light {color:#666}
            .info-list-text-lg {font-size:1rem}
            .info-list-text-xl {font-size:1.3rem}
            .info-list-text-xxl {font-size:2rem}
            .info-list-text-sm {font-size:.8rem}
            
        } // end of info-list-item

    } // end of info-list-group

    // AB placed here as overriding older vlibrary as we had black text on black and now when the selection gors black the text and help icons go white so user can still see the elements
    .list-group-item {
        &.active {
            label {
                &.radio-inline {
                    color: #ffffff;
                }
            }
            input {
                border: 1px solid #ffffff;
            }
            .fa {
                color: #ffffff;
            }
        }
    }



// Display a list of logos/titles in a 3 column layout (currently being used on events > employers who are booked)

    .d-grid-group {
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        align-content:space-between;
        align-items:stretch;
        margin-right: -1.35rem; // to counter out the right margin on items (makes a scroll bar locally, fine once in TC

        .group-item {        
            min-height:230px;    
            padding:1.35rem;
            flex-basis:100%;
            border:1px solid #E3E5E8;
            margin-bottom: 1.35rem;
            margin-right:1.35rem;
            position:relative;
            border-radius: 2px;
            
            @media(min-width: $screen-sm){
                flex-basis:46.8%; 
                min-height:240px; 
            }
            @media(min-width: $screen-md){
                flex-basis:30.9%;
            }

            .item-header {
                height:60%;
                display:flex;
                align-items: center;
                justify-content: center;
                a { // adding because otherwise the img inside is smaller
                    width:100%;
                }
                img {
                    display:block;
                    max-height: 75px;
                    height:auto;
                    width:auto;
                    max-width:75%;
                    margin:auto;
                }
                span {
                    display: block;
                    text-align: center;
                    font-weight:600;
                    font-size:1.2rem;
                    &:first-child:not(:only-child) { // if there is a logo, don't display the text
                        display: none;
                    }
                }
            }

            .item-status {
                position: absolute;
                top:.65rem;
                left:.65rem;
                border-radius: 4px;  
                padding: .2rem .4rem;
                line-height: 0;
                display: flex;
                align-items: center;
                background:#E3E5E8;
                color:#2F3338;
                opacity: 1;
                &.active {
                    color:#E7181F;
                    background:none;
                    border:1px solid #E7181F;
                }
                span {
                    font-size:.7rem;
                    padding-left:.25rem;
                }
                .fal {                                  
                    font-size: 1rem;
                }
            }
            .item-footer {
                height:40%;
                a, span {
                    text-decoration: none;
                }
                a:hover, a:focus, a:active {
                    color:$romeo-secondary;
                }
            }       
        }
        &:after { // this is to fake the last row (when there is an uneven amount), so you don't end up with an item on the far left, a big gap and then an item far right
            content: '';
            display:block;
            width:30.9%;
            margin-right: 1.35rem;
        }
    }


    // display a fancy list of links - being used on the resources slide out menu

    .d-links-group {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        align-content:space-between;
        align-items:stretch;

        .links-item { // LINK
            margin-bottom:1rem;
            min-height:82px;
            border-radius: 2px;
            box-shadow: $romeo-box-shadow-light;
            display:flex;
            justify-content: space-between;
            align-items:stretch;
            text-decoration:none;
            flex-basis: 100%;
            transition: all .25s ease-in-out;
            @media(min-width: $screen-sm){
                flex-basis:48.8%; 
            }

            .item-link { // changed from a 1 link block to 2 at the last minute, d'oh!
                text-decoration: none;
                display:flex;
                align-items: stretch;
                flex-grow:1;
                transition: all .25s ease-in-out;
                .item-image {
                    flex-basis: 85px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background:#ffffff;
                    border-radius: 2px;
                    margin: .4rem .6rem .4rem .4rem; // margin around the image, to allow for the grey hover
                    img {
                        max-width: 60px;
                        max-height: 60px;
                        height:auto;
                        width:auto;
                    }
                }
                .item-content {                   
                    p {
                        margin:.8rem .5rem 0.1rem 0;
                        color:$palette-grey-1000;
                        &:last-child {
                            margin:0 .5rem .85rem 0;
                            font-size: .7rem;
                            color:$palette-grey-700;
                        }                    
                    }
                }
                &:hover, &:active, &:focus {
                    color:$palette-grey-900;
                    background:$palette-grey-100;
                }
            }

            a.item-icon {
                text-decoration: none;
                border-left:1px solid #F1F2F4;
                flex-basis:55px;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                display: flex;
                color:$palette-grey-700;                
                transition: all .25s ease-in-out;
                @media(min-width: $screen-sm){
                    flex-basis:40px;
                }
                &:hover, &:active, &:focus {
                    background:$palette-grey-100;
                    color:$palette-grey-900;
                }
            }

            &.links-item-single { // not the best, but this caters for displaying the resource information on the more info page
                box-shadow:none;
                height:auto;
                min-height: 0;
                align-items: stretch;       
                flex-direction:column;
                @media(min-width: $screen-sm) {
                    flex-direction:row;
                    flex-basis: 100%;     
                    flex-wrap: nowrap;
                    align-content: stretch;
                    align-items: center;
                }
                .item-link {
                    align-items: center;
                    flex-basis: 100%; 
                    &:hover, &:active, &:focus {
                        background:inherit;
                        color:inherit;
                    }
                }
                .item-btn {  // very specific to resources, more info
                    margin-top:1.25rem;
                    @media(min-width: $screen-sm) {
                        margin-left:auto;
                        margin-top:0;
                    }
                }
            }

        }

    }

    .title-with-v-border {
        display:flex;
        align-items: center;
        font-weight:600;
        span:first-child {
            margin-right: .75rem;            
        }
        span:last-child {
            height:1px;
            background: #F1F2F4;
            display:block;
            width:100%;
        }
    }
    

} // romeo 