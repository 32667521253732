//
// BS4
// Upgraded dhtmlxscheduler - January 2023
//

// GLOBAL SETTINGS
.dhx_cal_container {
  font-family: $font-family-base !important;
  .dhx_cal_event .dhx_header,
  .dhx_cal_event .dhx_footer,
  .dhx_cal_event .dhx_body,
  .dhx_cal_event .dhx_title,
  .dhx_cal_event .dhx_body a {
    color:$calSessionTextColor; // this is controlling the colour in the appointment blocks
    }

  // CALENDAR HEADER :: buttons, dates etc
  .dhx_cal_header {
    box-shadow: 0 3px 5px 0 #0000001a;
    border: 1px solid #e0e0e0;
    .dhx_scale_bar {
      padding-top: 8px;
      color:$gray-800;
      a {
        // color:$primary;  TC-25952 this was the only 'theme' colour on the scheduler - rather than changing all the other colours to primary, I've just switched to the default dhtmlx blue
        color:#0288d1;
        &:hover {
          text-decoration: none;
          // color:$primary;
          color:$gray-800;
          }
        }
      &:hover {
        background-color: transparent;
        color:$gray-800;
        cursor: auto;
        }
      }
    }
  &.dhx_scheduler_week, &.dhx_scheduler_timeline, &.dhx_scheduler_grid {
    .dhx_cal_header {  // header hover on week, timeline & agenda views only
      .dhx_scale_bar {
        &:hover {
          background:$gray-100;
          }
        }
      }
    }

  .dhx_cal_tab {
    // view switch buttons (day, week, agenda etc)
    width:auto;
    padding:0 10px;
    &:first-child {
      border-radius: 5px 0 0 5px;
      border-right: 0;
      }
    &:last-child {
      border-radius: 0 5px 5px 0;
      }
    }


  // APPOINTMENT BLOCK
  .dhx_cal_event {
    // main container for a slot.   dhx_header is hidden by the system 
    .dhx_title { // time
      padding: 3px 0 0 5px;
      font-weight: 500;
      height: 20px;
      }
    .dhx_body {
      padding:1px 5px 5px 5px;
      font-size: 13px;
      // event title
      .calEventTitle { 
        font-weight: 600;
        padding: 0;
        display: block;      
        }
      // user and location
      .calEventDesc { 
        font-weight: 500;
        font-size: 13px;
        }
      }
    &.dhx_cal_select_menu .dhx_body {  // the block (edit/delete btns) at appears when clicking a slot
      padding:3px 0 0 1px !important;
      background-color: transparent !important;
      div.dhx_menu_icon {
        margin: 0 0 5px 0;
        }
      }
    &.calSessionCreated {  // when the appointment block is light grey, turning the white icons dark
      div.dhx_menu_icon {
        filter: invert(100%);
        }
      }
    &.cal-session-available {
      cursor:move;  // adding for available appointments that can be moved
      }
    }
  .dhx_scale_holder .dhx_cal_event .dhx_body { // needed to overwrite the default.  
    overflow: hidden;  // hiding overflowing content in a slot
    }


  // MONTH VIEW 
  .dhx_cal_event_clear {
    font-family: $font-family-base !important;
    text-overflow: unset;  // switching off as the ... was overlapping title text
    padding: 0px 5px;
    font-size: 12px !important;
    display:flex // TC-25767 quick bug fix, appointment so the appointment type displays alongside the 
    }


  // APPOINTMENT STATES/COLOURS
  .dhx_cal_event, .dhx_cal_event_clear, .dhx_cal_event_line {  // day, week, month amd timeline views.  
    color:$calSessionTextColor;
    font-size: 13px;
    border-top:1px solid #fff; // used to create a divide between appointments butted up together
    a {
      color:$calSessionTextColor;
      }
    .fa-exclamation-circle { // icon for 'booked, not accepted'
      color:#fff !important;
      }
    &.calSessionCreated { //created-grey
      background-color: $calSessionCreated; // this one is needed for month view  
      color:$calSessionTextColorDark;    
      .dhx_body, .dhx_footer, .dhx_header, .dhx_title { background-color: $calSessionCreated; color:$calSessionTextColorDark;} // this one needed for week/day view
      }    
    &.calSessionBooked { //booked-success
      background-color: $calSessionBooked;
      .dhx_body, .dhx_footer, .dhx_header, .dhx_title { background-color: $calSessionBooked; }
      }    
    &.calSessionMissed { //missed-danger
      background-color: $calSessionMissed;
      .dhx_body, .dhx_footer, .dhx_header, .dhx_title { background-color: $calSessionMissed; }
      }
    &.calSessionWaiting { //arrived-purple
      background-color: $calSessionArrived;
      .dhx_body, .dhx_footer, .dhx_header, .dhx_title { background-color: $calSessionArrived; }
      }
    &.calSessionFinished { //attended-primary
      background-color: $calSessionAttended;
      .dhx_body, .dhx_footer, .dhx_header, .dhx_title { background-color: $calSessionAttended; }
      }
    }    
  }
  

// AGENDA AND TIMELINE
.dhx_cal_header .dhx_grid_line { // agenda header
  height:35px;
  div {
    font-weight: 500;
    padding: 7px 8px;
    font-size: 14px;
    color:$primary;
    cursor: pointer;
    &:hover {
      background:$gray-100;
      }
    }
  }
.dhx_grid_area tr.dhx_grid_event:nth-child(2n) td, .dhx_grid_area tr.dhx_grid_event:nth-child(odd) td {
  border-bottom:3px solid #fff !important;
  line-height: 1.5;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  }
.dhx_grid_sort_desc, .dhx_grid_sort_asc {
  .dhx_grid_view_sort {
    height: 12px;
    width: 12px;
    padding: 0 !important;
    margin-top: 5px;
    }
  }
.dhx_scheduler_grid .dhx_grid_area .dhx_grid_event.calSessionCreated { background: $gray-100; } 
.dhx_scheduler_grid .dhx_grid_area .dhx_grid_event.calSessionBooked  { background-color: rgba($calSessionBooked, .08); }
.dhx_scheduler_grid .dhx_grid_area .dhx_grid_event.calSessionMissed { background-color: rgba($calSessionMissed, .08); }
.dhx_scheduler_grid .dhx_grid_area .dhx_grid_event.calSessionWaiting  { background-color: rgba($calSessionArrived, .08); }
.dhx_scheduler_grid .dhx_grid_area .dhx_grid_event.calSessionFinished  { background-color: rgba($calSessionAttended, .08); }


.dhx_scheduler_grid .calSessionCreated  td:first-child {
  background-color: #E4E4E4;
  // &:before { // ANG REMOVING - TC-25887
  //   content : 'Available';  
  //   }
  }
.dhx_scheduler_grid .calSessionBooked  td:first-child {
  background-color: $calSessionBooked;
  color:$calSessionTextColor;
  // &:before {
  //   content : 'Available';
  //   }
  }
.dhx_scheduler_grid .calSessionMissed  td:first-child {
  background-color: $calSessionMissed;
  color:$calSessionTextColor;
  // &:before {
  //   content : 'Missed';
  //   }
  }
.dhx_scheduler_grid .calSessionWaiting  td:first-child {
  background-color: $calSessionArrived;
  color:$calSessionTextColor;
  // &:before {
  //   content : 'Arrived';
  //   }
  }
.dhx_scheduler_grid .calSessionFinished  td:first-child {
  background-color: $calSessionAttended;
  color:$calSessionTextColor;
  // &:before {
  //   content : 'Attended';
  //   }
  }

// switching off resizes for booked appointments  (cal_event_line is timeline)
.cal-session-booked.dhx_cal_event .dhx_event_resize,
.cal-session-booked.dhx_cal_event_line .dhx_event_resize {
  cursor: auto;
  background-image:none;
  }

// SCHEDULER TOOLTIPS (and modal for new/edit appointment)
.dhtmlXTooltip.tooltip.scheduler-tooltip, .dhtmlXTooltip.tooltip[role=tooltip] {
  font-family: $font-family-base;
  padding:0.9rem;
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.3); /* should be using a variable! */    
  border-radius: .25rem;
  background:#fff;
  word-wrap: break-word;
  position: fixed;
  min-height: 100px;
  min-width: 200px;
  max-width: 300px;
  height: fit-content;
  z-index: 500; 
  border:none !important;
  .booking {
    border-top-width:2px;
    border-top-style: solid;
    border-top-color: $gray-200;
    background-color: $gray-100; 
    margin:1rem -.9rem -.5rem -.9rem;
    padding:1rem .9rem .9rem .9rem;
    position:relative;
    p, a {
      color:$gray-800;
      }
    h3 {
      margin-bottom:.75rem;
      }
    .booking-notes {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      }
    }
  .booking-btns {
    border-top: 1px solid $gray-200;;
    margin:.5rem -.9rem 0 -.9rem;
    padding:.75rem .9rem 0 .9rem;
    display: flex;
    justify-content: space-between;
    }
  }

.dhtmlXTooltip.tooltip.scheduler-tooltip, .dhtmlXTooltip.tooltip[role=tooltip], .scheduler-booked-status {  
  h3 {
    margin-bottom:.75rem;
    }
  p {
      font-size: 0.85rem;
      color:$gray-700;
      font-weight:500;
      margin-bottom:.5rem;
      display:flex;
      align-items:baseline;
      &:last-child {
        margin-bottom:0;
        }
      i {
        color:$gray-400;
        margin-right:.5rem;
        flex-shrink: 0;
        }
      }  
  }
// pulling out the status styling so it can be used outside the toolips (i.e. on live updates modal and new/edit appointment modal). 
.dhtmlXTooltip.tooltip.scheduler-tooltip, .dhtmlXTooltip.tooltip[role=tooltip], ul#live-update-list, .scheduler-booked-status {
    .booking-status {
      font-size: 0.75rem;
      font-weight: 600;
      border-radius: .25rem;
      position:absolute;
      right: 1rem;
      top: -.85rem;
      padding: .1rem .5rem .02rem .5rem;
      background:$gray-700;
      color:#fff;
      border-width:2px;
      border-style: solid;
      border-color:#c6c8cb;         
      }
    .booking {
      /* booked */
      &.booking-status-allocated, &.booking-status-booked, &.booking-status-book, &.booking-status-confirm {
        border-top-color: $calSessionBooked;
        .booking-status {
          background: $calSessionBooked;
          border-color:$calSessionBooked;
          }
        }
      /* not accepted */
      &.booking-status-notaccepted {
        border-top-color: $calSessionBooked;
        .booking-status {
          background: $gray-100;
          color:$gray-800;
          border-color:$calSessionBooked;
          padding: .06rem 0.5rem .06rem .5rem;
          }
        }
      /* arrived */
      &.booking-status-arrived, &.booking-status-arrive, &.booking-status-update {
        border-top-color: $calSessionArrived;
        .booking-status {
          background: $calSessionArrived;
          border-color:$calSessionArrived;
          }
        }
      /* attended */
      &.booking-status-attended, &.booking-status-finished, &.booking-status-finish {
        border-top-color: $calSessionAttended;
        .booking-status {
          background: $calSessionAttended;
          border-color:$calSessionAttended;
          }
        }
      /* missed */
      &.booking-status-missed, &.booking-status-miss, &.booking-status-demiss, &.booking-status-cancelled, &.booking-status-cancel, &.booking-status-delete, &.booking-status-unarrive {
        border-top-color: $calSessionMissed;
        .booking-status {
          background: $calSessionMissed;
          border-color:$calSessionMissed;
          }
        }
      /* created */
      &.booking-status-create {
        border-top-color: $calSessionCreated;
        .booking-status {
          background: $calSessionCreated;
          border-color:$calSessionCreated;
          color:$calSessionTextColorDark;
          }
        }
      }
    }

ul#live-update-list, .scheduler-booked-status { // list of updates in modal
  margin-top: -0.5rem;
  .booking {  
    .booking-status { // undoing the tooltip styles
      position:relative;
      right:unset;
      top:unset;
      display:inline-block;
      }
    }
  }

//
// APPOINTMENTS - LIVE UPDATES
//
.alert-live {
  display:flex;
  align-items: stretch;
  border:1px solid $gray-300;
  border-radius: $btn-border-radius;
  margin-right: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  width:100%;
  height:28px; // added to stop animation jumping
  padding-right:.25rem; // needed now the push notifications btn is hidden
  @media(min-width: $screen-md) {
    width:400px; // fixed width needed so it doens't jump around
    }
  .alert-live-status {
    display:flex;
    align-items: center;
    padding:0 .5rem;
    color:$success;
    font-size:1rem;
    }
  .alert-live-updates {
    display: flex;
    overflow: hidden;  
    align-items: center;
    flex-grow: 1;
    .alert-live-text, .alert-live-text2 { // alert-live-text2 is only for my local wireframes
      font-size: .75rem;
      padding:.25rem 0;
      line-height: normal;
      font-weight: 500;
      color:$body-color;
      transform: translateY(115%);
      margin-right:.5rem;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      strong {
        color:$success;
        }
      &:hover, &:focus {
        text-decoration: none;
        color:$success;
      }
      &.alert-live-text-out { // old item - fading up and out
        transform: translateY(-100%);
        animation: fadeOut .5s;
        }
      &.alert-live-text-active { // new item - fadin in and up
        transform: translateY(0);
        animation: fadeIn .5s;
        }
      &.animation-0 { // for any error messages that we dont want repeatedly scrolling! 
        animation:none;
        }
      }
    }
  .alert-live-counter {
    margin-right:.5rem;
    align-self: center;
    }
  .alert-live-actions {
    display:none; // TC-25720 hiding push notifications
    // display: flex;
    // .btn {
    //   border-radius: 0;
    //   background:$gray-300;
    //   i {
    //     margin-top:1px;
    //     margin-right:0;
    //     color:#037949; /*success*/
    //     }
    //   }
    }
  &.failed {
    border:1px solid $danger;
    .alert-live-status {
        color:$gray-400;
      }
    .alert-live-text {
      strong {
        color:$danger;
        }
      }
    }
  }
@keyframes fadeIn {
  0% { opacity: 0; transform:translateY(110%) }
  100% { opacity: 1; transform:translateY(0)}
  } 
@keyframes fadeOut {
  0% { opacity: 1; transform:translateY(0) }
  100% { opacity: 0; transform:translateY(-100%)}
  }   
#live-updates-options-push {
  display: none; // TC-25720 hiding the push settings in the dialog
  }


// MINI POPUP CALENDAR : its a table, so switching off all the table styling
.dhx_mini_calendar {
  table td {
    padding: 0;
    font-size: 14px;
    border: none;
  }
}

// hiding the scheduler-tooltip that appears in the sidebar.  this needs removing from the code properly!
.content-search-filters #scheduler-info-box {
  display:none;
  }

// EVENTS CALENDAR 
#calendar-container {
  &.dhx_cal_container {
    
  .dhx_cal_event_clear { // month view
    color:$link-color;
    border-top:none;
    padding: 0px 5px;
    font-size: 12px !important;
    b {
      font-weight:500 !important;
    }

    &.calEventClosed {
      background-color: #017eb4;
      color:#fff;
      }
  }

  // event state colours  
  .calEventCancel {
    text-decoration:line-through;
    div {
      background-color: $danger;
      border-color: $danger;
      }
    }
  .calEventClosed div {
    background-color: $primary;
    border-color: $primary;
    }
  .calEventPublished div {
    background-color: #FFE4AB;
    color:$body-color;
    }

  }
}



//// ORIGINAL CSS that I've had to put back, but should be removed as/when markup is updated
/// 
/// create new appointment modal:
#session-schedule-date select {
  padding: 5px 5px;
  display: inline-block;
  width: auto;
  margin-right: 8px;
  }
#scheduling-form .panel {
  margin-top: 15px;
  }


  
////////////////////////////   
///  
/// ORIGINAL CSS 
///

/**
 * TC v6.0 UPDATES
 *
 */
//  .scheduler-tooltip {
//   z-index: 500;
//   position: absolute;
//   min-height: 100px;
//   min-width: 100px;
//   max-width: 300px;
// }

// .scheduler-tooltip .panel-body p {
//   margin-bottom: 6px;
// }

// .scheduler-tooltip .panel-body {
//   padding-top: 10px;
// }

// .scheduler-tooltip.panel {
//   padding-bottom: 0;
// }

// .scheduler-tooltip .panel-footer .btn {
//   font-size: 85%;
//   margin-bottom: 4px;
// }

// .scheduler-tooltip .panel-footer .btn .fa {
//   font-size: 100%;
// }

// .dhx_grid_event td {
//   font-size: 12px;
// }

// .dhx_scheduler_month .dhx_cal_event_clear div {
//   display: inline-block;
// }

// #scheduling-form .panel {
//   margin-top: 15px;
// }

// #session-schedule-date select {
//   padding: 5px 5px;
//   display: inline-block;
//   width: auto;
//   margin-right: 8px;
// }

// #session-schedule-date .date.date-date {
//   margin-left: 25px;
// }

// /** This is so full-screen doesn't shown the menu behind it **/
// .dhx_cal_navline, .dhx_cal_header {
//   background-color: #fff;
// }

// #sidebar-content .scheduler-tooltip {
//   position: inherit;
// }

// #sidebar-content .scheduler-tooltip .panel-body {
//   background-color: #fff;
//   padding: 5px;
// }

/**
*	Scheduler 6.0 (TCv8.8.16) updates
*/
// .dhx_scale_holder .dhx_cal_event .dhx_body {
// overflow: hidden;
// }


/**
* V 4.0 Terrace Skin Updates
*/

/*#page {
min-width: 1280px;
}*/

// #session-info {
// width:35%;
// float:left;
// }


// .dhx_cal_data .dhx_agenda_line {
// border:none;
// }

// .dhx_agenda_line .dhx_event_icon {
// width:2%;
// }


// .dhx_v_border {
// display:none;
// }

// .dhx_agenda_line > span {
// display:block;
// }

// .dhx_agenda_area .dhx_agenda_line .dhx_event_icon {
// background-color:transparent;
// }

// .dhx_cal_header.dhx_agenda_line div:first-child {
// padding-left: 0;
// }

// .dhx_agenda_line div {
// width:22%;
// }

// .dhx_cal_header .dhx_agenda_line div {
// border:none;
// }

// .dhx_cal_header .dhx_agenda_line div span {
// display:inline-block;
// padding:0;
// /* borders don't line up properly on agenda view */
// /*border-left: 1px solid #CECECE;*/
// width:100%;
// }

// .dhx_agenda_line div:first-child {
// width:10%;
// padding-left: 0;
// }

// .dhx_cal_header .dhx_agenda_line .icon-spacer {
// width:2%;
// border-right: none;
// display:inline-block;
// }

// .dhx_agenda_line span span.agenda-text {
// display:block;
// float:left;
// width:21.65%;
// text-align: center;
//   border-right: 1px solid #CECECE;
//   text-overflow:ellipsis;
// }

// .dhx_agenda_line {
//   clear: both;
//   height: 22px;
//   overflow: hidden;
// }

// /*
// * Legacy Styles
// */
// .dhx_cal_container {
// float:none;
// }

// .dhx_cal_container table {
// margin: 0;
// width:auto;
// }

// /*.dhx_cal_container div {
// min-height:0;
// margin:0;
// }*/

// /*.dhx_cal_container div .dhx_cal_tab {*/
// /*margin-top:3px;*/
// /*}*/

// // .dhx_body > .dhx_menu_icon  {
// //   margin-left: -5px;
// //   margin-top: 0;
// // }

// .dhx_title > .dhx_menu_head {
//   margin-left: 5px;
//   margin-top: 1px;
// }

// .dhx_cal_container div > .dhx_month_body {
// position:relative;
// }

// .dhx_cal_container #dropdown_div {
// margin:0;
// min-height:0;
// }

// // .dhx_scale_holder_now div {
//   // not sure what this is doing, removing
// // margin:0;  
// // }

// .dhx_scale_holder div {
// margin:0;
// position:relative;
// }

// .dhx_body div {
// position:relative;
// }

// // .dhx_menu_icon {
// // margin-right:4px;
// // }

// .dhx_body > .dhx_cal_editor_quicksearch {
// overflow:visible;
// top:5px;
// }

// .dhx_cal_editor_quicksearch > div {
// border:none;
// margin:4px;
// }


// #searchResultList1 {
// overflow:visible;
// }

// .dhx_matrix_scell + td {
// padding: 0;
// }

// .dhx_matrix_scell {
// padding : 0;
// }

// .dhx_matrix_cell  {
// padding: 0;
// }

// .overflowCell {
// overflow:visible !important;
// }

// .dhx_cal_editor_quicksearch {
// z-index:35;
// }

// // .calEventTitle {
// // font-weight:bold;
// // padding: 0 3px 3px 3px;
// // display:block;
// // }

// // .calEventDesc {
// // padding:3px;
// // }

// .calEventInlineTitle {
// font-weight:bold;
// padding: 0 5px 0 0;
// display:inline;
// }

// .calEventLine {
// display:block;
// padding:3px;
// }

// .dhx_scale_holder .calEventPrePublish div {
// background-color: rgb(222,222,222);
// border-color: #999999;
// }



/* calendar states that Angela knows about and has tested */
// .dhx_cal_event .dhx_header, .dhx_cal_event .dhx_footer, .dhx_cal_event .dhx_body, .dhx_cal_event .dhx_title, .dhx_cal_event .dhx_body a { color:#333;}
// .dhx_cal_data .calSessionCreated div {background-color: #E4E4E4; border-color: #BCBCBC; /*grey*/ }
// .dhx_cal_data .calSessionBooked div {background-color: #C4FFC4; border-color: #54FF54; /*green*/}
// .dhx_cal_data .calSessionMissed div {background-color: #FFCACA; border-color: #FF7575; /*red*/}
// .dhx_cal_data .calSessionWaiting div {background-color: #F1DAFE;border-color:#D385FE;/* purple */ }
// .dhx_cal_data .calSessionWaiting .dhx_title {color:#580286;/* purple */}
// .dhx_cal_data .calSessionFinished div {background-color: #BFDFFF;	border-color: #64B1FF; /* light blue */}

//.dhx_cal_data .calSessionMissed .cal-user-name {text-decoration: line-through;} decided I don't like the strike-thru, switching off!
// .dhx_scheduler_month .dhx_cal_data .calSessionCreated div {background: none;}


// .dhx_cal_data .dhx_cal_select_menu.calSessionCreated div {background-color: #BCBCBC; }
// .dhx_cal_data .dhx_cal_select_menu.calSessionBooked div {background-color: #54FF54; }
// .dhx_cal_data .dhx_cal_select_menu.calSessionMissed div {background-color: #FF7575; }
// .dhx_cal_data .dhx_cal_select_menu.calSessionWaiting div {background-color: #D385FE; }
// .dhx_cal_data .dhx_cal_select_menu.calSessionFinished div {background-color: #64B1FF; }

// .dhx_scheduler_timeline .calSessionCreated  {background-color: #BCBCBC; }
// .dhx_scheduler_timeline .calSessionBooked,   #content .dhx_grid_area .dhx_grid_event.calSessionBooked  {background-color: #54FF54; }
// .dhx_scheduler_timeline  .calSessionMissed,  #content .dhx_grid_area .dhx_grid_event.calSessionMissed {background-color: #FF7575; }
// .dhx_scheduler_timeline  .calSessionWaiting, #content .dhx_grid_area .dhx_grid_event.calSessionWaiting  {background-color: #D385FE; }
// .dhx_scheduler_timeline .calSessionFinished, #content .dhx_grid_area .dhx_grid_event.calSessionFinished  {background-color: #64B1FF; }



// .dhx_cal_data .allocated div {
// background-color: #FFE4AB;
// }

// #scaleSliderHolder, #scaleSliderBar, #scaleSliderHandle, #scaleHandleText {
// border:0;
// padding:0;
// margin:0;
// }

// .tooltip a.cssbtn {
// padding:4px;
// border:1px solid #ccc;
// border-radius: 4px;
// display:inline-block;
// margin:2px;
// text-decoration: none;
// }

// .tooltip a.cssbtn:hover {
// background-color:#ccc;
// color:#fff;
// }

// .dhx_cal_navline .fullscreen  {
// font-size:100%;
// left:385px;
// position:relative;
// margin-top:5px;
// width:220px;
// }

// .sessionCalendarBook {
// display:block;
// width:35px;
// padding:5px;
// padding-left: 8px;
// background: url("/bundle//styles/lib/scheduler//images/hosted/arrow-link-small.png") no-repeat 0 8px;
// cursor:pointer;
// font-size: 120%;
// }

// .sessionCalendarBook:hover {
// text-decoration: underline;
// }

// #scheduler_dropdown {
// margin: 0px !important;
// }

// #scheduler_dept_dropdown {
// margin: 0px !important;
// }

// .dropdown_float_right {
// display: inline;
// float: right;
// margin: 0px !important;
// }

// #dropdown_div {
// display: block;
// /* position: relative; */
// }

// #scheduler_progress {
// visibility: hidden;
// display: inline;
// position: relative;
// float: left;
// left: 0px;
// height: 25px;
// background-position: top left;
// background-repeat: no-repeat;
// visibility: hidden;
// }

// #dropdown_div .message {
// margin:3px;
// }

// #bookingForm select.field-autowidth {
// margin: 10px 10px 8px 10px;

// }

// #bookingForm .dhx_cal_lsection {
// padding: 5px 10px;
// }

// #bookingForm .dhx_menu_icon {
// margin-left:15px;
// float:left;
// position:relative;
// width:20px;
// height:20px;
// padding:1px;
// border: 1px solid #fff;
// border-radius: 3px;
// margin-top:4px;
// }

// #bookingForm p.errorMessage {
// padding:8px 10px;
// text-align:center;
// color:#C10000;
// }

// #dynamicForm p.errorMessage {
// text-align:center;
// color:#C10000;
// }

// #dropdown_div {
// margin-bottom:4px;
// }

// #bookingDeleteConfirm h3+div {
// padding: 15px;
// }

// #bookingDeleteConfirm input {
// margin-left:15px;
// }

// #caseBookAssociation span {
// display: block;
// font-size: 90%;
// margin-top: 6px;
// }

// #scaleSliderBox {
// float:left;
// }

// /* scheduled appointments */
// .panelBody .calendar-outer #dropdown_div  .dropdown_float_right {
// display: block;
// /* top: -15px; needs to be inline with hour scale */
// float: left;
// height: auto;
// padding-left:15px;
// }

// .panelBody .calendar-outer #dropdown_div  .dropdown_float_right select {
// max-width: 300px;
// width: auto;
// }

// .dhx_cal_light select {
// width: auto;
// }

// #bookingForm p {
// padding:0;
// }

// #toggleAdvancedBooking {
// cursor:pointer;
// background:url(/styles/lib/scheduler//images/form_hide.png) 25% 50% no-repeat,
//        url(/styles/lib/scheduler//images/form_hide.png) 75% 50% no-repeat;
// border-radius:5px;
// border:rgb(180,180,180) solid thin;
// padding:3px;
// text-align:center;
// width:70%;
// margin: 10px auto;
// }

// #toggleAdvancedBooking:hover {
// border-color:black;
// }


// #bookingFormOptions .form-group {
//   margin-bottom: 4px;
// }

// div.dhx_btn_set div.dhx_delete_btn {
// display:none;
// }

// div.dhx_btn_set div.dhx_delete_btn+div {
// display:none;
// }

// #bookingForm .fieldcontainer textarea {
// height:200px;
// }

// #dynamicForm label {
// padding:5px;
// }

// #dynamicForm select {
// margin-right:10px;
// }

// #deleteInfo {
// padding:8px 10px 8px 25px;
// font-size:90%;
// width:75%;
// float:left;
// }


// #deleteProgress {
// margin-top:15px;
// }

// #schedulerLoading {
// margin-left:480px;
// }

// .dhx_scheduler_month .dhx_cal_event_clear .calEventTitle {
// display:inline;
// }

// .dhx_scheduler_month .dhx_cal_event_clear .calEventDesc {
// display:none;
// }

// .dhx_cal_data .event-selected {
//   background-color: #83DB63;
// }

// .dhx_delete_btn_set {
// border:none;
// }

// .dhx_repeat_right {
// width: 225px;
// }

// .dhx_cal_light_wide .dhx_repeat_right {
// margin-right:35px;
// }

// .dhx_repeat_right label {
// float:left;
// padding-right:5px;
// }

// .dhx_repeat_center {
// width: 260px;
// }

// .dhx_repeat_row  {
// margin-bottom:5px;
// }

// .dhx_scale_bar.delete {
// background: url(/styles/lib/scheduler//images/button-icons.png) no-repeat 97% -117px transparent;
// }

// .dhx_scale_bar.delete:hover {
// background-color:#ccc;
// font-style: italic;
// cursor: pointer;
// }

// /*.dhx_cal_select_menu {
// border-left:1px solid #117589;
// border-top:1px solid #117589;
// border-bottom:1px solid #117589;
// border-radius:6px
// }*/

// .dhx_cal_data .slot-has-bookings div {
// background-color: #F1DAFE;
// border-color:#D385FE;
// }

// .dhx_cal_data .slot-no-bookings div {
// background-color: #E4E4E4;
// border-color: #BCBCBC;
// }

// .dhx_cal_data .slot-selected div {
// background-color: #C4FFC4;
// }

// .dhx_cal_event_clear.slot-has-bookings {
// border:1px solid #54FF54;
// }

// .dhx_cal_event_clear.slot-selected {
// background-color: #E4E4E4;
// }

// .event-slot-calendar .dhx_title:hover {

// font-weight: normal;
// font-style: italic;
// }

// .delete-slot-confirm .details-box {
// margin:0 8px 10px 8px;
// min-width: 150px;
// text-align: center;
// }

// .cal-header-icon {
// width:20px;
// height:20px;
// display:inline-block;
// }

// .event-slot-calendar .dhx_scale_bar span {
// width:33%;
// }

// .dhx_scale_bar:hover {
// background-color: #7f7f7f;
// color:#fff;
// cursor: pointer;
// }

// .scheduler-form-select {
// max-width:280px;
// }

// .tooltip h2 {
// margin-bottom: 3px;
// margin-top: -7px;
// }

// .tooltip .row {
// padding-bottom: 0;
// }

// .tooltip .tooltip-actions {
// margin-top: 3px;
// position: relative;
// bottom: 1px;
// }


// .cal-session-booked.dhx_cal_event .dhx_header, .cal-session-booked.dhx_cal_event .dhx_title {
// cursor: auto;
// }


// // .dhx_cal_event_clear.calSessionMissed {
// // text-decoration:line-through;
// // }

// .dhx_wrap_section .dhx_wrap_section {
//   border-top: 1px solid #CECECE;
//   border-bottom: none;
// }

// .calendar-legend {
// position: static;
// background: none;
// margin-top:5px;
// }

// .calendar-legend .legend-item {
// width: 120px;
// height: 25px;
// margin: 0 auto;
// text-align: center;
// text-decoration: none;
// }

// .scheduler-tooltip-notes {
//   border-left: 2px solid #ccc;
//   font-size: 1.2em;
//   margin: 4px 0 9px;
//   padding: 3px 6px;
// }


