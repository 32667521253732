.label-success {
	background-color:$success;
	border-color:$success;
	color:$success-text-color;
}

label, .label
{
	&.text-left
	{
		text-align: left;
	}

	&.text-right
	{
		text-align: right;
	}

	&.text-center
	{
		text-align: center;
	}
	.optional {
		color: #737373;
		font-size: 90%;
		font-weight: normal;
	}
	&.disabled {
		color: #ccc;
	}
	&.label-with-notification {
		position: relative;
	}
}
