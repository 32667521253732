@media print {
    * {-webkit-print-color-adjust:exact;}    

    // ang adding some default-operator tweaks.  
    .page-operator header, 
    .page-operator .breadcrumb-outer,
    .page-operator .table-footer,
    .page-operator ._toggle-sidebar,
    .page-operator footer {
        display: none !important;
        }
    .page-operator .content-wrapper {
        margin:0 !important;
        }
    .page-operator .content-body {
        padding:0 !important;
        }
    .page-operator #content-with-sidebar.col-sm-9 {
        width:100%;
        flex: 0 0 100%;
        max-width: 100%;
        }
    .page-operator .page-content .pv-section.content-body {
        padding:0 !important;
        margin:0 !important;
        }
        

    // Not sure if this is being used for student.
    header, .header {
        position: relative !important;
        padding:0px !important;
        margin:0px !important;
        left:0 !important;
        img.site-logo, .user-details {
            display:none;   
        }
        #site-name {
            padding-top:10px;  
        }
    }
    .filters {
        display:none;
    }
    .top-search-bar {
        display: none;
    }
    .content-body {
        padding: 20px 0 0 0 !important;
    }
    .container-fluid {
        padding:0px !important;
    }
    footer, .footer-container {
        display:none;
    }
    a[href]:after {
        content: none !important;
    }    
    .btn, header a {
        display:none !important;
    }  
    .dhx_cal_container div {
        display:block;
        width:100%;
    }
    .dhx_cal_container .dhx_cal_navline {
        display:none;
    }
    .dhx_cal_container .dhx_grid_line {
        display:flex;
    }
    .content-body.wrapper-with-sidebar {
        overflow: -webkit-paged-y !important;
    }    
}
