/****** input-animation-slide-right *******/

.input-animation-slide-right {
	@media(min-width:$screen-md){
		position: relative;
		z-index: 1;
		display: inline-block;
		vertical-align: top;	
		display: block;
		overflow: hidden;
		@include remConversion(border-radius, $input-border-radius);
		border:$input-border;
	}
	.form-control {
		@media(min-width:$screen-md){
			position: relative;
			display: block;
			float: right;	
			position: absolute;
			top: 0;
			right: 100%;
			width: 50%;
			height: 100%;
			-webkit-transition: -webkit-transform 0.5s;
			transition: transform 0.5s;
			-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
			background:$input-animation-form-control-background;
		}
		&:focus {
			@media(min-width:$screen-md){
				-webkit-transform: translate3d(100%, 0, 0);
				transform: translate3d(100%, 0, 0);
				-webkit-transition-delay: 0.06s;
				transition-delay: 0.06s;	
				+ label {
					-webkit-transform: translate3d(60%, 0, 0);
					transform: translate3d(60%, 0, 0);
					pointer-events: none;				
				}	
			}	
		}
	}	
	label {
		background:$input-background-mobile;
		@media(min-width:$screen-md){
			float: right;
			padding: 10px;
			-webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;	
			z-index: 10;
			display: block;
			width: 100%;
			height: 100%;
			text-align: left;
			cursor: text;
			-webkit-transform-origin: 0% 50%;
			transform-origin: 0% 50%;
			-webkit-transition: -webkit-transform 0.5s;
			transition: transform 0.5s;
			-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
			background:$input-background-desktop;
			color:$input-placeholder-color;
			font-weight:normal;			
		}
	}	
	@media(min-width:$screen-md){
		&.input--filled {
			.form-control {
				-webkit-transform: translate3d(100%, 0, 0);
				transform: translate3d(100%, 0, 0);
				-webkit-transition-delay: 0.06s;
				transition-delay: 0.06s;			
			}
			label {
					-webkit-transform: translate3d(50%, 0, 0);
					transform: translate3d(50%, 0, 0);
					pointer-events: none;		
				@media(min-width:$screen-sm){
					-webkit-transform: translate3d(50%, 0, 0);
					transform: translate3d(50%, 0, 0);
				}	
			}
		}
	}
	> label {		
		margin-bottom:0px;
		@media(min-width:$screen-md){
			padding-left: 15px;
			padding-top: 10px;
			padding-bottom: 12px;			
		}
	}
	.form-control {
		@media(min-width:$screen-md){
			border:none;
			box-shadow:none;
			text-shadow:none;
		}
	}
	@media(min-width:$screen-md){	
		&.input-textarea {
			height: 150px;
		}
	}
	@media(min-width:$screen-md){
		&.input-select {
			select.form-control {
				border-bottom-right-radius: 0px;
				border-top-right-radius: 0px;
				-webkit-appearance: none;
				-moz-appearance: none;
				text-indent: 1px;
				text-overflow: '';		
				cursor:pointer;	
			}
		}
	}	
}

