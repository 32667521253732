.banner {
	width:auto;
    overflow:hidden;
    > div {
        @include flex-grow(1);
        .d-flex-md {
            .right-margin-md {
                @include flex-grow(1); 
            }
        }
    }
	.banner-info-details{
		h1 {
			margin-bottom:0px;
			padding-bottom:5px;
		}
		p {
			font-size:1rem;
		}
	}			    		    	
	> i {
		margin: -50px 40px -50px -60px;		
	}	
	.progress-label {
		font-size:1rem;
		color:lighten($romeo-primary, 50%);
		font-style:italic;
	}
}

.award-activity {
	text-align:center;
	padding: 40px;
	.row {
		display:flex;
	}
	i {
		height: 75px;
	    width: 75px;
	    border-radius: 50%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    padding: 0;
	    font-size: 1.5rem;
	    margin: 0 auto;
	    margin-bottom: 10px;
	    text-align: center;
	}
	h2 {
		margin-top:20px;
	    margin-bottom: 20px;
	    font-size: 1.5rem;		
	    text-align:center;
	}
	.btn {
	    margin: 40px auto 0;
	}
	.event-info-container {
		@include flexbox();
		@include flex-wrap(wrap);
		.event-info {
			margin-top:15px;
			border:$input-border;
			padding:10px;
			flex-grow: 1;	
			display:flex;	
		    flex-direction: column;
		    text-align: center;
		    align-items: center;
		    align-content: center;		
		    justify-content: center;	
		    i {
		    	margin:0;
		    	padding:0;
		    	height:auto;
		    	width:auto;
		    }	
		    .event-info-number {
		    	color: $romeo-primary;
		    	font-weight:bold;
		    	font-size:1rem;
		    }
		}		
	}
	.rating {
		margin-top: 30px;		
		i {
			display:inline;
		}
		.label {
			display:block;
			color:#333;
			margin-bottom:7px;
		}
	}
}

.filters {
	.dropdown {
		display:inline;
	}
	.input-text-slide-out {
	    float: right;
	    position: relative;
	    width: 30%;
	    display: inline-block;
	    float: right !important;
	    input {
	    	background:darken($content-background, 10%);
	    	@include placeholder {
	    		color:darken($content-background, 40%);
	    	}
	    }
	    button.btn-search {
	    	color:darken($content-background, 40%);
	    }
	}	
}

.count {
	background: lighten($romeo-primary, 20%);
	border-radius: 50%;
	height: 100px;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;	
	border:1px solid #fff;	 
	font-size: 2rem;   	
	margin-bottom: 10px;
	font-weight: bold;	
	&.count-lg {
		height: 100px;
		width: 100px;		
	}				 
	&.count-sm {
		height: 50px;
		width: 50px;
	}   
	&.count-xs {
		height:25px;
		width:25px;
	}
}