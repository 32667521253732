
// .page-operator {

// 	footer {
// 		border-top:1px solid #d7d7d7;
// 		padding:1rem;
// 		text-align: center;
// 		.col:first-child {
// 			text-align: left;
// 			}
// 		.col:last-child {
// 			text-align: right;
// 			}	
// 		.groupgti img {
// 			width:85px;
// 			}
// 		.langswitch-link {
// 			padding-right:.5rem;
// 			&.active {
// 				font-weight:500;
// 				}
// 			}
// 		}
		
// 	}


// footer {
//     position:relative;
//     position: sticky;
//     bottom: 0px;
//     height: 60px;
// 	color:$footer-text-color-mobile;
// 	padding:$footer-padding-mobile;
// 	background:$footer-background-mobile;
// 	margin:$footer-margin-mobile;
// 	border-top:1px solid #d7d7d7;
// 	@media(min-width: $screen-md){
// 		color:$footer-text-color-desktop;
// 		padding:$footer-padding-desktop;
// 		background:$footer-background-desktop;
// 		// margin:$footer-margin-desktop;		
// 		margin:0;		
// 	}
// 	a {
// 		color:$footer-a-tag-color;
// 		&:hover, &:active {
// 			color:$footer-a-tag-color;
// 			text-decoration: underline;
// 		}
// 	}
// 	.version {
// 		padding-top:10px;
// 	}
// 	.version, .terms {
// 		padding-right:125px;		
// 	}
// 	.langswitch-link { 
// 		//quick fix by ang to style the langswitch on operator only
// 		padding-right:15px;
// 	}
// 	// .groupgtilink {  OLD LOGO!
// 	// 	background: $footer-gti-background;
// 	// 	float: right;
// 	// 	margin: 0;
// 	// 	position: absolute;
// 	// 	bottom: 0;
// 	// 	right:$footer-gti-right-position-mobile;
// 	// 	@media(min-width: $screen-md){
// 	// 		right:$footer-gti-right-position-desktop;
// 	// 	}
// 	// 	a {
// 	// 		border: none;
// 	// 		font-weight: normal;
// 	// 		padding: 12px;
// 	// 		display: block;
// 	// 		color:$footer-gti-text-color;
// 	// 		span {
// 	// 			font-size:12px;
// 	// 			display:inline-block;
// 	// 		}	
// 	// 		img {
// 	// 			width: 25px;
// 	// 			vertical-align: text-bottom;
// 	// 		}	
// 	// 		&:hover, &:focus {
// 	// 			text-decoration:none;
// 	// 		}	
// 	// 	}
// 	// }
// 	.groupgtilink {
// 		margin:0;
// 		position: absolute;
// 		bottom: 15px;
// 		right:$footer-gti-right-position-mobile;
// 		@media(min-width: $screen-md){
// 			right:$footer-gti-right-position-desktop;
// 		}
// 		img {
// 			width:85px;
// 		}
// 	}
// }

// footer.chunky {
//     position:relative !important;
// 	height:auto;
// 	line-height:25px;
//     @include remConversion(font-size, 0.8);
// 	a {
// 		color:$footer-chunky-text-color;
// 	}   
// 	p.groupgtilink {
// 		position:relative;
// 		right:auto;
// 		float:right;
// 		a {
// 			padding: 12px;
// 			span {
// 				font-size:12px;
// 			}
// 		}
// 		img {
// 			height:auto;
// 		}
// 	}
//     img.alternate-layout-bottom-footer-logo {
//         height:$footer-chunky-logo-height;
//     }
//     h3 {
//         margin-top:0px;
//         border-bottom: 1px dotted;
//         padding-bottom: 4px;
// 		margin-bottom: 10px; 
// 		color:$footer-chunky-text-color;           
//     }	        
// }
