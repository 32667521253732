/** Woo hoo! New navbar!   Annoyingly, this has ended up being pretty messy :( **/
/** ugh!  I had a complete nightmare getting long nav items to work, across full width, mobile and mini states - in particular with mini mode and the dropdowns on hover (position absolute, heights, blah blah!).  SO?!  The menu item/title is duplicated within the dropdown - and then hidden/shown in different states

/*** MAIN SIDEBAR !!! ***/

.page-operator {

	.navbar {
		padding:0;	
		align-items: start;
		z-index:1000;
		background-color: $nav-bg;  //needed here to stop it flickering
		@media(min-width: $screen-lg) {
			min-height: 100vh;
			position:fixed;
			}
		
		.navbar-collapse.nav-sidebar {

			// ALL
			transition: width .2s, height .2s;
			z-index: 1000;
			padding:0;
			align-items: start;
			
			// MOBILE
			width:100%;
			margin-top:$header-height;
			background-color: $nav-bg; // needed here for mobile

			// LARGE SCREENS
			@media(min-width: $screen-lg) {		
				width: $nav-width; 	
				height: calc(100vh - #{$header-height-lg}); 
				top:0;
				margin-top: $header-height-lg; 
				position: sticky;
				overflow-x: hidden;  // hidden to stop a x scrollbar showing on toggle
				overflow-y: auto;  // without this the sticky nav doens't scroll
				}

			// TOGGLED/MINI
			// see below

			// menu items
			ul {
				padding:0;
				margin:5px 0 0 0;
				list-style: none;
				flex-direction: column !important; // overwriting BS nav
				width:100%;
				li {
					display: block;
					
					a {
						font-size: $nav-a-font-size;
						font-weight: 500;
						color: $nav-a-color;
						padding: .7rem .55rem;
						transition: $transition-base;
						display:block;
						white-space: normal; // needed because bootstrap has nowrap on .dropdown-toggle
						@media(min-width: $screen-lg) {	
							display: flex;
							width: $nav-width; // adding so the text doesn't jump around on toggle					
							}
						i {					
							margin-right:.4rem;
							height: 21px; // to stop jumping on toggle
							padding-top: 3px;
							@media(min-width: $screen-lg) {	
								display: block;
								width:28px;  //needed to use px because otherwise it was jumping around
								flex-shrink: 0;
								}
							}	
						.title {
							line-height: normal;
							@media(min-width: $screen-lg) {	
								display:block;
								width:160px; //needed to use px because otherwise it was jumping around
								margin-right:1rem;
								}
							}				
						}

					&.active,
					a:hover, a:focus, a:active {
						text-decoration: none;
						background-color: $nav-a-hover-bg;
						color: $nav-a-hover-color; 
						}
					&.active a {
						color: $nav-a-hover-color; 
						}

					/* dropdown arrows */
					.dropdown-toggle:after {
						content: "\f104";
						font-family: 'Font Awesome 5 Pro';
						border:none; // switching off the default dropdown
						position: absolute;
						right: 1rem;
						transition: $transition-base;
						opacity: 1;
						}
						
					// DROPDOWNS
					.dropdown-menu {
						background-color: $nav-dropdown-bg; 
						border:none;
						position: static !important; // overwriting BS
						padding:0;
						margin:0;
						border-radius: 0;
						//for transition
						display: block;
						max-height: 0;  // this is what's controlling the dropdown displaying (crazy I know!) - needed for a nice growing transition
						overflow: hidden;
						transition: $transition-base ;						
						li {
							&.dropdown-header {
								padding: .5rem 1rem .25rem 1rem;
								font-size:.85rem;
								font-weight:600;
								color: $nav-a-color;
								border-top:1px solid #ffffff21; //!! not sure this will work with different themes
								white-space: normal;
								&:nth-child(2),
								&:first-child {
									border-top:none;
									}
								&.operator-dropdown-header {
									display: none;  // hiding the duplicated menu title within a full width menu
									}
								}
							a {
								font-size: .8rem;
								padding:.5rem 1rem;
								white-space:normal;  // undoing no-wrap on the main menu
								}

							&[data-level="2"] {
								a {
									padding-left:1.5rem;   // also needs updating on mini sidebar below
									}
								}
							}
						li.dropdown-header {
							// new angular nav markup - ul dropdown within a ul dropdown
							padding:0;
							.title {
								padding: .5rem 1rem .25rem 1rem;
								display: block;
								}
							ul {							   
							   background-color: $nav-dropdown-bg;
							   li {
								   padding:0;		
								   .title {
										padding:0;
										} 
								   }
							   }
							&.active {
								background-color: $nav-dropdown-bg;
								}
							}
						}

					}
					.show .dropdown-menu, 
					.active.show .dropdown-menu { 
						max-height: 900px; // // this is what's controlling the dropdown displaying (crazy I know!) needed for a nice growing transition, too big and it will slow down the animation.  SO big because of the maintenance menu
						padding-top: 2px; // adding padding here to give space between the active top level item and active sub level
						}
					.show .dropdown-toggle:after,
					.active.show .dropdown-toggle:after {
						transform: rotate(-90deg);
						}
				}
				&.show {
					margin-bottom: -#{$header-height}; //!!  needed to hide the top-margin on content-wrapper
				}
			}
		}

	/*** TOGGLED/MINI SIDEBAR !!! ***/

	&.sidebar-toggled .page-wrapper > .navbar {  // being specific so it doesn't affect other navbar's

		position:absolute; // switching off fixed
		height:100%;

		.navbar-collapse.nav-sidebar {
			position:relative; // switching off sticky
			height:100%;
			width: $nav-mini-width; 
			overflow: visible; // !! this is not great, as it means you can't scroll a toggled nav (expand to see all) - but it's needed for li's to popout

			li {		
				z-index: 1000;
				a {
					transition: none; //otherwise it flickers too much :( 
					width:auto;
					.title {
						right: 180px; //new
						position: absolute; //new
						display:none; //new
						}
					}
				.dropdown-menu {
					position: relative !important; 
					display: none !important;   //display none until hover.  added important to overwrite .show (triggered by the dropdown toggle)
					background-color: $nav-dropdown-bg;					
					padding-top:2px;  // adding padding here to give space between the active top level item and active sub level
					li.operator-dropdown-header {
						display: none;  // hiding the duplicated menu title within a full width menu
						}
					a {
						// needed for new angular nav - undoing all the styling on the .title styling 
						.title {
							display:block;   
							position: relative;
    						right: 0;
							}
						&:hover, &:active, &:focus {
							.title {
								left: 0;
								position: relative;
								width: auto;
								padding: 0;
								}
							}
						}
					}	

				&:hover, &:focus, &:active {
					> a {
						overflow: visible;
						width: 260px;
						background-color: $nav-a-hover-bg; // needed here so it stays hovered when on the dropdown
						color: $nav-a-hover-color; 
						
						.title {
							display: block;
							left: $nav-mini-width; // !!
							background: $nav-a-hover-bg;
							position: absolute;
							width: 212px; // !!
							padding:0 0 .7rem 0.75rem;						
							}

						&.dropdown-toggle {
							.title {
								display:none;  // hiding the main nav title when there are dropdowns, title now displays within the dropdown menu
								}
							}
						}
					.dropdown-menu {
						max-height: none; 
						position: absolute !important;
						left: $nav-mini-width;
						display: block !important;
						width: calc(260px - #{$nav-mini-width});
						overflow:visible;
						li {
							a {
								padding:.5rem 1rem;
								width:auto;
								}
							&[data-level="2"] {
								a {
									padding-left:1.5rem;   // also needs updating on mini sidebar below
									}
								}
							&.operator-dropdown-header {  // displaying the duplicated menu title (within dropdown) instead of the normal title.
								background:$nav-a-hover-bg;
								color:$nav-a-hover-color;
								padding: 0.2rem 0rem;
								white-space: normal;
								margin-top: -43px;
								margin-bottom: 6px; // added to create space for the next item hover
								display: block;
								}
							}
						}
					}

				.dropdown-toggle:after {
					display:none;
					}	

				}
			}
		}
		&.sidebar-toggled.page-angular .page-wrapper > .navbar {
			.navbar-collapse.nav-sidebar li:hover>a.dropdown-toggle .title {
				// needed for new angular nav - undoing all the styling on the .title styling 
				display: block;
				}			
			}


	// Scroll bar
	.navbar-collapse.nav-sidebar::-webkit-scrollbar {
		width: .5rem;
		background-color:transparent;
		} 
	.navbar-collapse.nav-sidebar::-webkit-scrollbar-thumb {
		background-color: #ffffff36;
		border:2px solid $nav-bg;
		border-radius: 1rem;
		}
	

	// SUB SUB NAV (i.e. work with)

	.subnav {
		background:$nav-sub-bg;
		min-height:auto;
		position:relative;
		font-size: $nav-sub-a-font-size;
		border-top: $nav-sub-border;
		border-bottom: $nav-sub-border;
		padding:0 .55rem;
		font-weight:500; 
		justify-content: start;
		z-index: auto;
		.navbar-nav {
			@media(min-width: $screen-lg) {			
				align-items: center;
				}
			a {
				color:$nav-sub-a-color;	
				@media(min-width: $screen-lg) {			
					text-align: center;
					}
				&:hover, &:active, &:focus {
					color:$nav-sub-a-hover-color
					}
				}
			// dividing line
			@media(min-width: $screen-lg) {	
				li {
					position: relative;		
					&::before {   //adding before, so we don't mess with the dropdown items
						display: block;
						height: 15px;
						width: 1px;
						background: #c7cbd1;
						content: "";
						position: absolute;
						top: 33%;
						left: 0;
						}
					&:first-child {
						&::before {
							display: none;
							}
						}	
					}
				}

			.dropdown-menu {
				@media(max-width: $screen-lg) {
					border:none;  //removing the dropdown border on mobile
					}
				li {
					a {
						font-size: $nav-sub-a-font-size;
						text-align: left;
						padding: 0.5rem 0.75rem;  // styling here because it's different markup in TC
						}
						&.operator-dropdown-header {
							display: none;  // hiding the duplicated menu title within a full width menu
						}
					}
					::before {
						display: none;  // hiding the dividing line
						}
				}

			}
		.navbar-toggler {
			color:$link-color;
			}
		}
	}


// .navbar-default {
// 	margin-bottom:0px;
// 	background:transparent;
// 	box-shadow: none;
// 	border:none;
// 	box-shadow: none;
// 	z-index:99;
// 	z-index:20;
// 	@media(min-width: $screen-sm) {
// 		margin-top:$navigation-margin;
// 		background:$primary-background-color;
// 		min-height:50px;
// 		&.navbar-hidden {
// 			margin-top:$logo-desktop-size;
// 		}
// 	}
// }
// .navbar-collapse {
// 	padding-left:0px;
// 	padding-right:0px;
// }
// .navbar-nav > li:first-child {
// 	border:none;
// }
// .navbar-nav > li:last-child {
// 	border:none;
// }
// .navbar-nav > li {
// 	border:none;
// }
// .navbar-nav {
// 	margin-left:0px;
// 	margin-top:-1px;
// 	margin-right:0px;
// 	min-height:50px;
// 	@media(min-width: $screen-sm) {
// 		margin-top:0px;
// 	}	
// 	li {
// 		margin-right:$mobile-navigation-primary-item-margin-right;
//         @media(min-width: $screen-sm) {
//             margin-right:$desktop-navigation-primary-item-margin-right;
//         }	        
// 		a {
// 			padding-top: 17px;
// 			padding-bottom: 15px;
// 			background: $mobile-navigation-primary-background;
// 			color:$mobile-navigation-primary-text-color;
//             @media(min-width: $screen-sm) {
//                 background: $desktop-navigation-primary-background;
//                 color:$desktop-navigation-primary-text-color;
//             }	            
// 		}
// 	}
// }
// .navbar-default .navbar-collapse, .navbar-default .navbar-form {
//     position: fixed;
//     overflow: visible;
//     top:35px;
//     right:$mobile-navigation-spacing;
//     width:auto;
//     left:$mobile-navigation-spacing;
//     bottom:0;
//     background: $mobile-navigation-primary-background;
//     overflow-y:auto;
//     overflow-x:hidden;
//     padding:$mobile-navigation-padding;
//     @media(min-width: $screen-sm){
//         background: $desktop-navigation-primary-background;
//     	right:0px;
//     	left:0px;
//     	position:absolute;
//     	top:0;
//     }
// }
// .navbar-default .navbar-nav > li > a {
// 	color:$mobile-navigation-primary-text-color;
// 	padding-left:$mobile-navigation-primary-padding-left;
// 	padding-right:$mobile-navigation-primary-padding-right;
// 	@include remConversion(font-size, 0.85);
// 	@media(min-width:$screen-sm){
//         color:$desktop-navigation-primary-text-color;
// 		padding-left:$desktop-navigation-primary-padding-left;
// 		padding-right:$desktop-navigation-primary-padding-right;		
// 	}
// }

// .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
// 	text-shadow: none;
// 	box-shadow: none;
// 	color:$mobile-navigation-primary-active-text-color;
// 	font-weight:bold;
// 	background:$mobile-navigation-primary-active-background;
// 	@media(min-width:$screen-sm){
// 		color:$desktop-navigation-primary-active-text-color;
// 		font-weight:normal;
// 		background:$desktop-navigation-primary-active-background;	
// 	}	
// }
// .navbar-toggle .icon-bar {
// 	background:$mobile-toggle-icon-bar;
// }
// .mobile-user-details {
// 	background:$menu-items-navigation-mobile;
//     padding: 10px 15px;
//     margin-top: $mobile-user-details-margin-top;
// 	.btn-primary {
// 		background: $tertiary-background-color;
// 		padding-top: 6px;
// 		padding-bottom: 8px;
// 		padding-right: 10px;
// 		border: 1px solid lighten($menu-items-navigation-mobile, 10%);
// 		border-radius:0px;
// 		color:$tertiary-text-color;
// 		&:hover, &:focus {
// 			background: $tertiary-background-color;
// 			color:$tertiary-text-color;
// 			border: 1px solid lighten($menu-items-navigation-mobile, 10%);
// 		}	
// 	}
// 	.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
// 		background: $tertiary-background-color;
// 		color:$tertiary-text-color;
// 		border: 1px solid lighten($menu-items-navigation-mobile, 10%);		
// 	}
// 	@extend .clearfix;
// 	.sign-out {
// 		display:inline-block;
// 		background: $primary-background-color;
// 		color: $primary-text-color;
// 		padding: 7px 10px;
// 		font-size: 16px;
// 		.fa {
// 			padding-right:0px;
// 		}		
// 	}
// 	.username {
// 		float:left;
// 		background: lighten($navigation-item-color, 1%);
// 		color: $secondary-text-color;
// 		padding-right: 0;
// 		margin-right: 10px;
// 		border: 1px solid lighten($navigation-item-color, 10%);		
// 		i {
// 			font-size: 18px;
// 			color: $primary-text-color;
// 			background: $secondary-background-color;
// 			padding: 8px 9px 9px;			
// 		}
// 	}
// 	.partition-selector {
// 		display:inline-block;
// 		float: left;
// 		margin-right: 10px;		
// 	}
// 	.divider {
// 		display:inline-block;
// 	}
// 	.open > .dropdown-menu {
// 		background: $profile-menu-background;	
// 		margin-top:15px;
// 		border-radius:0px;
// 		&.partition-menu {
// 			background: $partition-menu-background;	
// 			border-radius:0px;
// 			&:before {
// 				border-color: transparent transparent $partition-menu-background;
// 			}
// 			> li {
// 				border-bottom:1px solid darken($partition-menu-background, 10%);
// 			}
// 			> a {
// 				color:$partition-menu-color;
// 				&:focus, &:hover {
// 					color:$partition-menu-color;;
// 				}
// 				i {
// 					color:$partition-menu-color;
// 				}
// 			}			
// 		}
// 		&:before {
// 			top: -10px;
// 		    left: -1px;
// 		    width: 0;
// 		    height: 0;
// 		    content: ' ';
// 		    position: absolute;
// 		    border-style: solid;
// 		    display: inline-block;
// 		    border-width: 0px 10px 10px 0px;
// 		    border-color: transparent transparent $profile-menu-background;
// 		}			
// 		>li {
// 			padding-bottom:7px;
// 			padding-top: 5px;
// 			border-bottom:1px solid darken($profile-menu-background, 10%);
// 			&:last-child {
// 				padding-bottom:0px;
// 				border:none;
// 			}
// 			&:first-child {
// 				padding-top:0px;
// 			}			
// 			> a {
// 				font-size:0.8rem;
// 				color:$profile-menu-color;
// 				&:focus, &:hover {
// 					background:transparent;
// 					color:$profile-menu-color;;
// 				}
// 				i {
// 					padding: 0;
// 					margin: 0;
// 					font-size: .7rem;
// 					margin-right: 10px;
// 					margin-left: -5px;
// 					background:transparent;
// 					color:$profile-menu-color;
// 					width:15px;
// 				}
// 			}

// 		}
// 	}   	
// }
// .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
// 	background:$mobile-navigation-primary-hover-background;	
// 	color: $mobile-navigation-primary-hover-text-color;	
// 	@media(min-width:$screen-sm){
//         background:$desktop-navigation-primary-hover-background;	
//         color: $desktop-navigation-primary-hover-text-color;		
// 	}	    
// }
// .navbar-toggle {
// 	margin-right: 0px;
// 	padding-right: 0px;
// 	position: relative;
// 	padding-top: 0;
// 	padding-bottom: 0px;
// 	top: calc(#{$logo-mobile-size} / 2 - 15px);
// 	margin-top: 0px;
// 	float:right;
// 	background:transparent;
// 	border:none;
// 	.icon-bar {
// 		display: block;
// 		width: 22px;
// 		height: 2px;
// 		border-radius: 1px;
// 		+.icon-bar {
// 			margin-top: 4px;
// 			}
// 		}
// 	}
// .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
// 		background:$mobile-navigation-primary-hover-background;	
// 		color: $mobile-navigation-primary-hover-text-color;	
//         @media(min-width: $screen-md){
//             background:$desktop-navigation-primary-hover-background;	
//             color: $desktop-navigation-primary-hover-text-color;	
//         }
// }
// .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu>li>a {
//     padding: 15px 15px 15px 15px;
// 	@media(min-width: $screen-sm){
// 		padding:6px 15px 6px 15px;
// 	}    
// }
// .navbar-default .navbar-nav .open .dropdown-menu, .navbar-default .navbar-nav .show .dropdown-menu {
// 	background:$mobile-navigation-secondary-background;
// 	border-radius:0px;
//     padding:0px;
// 	@media(min-width: $screen-sm){
// 		background: $desktop-navigation-secondary-background;
// 		border-top:none;
// 		box-shadow:none;
// 	}	
// 	> li {
// 		margin-right:0px;
// 		> a, span {
// 			background:$mobile-navigation-secondary-background;
// 			color:$mobile-navigation-secondary-text-color;
// 			@media(min-width: $screen-sm){
// 				background: $desktop-navigation-secondary-background;
// 				color:$desktop-navigation-secondary-text-color;
// 				font-size: .85rem;
// 			}
// 			&:hover, &:focus  {
// 				background:$mobile-navigation-secondary-hover-background;
//                 color:$mobile-navigation-secondary-hover-text-color;
//                 @media(min-width: $screen-sm){
// 				    background:$desktop-navigation-secondary-hover-background;
//                     color:$desktop-navigation-secondary-hover-text-color;
//                 }                
// 			}
// 		}
//         &.active > a {
//             background: $mobile-navigation-secondary-active-background;
//             color: $mobile-navigation-secondary-active-text-color;
//             @media(min-width: $screen-sm){
//                 background: $desktop-navigation-secondary-active-background;
//                 color: $desktop-navigation-secondary-active-text-color;
//             }              
//         }
// 	}
// }
// @media(min-width: $screen-sm){
// 	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
// 	    position: relative;
// 	    width: auto;
// 	    background:transparent;
// 	    min-height:auto;
// 	}
// }
// .flexMenu-popup {
// 	position: absolute;
// 	margin: 0;
// 	list-style: none;
// 	right:0;
// 	min-width:220px;
// 	padding: 5px 0;
// 	box-shadow: 0 6px 12px rgba(0,0,0,.175);
// 	border: 1px solid #ccc;
// 	border: 1px solid rgba(0,0,0,.15);	
// 	display:flex;
// 	flex-direction:column;	
// 	background: $navigation-secondary-background;
// 	color:$navigation-secondary-text-color;	
// 	border-top:none;
// 	li {
// 		width:100%;
// 		padding:6px 15px 6px 15px;
// 		width:100%;			
// 		&.open {
// 			ul {
// 				display:flex;
// 				flex-direction:column;
// 				top:0;		
// 				margin-top: 5px;					
// 			}
// 			> a {
// 				text-decoration:none;
// 				display:block;
// 				&:hover, &:focus {
// 					background:transparent;
// 					color:$navigation-secondary-text-color;
// 					display:block;
// 				}
// 			}	
// 			.dropdown-menu {
// 				background:transparent !important;
// 				span {
// 					padding-left:0px;
// 					padding-right:0px;
// 				}
// 				li {
// 					a {
// 						padding-left:0px;
// 						padding-right:0px;						
// 					}
// 				}
// 			}		
// 		}		
// 		ul {
// 			border:none;
// 			padding:0;
// 			margin:0;
// 			position:relative;
// 			box-shadow:none;
// 			li {
// 				padding:0;
// 				a {
// 					padding:0;
// 				}				
// 			}
// 		}	
// 		a {
// 			padding:0;	
// 			background:transparent;
// 			color:$navigation-secondary-text-color;
// 		}
// 	}
// }
// .submenu {
// 	.navbar {
// 		background:$navigation-sub-menu-background;
// 		border-bottom:1px solid $navigation-sub-menu-background;
// 		border-radius: 0px;
// 		margin-bottom: 0px;
// 		z-index:10;
// 		.navbar-nav {
// 			min-height:auto;
// 			li {
// 				margin-right:0px;
// 				padding-top:10px;
// 				padding-bottom:10px;
// 				@include remConversion(font-size, $text-font-size);
// 				a {
// 					background: transparent;
// 				    padding: 0px 15px;
// 				    border-right: 1px solid $navigation-sub-menu-text-color;
// 					color:$navigation-sub-menu-text-color;
// 					&:hover, &:focus  {
// 						color:lighten($navigation-sub-menu-text-color, 100%);		
// 						text-decoration: underline !important;	
// 					}

// 				}
// 				&:last-child a {
// 					border-right:none;
// 				}
// 				ul {
// 					border-radius:0px;
// 					background:darken($navigation-sub-menu-background,10%);
// 					li {
// 						a {
// 							color:lighten($navigation-sub-menu-text-color, 100%);	
// 							@include remConversion(font-size, $text-font-size);
// 							border-right:none;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	@media(max-width: $screen-sm){
// 		top:80px;
// 		.navbar-nav {
// 			margin:0;
// 			li {
// 				display:inline-block;
// 			}
// 		}
// 		}
// 	}
// }