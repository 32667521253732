
.romeo {

    @include buttonStyle("primary", $romeo-primary, color-yiq-r($romeo-primary));
    @include buttonStyle("secondary", $romeo-secondary, color-yiq-r($romeo-secondary));
    @include buttonStyle("tertiary", $romeo-tertiary, color-yiq-r($romeo-tertiary));
    @include buttonStyle("quaternary", $romeo-quaternary, color-yiq-r($romeo-quaternary));
    
    @include buttonClear("primary", $romeo-primary, color-yiq-r($romeo-primary));
    @include buttonClear("secondary", $romeo-secondary, color-yiq-r($romeo-secondary));
    @include buttonClear("tertiary", $romeo-tertiary, color-yiq-r($romeo-tertiary));
    @include buttonClear("quaternary", $romeo-quaternary, color-yiq-r($romeo-quaternary));

    // Base
    .btn {
        border-radius: 8px;
        font-size: .9rem;
        padding: .6rem 1rem;
        text-decoration: none;
        font-weight: 600;
        .caret {
            margin-left: 8px;
        }
        &:focus-visible {
            outline: 1px auto #000;
            outline-offset: 4px;
          }
    }
    .btn-sm {
        font-size:.8rem;
        padding: .3rem .5rem;
    }

    .dropdown-menu > li > a {
        white-space: normal;
    }

    /** added by ang to style TJ stuff within TC **/
    .btn-inferno { 
        background-color: #4E21FF !important;
        border-color: #4e21ff !important;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem !important;
        box-shadow: 0px 1px 4px #0000001a !important;
        border-radius: 2px;
        font-weight: 700;
        color: #fff;
        width: auto !important;
        display: inline-block;
        }

    .btn-outline-primary, .btn-outline-secondary {
        padding-top:.5rem;
        padding-bottom:.5rem;
        }

    // **WIP** new fancy buttons (ang) - currently only on employer ui > jobs manage
    .btn-round-group {
        padding-bottom: 1.1rem; // adding some padding to make space for the words under the button

        .btn-round {
            border: 1px solid #dcdcdc;
            background: #ffffff;
            border-radius: 100%;
            color: $romeo-secondary;
            height:34px;
            width:34px;
            position: relative;
            margin-left: .5rem;
            padding:0;
            &:hover {
                background:$romeo-secondary;
                color:color-yiq-r($romeo-secondary);
                transition: all .3s linear
            }    
            &:first-child {
                margin-left: 0;
            }  
            .btn-round-icon {
                position: absolute;
                display: block;
                width: 1.2em;
                height: 100%;
                text-align: center;
                line-height: 2.2;
                z-index: 1;
                top: 0;
                left: 25%;  // some crazy styles to get the icons in the center!
            }        
            .btn-round-text {
                display: block;
                color: #292929;
                font-size: .7rem;
                position: absolute;
                bottom: -20px;  
                left: -5%;
                text-align: center;
                width: 110%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 600;
            }
        }
    }

    // **WIP** new fancy buttons (ang) - these are the round buttons that display the icon and text next to each other (currently previewed on link>basket)
    // .btn-round-flat-group {
    //     a {
    //         display:inline-block;
    //         margin-right:25px;

    //         .btn-round-icon {
    //             border: 1px solid #dcdcdc;
    //             background: #ffffff;
    //             border-radius: 100%;
    //             color: $romeo-secondary;
    //             height:28px;
    //             width:28px;
    //             position: relative;
    //             margin-left: .5rem;
    //             padding:0;
    //             display: inline-block;
    //             &:hover {
    //                 background:$romeo-secondary;
    //                 color:color-yiq-r($romeo-secondary);
    //                 transition: all .3s linear
    //             }    
    //             &:first-child {
    //                 margin-left: 0;
    //             }  
    //             .fal {
    //                 position: absolute;
    //                 display: block;
    //                 width: 1.2em;
    //                 height: 100%;
    //                 text-align: center;
    //                 line-height: 2.2;
    //                 z-index: 1;
    //                 top: 0px;
    //                 left: 6px;
    //                 font-size: .75rem;
    //             }        
    //         }
    //         .btn-round-text {
    //             display: inline-block;
    //             color: #292929;
    //             font-size: .7rem;
    //             font-weight: 600;
    //             position: relative;
    //             top: -10px;
    //             left: 7px;
    //         }
    //     }
    // }

}