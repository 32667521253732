// custom colours for Appointment Types
$AT-customColor1: #eee;  // bg colour
$AT-customColor2: #fff;  // bg colour
$AT-customTextColor1: $default-text-color; 
$AT-customTextColor2: $romeo-primary;

// appointment types
.AT {

    // Start: Added by Robbie
    .AT-modal-image {
        width: 150px;
        min-width: 150px;
        background-image: url('https://cdn.groupgti.com/targetconnect/appointment-default-image.jpg');
        background-position: center;
        background-size: cover;
    }

    .AT-customBgcolor1 {
        background-color: $basic-text-color !important;   
    }

    h2._session-type-name {
        color:$AT-customTextColor2;
    }

    // Added by Robbie
    .session-image {
        width: 150px;
    }

    .AT-customColor1 { 
        background-color: $AT-customColor1 !important;
        color: $AT-customTextColor1 !important;
        // .btn.AT-textCustom1 {  // removing these and adding btn-primary to the actual buttons
        //     background: $AT-customBtnColor !important;
        //     //color: color-yiq-r($AT-customBtnColor) !important; //adding important because otherwise the hover is wrong         
        // }
        .text-white {
            color: $AT-customTextColor1 !important;
        }
    }

    .AT-customColor2 {
        background-color: $AT-customColor2 !important; 
        color: $AT-customTextColor1 !important;
        // .btn.AT-textCustom1 {
        //     background: $AT-customBtnColor !important;
        //     color: color-yiq-r($AT-customBtnColor) !important; //adding important because otherwise the hover is wrong         
        // }
        .text-white {
            color: $AT-customTextColor1
        }
    }

    .AT-textCustom1 {
        color: $AT-customTextColor1; 
    }

    .AT-borderColorCustom1 {
        border-color: $AT-customColor1 !important;
    }

    .AT-borderColorCustom2 {
        border-color: $AT-customColor1 !important;
    }

    .AT-borderBottomColorCustom1 {
        border-bottom: 1px solid $AT-customColor1 !important;
    }

    .AT-slotsAvailableText {
        line-height: 14px;
        .reason {
            font-size:75%;
        }
    }

    .AT-toggleColor {
        input:checked+.switch {
            background-color: $AT-customTextColor2;
        }
    }

    // book button in appointment slot table
    .AT-appointmentSlotSelected {
        background: $success;
        color: $AT-customTextColor1;
    }

    .AT-card {


        // @media (min-width: 768px) {
        //     .AT-noAppointmentMessage {
        //         width: 11rem;
        //     }
        // }

        .AT-uploadZone p {
            position: initial;
        }

        .card-header {
            p {
                min-width: 100%;
                @media (min-width: 768px) {
                    min-width: 360px; 
                }
            }
        }

    }

    .AT-heroImage {
        background: url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_header.png') no-repeat left;
        background-size: cover;
    }

    .AT-image {
        background: #d3d3d3 url('https://cdn.groupgti.com/targetconnect/appointment-default-image.jpg') center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 150px;
        width: 100%;

        @include media-breakpoint-up(md) {
            background-size: cover;
        }

        @include media-breakpoint-up(lg) {
            width: 150px;
            min-width: 150px;
            height: auto;
        }
    }

    .AT-Image1 {
        background: #d3d3d3 url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_panel_1.png') no-repeat right top;
        background-size: cover;
    }

    // .AT-Image2 {
    //     background: #d3d3d3 url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_panel_2.png') no-repeat right top;
    //     background-size: cover;
    // }

    // .AT-Image3 {
    //     background: #d3d3d3 url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_panel_3.png') no-repeat right top;
    //     background-size: cover;
    // }

    // .AT-Image4 {
    //     background: #d3d3d3 url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_panel_4.png') no-repeat right top;
    //     background-size: cover;
    // }

    // .AT-Image5 {
    //     background: #d3d3d3 url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_panel_5.png') no-repeat right top;
    //     background-size: cover;
    // }

    // .AT-Image6 {
    //     background: #d3d3d3 url('https://images1.groupgti.com/targetconnect/2019/appointment-types/images/at_panel_6.png') no-repeat right top;
    //     background-size: cover;
    // }

    .AT-Angle-image {
        -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
    }

    button.viewAppointmentBtn {
        &.dropdown-toggle {

        span.caret {
            transform: rotate(0deg);
            transition: transform 0.5s;
        }

        &.btn-success {
            span.caret {
                transform: rotate(180deg);
                transition: transform 0.5s;
                }
            }
        }
    }
}