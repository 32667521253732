// used on appointment dropins

$avatar-background: $primary;
$avatar-color: $primary-text-color;

.avatar-initials {
    @include flexbox;
    @include justify-content(center);
    background-color: $avatar-background;
    color:$primary-text-color;;
    height: 120px;
    span {
        align-self: center;
        font-size: 5em;
        margin-top: -5px;        
    }
    &.avatar-initials-circle {
        height: 150px;
        width: 150px;
        border-radius: 50%;        
    }
    &.avatar-initials-circle-sm {
        height: 100px;
        width: 100px;
        border-radius: 50%;    
        span {
            @include remConversion(font-size, 3);
        }    
    }   
    &.avatar-initials-circle-xs {
        height: 50px;
        width: 50px;
        border-radius: 50%;    
        span {
            @include remConversion(font-size, 1);
        }    
    }        
}