//
// Helpers mixin
// --------------------------------------------------

$percentages: 100;

@function calculateRem($size) {
	$remSize: $size / 16px;
	@return #{$remSize}rem;
}

@function multiply-rem($value1, $value2) {
  @return $value1 * $value2;
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin fontSize($size) {
	font-size: $size; //Fallback in px
	font-size: calculateRem($size);
}

@mixin remConversion($property, $values...) {
	$max: length($values);
	$pxValues: '';
	$remValues: '';

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$pxValues: #{$pxValues + $value*16}px;

		@if $i < $max {
			$pxValues: #{$pxValues + " "};
		}
	}

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value}rem;

		@if $i < $max {
		  $remValues: #{$remValues + " "};
		}

	}

	#{$property}: $pxValues;
	#{$property}: $remValues;
}

@mixin remConversionImportant($property, $values...) {
	$max: length($values);
	$pxValues: '';
	$remValues: '';

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$pxValues: #{$pxValues + $value*16}px;

		@if $i < $max {
			$pxValues: #{$pxValues + " "};
		}
	}

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value}rem;

		@if $i < $max {
		  $remValues: #{$remValues + " "};
		}
	}

	#{$property}: $pxValues !important;
	#{$property}: $remValues !important;
}
@function strip-px($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin float($class, $screen-size){

	$alignments: left, right, none;

	@each $alignment in $alignments {

		.#{$class}-#{$screen-size}-#{$alignment} {
			float: $alignment !important;
		}

	}
}
