.widget-tabs {
	border-bottom: $content-block-border;
	margin-bottom:15px;
	@extend .clearfix;
	.nav-tabs > li > .tab-nolink, .nav-tabs > li > a {
		background:$content-block-background-color;
		border:none;
		font-weight:normal;
		padding:0px 12px 10px;
		color:$content-block-text-color;
		text-transform: capitalize;
	}
	.badge {
		background:$widget-primary-background;
		color: $primary-text-color;
	}
	.button-container {
		position: absolute;
		right: 15px;
		top: 8px;	
		.dropdown {
			display:inline;
		}
		.btn {
			padding: 4px 10px 5px;
			margin-left:7px;
		}		
		.checkbox {
			label {
				padding-left:30px;
				input[type="checkbox"] {
					height:15px;
					margin-left:-20px;
				}		
			}
		}	
	}
	.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        background-color: transparent;
        color:$content-block-text-color;
        border:none;
		border-bottom:3px solid $widget-primary-background;        
	}
	.nav-tabs > li > a:focus, .nav-tabs > li > a:hover {
        text-decoration: none;
		border-bottom:3px solid lighten($widget-primary-color, 20%);
		transition:none;
	}
	h2 {
		margin-top:0px;
		// text-transform:capitalize; //AB TC-19798 we should not be doing this in CSS 
		font-family:$heading-font;
		float:left;
		margin-bottom:0px;
		padding-bottom:12px;
		border:none;
	}
	.nav-tabs {
		float:right;
	    border-bottom: none;
	    padding-bottom: 0px;
	    margin-bottom: 0px;
	}
}

.content-block-tabs {
	border-bottom: $content-block-border;
	margin-bottom:15px;
	@extend .clearfix;
	.nav-tabs > li > .tab-nolink, .nav-tabs > li > a {
		background:$content-block-background-color;
		border:none;
		font-weight:normal;
		padding:0px 12px 10px;
		color:$content-block-text-color;
		text-transform: capitalize; 
	}
	.badge {
		background:$widget-primary-background;
		color: $primary-text-color;
	}
	.button-container {
		position: absolute;
		right: 15px;
		top: 8px;	
		.dropdown {
			display:inline;
		}
		.btn {
			padding: 4px 10px 5px;
			margin-left:7px;
		}		
		.checkbox {
			label {
				padding-left:30px;
				input[type="checkbox"] {
					height:15px;
					margin-left:-20px;
				}		
			}
		}	
	}
	.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        background-color: transparent;
        color:$content-block-text-color;
        border:none;
		border-bottom:3px solid $widget-primary-background;        
	}
	.nav-tabs > li > a:focus, .nav-tabs > li > a:hover {
        text-decoration: none;
		border-bottom:3px solid lighten($widget-primary-color, 20%);
		transition:none;
	}
	h2 {
		margin-top:0px;
		text-transform:uppercase;
		font-family:$heading-font;
		float:left;
		margin-bottom:0px;
		padding-bottom:12px;
		border:none;
	}
	.nav-tabs {
		float:right;
	    border-bottom: none;
	    padding-bottom: 0px;
	    margin-bottom: 0px;
	}
}