.romeo {

    .form-control {
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: .85rem;
        font-weight: 400;
        line-height: 1.5;
        color: $default-text-color;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d8d8;
        border-radius: .25rem;
    
        &:focus {
            color: $default-text-color;
            background-color: $white;
            border-color: $gray-600;
            outline: 0;
            box-shadow: none;
        }

        &[readonly], &[disabled] {
            color: #aaa;
            background-color: #eee;
        }
    &.form-control-lg {
        font-size: 1.15rem;        
        }
    }


    .radio-inline, .radio {
        color: $default-text-color;
        input[disabled] {
            color: #aaa;
            &:checked {
                background: #585858;
                border: 1px solid #585858;                    
            }
        }
    }

    textarea.form-control {
        height: auto;
    }    

    // date picker
    .datepicker.datepicker-dropdown {
        padding:5px;
    }
    .datepicker table tr td, .datepicker table tr th {
        border-radius: 0;
    }

    // headings - ANG, WIP
    .form-horizontal {
        .legend {
            margin: 30px 0 20px 0;
            text-transform: none;
            color: $romeo-primary;
        }
    }

    .input-group .form-control {
        height: 40px;
    }


    .custom-control-label {
        // adding here so it picks up variables
        &::before {
            border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
            }
        &::after {
            background-color: $custom-control-indicator-border-color;
            }
        }

}


// formio
.formio-component-datetime .input-group input.form-control {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    }
.formio-component input.form-control,
.formio-component .input-group {
    width:100%;
    }
.formio-component .input-group input.form-control {
    width:unset;
    }
// formio ordering, moving description below label and above input.  TC-29021
.formio-component {
    display:flex;
    flex-direction: column;
    label.col-form-label {
        order: 1;
        padding: 0;
        margin: .5rem 0 .3rem 0;
        }
    .form-text {
        order:2;
        font-size: .82rem;
        margin: 0 0 .4rem 0;
        }
    div[ref="element"], .form-radio, .formio-choices,
    .formio-select-autocomplete-input, // need to check autocomplete
    .list-group.list-group-striped, .fileSelector // fileupload
      {        
        order:3;
        }
    .formio-errors {
        order:4;
        }
    .form-check {
        padding-left: 0;
        margin-bottom: .35rem;
        }
    }
.row.formio-component, 
.formio-component-columns {
    // catering for columns within a form (ie. date rand)
    flex-direction: row;
    .col-md-6:nth-child(2) {
        display: flex;
        align-items: flex-end;
        .formio-component {
            width:100%;
            label.col-form-label {
                display: none;
                }
            }
        }
    }
.formio-component-fieldset fieldset {
    // catering for the address field
    legend {
        margin-bottom:0;
        border-bottom:none;
        }
    .form-group {
        margin-bottom:0;
        }
    label.col-form-label {
        order: 2;
        }
    .form-text {
        order:1;
        }
}