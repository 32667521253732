// ANG - quick hack to hide a new button that we don't want to go live - TC-25889
#activity-status-change-container {
    display:none;
    }

// GENERAL STYLES
@mixin make-pathway-type-classes($pathway-name, $pathway-type-color, $pathway-text-color) {
    .pw-#{$pathway-name} {
        &.pw-card, .pathways-image {
            background-image: url('https://images1.groupgti.com/targetconnect/2019/Pathways/pw-#{$pathway-name}-default-bg.png');
        }

        .pw-type-color-bg {
            background-color: $pathway-type-color;
            color: $pathway-text-color;

            h1,
            h2,
            p,
            a {
                color: $pathway-text-color;
            }
        }

        .pw-type-color-text {
            color: $pathway-type-color;
        }

        .pie-wrapper .label {
            background-color: $pathway-type-color;
            color: $pathway-text-color;
        }

        .btn-primary, .btn-secondary {
            background-color: $pathway-type-color;
            color: $pathway-text-color !important;
            border-color:$pathway-type-color;

            &:hover {
                background-color: darken($pathway-type-color, 15%);
                color: $pathway-text-color;
            }
        }

        .hexagon {
            border-color: $pathway-type-color;

            i {
                color: $pathway-type-color;
            }

            .hexagon-top,
            .hexagon-bottom {
                border-color: $pathway-type-color;
            }

            &.solid {
                background-color: $pathway-type-color !important;

                i {
                    color: $pathway-text-color;
                }
            }
        }

        #pw-levels-container .pw-level:nth-child(even) {
            .hexagon.solid {
                background-color: $pathway-type-color !important;
            }
        }

        #pw-single-hero {
            background-image: url('https://images1.groupgti.com/targetconnect/2019/Pathways/pw-#{$pathway-name}-default-bg.png');
        }
    }

    .pw-operator-template.#{$pathway-name} {
        .type {
            color: $pathway-type-color;
        }
    }
}

@include make-pathway-type-classes('placement', $pathways-type-colour-placement, #ffffff);
@include make-pathway-type-classes('assessment', $pathways-type-colour-assessment, #ffffff);
@include make-pathway-type-classes('award', $pathways-type-colour-award, #ffffff);
@include make-pathway-type-classes('basic', $pathways-type-colour-basic, #ffffff);
@include make-pathway-type-classes('support', $pathways-type-colour-support, #ffffff);
@include make-pathway-type-classes('learning', $pathways-type-colour-learning, #ffffff);
@include make-pathway-type-classes('skills', $pathways-type-colour-skills, #ffffff);

// PATHWAYS STUDENT LIST STYLES
.pw-card {
    width: 100%;
    max-width: 325px;
    height: 325px;
    vertical-align: top;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .pw-card-top {
        margin-top: 215px;
        position: relative;
        transition: all 0.5s;
        top: 0;

        .pw-title-container {
            height: 70px;

            .pw-title {
                vertical-align: bottom;
                font-weight: 600;
            }
        }

        .percentage-container {
            width: 0;
            height: 0;
            position: relative;
            margin: 0 0 0 auto;
            border-bottom: 60px solid #ffffff;
            color: #333333;
            border-left: 60px solid transparent;
            margin-top: -50px;

            .inner {
                position: absolute;
                top: 30px;
                right: 6px;
                transform: rotate(-45deg);

                &.complete {
                    transform: none;
                }
            }
        }
    }

    .pw-card-bottom {
        background-color: #ffffff;
        height: 215px;
        overflow-y: auto;

        .pw-description {
            min-height: 60px;
            margin: 25px 0 0;

            h1, h2, h3, h4 {
                font-size: 1rem;
            }
        }

        .pw-stats-container {
            margin-top: 10px;
        }

        .pw-button-container {
            margin-top: 20px;
            padding-bottom: 20px;
        }
    }

    &:hover {
        .pw-card-top {
            margin-top: 0px;
        }
    }

    &:focus-within {
        .pw-card-top {
            margin-top: 0px;
        }
    }

    &.pw-more {
        background-color: #d9dee4;

        a {
            background-color: #343844;
            border-radius: 50%;
            height: 100px;
            width: 100px;
            margin-bottom: 15px;

            i {
                color: #ffffff;

                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
}

// PATHWAYS STUDENT SINGLE STYLES
.hexagon {
    position: relative;
    width: 100px;
    height: 57.74px;
    background-color: #ffffff !important;  /* added because bg-primary is overriding it */
    margin: auto;
    border-left: solid 5px #333333;
    border-right: solid 5px #333333;

    .hexagon-top,
    .hexagon-bottom {
        content: "";
        position: absolute;
        z-index: 1;
        width: 70.71px;
        height: 70.71px;
        -webkit-transform: scaleY(0.5774) rotate(-45deg);
        -ms-transform: scaleY(0.5774) rotate(-45deg);
        transform: scaleY(0.5774) rotate(-45deg);
        background-color: inherit;
        left: 9.6447px;
    }

    .hexagon-top {
        top: -35.3553px;
        border-top: solid 7.0711px #333333;
        border-right: solid 7.0711px #333333;
    }

    .hexagon-bottom {
        bottom: -35.3553px;
        border-bottom: solid 7.0711px #333333;
        border-left: solid 7.0711px #333333;
    }

    i,
    span,
    .partner {
        z-index: 1;
        color: #ffffff;
        max-width: 80px;
        // overflow: hidden;
        text-overflow: ellipsis;
    }
    .partner {
        background:#fff;
        padding:4px;
        max-width: 75px;
        max-height:75px;
        img {
            max-width: 100%;
            height:auto;
        }
    }

    &.small {
        width: 15px;
        height: 8.66px;
        border-width: 2px;

        .hexagon-top, .hexagon-bottom {
            width: 10.61px;
            height: 10.61px;
            left: 0.1px;
            border-width: 2.8284px;
        }
        .hexagon-top {
            top: -5.3033px;
        }
        .hexagon-bottom {
            bottom: -5.3033px;
        }
    }

    &.medium {
        width: 48px; 
        height: 28.87px;
        border-width: 2px;

        .hexagon-top, .hexagon-bottom {
            width: 34px;
            height: 35px;
            left: 5px;
            border-width: 3px;
        }
        .hexagon-top {
            top: -17.6777px;
        }
        .hexagon-bottom {
            bottom: -17.6777px;
        }
    }

    &.medium {
        width: 48px; 
        height: 28.87px;
        border-width: 2px;

        .hexagon-top, .hexagon-bottom {
            width: 34px;
            height: 35px;
            left: 5px;
            border-width: 3px;
        }
        .hexagon-top {
            top: -17.6777px;
        }
        .hexagon-bottom {
            bottom: -17.6777px;
        }
    }
}

.pw-status-indicator {
    border-radius: 3px;
    text-transform: uppercase;
}

#pw-single-hero {
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: $screen-md) {
        height: auto;
    }
}

#pw-hero-data {
    position: relative;
    z-index: 3;

    #pw-data-container {
        width: calc(100% - 200px);
    }

    @media (min-width: $screen-xs) and (max-width: $screen-sm) {
        #pw-data-container {
            width: 100%;
        }
    }

    #pw-short-description {
        p {
            a {
                font-weight: bold;
            }
        }
    }
}

#pw-levels-container {
    width: 100%;

    .pw-level {
        background-color: #ffffff;
        position: relative;
        border-bottom: 1px solid #efefef;

        ul.completed-activity-indicators {
            display: none;
        }

        a.toggle-complete-level {
            height: 30px;
            width: 30px;
        }

        .pw-activity {
            width: 200px;
            vertical-align: top;
            margin: 50px 0;
            position: relative;
            text-decoration: none;

            @media (min-width: $screen-sm) {
                margin: 50px;
            }

            p {
                color: $default-text-color;
            }

            &.disabled {
                opacity: 0.5;
                cursor: default;
            }
        }

        .btn {

            &:hover,
            &:focus {
                color: #ffffff;
            }
        }

        .pw-level-next {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #ffffff;
            color: #b3b3b3;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -25px;
            z-index: 1;
            border-bottom: 1px solid;

            &.strict {

                &.bg-info,
                &.bg-warning,
                &.bg-danger {
                    i {
                        color: #ffffff !important;
                    }
                }
            }
        }

        &.pw-collapse {
            .level-activities {
                display: none;
            }

            ul.completed-activity-indicators {
                display: block;
            }
        }

        &:nth-child(even) {
            background-color: #fbfbfb !important;  /* adding important because bg-primary is overriding it */

            .hexagon {
                background-color: #fbfbfb !important;
            }

            .pw-level-next {
                background-color: #fbfbfb !important;
            }
        }

        &:first-child {
            .hide-in-first-level {
                display: none !important;
            }
        }

        &:last-child {
            .pw-level-next {
                display: none;
            }

            .hide-in-last-level {
                display: none !important;
            }
        }
    }
}

#activity-modal {
    .modal-dialog {
        top: 100px;
        max-width: 780px;
    }

    .hexagon {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -72px;
        width: 125px;
        height: 72.17px;

        .hexagon-top,
        .hexagon-bottom {
            width: 88.39px;
            height: 88.39px;
            left: 14px;
        }

        .hexagon-top {
            top: -44.1942px;
        }

        .hexagon-bottom {
            bottom: -44.1942px;
        }
    }

    .pw-attachment-icon {
        height: 40px;
        max-width: 40px;
    }

    #ac-notes {
        li {
            background-color: #f2f2f2;
            border-radius: 3px;
            width: 80%;

            &.message-from-another-user {
                background-color: #dedede;
                position: relative;
                left: 20%;
            }

            .pill {
                border-radius: 3px;
                text-transform: uppercase;
            }

            .show-more {
                padding-top: 0;
            }
        }
    }
}

// STYLES FOR THE EDITOR
.pw-editor {
    #pw-levels-container .pw-level .pw-activity {
        margin: 20px;

        &.pw-add-activity {
            width: 100px;
        }
    }

    .level-activities {
        margin: 50px 0;
    }

    .round-button {
        box-shadow: 0px 1px 2px #555555;
        cursor: pointer;
        transition: all 0.25s;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.dropdown-toggle::after {
            display:none; /* adding so we dont end up with the BS4 caret */
        }

        &.small-rb {
            height: 30px;
            width: 30px;

            i {
                font-size: 14px;
            }
        }

        &.level-options {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -25px;
            z-index: 2;
        }

        &.light-rb {
            background: #ffffff;
            color: #565656;
        }

        &.grey-rb {
            background: #f6f6f6;
            color: #b3b3b3;
        }

        &:hover,
        &:focus {
            box-shadow: 0px 1px 12px #555555;
        }

        &.disabled {
            box-shadow: none;

            &:hover,
            &:focus {
                box-shadow: none;
                cursor: default;
            }
        }
    }

    .flow-item-controls {
        position: absolute;
        height: 85px;
        width: 125px;
        margin: auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -15px;
        left: 0;
        right: 0;
        z-index: 1;

        i {
            font-size: 20px;
        }

        .flow-icon-inner-icon {
            position: absolute;
            height: 30px;
            width: 30px;
            padding: 0;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 14px;
                padding: 0;
            }

            &.flow-icon-inner-icon-top-right {
                top: 0;
                right: 0;
            }

            &.flow-icon-inner-icon-top-left {
                top: 0;
                left: 0;
            }

            &.flow-icon-inner-icon-bottom-left {
                left: 0;
                bottom: 0;
            }

            &.flow-icon-inner-icon-bottom-right {
                right: 0;
                bottom: 0;
            }
        }
    }

    .flow-item {
        display: inline-block;
        width: 50%;
        max-width: 200px;
        min-width: 125px;
        vertical-align: top;
        padding: 15px 10px;
        margin: 5px 0;
        position: relative;
        border-radius: 3px;
        box-shadow: none;

        &.flow-control-item {
            .round-button {
                margin: 12.5px auto;
            }
        }

        .flow-item-title {
            display: inline-block;
            margin: 10px 0 0;
        }

        .flow-item-subtitle {
            font-size: 12px;
            margin-top: 5px;

            i {
                font-size: 10px;
            }
        }
    }
}

// PATHWAYS INSTANCES STYLES
tr {
    .archive-container {
        height: 20px;
        overflow: hidden;

        i:first-child {
            margin-top: 0;
            display: block;
            transition: 0.2s margin;
        }
    }

    &:hover, &:focus-within {
        .archive-container i:first-child {
            margin-top: -25px;
        }
    }
}

// PATHWAYS PREVIEW
#pw-preview {
    position: relative;

    #begin-path-header-button {
        position: absolute;
        right: 20px;
        top: -80px;

        @include media-breakpoint-down(md) {
            position: relative;
            right: 0;
            top: 0;
        }
    }

    // Can't think of any reason why images would need to overflow their containers
    img {
        max-width: 100%;
        height: auto;
    }

    #pw-preview-details {
        min-width: 400px;
        width: 400px;
        background-color: #fbfbfb;
        border-right: 1px solid #efefef;
        border-left: 1px solid #efefef;

        @include media-breakpoint-down(md) {
            min-width: auto;
            width: 100%;
            border-bottom: 1px solid #efefef;
            margin-bottom: 20px;
        }
        
        #pw-preview-image {
            width: 100%;
            height: 200px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        #pw-description {
            
        }
    }

    #pw-preview-activities {
        width: 100%;
        padding: 20px;
    }
}



// new skills pathways TC-27629
.pathways-skills-box {
    border:1px solid $gray-200;
    box-shadow: 0px 1px 3px 0px #0000001A;
    padding: 1.25rem;
    }