// @keyframes onAutoFillStart {  from {/**/}  to {/**/}}
// @keyframes onAutoFillCancel {  from {/**/}  to {/**/}}

// .login-simple {
// 	.form-group {
//         position: relative;
//         margin-bottom:25px;
//         &:last-of-type {
//             margin-bottom:0px;
//         }
// 	}
//     input[type="text"],
//     input[type="password"],
//     textarea {
//         background: none;
//         padding: 10px 10px 10px 0px;
//         display: block;
//         width: 100%;
//         border: none;
//         border-radius: 0;
//         border-bottom: 1px solid #ccc;
//         height: auto;
//         font-size: unset;
//         -webkit-box-shadow: none;
//         box-shadow: none;
//         &:focus {
//             outline: none;
//             -webkit-box-shadow: none;
//             box-shadow: none;          
//         }
//         &:focus ~ label,
//         &:valid ~ label,
//         &.is-autofilled ~ label {
//             top: -14px;
//             font-size: 12px;
//             color: $primary-background-color;
//         }
//         &:focus ~ .bar:before {
//             width: 100%;
//         }
//         // &:-webkit-autofill {
//         //     -webkit-box-shadow: 0 0 0 30px green inset;
//         //     box-shadow: 0 0 0 30px green inset;
//         // }
//         &:-webkit-autofill {
//             // Expose a hook for JavaScript when autofill is shown
//             // JavaScript can capture 'animationstart' events
//             animation-name: onAutoFillStart;
            
//             // Make the background color become yellow really slowly
//             transition: background-color 5s ease-in-out 0s;
//         } 
//         :not(:-webkit-autofill) {
//             // Expose a hook for JS onAutoFillCancel
//             // JavaScript can capture 'animationstart' events
//             animation-name: onAutoFillCancel;
//         }           
//     }
	  
//     input[type="password"] {
//     letter-spacing: 0.3em;
//     }
	  
//     input[type="text"] ~ label,
//     input[type="password"] ~ label {
//         color: #ccc;
//         font-size: 14px;
//         font-weight: normal;
//         position: absolute;
//         pointer-events: none;
//         top: 10px;
//         transition: 300ms ease all;
//     }
	  
//     .bar {
//     position: relative;
//     display: block;
//     width:100%;
//     &:before {
//         content: '';
//         height: 2px;
//         width: 0;
//         bottom: 0px;
//         position: absolute;
//         background: $primary-background-color;
//         transition: 300ms ease all;
//         left: 0%;
//     }
//     }
	  
// }