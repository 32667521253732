.widget-simple-action {
	border: $input-border;
	position: relative;
    overflow: hidden;
    padding: 20px;
    margin-bottom:20px;
	background:$content-block-background-color;
	&.widget-simple-action-with-icon {
		padding-right: 75px;		
	}
	.widget-simple-action-options {
		position: absolute;
		top: 20px;
		right: 10px;	
	}
	> i {
		position:absolute;
		bottom:-20px;
		right:-20px;
		font-size:6rem;
	}
	&.text-primary {
		i {
			color: lighten($primary, 20%);
		}
	}
	&.text-secondary {
		i {
			color: lighten($secondary, 20%);
		}
	}	
	&.text-tertiary {
		i {
			color: lighten($tertiary, 20%);
		}
	}		
	h2 {
		font-size: 1.6rem;
		margin-bottom:0.5rem;
		margin-top:0;
	}
	p {
		margin: 0 50px 0px 0;
		color: $text-font-color;
	}
	a {
		color:inherit;
	}
	a:hover {
		text-decoration:underline;
	}
}