.widget-links-list {
	padding:0;
	list-style:none;
	position:relative;
	li {
		background:darken($content-block-background-color,5%);
		padding:5px;
		margin-bottom:7px;
		cursor:pointer;
		i {
			margin: -5px 7px 0 -6px;
			text-align:center;
			padding-top:10px;
			position: absolute;
			height: 30px;
			width: 32px;
			font-size:12px;
			padding-right:0px;
		}
		.widget-link-details {
			margin-left:35px;
			.widget-link-details-description {
				font-style:italic;
				font-size:0.8rem;
				margin-left:2px;
				color: lighten($text-font-color, 40%)
			}
			i {
				position: absolute;
				right: 5px;
			}
		}
		&.bg-primary {
			i {
				background:$primary;
				color:$primary-text-color;
			}
			.widget-link-details {
				i {
					color:$primary;
					background: transparent;
				}
			}
		}
		&.secondary-background {
			i {
				background:$secondary;
				color:$secondary-text-color;
			}
			.widget-link-details {
				i {
					color:$secondary;
					background: transparent;
				}
			}
		}	
		&.tertiary-background {
			i {
				background:$tertiary;
				color:$tertiary-text-color;
			}
			.widget-link-details {
				i {
					color:$tertiary;
					background: transparent;
				}
			}
		}	
		&.quaternary-background {
			i {
				background:$quaternary;
				color:$quaternary-text-color;
			}
			.widget-link-details {
				i {
					color:$quaternary;
					background: transparent;
				}
			}
		}						
	}
}