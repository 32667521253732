.d-grid {
    display:grid;
}
.d-grid-template-columns-3-1fr{
    grid-template-columns:1fr 1fr 1fr;
}
@each $breakpoint, $size in $grid-breakpoints {
	@media(min-width: $size){
        .d-grid-#{$breakpoint} {
            display:grid;
        }
        .d-grid-template-columns-3-1fr-#{$breakpoint}{
            grid-template-columns:1fr 1fr 1fr;
        }
    }
}


// .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
//     position: relative;
//     width: 100%;
//     padding-right: 15px;
//     padding-left: 15px;
//     }
// .col-xs-1 {
//     flex: 0 0 8.333333%;
//     max-width: 8.333333%;
//     }
// .col-xs-2 {
//     flex: 0 0 16.666667%;
//     max-width: 16.666667%;
//     }
// .col-xs-3 {
//     flex: 0 0 25%;
//     max-width: 25%;
//     }
// .col-xs-4 {
//     flex: 0 0 33.333333%;
//     max-width: 33.333333%;
//     }
// .col-xs-5 {
//     flex: 0 0 41.666667%;
//     max-width: 41.666667%;
//     }
// .col-xs-6 {
//     flex: 0 0 50%;
//     max-width: 50%;
//     }
// .col-xs-7 {
//     flex: 0 0 58.333333%;
//     max-width: 58.333333%;
//     }
// .col-xs-8 {
//     flex: 0 0 66.666667%;
//     max-width: 66.666667%;
//     }
// .col-xs-9 {
//     flex: 0 0 75%;
//     max-width: 75%;
//     }
// .col-xs-10 {
//     flex: 0 0 83.333333%;
//     max-width: 83.333333%;
//     }
// .col-xs-11 {
//     flex: 0 0 91.666667%;
//     max-width: 91.666667%;
//     }
// .col-xs-12 {
//     flex: 0 0 100%;
//     max-width: 100%;
//     }