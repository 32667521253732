.widget-card {
	background:$content-block-background-color;
	border: $content-block-border;
	padding:20px;
	margin-bottom:20px;
	position:relative;
	.widget-card-title {
		.button-container {
			.btn {
				top:-10px;
			}
		}
	}
	&.widget-card-with-icon {
		.widget-card-icon-container {
			order:2;
			width:75px;
			height:75px;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);	
			margin-bottom:15px;		
			.widget-card-icon {
				padding-right:0px;
			}
		}
		.widget-card-content {
			order:1;
			flex-grow:1;
			margin-right:20px;
		}			
	}
	h2 {
		border-bottom: $content-block-border;
		padding-top:0px;
		margin-top:0px;
		@include remConversion(padding-bottom, 0.4);
	}
	.card-footer {
		margin:0 -20px -20px -20px;
		padding:10px 15px;
	}
}

// .widget-card {
// 	&.widget-card-icons {
// 		.card-header {
// 			 i {
// 			 	@include remConversion(margin, -0.625, -0.4375, -0.625, -2.438);
// 				text-align: center;
// 				@include remConversion(padding-top, 0.6);
// 				position: absolute;
// 				height: 100%;
// 				@include remConversion(width, 2);
// 				@include remConversion(font-size, 0.75);
// 				padding-right: 0;
// 			}
// 		}
// 	}
// 	.card {
// 		border-radius:0;
// 		border:none;
// 		@include remConversion(margin-bottom, 0.4375);
// 	}
// 	.card-header {
// 		background:$widget-tab-background;
// 		border:none;
// 		@include remConversion(padding, 0.6);
// 		position:relative;
// 		a {
// 			padding-left:30px;
// 			color:$text-font-color;
// 		}
// 	}
// 	img {
// 		@extend .img-fluid;
// 	}
// 	.card-block {
// 		border: $widget-block-border;
// 		@include remConversion(padding, 1);
// 	}
// 	h5 {
// 		font-size:$text-font-size;
// 	}
// 	.toggle-icon::before {
// 		font-family: "FontAwesome";
// 		content: "\f078";		
// 		float:right;
// 	}
// 	.collapsed {
// 		.toggle-icon::before  {
// 			content: "\f054";	
// 		}
// 	}
// 	.badge {
// 		@include remConversion(padding, 0.02, 0.3, 0.1);
// 		@include remConversion(margin, 0, 0.5)
// 	}
// 	p {
// 		margin-bottom:0;
// 	}	
// 	.widget-card-details {
// 		border-right:$widget-block-border;
// 		> div {
// 			text-align:right;
// 		}
// 	}
// 	label {
// 		@include remConversion(padding-right, 0.9375);
// 	}
// 	.widget-card-title {
// 		text-transform:uppercase;
// 		font-weight:bold;
// 	}
// }