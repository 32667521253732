.content-block {
	background:$content-block-background-color;
	display: block;
	width: auto;
	margin: 0 auto 15px;
	padding: 15px;
	border-bottom: 2px solid #ddd;
	border-right: 2px solid #ddd;
    position: relative;
    &.d-flex {
        display:flex;
    }
}
.panel, .panel-default, .panel-group .panel {
	border: 1px solid #ddd;
}
.content-block {
	background:$content-block-background-color;
	border:$content-block-border;
	color:$content-block-text-color;
	position:relative;
	margin-bottom:15px;
	@media(min-width: $screen-md){
		margin-bottom:20px;
    }
    .content-block-container {
        max-height: 480px;
        overflow-y: auto;     
    }    
    .content-block-container-sm {
        max-height: 275px;
        overflow-y: auto;        
    }    
    .content-block-footer {
        background-color: #EEE;
        border-radius: 0;
        padding: 8px;        
    }
    .content-block-tabs {
        @extend .widget-tabs;
        > h2 {
            text-transform: capitalize;
        }
        &.content-block-tabs-chevron {
            @extend .widget-tabs-chevron;     
        }        
    }
    .content-block-chevron {
        @include remConversion(padding-bottom, 0.3125);
        margin-bottom: 13px;
        border-bottom:$content-block-border;
        @include flexbox();
        @include justify-content(space-between);
        cursor:pointer;
        &.collapse-heading {
            margin:0;
            padding:0;
            border:none;
            h2 {
                @include align-self(flex-start);
            }
        }
        h2 {
            margin:0 ;
            padding: 0;
            border: none;
            text-transform: capitalize;
        }
    }
    img { // img-responsive - global fix for images that the client has added to their content (i.e job/placement details) rather than asking the client add the 'img-responsive' class (Ang, TC-18015).
        display: block;
        max-width: 100%;
        height: auto;
    } 
    h2 {
        margin-top:0; 
        border-bottom: $content-block-border; 
        text-transform:capitalize;
        @include remConversion(padding-bottom, 0.3125)      
    }
    .content-block-container, &.content-block-container {
        max-height: 480px;
        overflow-y: auto;
    }   
    &.content-block-top-bleed {
        margin-top: -#{$content-padding-top-mobile};
    }   
    &.content-block-bottom-bleed {
        margin-bottom: -#{$content-padding-bottom-mobile};
    }   
    &.content-block-right-bleed {
        margin-right: -#{$content-padding-right-mobile};
    }   
    &.content-block-left-bleed {
        margin-left: -#{$content-padding-left-mobile};
    } 
    .content-block-toggle {
        background: $content-block-text-color;
        color:$content-block-background-color;
        position: absolute;
        bottom: -12px;
        right: 15px;
        padding: 6px 4px 8px 8px;
        i.collapsed {
            display: none;
        }
        i.opened {
            display:block;
        }
        &.collapsed {
            i.collapsed {
                display: block;
            }
            i.opened {
                display:none;
            }
        }
        &:hover, &:active, &:focus {
            text-decoration: none;
        }         
    }    
}


/*** PRICING CONTENT BLOCKS  ***/
.content-block.content-block-pricing {
    border: medium none;
    box-shadow: 1px 1px 6px -1px #000;
    display: table;
    height: 100%;
    width: 100%;
}

.content-block.content-block-pricing.content-block-pricing-no-price .content-block-pricing-title {
    padding-bottom: 10px !important;
}

.content-block-pricing .content-block-pricing-title {
    margin: -16px;
    font-weight: bold;
    padding-bottom: 50px !important;
}

.content-block-pricing .content-block-pricing-details {
    padding-top: 30px;
    margin-bottom:20px;
    font-size:14px;
}

.content-block-pricing .content-block-pricing-details ul {
    padding-left: 20px;
}

.content-block-pricing .content-block-pricing-value-band {
    max-height: 20px;
    margin: 16px -15px;
    background-color: #ccc;
}

.content-block-pricing .content-block-pricing-value {
    background: #c4141b none repeat scroll 0 0;
    border: 3px solid #fff;
    border-radius: 50%;
    color: #fff;
    height: 100px;
    left: 50%;
    margin-left: -50px;
    position: relative;
    text-align: center;
    bottom: 40px;
    width: 100px;
}

.content-block-pricing .content-block-pricing-value-check {
    opacity: 0;
    background: #7e9623 none repeat scroll 0 0;
    border: 3px solid #fff;
    border-radius: 50%;
    color: #fff;
    height: 35px;
    left: calc(50% + 65px);
    margin-left: -50px;
    position: relative;
    text-align: center;
    bottom: 145px;
    width: 35px;
    transition: opacity 1s linear;
}

.content-block-pricing.content-block-pricing-selected .content-block-pricing-value-check {
    transform: rotateY(360deg);
    transition: all 1s ease 0s;
    opacity: 1;
}

.content-block-pricing .content-block-pricing-value p {
    display: table;
    height: 100%;
    margin: 0;
    vertical-align: middle;
    width: 100%;
}
.content-block-pricing .content-block-pricing-value-from span:first-of-type {
    display: block;
    margin-top: 20px;
}

.content-block-pricing .content-block-pricing-value-check p {
    padding-top: 4px;
    padding-left: 6px;
}

.content-block-pricing .content-block-pricing-value p span {
    display: block;
    font-size: 28px;
    font-weight: 700;
    vertical-align: middle;
    padding-top: 24px;
}
.content-block-pricing .content-block-pricing-value-from p {
    margin: -50px 0 0;
}

.content-block-pricing .content-block-pricing-fixed-bottom {
    display: table-footer-group;
    height: 75px;
}

.content-block-pricing .btn-pricing-selected, .content-block-pricing.content-block-pricing-selected  .btn-pricing-unselected {
    display: none;
}

.content-block-pricing.content-block-pricing-selected   .btn-pricing-selected, .content-block-pricing  .btn-pricing-unselected {
    display: block;
}

.page-tabs .well.custom-content { 
    // added by Ang, TC-18355
    margin-bottom: 55px;
    }