
.romeo {

    .container {
        max-width: 1140px;
        margin: auto;
        width: 100%;
        padding-left:15px;
        padding-right:15px;
    }
    
    .main-header {
        background: $romeo-main-header-bg;
        box-shadow: $romeo-box-shadow;
        left: 0;
        height: auto;
        padding: .5rem 0;
        z-index: 3;
        transition: none;

        .container {
            .d-flex {
                width:100%;  /** bs4 upgrade fix, because the outer divs are now flex! **/
            }
        }        

        .navbar-toggler {
            padding: 0;
            border: 0;
            color: $romeo-main-header-icon-color;
            display: inline;
            background-color: transparent;

            @media (min-width: $screen-md) {
                display: none;
            }
        }

        .navbar-cta {            
            display: none; // hidden on small
            
            @media (min-width: $screen-md) {
                display: flex;
                justify-content: flex-end;
                margin-top: -14px;
                align-items: center;
            }

            a {
                margin-left: 1.6rem;
                position: relative;
                width: 55px;
                display: inline-block;
                text-align:center;
                color: $romeo-main-header-icon-color;
                &:hover, &:focus {
                    color: $romeo-secondary;               
                    background:none;
                    i {
                        color: $romeo-secondary;
                        transition: color .3s ease;
                    }
                }
                i {  
                    color: $romeo-main-header-icon-color; // had to put colour here because of an old vlibrary override
                    font-size: 1.5rem;
                }
                .cta-title {
                    font-size: 0.74rem;
                    text-decoration: none;
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    .fa-caret-down {
                        font-size: 1rem;
                        top: 0.1rem;
                        left: 0.2rem;
                        position: relative;
                    }
                }
            } 

            #user-profile-dropdown + .dropdown-menu {
                background: #fff;
                position: absolute;
                left: auto;
                margin-top: 0;
                padding: 0;
                width: 15rem;
                border: none;
                top: 3.4rem;
                box-shadow: 0 6px 12px #0000002e;

                li {

                    border-bottom: 0;
                    padding-bottom: 0;

                    a {
                        font-weight: 400;
                        font-size: 0.9rem;
                        // width: 100%;
                        width: 91%;
                        text-align: left;
                        padding: 0.5rem 0.3rem;

                        &:hover {
                            i {
                                color: $romeo-secondary;
                                transition: color .3s ease;
                            }
                        }

                        i {
                            font-size: 1.2rem;
                            margin-right: 0.7rem;
                            margin-left: 0;
                            position: relative;
                            top: 0.175rem;
                        }
                    }

                    &:first-of-type {
                        background: $palette-grey-100; //AB TODO replace with CSS Var

                        a {
                            font-weight: 500;
                            padding: 0.9rem 0.3rem;

                            &:hover {
                                color: $black;
                                transition: color 0s;
                                cursor: default;
                            }
                        }
                    }

                    &:last-of-type {
                        border-top: 1px solid $palette-grey-200;
                        padding: 0.2rem 0.3rem;
                        margin-top: 0.4rem;

                        a {
                            padding: 0.9rem 0;
                        }
                    }
                }

                &:before {
                    display: none;
                }
            }

            &.show {
                // ANG for whatever reason, clicking the 'me' dropdown puts 'show' on the outer navbar-cta, which means the icons jump around.  
                // Adding this here to stop the jump 
                display: flex !important;
            }
        }

        a.navbar-brand {
            position: relative;
            top: 0;
            left: 0;
            float: none;

            img {
                max-height: 50px;
                margin: 0;

                &.brand-gti {
                    display: none;
                    height: 40px;
                }
                &.brand-client {
                    display: block;
                    height: 40px;
                }

                @media(min-width: $screen-md) {
                    &.brand-gti {
                        display: block;
                    }
                    &.brand-client {
                        display: none;
                    }
                }
            }
        }
    }

    
}


// vlibrary ONLY, switch for romeo theme
#romeo-toggle {
    position: fixed;
    background: #fff;
    border: 0 solid #eceaea;
    box-shadow: 1px 1px 4px 0 #b0b0b0;
    bottom: 10px;
    right: -240px;
    z-index: 9999;
    padding: 20px 20px 20px 50px;
    transition: right 0.5s;
    &.show {
        right: 10px;
    }
    #romeo-toggle-arrow {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        color: #fff;
        background-color: #333;
    }
}