dl {
    dt {
        margin-bottom:6px;
    }
    dd {
        margin-bottom:15px;
    }
}
dl.dl-sm {
    dt {
        margin-bottom:6px;
    }
    dd {
        margin-bottom:10px;
    }
}
dl.dl-xs {
    dt {
        margin-bottom:6px;
    }
    dd {
        margin-bottom:5px;
    }
}
dl.dl-lg {
    dt {
        margin-bottom:6px;
    }
    dd {
        margin-bottom:20px;
    }
}
dl.dl-compressed {
    dt {
        margin-bottom:0px;
    }
    dd {
        margin-bottom:0;
    }
}
