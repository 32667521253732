.romeo {

    // COMMON BANNER & NAV STYLES
    .cta {
        position: relative;

        span.badge {
            position: absolute;
            top: -5px;
            padding:5px 8px;
            right: 0px;
            font-size: 80%;
            box-shadow: $romeo-box-shadow;
            display: block;
            background-color: $romeo-secondary;
            color: color-yiq($romeo-secondary);
            @media (min-width: $screen-md) {
                font-size: 70%;
                padding: 4px 6px 3px 6px;
            }
        }
    }

    // BANNER STYLES
    .main-banner-outer {
        display: none;

        @media (min-width: $screen-md) {
            display: block;
            position: relative;
            background-size: cover;
            background-position: $romeo-main-banner-bg-position;

            .main-banner-gradient {
                display:none;
                // position: absolute;  // ang removing
                // z-index: 0;
                // background-image: linear-gradient( 90deg, rgba(0,0,0,0.4) 0%, rgba(0, 0, 0, 0) 100%);
                // height: 100%;
                // width: 100%;
                // top: 0;
                // left: 0;
            }

            .main-banner-container {
                position: relative;
                display: flex;
                align-items: center;
                height: 160px;
                z-index: 1;

                .main-banner-txt {
                    background-color: transparent;
                    padding: 0 2rem;
                    order: 2;
                    text-shadow: $romeo-main-banner-text-shadow;
    
                    p {
                        color: $romeo-main-banner-text-color;
                        font-size: 1.9rem;
                        font-weight: 500;
                        margin: 0;
    
                        small {
                            font-weight: 400;
                            font-size: 80%;
                        }
                    }
                }

                .main-banner-brand {
                    display: flex;
                    background-color: $romeo-main-banner-brand-bg;
                    border: 2px solid $romeo-main-banner-brand-border-color;
                    height: 110px;
                    box-shadow: $romeo-main-banner-brand-box-shadow;
                    z-index: 1000;
                    order: 1;
                    border-radius: .15rem;
                    justify-content: center;
                    align-items: center;
                    min-width: 120px;
                    max-width: 350px;
                    padding: $romeo-main-banner-brand-padding;

                    img {
                        display: block;
                        margin: auto 5px;
                        position: relative;
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    
    // NAVIGATION STYLES
    .main-navbar-container {
        background: $white;
        position: relative;
        box-shadow: 1px 1px 4px -2px $gray-box-shadow-nav;

        a {
            font-size: 1rem;
            color: $romeo-nav-items;
            font-weight: 600;
            text-decoration: none;
            background: $white;
        }

        .navbar {
            background: $white;
            border: none;
            min-height: 0;
            padding:0;

            @media (min-width: $screen-md) {
                min-height: 0;
            }

            .main-navbar-inner {
                width: 100%;
                @media (min-width: $screen-md) {
                    height: auto !important;

                    &.collapse {
                        display: block;
                        width:100%; /** bs4 upgrade fix, because the outer divs are now flex! **/
                    }
                }

                .navbar-cta {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 1px solid $gray-500;
                    padding:1.4rem 0 2rem 0;
                    justify-content: space-evenly;    

                    @media (min-width: $screen-md) {
                        display: none;
                    }
    
                    .cta {
                        transition: all .5s ease;
                        color: $romeo-primary;
                        position: relative;
                        width: 55px;
                        display: inline-block;
                        text-align:center;
                        &:hover, &:active, &:focus {
                            color: $romeo-secondary;
                        }
                        i {
                            font-size:2rem;
                        }
                        .cta-title {
                            font-size: 0.8rem;
                            text-decoration: none;
                            position: absolute;
                            bottom: -20px;
                            left: 0;
                            width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            font-weight:500;
                        }
                    }
                }

                ul.navbar-nav {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    float: none;
                    margin-top: 1rem;

                    @media (min-width: $screen-md) {
                        flex-direction: row;
                        min-height: auto;
                        margin-top: 0;
                    }
    
                    > li.nav-item {
                        border-bottom: 1px solid $palette-grey-200;
                        display: block;
                        margin: 0;

                        &.active, &:hover, &:focus, &:focus-within, &:active {
                            a.nav-link {
                                color: $romeo-nav-items-hover;
                            }
                        }

                        @media (min-width: $screen-md) {
                            border-bottom: none;
                            // margin: 0 .8em;
                            position: relative;
                            float: none;
                            flex-grow: 1;

                            &:before { // underline on hover/active
                                display: block;
                                height: 2px;
                                width: 0;
                                background-color: transparent;
                                content: "";
                                position: absolute;
                                bottom: 9px;
                                right: 0;
                                left: 0;
                                margin: 0 auto;
                                transition: all .3s ease;
                            }

                            &:hover, 
                            &:focus,
                            &:active,
                            &.active {
                                &:before {
                                    background-color: $romeo-nav-items-hover;
                                    width: 15%; 
                                }

                                a.nav-link {
                                    color: $romeo-nav-items-hover;
                                }
                            }

                            &:after {
                                display: block;
                                height: 20px;
                                width: 1px;
                                background: #ccc;
                                content: "";
                                position: absolute;
                                top: 28%;
                                right: 0;
                                border: none; /* adding because the 'more' link was auto adding a dropdown caret */
                            }

                            // give only first desktop nav icon left border
                            &:nth-child(2) {
                                a {
                                    &:before {
                                        display: block;
                                        height: 20px;
                                        width: 1px;
                                        background: #ccc;
                                        content: "";
                                        position: absolute;
                                        top: 28%;
                                        left: 0;
                                    }
                                }
                                .dropdown-menu li a:before {
                                    display:none;
                                }
                            }
                        }

                        
    
                        &:last-child {
                            border-bottom: none;
                        }
    
                        a.nav-link {
                            padding: .75rem 1rem;
                            justify-content: space-between;
                            flex-direction: row;
                            display: flex;

                            @media (min-width: $screen-md) {
                                display: block;
                            }

                            background-color: transparent;
                            line-height: 22.4px;
                            white-space: nowrap;
                            transition: all .3s ease;
                            font-weight: 500;

                            @media (min-width: $screen-md) {
                                padding: .75rem 1.75rem;
                                line-height: 22.04px;
                                text-align: center;

                                &:first-child {
                                    margin-left: 0;
                                }
            
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
    
                            i.fa {
                                display: none;
                            }
    
                            &:hover, &:active, &:focus {
                                color: $romeo-nav-items-hover;
                                background: none;
                            }
                        }

                        // Dropdown toggle button
                        a.dropdown-toggle {
                            &::after {
                                font-family: "Font Awesome 5 Pro";
                                content: "\f078";
                                border: none;
                                vertical-align: 0;
                                margin-left: .255em;
                                font-weight: 300;
                            }
                        }

                        ul.dropdown-menu {
                            border-radius: 0;
                            border: none;
                            margin: 0;
                            padding-bottom: 0.5rem;
                            background: $white;
                            min-width: 14rem;
                            left: -9px;
                            top: 110%;
                            position: static;
                            width: 100%;
                            box-shadow: none;
                            z-index: 30;
                            color: $romeo-primary;
                            padding-bottom: 0;

                            @media (min-width: $screen-md) {
                                box-shadow: $romeo-box-shadow;
                                border: none;
                                position: absolute;
                                padding: .5rem 0;
                                left: 0;
                                top: 100%;

                            }

                            >li {
                                padding: 0;
                                margin: 0;

                                a.nav-link, span {
                                    white-space: normal;
                                    padding: .5rem 1rem;
                                    text-align: left;
                                    font-size: .9rem;
                                    font-weight: 400;
                                    border: none;
                                    color: $romeo-nav-items;
                                    margin: 0;
                                }
                                
                                a.nav-link{
                                    &:hover, &:active, &:focus {
                                        color: $romeo-nav-items-hover;
                                    }
                                }

                                span {
                                    font-weight: 600;
                                    color: $romeo-primary;
                                }

                                span.fa {
                                    padding: 0;
                                }

                                &[data-level="1"] {
                                    a.nav-link,
                                    > span {
                                        padding-left: 2rem;
                                    }
                                    
                                }

                                &[data-level="2"] {
                                    a.nav-link {
                                        font-weight: 500;
                                        padding: .25rem 1rem .25rem 3rem;
                                    }
                                }
                            }

                            // This element's current structure
                            // <ul class="dropdown-menu">
                            //     <li class="nav-item" data-level="1">
                            //         <a class="nav-link">Page link</a>
                            //     </li>
                            //     <li data-level="1">
                            //         <span>Group title</span>
                            //     </li>
                            //     <li class="nav-item" data-level="2">
                            //         <a> class="nav-link"Group page link</a>
                            //     </li>
                            //     <li class="nav-item" data-level="2">
                            //         <a> class="nav-link"Group page link</a>
                            //     </li>
                            //     <li class="nav-item" data-level="1">
                            //         <a class="nav-link">Page link</a>
                            //     </li>
                            // </ul>
                        }

                        &.rmm-more-container {
                            >ul.dropdown-menu {
                                @media (min-width: $screen-md) {
                                    left: -100px;
                                }

                                li.nav-item {
                                    a.nav-link {
                                        &:hover, &:active, &:focus {
                                            color: $romeo-nav-items-hover;
                                        }

                                        &.dropdown-toggle {
                                            cursor: default;

                                            &:hover, &:active, &:focus {
                                                color: $romeo-primary;
                                            }
                                            &:after {
                                                display:none;
                                            }
                                        }
                                    }

                                    &[data-level="2"] {
                                        a.nav-link {
                                            font-weight: 500;
                                            padding: .5rem 1rem;
                                        }
                                    }

                                    .dropdown-menu {
                                        display: block;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        box-shadow: none;
                                        border: none;
                                        padding: 0 0 0 1rem;
                                    }
                                }
                                

                                // This element's current structure
                                // <ul class="dropdown-menu">
                                //     <li class="nav-item" data-level="0">
                                //         <a class="nav-link">Page link</a>
                                //     </li>
                                //     <li class="nav-item" data-level="0">
                                //         <a class="nav-link">Dropdown title</a>
                                //         <ul class="dropdown-menu">
                                //             <li class="nav-item" data-level="1">
                                //                 <a class="nav-link">Dropdown link</a>
                                //             </li>
                                //             <li data-level="1">
                                //                 <span>Dropdown group title</span>
                                //             </li>
                                //             <li class="nav-item" data-level="2">
                                //                 <a class="nav-link">Dropdown link</a>
                                //             </li>
                                //             <li class="nav-item" data-level="2">
                                //                 <a class="nav-link">Dropdown link</a>
                                //             </li>
                                //             <li class="nav-item" data-level="1">
                                //                 <a class="nav-link">Dropdown link</a>
                                //             </li>
                                //         </ul>
                                //     </li>
                                //     <li class="nav-item" data-level="0">
                                //         <a class="nav-link">Group page link</a>
                                //     </li>
                                //     <li class="nav-item" data-level="0">
                                //         <a class="nav-link">Page link</a>
                                //     </li>
                                // </ul>
                            }
                        }
                    }
                    li.nav-item.dropdown {
                        &:before  {
                            left:-15px; // moving underline due to the dropdown arrow
                        }
                    }
                }

                #main-menu {

                    @media (max-width: $screen-md) { 

                        li:first-child {

                        ul.dropdown-menu { 
                            >li {

                                &:first-of-type {
                                    background: $palette-grey-100; //AB TODO replace with CSS Var
                                    margin-bottom: 0.3rem;
            
                                    a.nav-link {
                                        font-weight: var(--font-weight-bold, $romeo-font-weight-bold);
                                        padding: 0.9rem 1rem;

                                        &:hover, &:active, &:focus {
                                            color: $black;
                                            transition: color 0s;
                                            cursor: default;
                                        }
                                    }
                                }

                                &[data-level="1"] {
                                    a.nav-link {
                                        padding-left: 2rem;
                                    }
                                }
            
                                &:last-of-type {
                                    
                                    padding: 0.2rem 1rem;
                                    margin-top: 0.4rem;
            
                                    a.nav-link {
                                        border-top: 1px solid $palette-grey-200;
                                        margin: 0 1rem;
                                        padding: 0.9rem 0;
                                        justify-content: start;

                                        i {
                                            font-size: 1.2rem;
                                            margin-right: 0.4rem;
                                            margin-left: 0;
                                            position: relative;
                                            top: 0.1rem;
                                        }
                                    }

                                    &[data-level="1"] {
                                        a.nav-link {
                                            padding-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                        }

                        li:not(:first-child) {
                            ul.dropdown-menu { 
                                >li:last-child {
                                    &[data-level="1"] {
                                        margin-bottom: 0.7rem;
                                    }
                                }
                            }
                        }


                    }
                }

                .navbar-footer {
                    padding: 1.5rem .7rem;
                    border-top: 1px solid $palette-grey-200;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;

                    @media (min-width: $screen-md) { 
                        display: none;
                    }

                    a {
                        padding: 0;
                    }

                    img.brand-gti {
                        display: block;
                        height: 50px;
                    }

                    select {
                        width: 100%;
                        margin-left: 0.4rem;
                    }
                }
            }
        }
    }

    .main-content-header {
        background-color: #ececec;

        .container {
            position: relative;
            padding-top: 1.8rem;
            padding-bottom: 2.3rem;

            h1 {
                margin-bottom: .25rem;
                font-size: 1.8rem;
                font-weight: 600; // Robbie: This should be a gloabl style?
            }
 
            ol.breadcrumb 
            {
                margin-bottom: 0;
                font-size: .8rem;
                color: #666;
                font-weight: 500;
                padding:0;
                display: flex; // needed to update from block to flex for bs4 code  --add this to override it being hidden on mobile--
                background-color: transparent;

                // li::before {
                //     content: "\0005C";
                //     padding: 0 8px;
                // }

                // li:first-child::before {
                //     content: "";
                //     padding: 0;
                // }
                a {
                    color: #666;
                    text-decoration: none;
                    .fa-grip-horizontal {
                        display:none;
                        }
                }
            }
        }
    }
    // HACKS FOR THE NEW STUDENT ANGULAR TEMPLATE (NOVEMBER 2023)
    &.foundation {
        .main-navbar-container .navbar .main-navbar-inner ul.navbar-nav > li.nav-item ul.dropdown-menu > li a.nav-link {
            span {
                font-weight:400;
                color: $romeo-nav-items;
                padding-left: 0;
                padding-right: 0;
                }
            &:hover, &:active, &:focus {
                span {
                    color: $romeo-nav-items-hover;
                    }
                }
            }
        }
    }