.widget-simple-plain {
	@extend .content-block;
	@extend .clearfix;
	cursor:pointer;
	h3 {
		font-size:14px;
		text-transform:uppercase;
		color: lighten($text-font-color, 30%);
		margin-bottom:20px;
        margin-top:0;
	}
	i {
		height:60px;
		width:60px;
		text-align:center;
		padding-top:20px;
		float:left;
		margin-right:20px;
		font-size: 20px;
		padding-right:0px;
		padding-left:0px;
		margin-bottom:15px;
		&.bg-primary, .widget-primary-background {
			background:$widget-primary-background !important;
		}	
		&.bg-secondary, .widget-secondary-background {
			background:$widget-secondary-background !important;
		}		
		&.bg-tertiary, .widget-tertiary-background {
			background:$widget-tertiary-background !important;
		}		
		&.bg-quaternary, .widget-quaternary-background {
			background:$widget-quaternary-background !important;
		}			
	}
	.widget-simple-plain-container {
		float:left;
		height:60px;
		position:relative;
		font-size:32px;
		font-weight:bold;
		color: $text-font-color;
	}	
}