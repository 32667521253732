.romeo {

    @include add-to-color-palette-with-shades("primary", $romeo-primary, color-yiq-r($romeo-primary), lighten($romeo-primary, 5%));
    @include add-to-color-palette-with-shades("secondary", $romeo-secondary, color-yiq-r($romeo-secondary), lighten($romeo-secondary, 5%));
    @include add-to-color-palette-with-shades("tertiary", $romeo-tertiary, color-yiq-r($romeo-tertiary), lighten($romeo-tertiary, 5%));
    @include add-to-color-palette-with-shades("quaternary", $romeo-quaternary, color-yiq-r($romeo-quaternary), lighten($romeo-quaternary, 5%));
    @include add-to-color-palette-with-shades("success", $romeo-success, color-yiq-r($romeo-success), lighten($romeo-success, 5%));
    @include add-to-color-palette-with-shades("danger", $romeo-danger, color-yiq-r($romeo-danger), lighten($romeo-danger, 5%));
    @include add-to-color-palette-with-shades("warning", $romeo-warning, color-yiq-r($romeo-warning), lighten($romeo-warning, 5%));
    @include add-to-color-palette-with-shades("info", $romeo-info, color-yiq-r($romeo-info), lighten($romeo-info, 5%));
    @include add-to-color-palette-with-shades("dark", $romeo-dark, color-yiq-r($romeo-dark), lighten($romeo-dark, 5%));
    @include add-to-color-palette-with-shades("light", $romeo-light, color-yiq-r($romeo-light), lighten($romeo-light, 5%));

}