/*********** BODY **********/

body.page-operator {
	
	font-family: $font-family-base; 
	font-size: $font-size-base;
	background-color: $body-bg;

	.page-wrapper { /**  wraps the entire page**/
		width: 100%;
		min-height: 100vh;
		position: relative;
		overflow: hidden;
		display:flex;
		flex-direction: column;
		@media(min-width: $screen-lg) {
			flex-direction: row;
			}
		}
	.content-wrapper { /* wraps breadcumb and content */
		position: relative;
		display: flex;
		align-content: flex-start;
		flex-direction: column;
		transition: $transition-base; 
		width:100%;
		margin-top:$header-height;
		overflow: auto;
		@media(min-width: $screen-lg) {
			margin-left: $nav-width;
			margin-top: $header-height-lg;
			}
		.page-content {
			flex-grow: 1;
			padding: 1rem;
			}
		}	
	
	&.sidebar-toggled .content-wrapper {
		@media(min-width: $screen-lg) {
			margin-left: $nav-mini-width;
			}
		}	


// PAGE CONTENT HEADER : h1 and buttons	
	.content-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// margin-bottom: 1.2rem;
		flex-wrap: wrap;
		h1, .actions {
			flex-grow: 1;
			margin-bottom: 1rem; // adding a margin here (instead of the content-header, so there are margins for when stuff wraps
			}
		h1 {			
			// @media(min-width: $screen-md) {
			// 	margin-bottom:0;
			// 	}
			}
		.actions {
			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;
			.btn {
				margin-left:.5rem;
				margin-bottom:.5rem;
				@media(min-width: $screen-md) {
					margin-bottom:0;
					}
				i {
					display:none;
					@media(min-width: $screen-sm) {
						display:inline-block;
						}
					}
				}
			}
		.actions-outer {
			// this will allow something else to align nicely with the buttons - i.e the appointment calendar were we have the Live Updates!
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			// .actions {
			// 	flex-grow: 1;
			// 	}
			}
		}

	}


