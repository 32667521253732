// ADDED JUST WHILE WE TRANSITION TO BOOTSTRAP 4

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
$enable-rounded: true !default;

$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS

@mixin hover {
  &:hover { @content; }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}


// stylelint-disable indentation
@mixin hover {
    // TODO: re-enable along with mq4-hover-shim
  //  @if $enable-hover-media-query {
  //    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
  //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
  //    @media (hover: hover) {
  //      &:hover { @content }
  //    }
  //  }
  //  @else {
      &:hover { @content; }
  //  }
  }
  
  
  @mixin hover-focus {
    @if $enable-hover-media-query {
      &:focus {
        @content;
      }
      @include hover { @content; }
    } @else {
      &:focus,
      &:hover {
        @content;
      }
    }
  }
  
  @mixin plain-hover-focus {
    @if $enable-hover-media-query {
      &,
      &:focus {
        @content;
      }
      @include hover { @content; }
    } @else {
      &,
      &:focus,
      &:hover {
        @content;
      }
    }
  }
  
  @mixin hover-focus-active {
    @if $enable-hover-media-query {
      &:focus,
      &:active {
        @content;
      }
      @include hover { @content; }
    } @else {
      &:focus,
      &:active,
      &:hover {
        @content;
      }
    }
  }
// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }
  
  // Minimum breakpoint width. Null for the smallest (first) breakpoint.
  //
  //    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    576px
  @function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }
  
  // Maximum breakpoint width. Null for the largest (last) breakpoint.
  // The maximum value is calculated as the minimum of the next one less 0.02px
  // to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
  // See https://bugs.webkit.org/show_bug.cgi?id=178261
  //
  //    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    767.98px
  @function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
  }
  
  // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
  // Useful for making responsive utilities.
  //
  //    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    ""  (Returns a blank string)
  //    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    "-sm"
  @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
  }
  
  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  @mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($lower) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($upper) {
        @content;
      }
    }
  }
  
  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  @mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($name) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($name) {
        @content;
      }
    }
  }  

$border-width: 1px !default;
$border-radius: .25rem !default;
$grid-columns:                12 !default;
$grid-gutter-width: 30px !default;

$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     .75rem !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 rgba($black, .125) !default;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       rgba($black, .03) !default;
$card-bg:                           $white !default;
$border-width: 1px !default;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 ($grid-gutter-width / 2) !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;

$card-border: 1px solid rgba(0,0,0,.125);
$card-header-background-color:#fff;
$card-header-text-color:#333;
$card-body-background-color:#fff;
$card-body-text-color:#333;
$card-footer-background-color:#fff;
$card-footer-text-color:#333;

// List group

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$body-color: $gray-900 !default;

$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         .75rem !default;
$list-group-item-padding-x:         1.25rem !default;

$list-group-hover-bg:               #f8f9fa !default;
$list-group-active-color:           #fff !default;
$list-group-active-bg:              $primary !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-600 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $gray-700 !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;






//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -($card-spacer-x / 2);
  margin-bottom: -$card-spacer-y;
  margin-left: -($card-spacer-x / 2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -($card-spacer-x / 2);
  margin-left: -($card-spacer-x / 2);
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .card-img-top,
          .card-header {
            @include border-top-radius($card-border-radius);
          }
          .card-img-bottom,
          .card-footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child):not(:last-child):not(:only-child) {
          @include border-radius(0);

          .card-img-top,
          .card-img-bottom,
          .card-header,
          .card-footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

.card {
    @include border-radius(0rem);
    &.card-accordian {
        border-bottom:none;
        &:last-child {
            border-bottom: $card-border;
        }
    }
  .collapse {
    overflow:hidden;
  }
  .card-header {
        //@include flexbox();
        //@include align-items(center);
      @include remConversion(padding-top, 0.5);
      @include remConversion(padding-bottom, 0.5);
      background: $card-header-background-color;
      color:$card-header-text-color;
      min-height:50px;
      border-radius: 0px;
      h2 {
          margin:0;
          padding:5px 5px 5px 0px;
      }
      // a {
      //     color: $card-header-text-color;
      // }   
  }
  .card-body {
      @include remConversion(padding-top, 0.75);
      @include remConversion(padding-bottom, 0.75);        
      background: $card-body-background-color;
      color:$card-body-text-color;
      &.card-body-container {
          max-height: 480px;
          overflow-y: auto;            
      }
  }
  .card-footer {
        //@include flexbox();
        //@include align-items(center);        
      @include remConversion(padding-top, 0.5);
      @include remConversion(padding-bottom, 0.5);   
        //@include justify-content(flex-end);
      background: $card-footer-background-color;
      color:$card-footer-text-color;
      min-height:50px;        
  } 
  &.card-left-tabs, &.card-right-tabs {
    border:none;
    > .card-body {
      margin-top:-2px;
    }
    > .card-header {
      border-top:none;
      border-left:none;
      border-right:none;
      border-bottom: none !important;
      min-height:auto;
      padding:0;
      > .nav-tabs {
        border-bottom:none;
        li.nav-item.active, li.nav-item {
          a {
            padding-top: 8px;
            padding-top: .5rem;
            padding-bottom: 8px;
            padding-bottom: .5rem;
            font-weight: normal;         
            border-radius:0px;     
            margin-right:0px;   
            padding: 0.65rem 1rem;       
          }
        }
        li.nav-item {
          a, a:hover, a:link {
            background:transparent;
            border-color: transparent;
            border-top:4px solid transparent;
          }
        }
        li.nav-item.active {
          a, a:hover, a:link {
            border-color:transparent;
            border-top:4px solid $primary;
            border-left:1px solid #ccc;
            border-right:1px solid #ccc;
            border-bottom:none;
            color:#333;
            z-index: 19;
            position: relative;
            background: #fff;          
          }
        }
        li.nav-item a.nav-link.active { /** yes yes, ang is a muppet and I've duplicated the CSS, but I've lost the will trying to fix the issue TC-24322 */
            border-color:transparent;
            border-top:4px solid $primary;
            border-left:1px solid #ccc;
            border-right:1px solid #ccc;
            border-bottom:none;
            color:#333;
            z-index: 19;
            position: relative;
            background: #fff;          
        }
      }
    }
  }
  &.card-right-tabs {
    .card-header {
      > .nav-tabs {
        @include flexbox();
        @include justify-content(flex-end);
      }
    }
  }
}


$nav-tab-border-active-color: #333;
$nav-tab-text-color: #333;

.nav-tabs.card-tabs {
    .nav-item, .alt-nav-item {
        margin-bottom:-1px;
        &.flexMenu-viewMore {
            @include flexbox();
            @include align-self(stretch);            
            @include justify-content(flex-end); 
            @include flex(auto);
            position: relative;        
            &.active, &.alt-active {
                background:#fff;
                border:1px solid rgba(0,0,0,.125);
                border-top: 3px solid $nav-tab-border-active-color;
                border-bottom:none;
            }
            .flexMenu-popup {
                z-index:99;
                top:30px;
                background:#fff;
                border:1px solid rgba(0,0,0,.125);  
                padding:0 0 0.1rem;
                .nav-item {
                    padding:0;
                    .nav-link {
                        @include align-self(flex-start);     
                        @include justify-content(flex-start);   
                        padding:0.4rem 0.6rem; 
                        border:none;     
                        &.active, &:hover, &:focus {
                            background:#eee;
                            border:none;
                        }              
                    }
                }
            }            
            @media(min-screen: $screen-md){
                flex: 1;
            }
            a {
                color:$nav-tab-text-color;
                color: #333;
                align-self: stretch;
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: center;      
                background:transparent;
                border:none;    
            }       
        }
        .nav-link {
            color:$nav-tab-text-color;
            &.active, .alt-active {
                border-radius:0rem;
                border-top: 3px solid $nav-tab-border-active-color;
                &:hover, &:focus {
                    border-color:#ddd #ddd #fff;     
                    border-bottom:1px solid transparent;               
                    border-top: 3px solid $nav-tab-border-active-color;
                }
            }     
            &:hover, &:focus {
                border-color:transparent;
            }       
        }
    }
    &.nav-tabs-mini {
        .nav-item {
            .nav-link {
                padding: 3px; 
                @include remConversion(font-size,0.8);               
            }
        }
    }
}
.card {
    .nav-tabs-mini {
        .nav-item:first-child {
            .nav-link {              
                border-left:none;
            }
        }
    }
    .card-header {
        &.card-header-with-tabs {
            margin:0;
            padding:0 1.25rem;
            ul.nav-tabs {
                .flexMenu-viewMore {                    
                    &.flexMenu-allInPopup {
                        a {
                            padding-top:4px;
                            @include justify-content(flex-end);    
                            background: transparent;
                            border: none;        
                        }
                    }
                }
            }                      
        }     
        .card-header-tabs.nav-tabs {
            margin:0;
            padding:0;    
            @include align-self(stretch);
            border-bottom:none;  
            .flexMenu-popup {
                z-index:99;
                top:30px;
                background:#fff;
                border:1px solid rgba(0,0,0,.125);  
                padding:0 0 0.1rem;
                .nav-item {
                    padding:0;
                    .nav-link {
                        @include align-self(flex-start);     
                        @include justify-content(flex-start);   
                        padding:0.4rem 0.6rem; 
                        border:none;     
                        &.active, &:hover, &:focus {
                            background:#eee;
                            border:none;
                        }              
                    }
                }
            }                     
            .nav-item, .alt-nav-item {
                background: transparent;
                border: none;   
                font-weight:normal;     
                font-size: 0.8rem;  
                margin-right:4px;
                height:100%;
                @include flexbox();                                    
                > .nav-link {
                    background: transparent;
                    border: none;       
                    font-weight:normal; 
                    font-size: 0.8rem;      
                    @include align-self(stretch);   
                    @include align-items(center);
                    @include flexbox();
                    border-bottom: 3px solid transparent;    
                    padding-bottom:0px;   
                    &.active, .alt-active {
                        background-color: transparent;
                        color: #333;
                        border: none;
                        border-bottom: 3px solid #343844;         
                        .nav-link {
                            color:#333;
                        }               
                    }                                 
                }         
            }         
        }      
    }
    .nav {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
  }    
}

.list-group {
  
  display: block;
  //flex-direction: column;

  // No need to set list-style: none; since .list-group-item is block level
  padding-left: 0; // reset padding because ul and ol
  margin-bottom: 0;
  &.list-group-inline {
    display:block;
  }
}


// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive
// list items. Includes an extra `.active` modifier class for selected items.

.list-group-item-action {
  width: 100%; // For `<button>`s (anchors become 100% by default though)
  color: $list-group-action-color;
  text-align: inherit; // For `<button>`s (anchors inherit)

  // Hover state
  @include hover-focus {
    color: $list-group-action-hover-color;
    text-decoration: none;
    background-color: $list-group-hover-bg;
  }

  &:active {
    color: $list-group-action-active-color;
    background-color: $list-group-action-active-bg;
  }
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  position: relative;
  display: block;
  padding: $list-group-item-padding-y $list-group-item-padding-x;
  // Place the border on the list items and negative margin up for better styling
  margin-bottom: -$list-group-border-width;
  background-color: $list-group-bg;
  border: $list-group-border-width solid $list-group-border-color;

  &:first-child {
    @include border-top-radius($list-group-border-radius);
  }

  &:last-child {
    margin-bottom: 0;
    @include border-bottom-radius($list-group-border-radius);
  }

  @include hover-focus {
    z-index: 1; // Place hover/active items above their siblings for proper border styling
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color;
    background-color: $list-group-disabled-bg;
  }

  // Include both here for `<a>`s and `<button>`s
  &.active {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: $list-group-active-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border-color;
  }
}


// Flush list items
//
// Remove borders and border-radius to keep list group items edge-to-edge. Most
// useful within other components (e.g., cards).

.list-group-flush {
  .list-group-item {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);
  }

  &:first-child {
    .list-group-item:first-child {
      border-top: 0;
    }
  }

  &:last-child {
    .list-group-item:last-child {
      border-bottom: 0;
    }
  }
}