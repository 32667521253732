.widget-table-list {
	.widget-table-list-row {
		padding-bottom:10px;
		margin-bottom:10px;
		border-bottom: $content-block-border;
		&:first-child {
		    margin-top:0px;
		}	
		&:last-child {
			margin-bottom:0px;
			border:none;
		}
		.show-more {
			color:lighten($text-font-color, 40%)
		}
		.widget-table-list-column-icon {
			img {
				width:40px;
				height:40px;
				float:left;
			}
			i {
			    height: 40px;
			    width: 40px;
			    float: left;
			    text-align: center;
			    vertical-align: middle;
			    line-height: 40px;
			    padding-right: 0px;
			    font-size: 18px;				
			}
		}		
		.widget-table-list-column-details {
			padding-left: 30px;
			position: relative;
			overflow: auto; // changed from hidden to auto! because on small screens you couldn't see the scroll bar  TC-24592
			.btn {
				@include remConversion(font-size, .8);
			}				
			.widget-table-list-column-details-row {
				display: block;
				width: 100%;
				@media(min-width: $screen-md) {
					display: table;
				}
				.widget-table-list-column-details-info {
					display:block;
					width:100%;
					vertical-align:middle;
					@media(min-width: $screen-md) {
						display:table-cell;
						padding-top:10px;
					}					
				}
				.widget-table-list-column-details-date {
					vertical-align: top;
					display: block;
					width:100%;
					text-align: left;				
					width: 150px;
					white-space: nowrap;
					padding-top: 15px;
					@media(min-width: $screen-md) {
						display:table-cell;
						text-align:center;
						padding-right: 10px;
					}							
					i {
						color: $info;
                        font-size: 0.6rem;
                        vertical-align: top;
                        padding-top: 7px;
                        padding-left: 2px;
                        padding-right: 5px;
					}
				}
				.widget-table-list-column-details-buttons {
					vertical-align: top;
					display: block;
					width:100%;
					white-space: nowrap;
					padding-top: 10px;		
					text-align: left;
					@media(min-width: $screen-md) {
						display:table-cell;
						width: 160px;
						text-align: center;
					}								
				}
			}
		}
	}		
}
