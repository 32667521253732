// BS4 !!
// stylelint-disable property-disallowed-list, declaration-no-important

//
// Border
//

.border         { border: $border-width solid $border-color !important; }
.border-top     { border-top: $border-width solid $border-color !important; }
.border-right   { border-right: $border-width solid $border-color !important; }
.border-bottom  { border-bottom: $border-width solid $border-color !important; }
.border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}

//
// Border-radius
//

.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: $rounded-pill !important;
}

.rounded-0 {
  border-radius: 0 !important;
}





// ORIGINAL BS3 STUFF! 
// Borders mixin
// --------------------------------------------------

@mixin border($direction){

    @if $direction == '' {
        .border-dotted {
            border: 1px dotted $default-background-color;
        }
        .border-dashed {
            border: 1px dashed $default-background-color;
        }
        .border-solid {
            border: 1px solid $default-background-color;
        }
        .border-solid-strong {
            border: 2px solid $default-background-color;
        }
        .border-solid-extrastrong {
            border: 4px solid $default-background-color;
        }
        .border-bgcolor {
            border: 3px solid $container-background;
        }
        .no-border,
        .no-borders {
            border: none !important;
        }
        @each $breakpoint, $size in $grid-breakpoints {
            @media(min-width: $size){
                .border-dotted-#{$breakpoint} {
                    border: 1px dotted $default-background-color;
                }
                .border-dashed-#{$breakpoint} {
                    border: 1px dashed $default-background-color;
                }
                .border-solid-#{$breakpoint} {
                    border: 1px solid $default-background-color;
                }
                .border-solid-strong-#{$breakpoint} {
                    border: 2px solid $default-background-color;
                }
                .border-solid-extrastrong-#{$breakpoint} {
                    border: 4px solid $default-background-color;
                }
                .border-bgcolor-#{$breakpoint} {
                    border: 3px solid $container-background;
                }
                .no-border-#{$breakpoint},
                .no-borders-#{$breakpoint} {
                    border: none !important;
                }
            }
        }
    } @else {
        .#{$direction}-border-dotted {
            border-#{$direction}: 1px dotted $default-background-color;
        }
        .#{$direction}-border-dashed {
            border-#{$direction}: 1px dashed $default-background-color;
        }
        .#{$direction}-border-solid {
            border-#{$direction}: 1px solid $default-background-color;
        }
        .#{$direction}-border-solid-strong {
            border-#{$direction}: 2px solid $default-background-color;
        }
        .#{$direction}-border-solid-extrastrong {
            border-#{$direction}: 4px solid $default-background-color;
        }
        .#{$direction}-border-bgcolor {
            border-#{$direction}: 4px solid $container-background;
        }
        .no-#{$direction}-border {
            border: none;
        }
        @each $breakpoint, $size in $grid-breakpoints {
            @media(min-width: $size){
                .#{$direction}-border-dotted-#{$breakpoint} {
                    border-#{$direction}: 1px dotted $default-background-color;
                }
                .#{$direction}-border-dashed-#{$breakpoint} {
                    border-#{$direction}: 1px dashed $default-background-color;
                }
                .#{$direction}-border-solid-#{$breakpoint} {
                    border-#{$direction}: 1px solid $default-background-color;
                }
                .#{$direction}-border-solid-strong-#{$breakpoint} {
                    border-#{$direction}: 2px solid $default-background-color;
                }
                .#{$direction}-border-solid-extrastrong-#{$breakpoint} {
                    border-#{$direction}: 4px solid $default-background-color;
                }
                .#{$direction}-border-bgcolor-#{$breakpoint} {
                    border-#{$direction}: 4px solid $container-background;
                }
                .no-#{$direction}-border-#{$breakpoint} {
                    border: none;
                }
            }
        }
    }
}

@mixin border-colors($direction, $color, $colorName) {
  @if $direction == '' {
    .border-color-#{$colorName} {
      border-color: #{$color};
    }
  }
  @else {
    .#{$direction}-border-color-#{$colorName} {
      border-color: #{$color};
    }
  }
}

@mixin border-radius-classes($direction) {
    @if $direction == '' {
        .border-radius {
            border-radius: 5px !important;
        }
        .border-radius-md {
            border-radius: 10px !important;
        }
        .border-radius-lg {
            border-radius: 15px !important;
        }
        .border-radius-xl {
            border-radius: 25px !important;
        }
        .no-border-radius {
            border-radius:0px !important;
        }
        @each $breakpoint, $size in $grid-breakpoints {
            @media(min-width: $size){
                .no-border-radius-#{$breakpoint} {
                    border-radius: 0px !important;
                }
                .border-radius-#{$breakpoint} {
                    border-radius: 5px !important;
                }
                .border-radius-md-#{$breakpoint} {
                    border-radius: 10px !important;
                }
                .border-radius-lg-#{$breakpoint} {
                    border-radius: 15px !important;
                }
                .border-radius-xl-#{$breakpoint} {
                    border-radius: 25px !important;
                }
            }
        }
    } @else {
        .border-radius-#{$direction} {
            border-#{$direction}-radius: 0px !important;
        }
        .border-radius-#{$direction} {
            border-#{$direction}-radius: 5px !important;
        }
        .border-radius-#{$direction}-md {
            border-#{$direction}-radius: 10px !important;
        }
        .border-radius-#{$direction}-lg {
            border-#{$direction}-radius: 15px !important;
        }
        .border-radius-#{$direction}-xl {
            border-#{$direction}-radius: 20px !important;
        }
        @each $breakpoint, $size in $grid-breakpoints {
            @media(min-width: $size){
                .border-radius-#{$direction}-#{$breakpoint} {
                    border-#{$direction}-radius: 5px !important;
                }
                .border-radius-#{$direction}-md-#{$breakpoint} {
                    border-#{$direction}-radius: 10px !important;
                }
                .border-radius-#{$direction}-lg-#{$breakpoint} {
                    border-#{$direction}-radius: 15px !important;
                }
                .border-radius-#{$direction}-xl-#{$breakpoint} {
                    border-#{$direction}-radius: 20px !important;
                }
            }
        }
    }
}

@mixin border-triangle($directionVertical, $directionHorizontal) {

    $directionVerticalOpposite: "top";
    @if ($directionVertical == "top") {
       $directionVerticalOpposite: "bottom";
    }
    $directionHorizontalOpposite: "right";
    @if ($directionHorizontal == "right") {
       $directionHorizontalOpposite: "left";
    }

    .triangle-#{$directionVertical}-#{$directionHorizontal} {
        #{$directionHorizontal}: 0;
        border-#{$directionHorizontalOpposite}-color: transparent;
        border-#{$directionHorizontal}: none;
        &.triangle-success {
            border-#{$directionVertical}-color: $success;
            .triangle-icon {
                color: $success-text-color;
            }
        }
        &.triangle-danger {
            border-#{$directionVertical}-color: $danger;
            .triangle-icon {
                color: $danger-text-color;
            }
        }
        &.triangle-warning {
            border-#{$directionVertical}-color: $warning;
            .triangle-icon {
                color: $warning-text-color;
            }
        }
        &.triangle-info {
            border-#{$directionVertical}-color: $info;
            .triangle-icon {
                color: $info-text-color;
            }
        }
        &.triangle-primary {
            border-#{$directionVertical}-color: $primary;
            .triangle-icon {
                color: $primary-text-color;
            }
        }
        &.triangle-secondary {
            border-#{$directionVertical}-color: $secondary;
            .triangle-icon {
                color: $secondary-text-color;
            }
        }
        &.triangle-tertiary {
            border-#{$directionVertical}-color: $tertiary;
            .triangle-icon {
                color: $tertiary-text-color;
            }
        }
        &.triangle-quaternary {
            border-#{$directionVertical}-color: $quaternary;
            .triangle-icon {
                color: $quaternary-text-color;
            }
        }
        &.triangle-default {
            border-#{$directionVertical}-color: $default-background-color;
            .triangle-icon {
                color: $default-text-color;
            }
        }
        &.triangle-dark {
            border-#{$directionVertical}-color: $dark;
            .triangle-icon {
                color: $dark-text-color;
            }
        }
        &.triangle-light {
            border-#{$directionVertical}-color: $light;
            .triangle-icon {
                color: $light-text-color;
            }
        }
    }
    .triangle-#{$directionVertical}-#{$directionHorizontal} .triangle-icon {
        #{$directionVertical}: -50px;
        #{$directionHorizontal}: 3px
    }
}
