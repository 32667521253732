.widget-pricing-slash {
	@extend .widget-block;
	padding:0px;
    display: flex;
    flex-direction: column;	
    flex-wrap:wrap;
    overflow:hidden;
    margin-right:15px;
    margin-left:15px;
    flex: 1;
    max-width: 500px;
    min-width:280px;
    &.primary-background, &.secondary-background, &.tertiary-background, &.quaternary-background {
    	background:$content-block-background-color !important;
    	color:$text-font-color !important;
    }
	&:before {
		content: "";
		position: absolute;
		height: 170px;
		width: 139%;
		transform: rotate(-17deg);
		top: -83px;
		left: -56px;
		z-index: 0;
	    background:$primary;
	}
	&.primary-background {
		&:before {
			background:$primary;
		}
		h2 {
			color:$primary-text-color;
		}
	}
	&.secondary-background {
		&:before {
			background:$secondary;
		}
		h2 {
			color:$secondary-text-color;
		}		
	}
	&.tertiary-background {
		&:before {
			background:$tertiary;
		}
		h2 {
			color:$tertiary-text-color;
		}		
	}
	&.quaternary-background {
		&:before {
			background:$quaternary;
		}
		h2 {
			color:$quaternary-text-color;
		}		
	}			
	h2 {
		border-bottom:none;
		text-transform: initial;
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
			text-transform: capitalize;
		}		
		text-align:center;
		padding-top: 15px;
	    padding-bottom: 15px;
	    margin-bottom: 0px;		
		z-index: 1;
		color: $primary-text-color;
		position: absolute;
		left: 20px;
		top: 10px;
		font-size: 1.3rem;
		max-width:340px;
	}
	.widget-pricing-slash-cost {
		height: 155px;
	    position: absolute;
	    width: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: flex-end;
	    padding-left: 40px;
	    @include  remConversion(font-size, 2.3);
	}
	.widget-pricing-slash-details {
		margin-top:5px;
		ul {
			padding:0;;
			list-style:none;
			margin-bottom:0px;
			li {
				border-bottom: $input-border;
				padding: 10px 20px;
				&:before {
				    content: "\f0da";
				    font-family: FontAwesome;	
				    padding-right:10px;				
				}
			}
			&:first-child {
				margin-top:175px;
			}
		} 
		p {
			border-bottom: $input-border;
			padding: 10px 20px;
			&:before {
			    content: "\f0da";
			    font-family: FontAwesome;	
			    padding-right:10px;				
			}			
		}
	}
	.widget-pricing-slash-button-container {
		padding-bottom:30px;
	    padding-top: 30px;
	    flex: 1;
	    justify-content: center;
	    align-items: flex-end;
	    display: flex;
	    flex-direction: row;		
	}
}