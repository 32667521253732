.widget-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
    &::before {
        content: 'mobile';
        display: none;
    }
    .timeline {
        position: relative;
        height: 100px;
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
    }
    .events-wrapper {
        position: relative;
        height: 100%;
        margin: 0 40px;
        overflow: hidden;
    }
    .btn {
        @include remConversion(font-size, 0.8);
    }       
    .events {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 49px;
        height: 2px;
        background: #dfdfdf;
        -webkit-transition: -webkit-transform 0.4s;
        -moz-transition: -moz-transform 0.4s;
        transition: transform 0.4s;
        min-width: 125%;
        ol {
            li {
                a {
                    position: absolute;
                    bottom: 0;
                    z-index: 2;
                    text-align: center;
                    padding-bottom: 10px;
                    color: #383838;
                    transform: translateZ(0);
                    &.selected {
                        pointer-events: none;
                        &::after {
                            background-color: $info;
                            border-color: $info;
                            // background-color: #7b9d6f;  ang: this seems to be a duplicate of below - not really sure
                            // border-color: #7b9d6f;
                            }
                        }
                    &.older-event::after {
                        border-color: $info;
                        // border-color: #7b9d6f;
                        }
                    &::after {
                        /* this is used to create the event spot */
                        
                        content: '';
                        position: absolute;
                        left: 50%;
                        right: auto;
                        transform: translateX(-50%);
                        bottom: -5px;
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        border: 2px solid #dfdfdf;
                        background-color: #f8f8f8;
                        transition: background-color 0.3s, border-color 0.3s;
                        }
                    }
                }
            li:nth-of-type(even) {
                a {
                    bottom:18px;
                    &::after {
                        bottom:-23px;
                        }
                    }
                }
            }
        }
    &::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        height: 100%;
        width: 20px;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        height: 100%;
        width: 20px;
        left: 0;
        background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
        background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
    }
    &::after {
        right: 0;
        background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
        background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
    }
    .filling-line {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #7b9d6f;
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
    .widget-timeline-navigation a {
        position: absolute;
        z-index: 1;
        top: 50%;
        bottom: auto;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 34px;
        width: 34px;
        border-radius: 50%;
        border: 2px solid #dfdfdf;
        /* replace text with an icon */
        
        overflow: hidden;
        color: transparent;
        text-indent: 100%;
        white-space: nowrap;
        -webkit-transition: border-color 0.3s;
        -moz-transition: border-color 0.3s;
        transition: border-color 0.3s;
        &::after {
            content: '';
            position: absolute;
            height: 16px;
            width: 16px;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            -webkit-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            -o-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }
        &.prev {
            left: 0;
            -webkit-transform: translateY(-50%) rotate(180deg);
            -moz-transform: translateY(-50%) rotate(180deg);
            -ms-transform: translateY(-50%) rotate(180deg);
            -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
        }
        &.next {
            right: 0;
        }
        a.inactive {
            cursor: not-allowed;
            &::after {
                background-position: 0 -16px;
            }
        }
    }
    .events-content {
        position: relative;
        width: 100%;
        margin: 2em 0;
        overflow: hidden;
        -webkit-transition: height 0.4s;
        -moz-transition: height 0.4s;
        transition: height 0.4s;
        li {
            position: absolute;
            z-index: 1;
            width: 100%;
            left: 0;
            top: 0;
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            transform: translateX(-100%);
            padding: 0 5%;
            opacity: 0;
            -webkit-animation-duration: 0.4s;
            -moz-animation-duration: 0.4s;
            animation-duration: 0.4s;
            -webkit-animation-timing-function: ease-in-out;
            -moz-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            &.selected {
                /* visible event content */
                
                position: relative;
                z-index: 2;
                opacity: 1;
                -webkit-transform: translateX(0);
                -moz-transform: translateX(0);
                -ms-transform: translateX(0);
                -o-transform: translateX(0);
                transform: translateX(0);
            }
            &.enter-right,
            &.leave-right {
                -webkit-animation-name: cd-enter-right;
                -moz-animation-name: cd-enter-right;
                animation-name: cd-enter-right;
            }
            &.enter-left,
            &.leave-left {
                -webkit-animation-name: cd-enter-left;
                -moz-animation-name: cd-enter-left;
                animation-name: cd-enter-left;
            }
            &.leave-right,
            &.leave-left {
                -webkit-animation-direction: reverse;
                -moz-animation-direction: reverse;
                animation-direction: reverse;
            }
            > * {
                max-width: 800px;
                margin: 0 auto;
            }
        }
    }
    .no-touch .widget-timeline .events a:hover::after {
        background-color: #7b9d6f;
        border-color: #7b9d6f;
    }
    @media only screen and (min-width: 1100px) {
        .widget-timeline {
            margin: 6em auto;
            &::before {
                /* never visible - this is used in jQuery to check the current MQ */
                
                content: 'desktop';
            }
        }
    }
    .no-touch .widget-timeline-navigation a:hover {
        border-color: #7b9d6f;
    }
    .no-touch .widget-timeline-navigation a.inactive:hover {
        border-color: #dfdfdf;
    }
    em {
        display: block;
        font-style: italic;
        margin: 10px auto;
        &::before {
            content: '- ';
        }
    }
    p {
        color: #959595;
    }
    em,
    p {
        line-height: 1.6;
    }
&.loaded {
		opacity: 1;
		margin:40px 0px 0px;
		font-family: $font-family-base;
		.timeline {
			width:100%;
		}
		.events-wrapper::after, .events-wrapper::before {
			background:none;
		}
		.widget-timeline-navigation	{
			 a::after {
			 	background:none;
			 }
			 a {
			 	background:$info-text-color;
			 	border:2px solid $info;
			 }
		}
		ol, ul {
			padding:0;
			list-style:none;
		}
		a {
			text-indent:0%;
			color:$text-font-color;
			font-family: $font-family-base;
		}
		a.next {	
			i {
				padding-left:12px;
				font-size:20px;
				margin-top:3px;
				color:$info;
			}		
		}
		a.prev {
			i {
				padding-left:12px;
				font-size:20px;
				margin-top:3px;
				color:$info;
			}
		}
		.filling-line, a.selected::after {
			background-color: $info;
			border-color: $info;
		}
		a.selected::after {
			background: $info-text-color;
		}
		a.older-event::after {
			border-color: $info;
			background: $info;
		}
		.events-content {
			margin-bottom:0px;
            height: auto !important;
			.events-content-header {
				border-bottom:$content-block-border;
				padding-bottom: 10px;
				margin-bottom:10px;
				h2 {
					width:60%;
					display:inline-block;
					float:left;
					margin:0;
					border:none;
					padding:0;
				}
				.date-container {
					width:40%;
					display:inline-block;
					float:left;
					text-align:right;
					font-weight:normal;
					position:relative;
				}
			}
			h2 {
				font-family: $font-family-base;
			}
			li.selected {
				padding:0;
                margin-bottom: 90px;
			}
			p {
				color:lighten($text-font-color, 20%);
			}
		}
	}    
}
@-webkit-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}
@keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}
@-webkit-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}
@keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}