//
// Borders
// --------------------------------------------------
$romeo-border-direction-list: top bottom right left;

// Add values here for border colors that you want to exist
$romeo-border-colors: (
   ($romeo-primary, 'primary'),
   ($romeo-secondary, 'secondary'),
   ($romeo-tertiary, 'tertiary'),
   ($romeo-quaternary, 'quaternary'),
);

.romeo {

    @each $colorset in $romeo-border-colors {
        @include border-colors("", nth($colorset, 1), nth($colorset, 2));
    }

}