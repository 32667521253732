// @import "../../node_modules/compass-sass-mixins/lib/compass/css3/user-interface";
// .input-text-slide-out {
// 	float: right;
// 	position: relative;
// 	width:100%;
// 	button {
// 		text-align: center;
// 		&:focus {
// 			outline: none;
// 		}
// 		&.btn-search,{
// 			border: none;
// 			height: 30px;
// 			font-size: 12px;
// 			padding: 4px;
// 			position: absolute;
// 			width: 30px;
// 		}
// 	}
// 	input {
// 		border-radius: 0px;
// 		right: 0;
// 		transition: all .3s ease-in-out;
// 		width: 100%;
// 		 @include placeholder {
// 		    color: $top-search-bar-input-placeholder-color;
// 		}
// 		@media(min-width:$screen-sm){
// 			width:50%;
// 		}
// 		@media(min-width:$screen-md){
// 			width:100%;
// 		}		
// 		height: 30px;
// 		padding: 4px 8px;
// 		position: relative;
// 		float:right;
// 		border:none;
// 		background:darken($top-search-bar, 10%);		
// 		&:focus {
// 			outline: none;
// 		}		
// 		&:focus {
// 			width: 100%;
// 			~ button {
// 				&.btn-search {
// 				}
// 			}
// 		}
// 	}
// 	button {
// 		transition: all .3s ease-in-out;
// 		&.btn-search {
// 			height: 26px;
// 			right: 2px;
// 			top: 2px;
// 			transition: all .3s ease-in-out;
// 			width: 26px;
// 			background:transparent;
// 			color:$top-search-bar-input-placeholder-color;
// 		}
// 	}
// }












