/** INLINE EDITABLE ELEMENTS **/
.form-group {    
    &.inline-edit {
        margin-bottom:15px !important;
        .input-group {
            button, .btn {
                min-width: 45px;
                max-height: 34px;
            }
        }
        .not-editable {
            .form-control {
                border: none;
                border-bottom:  1px #ccc dashed;
                border-radius: 0px;
                box-shadow: none;
                font-size: $text-font-size;
                vertical-align: text-top;
                padding-top: 5px;  
                padding-left: 0px;
                padding-right: 0px;
                height: auto;
                .edit {
                    padding-left:10px;
                }               
                &:hover {
                    border: 1px #5D5D5D dashed;
                    padding-left: 7px;  
                    padding-top: 4px;          
                    cursor:text;
                    .edit {
                        margin-right:-1px;
                    }              
                }            
            }     
            @media(min-width:$screen-sm){
                .form-control {
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }                               
        }
    }
}