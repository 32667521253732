@mixin textSizes($numbers, $baseSize, $font-size, $class, $screen-size){
	@each $item in $numbers {
		$baseSizeStripped: strip-px($baseSize) * strip-px($font-size);
		$font-size-pixels: $baseSizeStripped + $item;

		@if $screen-size != "" {
			.#{$class}-#{$item}x-#{$screen-size} {
				font-size: $font-size-pixels * 1px !important;
				font-size: ($font-size-pixels / strip-px($baseSize)) * 1rem !important;
			}
		} @else {
			.#{$class}-#{$item}x {
				font-size: $font-size-pixels * 1px !important;
				font-size: ($font-size-pixels / strip-px($baseSize)) * 1rem !important;
			}
		}
	}
}

@mixin textAlign($class, $screen-size){

	$alignments: left, right, center;

	@each $alignment in $alignments {

		.#{$class}-#{$alignment}-#{$screen-size} {
			text-align: $alignment !important;
		}

	}
}

@mixin textStrong($class, $fontWeight, $screen-size){

	.#{$class}-#{$screen-size} {
		font-weight: $fontWeight !important;
	}

}
