
/** Woo hoo! New header! **/

.page-operator {

	header {
		box-shadow: $header-box-shadow;
		position: fixed;
		top:0;
		left:0;
		right:0;
		z-index: 1001; //!!   can't be higher than that because the 'full screen' calendar is 1001
		height:$header-height; // needs a height so we can move the navbar down
		@media(min-width: $screen-lg) {
			height:$header-height-lg; 
			display:block;
			}
		
		.navbar-toggler {
			padding:1rem 1rem 1rem .8rem;
			color: $header-toggle-color; 
			&:hover, &:active, &:focus {
				color:$header-toggle-hover-color;
				}
			}

		// logo and menu toggle
		.page-brand {
			background: $header-brand-bg; 
			height: $header-height-lg;
			width: 100%;
			@media(min-width: $screen-lg) {
				width:$nav-width; 
				height:auto;
				padding-right: 0.5rem;
				}		
			// logo
			.client-logo {
				max-height: $header-logo-height;
				max-width: 100%;
				}
			}
		
			// partition, user and search 
			.page-header {
				background: $header-bg;
				padding:0 1rem; //!!

				// partition and user dropdowns
				.dropdown {
					> a {
						padding:.25rem 0;
						color: $header-a-color;
						font-weight:500;
						&:hover, &:active, &:focus {
							text-decoration: none;
							color: $header-a-hover-color;
							}
						}
					}	
				
				// search
				.search-global {				
					@media(min-width: $screen-lg) {
						// margin-right:.75rem;
						margin-right:1.5rem;
						}
					@media(min-width: 1300px) {
						max-width:650px;
						}
					.input-group, .ng-select-container {
						background-color: $header-input-bg;
						border-radius: 1rem;
						padding:0rem .5rem;
						@media(min-width: $screen-lg) {
							padding:.25rem .5rem;
							}
						&.input-group {
							display: flex;  // overwriting my changes in migration - very annoying!
							}
						.form-control, .input-group-text, .form-control::placeholder {
							border:none;
							background:transparent;
							width:auto; // overwriting my changes in migration - very annoying!
							display:flex;
							color:$header-input-color;
							}
						}
					.ng-select-container {
						// specific styling for the new angular template
						border:none;
						font-size: .8rem;
						.ng-placeholder {
							color:$header-input-color;
							}
						.ng-input {
							top:10px;
							left:10px
							}
						}
					.ng-dropdown-panel {
						border:$dropdown-border-width solid $dropdown-border-color;
						@include border-radius($dropdown-border-radius);
						@include box-shadow($dropdown-box-shadow);
						width:90%;
						left:1rem;
						.ng-dropdown-panel-items {
							.ng-option {
								font-weight: $font-weight-medium;
								color: $dropdown-link-color;
								font-size: .85rem;
								&.ng-option-marked {
									color: $dropdown-link-hover-color;
									@include gradient-bg($dropdown-link-hover-bg);
									}
								}
							}
						}
					}

					// partition dropdown
					.partition-selector {
						.dropdown {
							margin-right:1.5rem;  // adding margin here so it's only there when there is indeed a partition dropdown
							}
						.dropdown > a {
							max-width:150px;  // width needed for text-truncate to work! 
							@media(min-width: $screen-sm) {
								max-width:170px;   
								}
							@media(min-width: $screen-md) {
								max-width:270px;   
								}
							@media(min-width: $screen-lg) {
								max-width:190px;   
								}
							@media(min-width: $screen-xl) {
								max-width:290px;   
								}
							}
						.dropdown-menu a {
							white-space: normal;
							}
						}

					.header-links {
						display: flex;
						align-items: center;
						@media(max-width: $screen-lg) {  // moving to brand bar on small screens
							position:absolute;
							top: 0.7rem;
							right: 1rem;						
							}

						// beamer 
						.beamer_button {
							margin-right:.5rem;
							.fa-stack {
								height: 1.7rem; // overwriting height so it matches the user icon
								i { // trying to match the profile icon
									color: $header-icon-color;
									&.fa-stack-2x {
										font-size: 1.7rem;
										opacity: .4;
										}
									&.fa-stack-1x {
										font-size: 1.15rem;
										}					
									}
								}
							.beamer_icon.active {  // copying .badge styling
								animation: none !important; // to stop it doing the auto jiggle
								background-color: $info !important;
								color: $primary-text-color;
								position: absolute;
								right: 0 !important;
								height: 18px;
								width: 18px;
								font-size: .75rem;
								padding:0;
								border-radius: .9375rem;
								font-weight: 700;
								text-align: center;
								}
							&:hover {
								.fa-stack i {
									color:$header-a-hover-color;
									cursor: pointer;
									}
								}
							}	
		
						// user dropdown
						.user-details {						
							> a {
								@media(max-width: $screen-lg) {
									color:$header-icon-color;
									}
								.fa-user-circle {
									color:$header-icon-color;
									font-size: 1.7rem;
									margin-right:.5rem;
									@media(max-width: $screen-lg) {
										color:$header-icon-color;
										}
									}
								&:hover {
									.fa-user-circle {
										color:$header-a-hover-color;
										}
									}
								}
							}

						}	
					}

				}

			}


// quicksearch drop down 
.search-global {
	.dropdown-item  {
		padding:.5rem .25rem;
		.quicksearch-display-row {	
			display: flex;	
			justify-content: space-between;
			.quicksearch-display-value {  // student name
				padding: 0 .25rem;
				overflow: hidden;
				text-overflow: ellipsis;
				order:1;
				flex-grow: 1;
				}
			.quicksearch-tag { // type (i.e. [S] etc)
				text-align: right;
				padding:0 .25rem;;
				overflow: hidden;
				text-overflow: clip;
				order:3;				
				}
			.quicksearch-info { // student id
				padding: 0 .25rem;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: right;
				order:2;
				flex-shrink: 1;
				}
			}
		}
	}

// header{
//     position: fixed;
//     top: 0;
//     left: $header-background-left;
//     width: 100%;
//     height:$header-shrink-size;
//     overflow: visible;
//     // transition: all 0.3s;
//     margin:$header-background-margin;
//     padding:$header-background-padding;
//     z-index:100;
//     @include flexbox;
//     @include flex-direction($header-flex-direction);
//     background:$header-shrink-background;
//     @include justify-content(space-between);
//     @media(min-width: $screen-sm){
// 		background:$header-background;
// 		height:$header-height;
//     } 
//     .site-logo {
// 		height: $logo-mobile-size;
// 		width:auto;
// 		-webkit-transition: all 0.3s ease;
// 		margin: $logo-mobile-margin;
// 		background: $logo-mobile-background;
// 		padding: $logo-mobile-background-padding;
// 	    @media(min-width: $screen-sm){
// 			height:$logo-desktop-size;
// 			margin:$logo-desktop-margin;
// 			width:auto;
// 			background: $logo-desktop-background;
// 			padding: $logo-desktop-background-padding;			
// 		}	
//     }
//     &.shrink { 
//         border-bottom:$header-shrink-border;
// 		@media(min-width: $screen-sm){
// 		    height:$header-shrink-size;
// 		    background: $header-shrink-background;
// 		    z-index: 100;		    
// 		    .site-logo {
// 			    -webkit-transition: all 0.3s ease;
// 			    width:auto;
// 			    height:$logo-mobile-size;
// 		    	margin: $header-shrink-margin 0;			    
// 			}   
// 		}
// 		.user-details {
// 			margin:$user-details-margin-shrink;
// 			}
//     }    
// 	a {
// 		color:$partition-menu-color;
// 		&:focus, &:hover {
// 			color:$partition-menu-color;;
// 		}
// 		i {
// 			color:$partition-menu-color;
// 		}
// 	}
// 	.user-details {
// 		position: $user-details-position;
// 	    right: $user-details-right;
// 	    top: $user-details-top;
// 		margin:$user-details-margin;
// 		transition: 0.5s;
// 		.sign-out, .block-button {
// 			background: $menu-sign-out-background;
// 			color: $menu-sign-out-color;
// 			padding: 7px 10px;
// 			font-size: 16px;
// 			float:right;
// 			.fa {
// 				padding-right:0px;
// 			}
// 		}
// 		.block-button {
// 			background: $secondary-background-color;
// 		}
// 		.username {
// 			float:right;
// 			color: $secondary-text-color;
// 			padding-right: 0;
// 			margin-right: 10px;
// 			border: 1px solid lighten($header-item-color, 10%);	
// 			cursor:pointer;
// 			.username-text{
// 				float: left;
// 				padding-top: 9px;
// 				font-size: 12px;
// 				padding-left: 15px;
// 				text-transform: uppercase;
// 				color:$header-item-text-color;
// 			}
// 			.claret {
// 				border-color:$header-item-text-color;
// 			}	
// 			i {
// 				font-size: 18px;
// 				color: $menu-username-color;
// 				background: $menu-username-background;
// 				padding: 8px 7px 9px;
// 				margin-left: 15px;
// 			}
// 		}
// 		.partition-selector {
// 			float: right;
// 			.dropdown-menu {
// 				border-radius:0px;
// 			}
// 			.btn-primary {
// 				background: transparent !important;
// 			    padding-top: 9px;
// 			    padding-bottom: 9px;
// 			    padding-right: 10px;
// 				border: 1px solid lighten($header-item-color, 10%);
// 				border-radius:0px;
// 				color:$header-item-text-color !important;
// 				font-size:12px;
// 				&:hover, &:focus {
// 					background: transparent !important;
// 					color:$header-item-text-color !important;
// 					border: 1px solid lighten($header-item-color, 10%);
// 				}
// 			}
// 			.claret {
// 				color:$header-item-text-color;
// 			}	
// 			@media(min-width: $screen-md) {		
// 				&.partition-selector-buttons {
// 					.dropdown {
// 						button.dropdown-toggle {
// 							background: $partition-button-active-background !important;	
// 							color: $partition-button-active-text-color !important;
// 							border:none;
// 							margin-left:5px;
// 							.caret {
// 								display: none;
// 							}
// 							&:hover {
// 								background: darken($partition-button-active-background, 10%) !important;
// 							}
// 						}
// 						ul {
// 							.partitionSearchContainer {
// 								display: none;
// 							}
// 							margin: 0;
// 							padding: 0;
// 							overflow: hidden;
// 							position: relative;
// 							display:inline-block;
// 							background: transparent;	
// 							box-shadow: none;
// 							-webkit-box-shadow: none;	
// 							border:none;	
// 							top:0;									
// 							li {
// 								float:left;							
// 								padding:0px;
// 								background: transparent;
// 								margin-left:5px;
// 								a {
// 									@include remConversion(font-size, 0.8);  								
// 									padding: $md-vertical $md-horizontal;
// 									background: $partition-button-background;	
// 									color: $partition-button-text-color;
// 									i {
// 										display:none;
// 									}
// 									&:hover {
// 										background: darken($partition-button-background, 10%) !important;
// 									}
// 								}
// 							}					
// 						}
// 					}
// 				}
// 			}
// 		}
// 		.divider {
// 			float: right;
// 			width: 2px;
// 			height: 36px;
// 			background: lighten($header-item-color, 10%);
// 			margin-right: 10px;
// 			margin-left: 10px;
// 			color:lighten($header-item-color, 10%);
// 		}
// 	}   	 
// 	.open > .dropdown-menu {
// 		background: $profile-menu-background;	
// 		margin-top:15px;
// 		border-radius:0px; 		
// 		z-index: 1000;
// 		&.partition-menu { 
// 			background: $partition-menu-background;		
// 		    padding-bottom: 0px;	  .partitionSearchContainer {
// 		    	position:relative;
// 				#partitionSearch {
// 					width: auto;
// 				    margin: 0 10px;	
// 				    background: lighten($partition-menu-background, 20%);	
// 				    border-color: darken($partition-menu-background, 20%);;	
// 				    padding-right: 20px;
// 				    border-radius:0px;  
// 				}		    
// 			    i {
// 				    position: absolute;
// 				    top: 10px;
// 				    right: 15px;
// 				    font-size: 12px;		    	
// 			    }
// 		    }
// 			&:before {
// 				border-color: transparent transparent $partition-menu-background;
// 			}
// 			> li {
// 				border-bottom:1px solid darken($partition-menu-background, 10%);
// 			}
// 			> a {
// 				color:$partition-menu-color;
// 				cursor:pointer;
// 				&:focus, &:hover {
// 					color:$partition-menu-color;;
// 				}
// 				i {
// 					color:$partition-menu-color;
// 				}
// 			}			
// 		}
// 		&.partition-menu-multiple {
// 		    max-height: 300px;
// 		    overflow-y: auto;	
// 		}    		
// 		&:before {
// 			top: -10px;
// 		    left: -1px;
// 		    width: 0;
// 		    height: 0;
// 		    content: ' ';
// 		    position: absolute;
// 		    border-style: solid;
// 		    display: inline-block;
// 		    border-width: 0px 10px 10px 0px;
// 		    border-color: transparent transparent $profile-menu-background;
// 		}			
// 		> li {
// 			padding-bottom:7px;
// 			padding-top: 5px;
// 			border-bottom:1px solid darken($profile-menu-background, 10%);
// 			&:last-child {
// 				border:none;
// 			}
// 			&:first-child {
// 				padding-top:0px;
// 			}			
// 			> a {
// 				font-size:0.8rem;
// 				color:$profile-menu-color;
// 				cursor:pointer;
// 				&:focus, &:hover {
// 					background:transparent;
// 					color:$profile-menu-color;;
// 				}
// 				i {
// 					padding: 0;
// 					margin: 0;
// 					font-size: .7rem;
// 					margin-right: 10px;
// 					margin-left: -5px;
// 					background:transparent;
// 					color:$profile-menu-color;
// 					width:15px;
// 				}
// 			}

// 		}
// 	}   
// }
// .no-nav {
// 	.navbar.navbar-default, .user-details {
// 		display:none;
// 	}

// 	.top-search-bar {
// 		margin-top: $navigation-margin;
// 	}

// 	&:not(.no-search-bar) {
// 		.top-search-bar {
// 			margin-top: ($header-shrink-size);

// 			@media(min-width: $screen-sm){
// 				margin-top: $header-height;
// 			}
// 		}
// 	}

// }

// .no-search-bar {
// 	.top-search-bar { 
// 		display:none;
// 	}

// 	&:not(.no-nav) {
// 		.content-body {
// 			margin-top: ($header-shrink-size);

// 			@media(min-width: $screen-sm) {
// 				margin-top: 0px;
// 			}
// 		}
// 	}
// 	.content-body {
// 		margin-top: $navigation-margin;
// 	}
// }

// .no-nav.no-search-bar {
// 	.content-body {
// 		margin-top: ($header-shrink-size);

// 		@media(min-width: $screen-sm){
// 			margin-top: $header-height;
// 		}
// 	}
// }





// .top-search-bar {
// 	background:	$top-search-bar;
// 	padding: 10px 15px;
//     font-size:0.85rem;	
// 	color:$top-search-bar-color;
// 	margin-top: $header-shrink-size;
//     border-top: $top-search-bar-mobile-border-top;
//     border-bottom: $top-search-bar-mobile-border-bottom;
// 	a {
// 		color:$top-search-bar-color;
// 	}
// 	@media(min-width: $screen-sm){
// 		margin-top:0px;
//         border-top: $top-search-bar-desktop-border-top;
//         border-bottom: $top-search-bar-desktop-border-bottom;
//         min-height: 52px;
// 	}
// }
// header.header-relative {
// 	position:relative;
// 	display:block;
// 	min-width:100%;
// 	width:auto;
// 	left:0;
// } 
// header.custom-header-1 {
// 	background: $custom-header-1-background;
// 	color: $custom-header-1-color;
// 	height: $custom-header-1-height;
// 	margin: $custom-header-1-margin;
// 	padding: $custom-header-1-padding;
// 	.site-logo {
// 		height: $custom-header-1-logo-height;
// 		width: $custom-header-1-logo-width;
// 		margin: $custom-header-1-logo-margin;
// 		padding: $custom-header-1-logo-padding;		
// 	}
// }