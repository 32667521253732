// As we're having a lot of flexbox, grid and svg issues in ie11 which wont fix I've added this file to add some specific IE11 overrides
// Idea going forward is as long as ie11 is functional its all good, users will have to be aware they wont get the same user experience as on modern browsers.

_:-ms-fullscreen, :root { // Place all IE Only class inside here
    .ie11-only-max-width-700px {
        max-width:700px !important;
    }
    .ie11-only-d-block-important {
        display:block !important
    }  
    .ie11-only-overflow-auto {
        display:block !important
    }          
}
