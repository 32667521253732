// html {
// 	font-size: $text-root-font-size;
// }
// .login {
// 	margin:0;
// 	background:$login-background-mobile;
// 	background-size:cover;
// 	font-size:$login-font-text-size;
// 	@media(min-width: $screen-md){
// 		background:$login-background-body-desktop;
// 	}
// 	._field-errors {
// 		display:none;
// 	}
// 	.container-fluid {
// 		padding-right: 15px;
// 		padding-left: 15px;		
// 	}
// 	.body {
// 		min-height: calc(100vh - 60px);
// 		padding-bottom:40px;
// 		.side-section {
// 			padding:0;
// 			overflow-x:hidden;
// 			font-size:$login-font-text-size;
// 			color: $login-secondary-text-color-mobile;
// 			@media(min-width: $screen-md){
// 			    height: 100%;	
// 			    background: $login-side-background;
// 			    overflow:auto;
// 			    position:absolute;
// 			    right:0px;
// 			    color: $login-secondary-text-color-desktop;
// 			}	
// 			.side-section-body {		
// 				padding:0 15px;
// 				@media(min-width: $screen-md){
// 					min-height:100%;
// 					position:relative;	
// 					overflow-x:hidden;
// 					padding-bottom:100px;
// 				}
// 				.side-section-body-container {
// 					max-width: 500px;
// 				    margin: 0 auto;		
// 				    padding-bottom:20px;						
// 					@media(min-width: $screen-md){
// 						max-width: initial;	
// 						padding:0 30px;	
// 						padding-top:10vh;
// 					}							  
// 				    h2 {
// 				    	text-align: center;
// 						@include remConversion(font-size, $login-heading-secondary-font-size);
// 						font-family: $login-side-heading-font;
// 						color: $login-secondary-text-color-mobile;
// 						margin-top:0px;
// 						@media(min-width: $screen-md){							
// 							color: $login-secondary-text-color-desktop;
// 						}
// 				    }  
// 				    h3 {
// 				    	margin-bottom:20px;
// 				    }
// 				    .btn, .btn-primary {
// 				    	width:100%;
// 						padding-top: 11px;
// 						padding-bottom: 12px;		   
// 						background:$login-button-background-color !important;
// 						color:$login-button-text-color !important;
// 				    }
// 				    .form-control {
// 				    	background:$login-input-background-mobile !important;
// 				    	padding: 0 15px;
// 				    	@include remConversion(border-radius,  $login-input-border-radius-mobile);
// 						@media(min-width: $screen-md){
// 							color: $login-secondary-text-color-desktop !important;
// 							-webkit-text-fill-color: $login-secondary-text-color-desktop !important;
// 						}				    	
// 				    }
// 				    a {
// 				    	color: $login-secondary-text-color-mobile;
// 				    	cursor:pointer;
// 						@media(min-width: $screen-md){
// 						    color: $login-secondary-text-color-desktop;
// 						}					    	
// 				    }
// 				    .forgottenPassword, .back {
// 				    	cursor:pointer;
// 				    }
// 				    label {
// 				    	padding-left:5px;
// 				    	@media(min-width:$screen-md){
// 				    		padding-left:15px;
// 				    	}
// 				    }
// 				    .pills {
// 				    	a {
// 				    		color:$login-pill-text-color;
// 				    	}	
// 				    }
// 				    .form-control,  {
// 						color: $login-input-text-color-mobile !important;
// 						-webkit-text-fill-color: $login-input-text-color-mobile !important;
// 						font-size:$login-font-text-size !important;
// 						height:$login-input-height-mobile !important;
// 						background: $login-input-background-mobile !important;
// 						border:$login-input-border-mobile;
// 						@media(min-width: $screen-md){
// 							color: $login-input-text-color-desktop !important;
// 							-webkit-text-fill-color: $login-input-text-color-desktop !important;
// 							height:$login-input-height-desktop !important;
// 							background: $login-input-background-desktop !important;
// 							border:$login-input-border-desktop;
// 						}				    	
// 				    }
// 				    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
// 						color: $login-input-text-color-mobile !important;
// 						-webkit-text-fill-color: $login-input-text-color-mobile !important;
// 						font-size:$login-font-text-size !important;
// 						height:$login-input-height-mobile !important;
// 						background: $login-input-background-mobile !important;
// 						border:$login-input-border-mobile;
// 						@media(min-width: $screen-md){
// 							color: $login-input-text-color-desktop !important;
// 							-webkit-text-fill-color: $login-input-text-color-desktop !important;
// 							height:$login-input-height-desktop !important;
// 							background: $login-input-background-desktop !important;
// 							border:$login-input-border-desktop;
// 						}	
// 					}								
// 					@-webkit-keyframes autofill {
// 					    to {
// 					        color: $login-secondary-text-color-mobile !important;
// 							background: $login-input-background-mobile;
// 							border:$login-input-border-mobile;
// 					        @media(min-width:$screen-md){
// 					            color: $login-secondary-text-color-desktop !important;
// 								background: $login-input-background-desktop;	
// 								border:$login-input-border-desktop ;		            
// 					        }
// 					    }
// 					}	
// 					input:-webkit-autofill {
// 					    -webkit-animation-name: autofill;
// 					    -webkit-animation-fill-mode: both;
// 					    padding-left: 15px;  
// 					    width:100%;
// 					}						
// 				}
// 				ul.pills, .pills { 
// 					margin:0;
// 					padding:0;
// 					&.pills-inline {
// 						li, .pill {
// 							display:inline-block;
// 							margin:0px;
// 						}
// 					}	
// 					&.pills-sm {
// 						li, .pill {
// 							padding:5px 10px;
// 							font-size: 90%;
// 						}
// 					}		
// 					li, .pill {
// 						padding:10px 15px;
// 						margin-bottom:10px;
// 						position:relative;
// 						overflow:hidden;
// 						transition:1s;
// 						min-width: 28px;
// 						background:$login-pill-background-color;
// 						color:$login-pill-text-color;
// 						@include remConversion(border-radius,$input-border-radius);
// 						&.pill-clear {
// 							background:transparent;
// 							color:$text-font-color;
// 						}
// 						&.pill-inverse {
// 							background:$login-pill-text-color;
// 							color:$login-pill-background-color;
// 							&:hover {
// 								background:darken($login-pill-text-color,10%);		
// 							}			
// 						}		
// 						&:hover {
// 							transition:0.5s;
// 							cursor:pointer;
// 							background:darken($login-pill-background-color,10%);
// 							i {
// 								transform: scale(4.5);	
// 								transition:0.5s;
// 								overflow:hidden;
// 							}			
// 						}
// 						i {
// 							position:absolute;
// 							right:15px;
// 							top:15px;
// 							transition:0.5s;
// 						}	
// 						a, span {
// 							color:$login-pill-text-color;
// 							cursor:pointer;
// 							&:hover {
// 								text-decoration:none;
// 							}
// 						}	
// 						button { // for leap view on tc
// 							background:transparent;
// 						}
// 					}
// 				}					
// 			}
// 		}		
// 	}
// }

