.widget-simple-action-center { 
	border: $input-border;
	position: relative;
    overflow: hidden;
    padding: 20px;
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    background:$content-block-background-color;
	flex: 1;
	-ms-flex: 1 0 auto;
    max-width: 500px;
    min-width: 280px;    
	.widget-simple-action-content {
	    display: flex;
	    align-items: flex-start;
	    flex-direction: column; 
	}
	.widget-simple-action-background {
		order:-5;
		display:flex;
		justify-content:center;
		align-items:center;
		height: auto;
    	width: 100%;
		> i {
			@include remConversion(font-size, 2rem);
			padding:0;
			margin:0;
		}
		img {
		    width: auto; 
			height: 90px;
		}
		&.bg-primary {
			i {
				color:$primary-text-color !important;
			}
		}	
		&.bg-secondary {
			i {
				color:$secondary-text-color !important;
			} 
		}	
		&.bg-tertiary {
			i {
				color:$tertiary-text-color !important;
			}
		}	
		&.bg-quaternary {
			i {
				color:$quaternary-text-color !important;
			}
		}				
	}			
	h2 {
		@include remConversion(font-size, 1.6rem);
	    margin-bottom: 15px;
	    margin-top: 15px;
	    padding:0;
	    width:100%;
	    text-align: center; 
	}
	p {
		color: $text-font-color;
		text-align:center;
		max-width:100%; // for IE
		width: 100%;
	}
	> a {
		color:inherit;
	}
	a:hover {
		text-decoration:underline;
	}
	.btn {
		margin:20px auto 5px;
	}
}
.row-eq-height {
	.widget-simple-action-center {
		margin-right: 15px;
	    margin-left: 15px;
	}
}