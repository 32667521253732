.profile-column {
    > div[class^='col'] {
        border-right:$profile-column-border;
    }
    > div[class^='col']:last-child {
        border-right:none;
    }
    .profile-column-details-logo {
        border: $profile-column-border;
        img {
            width: 100%;
            padding: 20px;   
            max-height:200px;   
            height:auto;      
        }
    }
    .profile-column-details {
        h2 {
            border-bottom:$profile-column-border;
            padding-bottom:7px;
            margin-bottom:20px;
            font-size:1.2rem;
            text-transform:uppercase;
        }
        .profile-column-details-items {
            margin:0;
            padding:0;
            li {
                margin-bottom:7px;
                div {
                    text-align:right;
                }
                label {
                    text-align:left;
                    font-weight:bold;
                }                
            }
        }
    }
    .widget-block {
        margin-bottom:10px;
    }
}