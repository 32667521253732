//////////////////////////////////////////////
// Temp Helpers
//////////////////////////////////////////////
// For chat-like comments list
// e.g. On Student Single Activity Details section (right) when an activity is submitted/accepted/rejected
.comment-list
{
	margin: 0 5px;
	padding: 0;
	display: flex;
	flex-direction: column;

	li
	{
		list-style: none;
		border-radius: 3px;
		padding: 5px;
		align-self: flex-start;
		width: calc(100% - 30px);

		&:nth-child(even)
		{
			align-self: flex-end;
		}
	}

	&.comment-list-light
	{
		li
		{
			background-color: $light;
			color: $default-text-color;
		}
	}

	&.comment-list-dark
	{
		li
		{
			background-color: $default-text-color;
			color: $light;
		}
	}
}
