$favourite-color: #c4141b;

.romeo {

    .content-body {

        /*** Sidebar ***/
        &.wrapper-with-sidebar {

            overflow:visible; /* AW added because on mobile the custom content and top right buttons were being hidden (due to a style in tc\custom) */

            .sidebar {
                position: fixed;
                top: 0;
                left:-160%;
                height: 100%;
                padding: 0; 
                z-index: 1049;
                background: #e6e6e6; 
                border-bottom: none;
                max-width: 261px; // has to be a fixed px because 'none' was causing problems for loooong ng-selects (on custom user>cohorts)
                @include media-breakpoint-down(md) {
                    width: calc(100vw);
                    max-width:none;
                } 
                @include media-breakpoint-up(lg) {
                    left:15px;
                    margin: -20px 0 0 0;
                    }
                .skipnavigation {
                    +h2.heading {
                        margin: 0;
                        position: absolute;
                        width: calc(100vw);
                        height: 50px;
                        display: flex;
                        align-items: center;
                        padding: 1rem;
                        background: #e6e6e6 !important;
                        font-size: 1rem;
                        font-weight: 600;
                        color: $default-text-color !important;
                        .fa-search {
                            display :none;
                        }
                        .btn-toggle-sidebar {
                            margin: 0;
                            padding: 0;
                            position: absolute;
                            right: 10px; 
                            font-size: 1.6rem; 
                            width: 30px;
                            top: 5px;
                            transform: none !important;
                            transition: none !important;
                            cursor: pointer;
                            &:before {
                                content: "\f00d";
                                font-family: "Font Awesome 5 Pro";
                                width: 30px;
                                display: flex;
                                color: $default-text-color;
                                justify-content: center;
                                font-weight: normal;                                                                                                     
                            }                              
                        }
                    }
                }                                  
                .form-group {
                    &.filters {
                        .checkbox {
                            margin-left: 0;
                            label {
                                font-size: .8rem;
                                font-weight: 400;
                                padding-top: 0;
                                input[type=checkbox] {
                                    border-radius: 0.25rem;
                                    height: 16px;
                                    width: 16px;
                                    border-color: $palette-grey-300;
                                    margin-top: 1px;
                                    &::before {
                                        height: 16px;
                                        width: 14px;
                                        font-size: 10px;
                                        line-height: 15px;
                                    }
                                }
                                &:has(input:focus-visible) {
                                    text-decoration: underline;
                                    }
                                &:hover {
                                    color: $romeo-secondary;
                                    cursor: pointer;
                                    input[type=checkbox] {
                                        border-color: $palette-grey-400;
                                        }
                                    }
                                }
                        }
                    }
                    .input-group {
                        width: 100%;
                        .input-group-btn {
                            .btn:not(.btn-no-text) .fa:first-of-type {
                                margin:0 5px;
                            }
                        }                            
                    }
                }
                .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
                    font-size:.85rem;
                    }
                .action-container-content {
                    background: $white;
                    #sidebar-content {
                        padding: 1rem;
                        @include media-breakpoint-up(lg) {
                            padding: 1rem 1rem 1rem 0;
                            }
                        #placement-info-holder {
                            h2 {
                                background: transparent !important;
                                color: $romeo-primary !important;
                                font-size: 1rem;
                                font-weight: 600;
                                padding: 0;
                                margin-bottom: 0.5rem;
                            }
                            em {
                                font-size: 0.8rem;
                                padding-bottom: 0.5rem;
                                display: inline-block;
                            }
                        }
                        > ul {
                            &:first-of-type {
                                border-bottom: 1px solid #eee;
                                padding-bottom: 0.75rem;
                                li {
                                    font-size: 0.9rem;
                                    padding-bottom: 0.2rem;                                    
                                }                                
                            }
                        }
                        .filters-collapse {
                            h3.heading {
                                background: transparent;
                                color: $default-text-color;
                                font-size: .9rem;
                                font-weight: 500;                                
                                padding:0;
                                a {
                                    border:1px solid $palette-grey-200;
                                    border-radius: 0.5rem;
                                    color: $default-text-color;
                                    padding: .5rem 1rem; 
                                    transition: $transition-base;
                                    &.collapsed {
                                        span.fa-collapsible {
                                            margin:2px 0 0 0;
                                            &:before {
                                                content: "\f078";
                                                font-family: "Font Awesome 5 Pro";                                            
                                                }
                                            }                                             
                                        }
                                    span.fa-collapsible {
                                        margin:2px 0 0 0;
                                        &:before {
                                            content: "\f053";
                                            font-family: "Font Awesome 5 Pro";                                            
                                            }
                                        }   
                                    &:hover, &:active, &:focus {
                                        background-color: $palette-grey-100;
                                        text-decoration: none;
                                        border:1px solid $palette-grey-300;
                                        } 
                                    &:focus-visible  {
                                        border:1px solid black;
                                        }                            
                                    }                                    
                                }
                            > div {
                                > div.small {
                                    margin: 0 0 0.5rem !important;
                                }
                            }
                            .filters.form-group {
                                padding:0 .4rem;
                            }
                        #quick-search-filters .form-group:last-child {
                            margin-bottom:1.5rem;
                            }
                        }                        
                        #selected-filter-panel {
                            .list-group {
                                .list-group-item {
                                    font-size: .85rem;
                                    font-weight: 500;
                                    padding: .25rem .5rem !important;
                                    background: $palette-grey-100 !important;
                                    border-radius: .5rem;
                                    a.fa {
                                        font-size: 0.9rem;
                                    }
                                }
                            }
                        }
                        select + .list-group-inline {
                            .list-group-item {
                                font-size: 0.9rem;
                                padding: 5px 8px;
                                background: #dfdfdf;
                                border-radius: 0;
                                a.fa {
                                    font-size: 0.9rem;
                                }
                            }                            
                        }
                        h2.withtopmargin {
                            display: none;
                        }
                        ._toggle-sidebar.toggle-bottom {
                            opacity: 0;
                            transition: all 1s ease-in-out;                    
                        }
                        ._toggle-sidebar.toggle-top {
                            display: none !important;
                        }
                        #reset-all-filters, #save-search-trigger {
                            opacity: 0;
                            transition: opacity 1s ease-in-out;
                        }
                    }                  
                }        
                
            #quick-search-filters .input-group {
                input.form-control {
                    background:$palette-grey-50;
                    padding: 1.2rem 1rem;
                    border-radius: .5rem;
                    width: 100%;                    
                    }
                .input-group-btn {
                    position: absolute;
                    right: 0;
                    height: 100%;
                    z-index: 10;
                    .btn.btn-default {
                        background: none;
                        border: none;
                        height: 100% !important;
                        color: $romeo-primary !important;
                        .fa-search:before {
                           content: "\f061"
                            }
                        &:hover, &:focus {
                            color:$romeo-secondary !important;
                            }
                        }
                    }
                }

            }
            

            &.toggled { 
                .sidebar {
                    @include media-breakpoint-up(lg) {
                        left: -5px;
                        margin-right: 0;
                    }
                }
                .action-container-content {
                    position: relative;
                    top: 50px;
                    height: calc(100% - 120px);
                    overflow-y: auto;
                    width: calc(100vw);  
                    #sidebar-content {
                        ._toggle-sidebar.toggle-bottom {
                            position: fixed;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin-left: auto;
                            margin-right: auto;
                            width: 200px;   
                            opacity: 1;
                            display: block !important;
                            @include media-breakpoint-up(lg) {
                                display: none !important;
                            }
                            span.fa-check {
                                display: none;
                            }                         
                        }
                        #selected-filter-panel {
                            &.collapse, &.collapsing {
                                display: block !important;
                                height: 100% !important;
                            }
                            #reset-all-filters {
                                opacity: 1;
                                font-size: 0rem;                                
                                &:after {
                                    content: "\f01e";
                                    font-family: "Font Awesome 5 Pro";
                                    color: $black;
                                    position: fixed;
                                    font-size: 1.5rem;
                                    bottom: 16px;
                                    left: 16px; 
                                    @include media-breakpoint-up(lg) {
                                        display: none !important;
                                    }                                                                  
                                }
                            }
                            #save-search-trigger {
                                opacity: 1;
                                font-size: 0rem;
                                &:after {
                                    content: "\f005";
                                    font-family: "Font Awesome 5 Pro";
                                    color: $favourite-color;
                                    position: fixed;
                                    font-size: 1.5rem;
                                    bottom: 16px;
                                    right: 16px;      
                                    @include media-breakpoint-up(lg) {
                                        display: none !important;
                                    }                                                             
                                }   
                                &:hover {
                                    &:after {
                                        font-weight: 600;
                                    } 
                                }                             
                            }
                        }
                    }                  
                }         
            }

            @include media-breakpoint-down(md) {
                .content-container.col-sm-9.col-md-10 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            @include media-breakpoint-up(lg) {
                // top: -1rem;
                background: transparent; 
                z-index: 0;   
                overflow:visible; //added by ang so the pull-top-right buttons don't get hidden;
                .sidebar, &.toggled .sidebar {
                    transition: none;
                    position: relative;
                    // box-shadow: $romeo-box-shadow;
                    border-right:1px solid $palette-grey-200;
                    flex: 1.1;
                    .skipnavigation {
                        +h2.heading {
                            // display: none;  switching to sr-only styling
                            border: 0;
                            clip: rect(0, 0, 0, 0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                            }
                        }  
                    .form-group {
                        &.filters {
                            .checkbox {
                                label {
                                    font-size: 0.8rem;                     
                                }
                            }
                        }
                    }
                    .action-container-content {
                        top: 0;    
                        height: auto;
                        width: auto;            
                        #sidebar-content {
                            ._toggle-sidebar.toggle-bottom {
                                display: none;
                            }
                            #selected-filter-panel {
                                .filters {
                                    > div {
                                        float: none !important;
                                        #reset-all-filters {
                                            opacity: 1;
                                            transition: none;
                                            font-size: 0.8rem;
                                            text-align: left;
                                            display: block;
                                            font-weight: 500;      
                                            margin: .5rem;
                                            text-decoration:none;
                                            color: $romeo-primary;
                                            // &:before {
                                            //     content: "\f01e";
                                            //     font-family: "Font Awesome 5 Pro";   
                                            //     padding-right: 2px;                           
                                            // }  
                                            &:hover, &:focus {
                                                color:$romeo-secondary;
                                                }                                                                                
                                            }  
                                        #save-search-trigger {
                                            opacity: 1;
                                            transition: none;
                                            width: 100%;
                                            display: block;
                                            color: $romeo-primary;
                                            font-weight: 600;
                                            font-size: 0.8rem;
                                            border: none;
                                            padding: 0;
                                            margin: 1.5rem 0 1.5rem .25rem;
                                            text-align: left;
                                            text-decoration: none;
                                            // span { // booooo :( heart vs star
                                            //     &:before {
                                            //         content: "\f004";
                                            //         font-family: "Font Awesome 5 Pro";
                                            //         padding-right: 2px;
                                            //     }
                                            // }
                                            &:hover {
                                                // background: $favourite-color;
                                                color: $romeo-secondary; 
                                                span {      
                                                    &:before {
                                                        font-weight: 600;
                                                    }     
                                                }                                                                                    
                                            }
                                        }                                                                                  
                                    }                     
                                }
                            }     
                        }
                    }                                     
                }
                .content, &.toggled .content {
                    flex: 3.5;
                    margin-left: 0;
                    padding-left:35px;
                }
            }              

        }

        /*** Tables|Grids|ListViews ***/

        // table header - basic
        table thead tr th,
        .table thead tr th {
            background-color:$romeo-table-header-bg-color;
            @include color-yiq($romeo-table-header-bg-color);
            a {
                text-decoration:none;
                @include color-yiq($romeo-table-header-bg-color);
                &:hover, &:active, &:focus {
                    text-decoration: underline;
                    }
                &:focus {
                    border: 1px solid black;
                    }
                }
            }
        .table-header {
            background-color:$romeo-primary;
            @include color-yiq($romeo-primary);
            border:none;
            font-size: 0.9rem;
            }
        
        // table links
        .table-responsive table,
        .table-responsive .table {
            th a {
            @include color-yiq($romeo-table-header-bg-color);
            }
            a {
            color:$romeo-link-color;
            }
        }
    
    
        // table footer (used on faceted and basic searches) 
        .table-footer {
            margin-top: 1rem;
            padding: .9rem 0;
            // background:#efefef !important;
            .pagination {
                display: flex;
                li {
                    display:flex;
                    min-width: auto;
                    a, span {
                        display:block;
                        position:relative;
                        margin-left:-1px;
                        flex: 1 1 auto;
                        display: flex;
                        align-items: center;
                        border-radius: .5rem;
                        font-weight:600;
                        }
                    &:not(:first-child) {
                        a, span {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0
                            }
                        }
                    &:not(:last-child) {
                        a, span {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            }
                        }
                    &:hover:not(.disabled),
                    &.active {
                        a {
                            border: 1px solid $romeo-secondary;
                            background: $romeo-secondary !important; 
                           @include color-yiq($romeo-secondary);
                            }
                        > span {
                            border: 1px solid #ddd;
                            }
                        }
                    }
                }
            .btn-group .btn.btn-default,
            .btn-group .btn.btn-primary,
            .pagination.pagination-sm li a,
            .pagination.pagination-sm li > span {
                padding:.4rem .6rem .3rem .6rem;
                font-size:.8rem;
                background:#fff !important;
                // border:none;
                }
            .pagination.pagination-sm li a {
                // border-radius: 2px;  

                .fa {
                    padding-right:0;
                    }        
                }
            .btn-group .btn.btn-primary,
            .pagination.pagination-sm li.active a,
            .pagination.pagination-sm li.active > span {
                background-color:$romeo-secondary !important;
                @include color-yiq($romeo-secondary);
                }
            }
    
        // LIST VIEW - display list on faceted search
        .search-results-list {
    
            .list-group {
            border-bottom: 2px solid #eee;
    
            >.list-group-item {  //only the first list-group-item otherwise it messages up the dates on the event search
                border:none;
                padding: 1.5rem 1rem;
    
                // a {
                // text-decoration: none;
                // }
        
                h2 {
                border:none;
                font-size: 1.1rem;
                font-weight: 600;
                }
                h3 {
                font-weight: 600;
                font-size: .85rem;
                margin: 0 0 .4rem 0;
                }
                .text-info {
                color:#666;
                font-size: .85rem;
                margin:0;
                }
                p {
                font-size:.85rem;
                margin:1rem 0 0 0;
                line-height: 1.7;
                word-break: break-word;
                }
                .expirydate {
                font-size: .75rem;
                }
            }
    
            &:last-child {
                border-bottom:none;
            }
            }
        }
        
        &.wrapper-with-sidebar {
            .content-container {

                h1 {
                  display:none !important;
                }
                
                // top search button
                >.btn-group {  // only the first button
                  float:right;
                  margin-top:0;
                  @include media-breakpoint-up(lg) {
                    position: absolute;
                    top: -90px;
                    right: 1rem;
                  }
                  @include media-breakpoint-down(md) {
                    .btn.save-search {
                      font-size:0px;
                    }
                  }
                }
            
                // TABLE HEADER (the advanced one used for faceted searches)
                .content-block {
                  border:none;
                  margin-bottom: 0;
                  .table-header {
                    box-shadow: none;
                    background: transparent;
                    color:$palette-grey-700;
                    border-bottom: none;
                    padding: 1rem 0;
                    margin-bottom: .55rem;
                    font-size: .85rem;
                    @include media-breakpoint-up(lg) {
                      margin-top:-1rem;
                    }
                    li { // sort by
                      margin-bottom:0;
                    }
                    .btn-basic {
                        color:$palette-grey-700 !important;
                        }
                    .display-by .list-inline a { // the little icons for grid/list switching
                        color:$palette-grey-700;
                      margin-left: .3rem;
                      .fa {
                        font-weight: 300;
                      }
                      .fa-th-list:before { // cheeky hack because I thought the 2 icons looked too similar
                        content: "\f03a";
                      }
                    }
                  }
                }
              }            
        }
        &.section-events {
            // don't think this is being used anymore
            .sidebar.filters {
                .heading-info.heading-bleed {
                    margin:0;
                    padding:15px;
                }
            }
        }
        &.section-user-bookings {
            .sidebar {
                @include media-breakpoint-up(lg) {
                    margin: -15px 0 0 0;
                    }
            }
        }
    }

}