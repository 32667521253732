////////////////////////////////////////////////////
// CSS covering the new search pages on student UI
//

.inferno-search-results {

    // job cards
    article {

        a.card-inferno {
            margin-bottom:.75rem;

            .card-body {
                padding: 1rem;
                color: $body-color;
                h2 {
                    font-size: 1.15rem;
                    font-weight: 500;
                    margin: .25rem 0;
                    border-bottom:none;
                    padding:0;
                    text-transform: none;
                    }
                .card-job-org {
                    color: $palette-grey-700;
                    margin: 0 0 .75rem 0;
                    font-weight: 500;
                    }
                .card-job-spec {
                    margin: 0 0 .5rem 0;
                    font-size: .75rem;
                    color: $palette-grey-700;
                    .separator {
                        padding: 0 .5rem;
                        }
                    }
                }

            .card-job-bookmark {
                border: none;
                padding: 0;
                background-color: transparent;
                position: absolute;
                bottom: .3rem;
                right: .5rem;
                font-size: 20px;
                color: $primary;
                padding: .25rem .5rem;
                &:hover, &:focus {
                    color: $secondary;
                    }
                }
                
            }

        }
        
    }


.inferno-search-results,
.inferno-job-details {

    // employer logo/initial
    .card-job-logo, 
    .card-job-initial,
    .job-logo,
    .job-initial {
        height: 58px;
        width: 58px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
        border-radius: .25rem;
        flex-shrink: 0;
        background-color: #fff;
        display: flex;
        padding: .5rem;
        margin-right: 1.5rem;
        align-items: center;
        justify-content: center;
        img {  
            // img-fluid
            display: block;
            max-width: 100%;
            height: auto;
            }
        @media(min-width: $screen-md) {
            height: 80px;
            width: 80px;
            }
        }
    .card-job-initial,
    .job-initial {
        background: $palette-grey-200;
        span {
            font-weight: 600;
            font-size: 2.25rem;
            color: $palette-grey-700;
            text-transform: uppercase;
            }
        }

    // days to apply
    .card-job-days {
        font-size: .75rem;
        color: $palette-grey-700;
        display: inline-block;
        margin-bottom:0;
        }
    .card-job-days.danger,
    .card-job-days.warning,
    .card-job-days.default,
    .card-job-days.closed {  
        color: $body-color;
        padding: .25rem .75rem;
        border-radius: 1rem;
        font-weight: 600;
        background-color: $gray-200;
        white-space: nowrap;
        }
    .card-job-days.danger {
        background: $inferno-warning !important;
        // important so it overwrites the 'default' class (that's always there)
        }
    .card-job-days.warning {
        background: $inferno-warning-200 !important;
        }
    .card-job-days.closed {
        background: white !important;
        border:2px solid $danger;
        color: $danger;
        }

    }



// INFERNO DETAILS (i.e. job details)

.inferno-job-details {

    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
        }
    h3 {
        font-size: 1.125rem;
        margin-bottom: .5rem;
        font-weight: 600;
        margin: 0 0 .5rem 0;
        }    
    p.job-employer {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom:0;
        a {
            text-decoration: none;
            }
        }
    .job-btns .btn {
        display: block;
        width: 100%;
        @media(min-width: $screen-sm) {
            display: inline-block;
            width:auto;
            }
        }
    .job-extras p {
        font-size: .72rem;
        text-transform: uppercase;
        border: 1px solid $gray-300;
        padding: 0.25rem 0.75rem;
        border-radius: .5rem;
        margin-right: .5rem;
        letter-spacing: .0625rem;
        font-weight: 600;
        }
    .job-extras p i {
        padding-right: .5rem;
        }

    .job-origin {
        flex-direction: column;        
        justify-content: center;
        @media(min-width: $screen-sm) {
            flex-direction: row; 
            justify-content: start;
            }

        img {
            max-height: 70px;
            margin-top: .5rem;
            @media(min-width: $screen-md) {
                max-width: 50%;
                max-height:none;
                margin-top: 0;
                }
            }
        }
    
    img { // img-responsive
        display: block;
        max-width: 100%;
        height: auto;
        } 

    }


// hacky hack to remove double border on operator
.page-operator .wrapper-with-sidebar #search-main #results.content-block {
    border:none;
    }