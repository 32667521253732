// CARDS

.card {
    //bootstrap (upgrading from 3 to 4)
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0;
    //ang
    border: 0 solid #eceaea;
    box-shadow: $romeo-box-shadow;
    .card-header { // this needs building on - different variations etc
        font-size:1.2rem;
        font-weight: 600;
        padding: 0.85rem 1.25rem;  
        margin:0;
        color:$default-text-color;  
    }
    .card-body {
        padding: 1.25rem; //please note, changing this will affect any negative margins (i.e. fancy lists within cards)
    }
    &.h-100 {
        height:100%;
    }
    &.card-border-standout { // using new class (rather than 'border-solid, border-primary' because cards have border switched off and it's overriding
        border:1px solid $romeo-card-border-standout-color;
    }
    img { // img-responsive - global fix for images that the client has added to their content (i.e job/placement details) rather than asking the client add the 'img-responsive' class (Ang, TC-18015).
        display: block;
        max-width: 100%;
        height: auto;
    } 
    .corner-standout { //default is top-right.  currently only caters for a single colour option (set in variables romeo-corner-standout-color)
        width: 0px;
        height: 0px;
        position: absolute;
        top: 0px;
        right: 0px;
        border-width: $romeo-corner-standout-width;
        border-style: solid;        
        border-left-color: transparent;
        border-top-color: $romeo-corner-standout-color;
        border-right: none;
        border-bottom: none;       
        .text-new { // this is specific to the words 'new' within corner-top-right
            position: absolute;
            color:#fff;
            transform: rotate(45deg);
            top: -47px;
            left: -32px;
            font-size: 0.8rem;
        } 
        &.corner-standout-bottom-right {
            bottom: 0px;
            right: 0px;
            top:auto;
            border-left-color: transparent;
            border-bottom-style: solid;
            border-bottom-width: $romeo-corner-standout-width;
            border-bottom-color: $romeo-corner-standout-color;
            border-right: none;
            border-top: none;
            .text-new {
                transform: rotate(-45deg);
                bottom: -44px;
                left: -33px;
                top:auto;
            }
        }
    }
}

//// added by ang to style TJ stuff within TC 
// also being used for new student ui search (jan 2024)
.card-inferno {
    transition: all .2s ease-in-out;
    border-radius: 0.5rem;
    box-shadow: none;
    border: 1px solid $gray-200;
    margin-bottom: 1rem;
    .card-header {
        border-radius: 8px 8px 0 0;
        border-bottom:0;
        }
    .card-header-fancy {
        background: $gray-50;
        border-bottom: 1px solid $gray-200;
        }
    .card-body {
        background-color: transparent;
        padding: 1.25rem 1.5rem;
        &.compact {
            padding:.75rem;
            }
        }
    }


// used on job search    
a.card-inferno {
    text-decoration: none !important;
    &:hover, &:focus {
        background: #faf9ff;
        color:$primary !important;
        h2 {
            color:$primary;
            } 
        }
    }

// basic inferno card
.card-inferno-basic {
    background-color: $gray-50;
    border-radius: .75rem;
    padding:2.5rem 2rem;
    dl {
        font-size: 1rem;
        dt {
            font-weight: 600;
            margin-bottom:.25rem;
            }
        }
    .card-inferno-basic-title {
        font-size:1.2rem;
        font-weight: 700;
        padding-bottom:.25rem;
        border-bottom: 1px solid $gray-200;
        margin-bottom:1.5rem;
        }
    &.border {
        border:1px solid $gray-200 !important;
        }
    &.compact {
        border-radius: .5rem;
        padding:1.25rem 1.5rem;
        }
    img {  
        // img-fluid
        display: block;
        max-width: 100%;
        height: auto;
        }
    }

// basic inferno card
.card-inferno-simple {
    border:1px solid $gray-200;
    border-radius:16px;
    padding: 1.25rem !important;
    img {  
        // img-fluid
        display: block;
        max-width: 100%;
        height: auto;
        }
    }