// Have custom widget colours mainly for the dashboard, allows them to change the dashboard widget colours to better match their theme.

.widget-bg-primary {
	background:$widget-primary-background !important;
	color: $primary-text-color !important;
	i {
		color: lighten($widget-primary-background, 5%) !important;
	}
}
.widget-bg-secondary {
	background:$widget-secondary-background !important;
	color: $secondary-text-color !important;
	i {
		color: lighten($widget-secondary-background, 5%) !important;
	}		
}
.widget-bg-tertiary {
	background:$widget-tertiary-background !important;
	color: $tertiary-text-color !important;
	i {
		color: lighten($widget-tertiary-background, 5%) !important;
	}		
}	
.widget-bg-quaternary {
	background:$widget-quaternary-background !important;
	color: $quaternary-text-color !important;
	i {
		color: lighten($widget-quaternary-background, 5%) !important;
	}		
}	
.widget-primary-color {
	color:$primary !important;
}
.widget-secondary-color {
	color:$secondary !important;	
}
.widget-tertiary-color {
	color:$tertiary !important;	
}	
.widget-quaternary-color {
	color:$quaternary !important;	
}		
.widget-block-header {
	margin:-15px -16px;
	min-height:50px;
	h2 {
		border-bottom: none;
	}
}
.widget-block-strip {
	max-height: 20px;
	min-height: 20px;
	margin: 0px -16px;   
	line-height: 20px; 
	.widget-badge-image {
		position: relative;
		top:-22px;
	}
	.widget-badge-sm-circle {
		border-radius: 50%;
		height: 50px;
		margin-left: -25px;
		position: relative;
		text-align: center;
		bottom: 15px;
		width: 50px;        
	} 
	.widget-badge-circle {
		border-radius: 50%;
		height: 75px;
		margin-left: -37px;
		position: relative;
		text-align: center;
		bottom: 28px;
		width: 75px;       
	}      
	.widget-badge-lg-circle {
		border-radius: 50%;
		height: 100px;
		margin-left: -50px;
		position: relative;
		text-align: center;
		bottom: 40px;
		width: 100px;        
	}
	.widget-badge-sm-icon i {
		font-size:16px;
	}
	.widget-badge-icon i {
		font-size:36px;
	}     
	.widget-badge-lg-icon i {
		font-size:50px;
	}        
}    
.widget-container {
	max-height: 480px;
	overflow-y: auto;
}	
