/////////////////////////////////////////
//// ABERYSTWYTH student/employer theme!
////////////////////////////////////////

// Romeo :: colours and banner/logo variables for the student/employer interface
$romeo-primary:         #3b3b3b;
$romeo-secondary:       #3498DB;
$romeo-tertiary:        #F4C900;
$romeo-quaternary:      #56318E;
$primary:               $romeo-primary;
$secondary:             $romeo-secondary;
// navigation
$romeo-nav-items-hover: $romeo-secondary;
// table
$romeo-table-header-bg-color:   $romeo-secondary;
//banner logo
$romeo-main-banner-brand-bg:            transparent; 
$romeo-main-banner-brand-border-color:  transparent;
$romeo-main-banner-brand-box-shadow:    none;
$romeo-main-banner-text-color:        #000;
$romeo-main-banner-text-shadow:         none;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":          $romeo-primary,
    "secondary":        $romeo-secondary,
    "tertiary":         $romeo-tertiary,
    "quaternary":       $romeo-quaternary,
    "success":          $success,
    "success-muted":    $success-muted,
    "info":             $info,
    "info-muted":       $info-muted,
    "warning":          $warning,
    "warning-muted":    $warning-muted,
    "danger":           $danger,
    "danger-muted":     $danger-muted,
    "light":            $light,
    "dark":             $dark,
    "default":          $default,
    "inferno-primary":  $inferno-primary,
    "inferno-secondary":$inferno-secondary
  ),
  $theme-colors
);
// Link colour for unauth pages
$link-color:            $romeo-primary;
$link-hover-color:      $romeo-secondary;