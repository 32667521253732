// Target Feedback forms inside Pathways


// stuff not within Columbo
#activity-modal {

    #activity-modal-content {

        .modal-dialog {
            max-width: 820px;
    
            &.modal-content .modal-body {
                padding: inherit;
            }
        }

        .bg-info {
            border-radius: 4px;
        }
    }

    div[data-section="activity-modal-description"] { 
        p {
        margin-left: 15px !important;
        margin-right: 15px !important; 
        }
    } 
}

#feedback-fragment {

    #activity.reflection-container {
        margin-left: -15px;
        margin-right: -15px;
    }

    ._mode-view,
    ._mode-view + .form-group {
        margin-left: 15px;
        margin-right: 15px;
    }

    #activity-feedback-form {
        ._mode-view {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .alert {
        margin: 15px 15px 0;
    }
}

.columbo-ui-tc {
    font-family: 'Open Sans', Arial, sans-serif;  
    font-size: 14px;
    font-weight: 400;

    &.columbo-ui-noconflict-bootstrap { 
        margin: 0;
    }

    .navbar-default .navbar-nav {

        li {

            a {
                color: #fff !important;

                &:hover,
                &:focus {
                    background-color: #b0b0b0 !important;
                }
            }
            
            &.active > a {
                background-color: #b0b0b0; 
            }
        }
    }

    h1, h2, h3 {
        text-align: center;
        margin-top: 0;
        color: $pathways-type-colour-placement !important;
        font-weight: 700 !important;
    }

    h1 {
        font-size: 1.275rem !important;
    }

    h2 {
        font-size: 1.10rem !important;
        // font-weight: 600 !important;
    }

    h3 {
        font-size: 1rem !important;
        // font-weight: 500 !important;
    }

    p {
        color: #333333;
    }

    .content-block {
        border: none;
        margin-bottom: 0;
        padding-top: 0;

        .evaluation-instance,
        .evaluation-problem,
        .evaluation-results,
        .evaluation-status {
            padding: 0 !important;
        }
    }

    .columbo-ui-loader {
        text-align: center;
    }

    .columbo-ui {
        hr {
            margin: 0px 0 30px 0;
        }
    }

    .form-control,
    .form-group .input-group {
        width: 100%;
    }

    .checkbox, 
    .radio {
        margin-left: 0 !important;

        label {
            span {
                margin-left: 1rem;
            }
        }
    }

    button {
        &.btn-icon-right {
            padding: 8px 13px !important;
        }
    }

    input {
        &.file-upload-textinput {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
            height: 34px !important;
        }
    }

    label {
        &.font-normal:not(.optional) {
            font-weight: bold;
        }
    }

    select {

        &.form-control {
           height: auto; 

            option {
                padding: 0.2rem 0 0 0.5rem;
            }
        }
    }

    .dropdown-menu {
        li {
            margin: 5px 0;
        }
    }

    dyn-evaluation-question {
        margin-top: 1rem;
    }

    .panel-heading {
        a.btn-repeating-section-remove {
            color:#333333;

            &:hover {
                color: #23527c;
            }
        }
    }

    .control-label-singleQuestion {
        span {
            padding-top: 1rem;
        }
    }

    // Font Awesome classes don't exist in v5+ so using non outline versions instead

    .fa-star-o:before {
        content: "\f005";
    }

    .fa-trash-o:before {
        content: "\f1f8";
    }

    .star-rating .star-rating__wrap .star-rating__ico.fa-star-o {
        color: #d4d4d4;
        font-size: 1.7rem;

        &.fill,
        &.fill-hover {
            color: #FFB300;
        }
    }

    #datetimepicker {  
        .uib-button-bar {
            button {
                width: auto !important;  
                clear: none;
            }
        }
    }

    #datetimepicker .uib-datepicker-popup {
        display: block;
        padding-top: 0;

        &.dropdown-menu {
            li {
                margin-top: 0;
                
                span {
                    border-color: transparent;
                    padding-top: 0;
                    padding-left: 0;
                    margin-top: 0;
                }
            }
        }

        table thead tr th,
        .btn-default, 
        .btn-simple {
            background: #fff !important;
            color: #000 !important;
            text-align: center;
        }  

        button {
            &.btn-default {
                &.active {
                    background-color: $table-header-background-color !important;
                    span {
                        color: #fff !important;
                    }
                }
            }
        }
    }

    .alert {
        margin: 15px 0 !important;
    } 

    evaluation-question-contrasting-contrasting {
        .contrasting {
            .statement-left {
                text-align: left;
                padding-left: 0;
            }
            .statement-right {
                text-align: right;
            }
        }
    }

    .rating-grid {
        .statement-left {
            text-align: left;
            padding-left: 0;
        }
    }

    evaluation-question-radio-fancy,
    .btn-group {
        .btn-primary {
            margin-right: 10px;
            opacity: 0.5;

            &.active {
                opacity: 1;
            }
        }
    }

    .image-selector {
        label {
            padding-left: 0;
            position: relative;
            left: -5px;
        }

        img {
            max-width: 19rem;
        }
    }

    #feedback-fragment {
        form {
            > div {
                padding: 0 !important;
            }
        }

    }

    evaluation-question-rating-ratinggrid {
        .rating-grid.form-group {
            margin-top: 1rem;
        }
    }

    .evaluation-instance {
        padding-top: 0;
    }
}

.page-operator {
    .columbo-ui-tc {
        .no-bottom-margin-lg, 
        .content-block p:last-child, 
        .widget-simple-plain p:last-child, 
        .widget-simple-stat p:last-child, 
        .list-group-item p:last-child {
            margin-bottom: 0.2rem !important;
        }
    }
}