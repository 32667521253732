// .table-header {
// 	background-color: $table-header-results-background-color;
// 	border-color: $table-header-results-background-color;
//     padding: 5px 5px 5px 5px;
//     color: $table-header-results-text-color;
//     a, i {
//     	color: $table-header-text-color;
//     }
// }

// trying to remove the border from the block around the tables
.valuelist.content-block.no-padding {
	border:none;
	}

// table header - number of results
.table-header {
	background:$secondary; ///!!!
	color:#fff; ///!!!
	padding:0.25rem .5rem;
	font-size: .8rem;
	}

.table-hover {
	tbody tr {
		&:hover {
			background-color: $table-hover-bg;
			}
		}
	}
	  
.table-responsive {
	overflow-x: visible;   //Ang changed from 'auto', see TC-18627 for more details which fixes mobile issue (action dropdowns within a table)
}

//.table-responsive, .table {  // Ang adding .table here because we found several tables not within .table-responsive.
  	

	// @media(min-width: $screen-md){
	// 	overflow-x: auto;   // another ang change, see TC-18627 for details
	// }

// ang - quite a few tables without .table-responsive or .table - so just doing all tables instead!  which may cause issues :O   actually, should be fine, it's how it was before

	table, .table {
		td, th {
			padding:.45rem;
			font-size: 0.85rem;
			vertical-align: top;
			border:$table-border;
			}
		// table head with column sorting arrows
		thead {
			tr {
				th {
					background:$gray-200;
					font-weight: 500;			
					position:relative;		
					a {
					    display: block;
	    				position: relative;
	    				color: $body-color;
						}
					.fa {
						opacity: 0.4;
						transition: $transition-base;
						position: absolute;
						top: 5px;
						right: 2px;
						}
					.fa-caret-up {
						top: -2px;
						}
				}
			}
		}
		a {
			// color:$table-link-color;  ANG removing as it's overwriting the a.btn styles
			font-weight: 500;
			&:hover, &:active, &:focus {
				// color:$table-link-hover-color; ANG removing as it's overwriting the a.btn styles
				text-decoration: $table-link-hover-decoration;
			}
		}
	}


.dhx_cal_data table {   // fixing calendar display issue - on month view
	td, th {
		padding:0;
		font-size:1rem;
		border:none;
		}
	.dhx_grid_event { 
		td {  /* TC-24667 */
			font-size: 12px;
			padding-left: 8px !important;
			padding-right: 8px !important;
			}
		}
	}

//}
// table, .table {
// 	margin-bottom:0px;
// 	thead{
// 		tr{
// 			th {
// 				background-color: $table-header-background-color;
// 				border: $table-border;
// 				position: relative;
// 				vertical-align: middle;
// 				color: $table-header-text-color;
// 				a {
// 				    display: block;
//     				position: relative;
//     				color: $table-header-text-color;
// 				}
// 				.fa {
// 					opacity: 0.4;
// 					transition: all 0.5s ease;
// 					position: absolute;
// 					top: 5px;
// 					right: 2px;

// 				}
// 				.fa-caret-up {
// 					top: -2px;
// 				}
// 			}
// 		}
// 	}
// 	a {
// 		color:$link-color;
// 	}
// }
// .table-footer {
// 	padding: 5px 5px 5px 5px;
// 	background: #e6eaed;
// }

.table-selectable // By Robbie
{
	tbody
	{
		tr
		{
			cursor: pointer;

			&.selected
			{
				td
				{
					position: relative;

					&::after // For the dark overlay on selected items
					{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: #000;
						display: inline-block;
						opacity: 0.2;
					}
				}
			}

			&.locked
      {
        opacity: 0.35;
        cursor: default;
      }
		}
	}
}

/********* FOR EXPANDING ROWS *********/

.d-table-row-expander {
	font: normal normal normal 14px/1 FontAwesome;
	text-decoration: none !important;
	color: $text-font-color !important;
	&:first-of-type {
		padding-left:10px;
	}	
	&:after {			
		content: "\f068";
	}
	&.collapsed:after {		
		content: "\f067";					
	}		
	&.arrows {
		font-size:20px/1;
		&:after {			
			content: "\f0d7";
		}
		&.collapsed:after {		
			content: "\f0da";			
		}	
	}
}

tbody[data-level="2"] {

	.d-table-row-expander {
		margin-left:10px;	
	}

	// AB do not wish to pick up styles above as used for other columns
	.d-table-row-expander-no-reset {
		padding-left: 20px; 
	}
}
tbody[data-level="3"] {

	.d-table-row-expander {
		margin-left:25px;
	}

	.d-table-row-expander-no-reset {
		padding-left: 30px;
	}

}
tbody[data-level="4"] {

	.d-table-row-expander {
		margin-left:40px;
	}

	.d-table-row-expander-no-reset {
		padding-left: 40px;
	}

}
tbody[data-level="5"] {

	.d-table-row-expander {
		margin-left:55px;
	}

	.d-table-row-expander-no-reset {
		padding-left: 55px;
	}

}
tbody[data-level] {
	border-top:none !important;

	tr {
		td:first-child {
			width: 7rem;
		}
	}
}


/********* FOR DIV BASED TABLES *********/

/*
.d-table {
	display: table;
	background-color: transparent;
	border-spacing: 0;
    border-collapse: collapse;	
}
.d-table-header-group {
	display: table-header-group;
    vertical-align: middle;
	border-color: inherit;	
}
.d-table-row {
	display: table-row;
	vertical-align: inherit;
	border-color: inherit;
	&.active {
		.d-table-cell {
			background-color: #f5f5f5;
		}
	}
}
.d-table-cell {
	display: table-cell;
	vertical-align: middle;	
	text-align: left;
	padding: 8px;
	line-height: 1.42857143;	
	border-top: 1px solid #ddd;		
}
.d-table-cell-full-width {
	display: table;
	width: 100%;
	text-align: right;
}
.d-table-footer-group {
	display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;	
}
.d-table-column {
	display: table-column;
}
.d-table-colgroup {
	display: table-colgroup;
}
.d-table-row-group {
	display: table-row-group;
	&.collapse {
		display:none;
		&.in {
			display: table-row-group;
		}
	}
	&.collapsing {
		position: relative;
		height: 0;
		overflow: hidden;
		-webkit-transition-timing-function: ease;
		-o-transition-timing-function: ease;
		transition-timing-function: ease;
		-webkit-transition-duration: .35s;
		-o-transition-duration: .35s;
		transition-duration: .35s;
		-webkit-transition-property: height,visibility;
		-o-transition-property: height,visibility;
		transition-property: height,visibility;		
	}
}
.d-table-caption {
	display: table-caption;
}
*/

/********* ADDITIONAL BOOTSTRAP 3 TABLE CLASSES **********/

.table > thead > tr > th a:hover .fa-caret-up, .table > thead > tr > th.sort-asc-active .fa-caret-up, .table > thead > tr > th.sort-desc-active .fa-caret-down, .table > thead > tr > th.sort-asc-active a:hover .fa-caret-down, .table > thead > tr > th.sort-desc-active a:hover .fa-caret-up, .table > thead > tr > th.st-sort-ascent a .fa-caret-up, .table > thead > tr > th.st-sort-descent a .fa-caret-down, .table > thead > tr > th.st-sort-ascent a:hover .fa-caret-down, .table > thead > tr > th.st-sort-descent a:hover .fa-caret-up, .sort-by-sortarrows li a:hover .fa-caret-up, .sort-by-sortarrows li.sort-asc-active a .fa-caret-up, .sort-by-sortarrows li.sort-desc-active a .fa-caret-down, .sort-by-sortarrows li.sort-asc-active a:hover .fa-caret-down, .sort-by-sortarrows li.sort-desc-active a:hover .fa-caret-up {
    opacity: 1;
}

