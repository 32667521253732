// PAGES WITH NEW SIDE/SEARCH BAR 

.content-search {
    display: flex;
    flex-direction: column;
    @media(min-width: $screen-lg) {
        height: calc(100% - 50px);  /* -50px is for the content-header (h1 / buttons) */
        flex-direction: row;
        }
    
    // SIDEBAR
    .content-search-filters {
        background:#e9ecef; /* f7f8f9 gradsf0 */
        width:100%;
        padding: 0.75rem 0.75rem 0 0.75rem;
        margin-right: 1rem;
        position: relative;
        transition: all .2s;
        margin-bottom:1.5rem;
        height:auto;
        @media(min-width: $screen-lg) {
            width:230px;
            margin-bottom:0;
            flex-shrink:0;
            }

        // main searchbar heading
        h2 {
            margin: -.75rem -.75rem 0 -.75rem;
            a {
                background:$primary;
                padding: 0.5rem .75rem;
                color:#fff;   
                font-size: .9rem;
                //letter-spacing: 0.05rem;   
                font-weight: 500;
                display: flex;
                width:100%;
                justify-content: space-between;
                align-items: baseline;
                &:hover, &:focus-visible {
                    background:$secondary;
                    text-decoration: none;
                    }
                @media (hover: none) {
                    &:hover { background-color: $primary}
                    }                      
                .text {
                    color:#fff;
                    order:1;
                    white-space: nowrap;
                    }
                .arrow {
                    color:white;
                    order:2;
                    }
                .arrow:before {
                    content: "\f323";   
                    }
                .nofilters {
                    display:none;
                    }
                }
            }

        // TOGGLED SIDEBAR
        &.filters-toggled {    
            .filters {
                opacity: 0;
                display: none;
                // note, I decided against a nice transition for mobile, too much faff for not a lot of gain (it would need a set max-height, display:block
                }
            h2 a {
                .arrow {
                    order:3;
                    &:before {
                        content:"\f322"
                        }     
                    }
                .nofilters {
                    display: flex;
                    order: 2;
                    border: 1px solid #fff;
                    color: #fff;
                    border-radius: 100%;
                    height: 20px;
                    width: 20px;
                    line-height: 1.5;
                    font-size: .8rem;
                    font-weight: 700;                    
                    padding: 0 0 0 0.1rem;
                    justify-content: center;
                    }      
                }
            @media(min-width: $screen-lg) {
                width: 30px;
                h2 a {
                    background: transparent;
                    padding: 0;
                    display: block;
                    .arrow {
                        background: #017EB4;
                        display: block;
                        padding: 0.58rem 0.55rem;
                        text-align: center;
                        width: 100%;
                        order: 1;
                        &:before {
                            content: "\f324";
                            }
                        }
                    .text {
                        writing-mode: vertical-lr;
                        transform: rotate(180deg);
                        color: #5c6470;
                        padding: 2rem 0.45rem 0.5rem 0.35rem;
                        order: 3;
                        }
                    .nofilters {
                        border: 1px solid $success;
                        color: $success;
                        margin: 0.5rem 0.3rem 0 0.35rem;
                        }
                    &:hover, &:focus-visible {
                        .arrow {
                            background:$secondary;
                            }
                        }
                    }                 
                }
            &.collapsing .filters {
                opacity: 0;
                }
            }          

        // form filters
        .filters {
            opacity: 1;
            transition: all .3s;
            margin:.75rem 0;
            .form-group {                
                label, legend {
                    font-size: 0.8rem;
                    margin-bottom: 0.3rem;
                    // for legend
                    border-bottom:0;
                    margin-top:0;
                    padding-bottom:0;
                    }
                .form-control {
                    font-size: .8rem !important;
                    height: 31px;
                    padding: 0.3rem 0.5rem;
                    width:100%;
                    }
                .form-check {
                    margin-bottom:.2rem;
                    label {
                        font-weight: normal;
                        margin-bottom:0;
                        }
                    .form-check-input {
                        margin-top: 0.2rem;
                        }
                    }
                .list-group-item {  
                    // NEW styling for multiselect selected options.  Specific to search because it broke everywhere else! 
                    background:none !important;
                    padding:0 !important;
                    margin:0;
                    border-radius: 0;
                    float:none;
                    a {
                        background:$success !important;
                        border-radius: $button-border-radius; 
                        display: inline-block;
                        padding: .25rem .5rem .2rem .5rem !important;
                        margin: 0 .25rem .25rem 0 !important;
                        font-weight: 500;
                        color: $success-text-color;
                        font-size: .75rem;
                        .fa-times  {
                            font-size: .75rem;
                            margin-left: 0.2rem;
                            }
                        &:hover, &:active, &:focus {
                            background:$success;
                            color:$success-text-color;
                            color:$success-text-color;
                            text-decoration: none;
                            }

                        }
                    }
                }
            }

        }
    

    // SEARCH RESULTS
    .content-search-results {
        width:auto;
        flex-grow: 1;
        } 

    }