.pill.pill-xs, .pills.pills-xs li.pill {
	padding:$xs-vertical $xs-horizontal;
	@include remConversion(font-size, multiply-rem($text-font-size, 0.8));
}
.pill.pill-sm, .pills.pills-sm li.pill {
	padding:$sm-vertical $sm-horizontal;
	@include remConversion(font-size, multiply-rem($text-font-size, 0.95));
}
.pill.pill-md, .pills.pills-md li.pill {
	padding:$md-vertical $md-horizontal;
	font-size: $text-font-size;
}
.pill.pill-lg, .pills.pills-lg li.pill {
	padding:$lg-vertical $lg-horizontal;
	@include remConversion(font-size, multiply-rem($text-font-size, 1.25));
}
.pill.pill-xl, .pills.pills-xl li.pill {
	padding:$xl-vertical $xl-horizontal;
	@include remConversion(font-size, multiply-rem($text-font-size, 1.5));
}

@each $breakpoint, $size in $grid-breakpoints {
	@media(min-width: $size) {
		.pill.pill-xs-#{$breakpoint}, .pills.pills-xs-#{$breakpoint} li.pill {
			padding:$xs-vertical $xs-horizontal;
			@include remConversion(font-size, multiply-rem($text-font-size, 0.8));
		}
		.pill.pill-sm-#{$breakpoint}, .pills.pills-sm-#{$breakpoint} li.pill {
			padding:$sm-vertical $sm-horizontal;
			@include remConversion(font-size, multiply-rem($text-font-size, 0.95));
		}
		.pill.pill-md-#{$breakpoint}, .pills.pills-md-#{$breakpoint} li.pill {
			padding:$md-vertical $md-horizontal;
			font-size: $text-font-size;
		}
		.pill.pill-lg-#{$breakpoint}, .pills.pills-lg-#{$breakpoint} li.pill {
			padding:$lg-vertical $lg-horizontal;
			@include remConversion(font-size, multiply-rem($text-font-size, 1.25));
		}
		.pill.pill-xl-#{$breakpoint}, .pills.pills-xl-#{$breakpoint} li.pill {
			padding:$xl-vertical $xl-horizontal;
			@include remConversion(font-size, multiply-rem($text-font-size, 1.5));
		}		
	}    
}

ul.pills, .pills { 
	margin:0;
	padding:0;
	li.pill {
		display:inline-block;
		margin: 0 0 5px 0;
	}	
}

.pill {
	padding:$md-vertical $md-horizontal;
	font-size: $text-font-size;
	display:inline-block;
	position:relative;
	overflow:hidden;
	transition:1s;
	vertical-align: top;
	@include remConversion(border-radius,$pill-border-radius);
	&.pill-default {
		background:$pill-background-color;
		color:$pill-text-color;
	}
	&.pill-clear {
		background:transparent;
		color:$text-font-color;
	}
	&.pill-inverse {
		background:$pill-text-color;
		color:$pill-background-color;
		border: 1px solid $pill-background-color;	
	}
	&.pill-square {
		@include remConversion(border-radius,0);	
	}					
	button { // for leap view on tc
		background:transparent;
	}		
}