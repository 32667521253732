/* RADIOS & CHECKBOXES */
.radio, .checkbox, .radio-inline, .checkbox-inline {
    label {
        position:relative;
        z-index:0;
        padding-left:28px;
        padding-top:3px;
        &.disabled {
            input[type=radio], input[type=checkbox] {
                background: #ccc;
                border: 1px solid #ccc;
            }
        }
    }
    input[type=radio], input[type=checkbox] {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        position: absolute !important;
        top:0;
        height: 20px;
        width: 20px;
        transition: all 0.15s ease-out 0s;
        background: $input-check-inactive-background-color;
        border: $input-check-inactive-border;
        color: $input-check-icon-color;
        cursor: pointer;
        display: inline-block;
        margin-right: 0;
        outline: none; 
        border-radius: 50%;  
        margin-left:-28px;  
        box-shadow:inset 0 1px 1px rgba(0,0,0,.075);
        &:hover {
            background: $input-check-hover-background-color;
            border: $input-check-hover-border;
        }   
        &:checked {
            background: $input-check-background-color;
            border: $input-check-border;
        }    
        &:checked::before {
            height: 18px;
            width: 18px;
            position: absolute;
            font-family: 'Font Awesome 5 Pro';
            content: "\f00c";
            display: inline-block;
            font-size: 12px;
            text-align: center;
            line-height: 18px;
        }
        &::after {
            border-radius: 50%;
        }  
        &:focus {
            border-color: #66afe9 !important;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }
        &:focus-visible {
            outline: 1px auto #000;
            outline-offset: 4px;
          }
    }
    input[type=checkbox] {
        border-radius:4px;
    }
    @media (min-width:992px) {
        .radio-inline-custom {
            .option-input[type=radio] {
                top:0px;
            } 
        }
    }
} 
label {
    &.radio-inline, &.checkbox-inline {        
        margin-right:0.4rem;
        padding-top:3px !important;
        &:first-of-type {
            padding-left:28px;
        }
    }
}  

.form-inline {
    .radio, .checkbox {
        label {
            padding-left:28px;
        }
        input[type=radio], input[type=checkbox] {
            margin-left:-28px; 
        }
    }
}

.dropdown-menu {
    .radio, .checkbox {
        padding:0px 20px;
    }
}

.search-filter-container .checkbox label,
.search-filter-container .radio label  {
    padding-top:5px;
    }
