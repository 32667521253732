// .icon-square {
//     height:$icon-square-size;
//     width:$icon-square-size;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     align-content:center;
//     background: $icon-square-background-color;
//     i {
//         color:$icon-square-color;
//         padding-right:0px;
//     }
// }
// .icon-count-container {
//     position:relative;
//     .icon-count {
//         position: absolute;
//         background: $icon-count-background-color;
//         color: $icon-count-color;
//         font-size: 0.6rem;
//         width: 15px;
//         height: 15px;
//         border-radius: 50%;
//         right: $icon-count-position-right;
//     } 
// }  