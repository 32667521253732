.text-line-center { 
  display: table;
  width: 100%;
  // color:$text-color-mobile;
	@media(min-width: $screen-md){
		// color:$text-color-desktop;
	}				  
  margin-top:30px;
  margin-bottom:30px;
	.item {
	  display: table-cell;
	}
	.text { 
	  white-space: nowrap;
	  width: 1%;
	  padding: 0 10px;
	}
	.line {
	  position: relative;
	  top: -.5em;
	  border-bottom: 1px solid $item-line-center-mobile;
		@media(min-width: $screen-md){
			color:$text-font-color;
			border-bottom: 1px solid $item-line-center-desktop;
		}					  
	}			  
}	