// .nav-pop-right {
//     position: fixed;
//     right: 0px;
//     overflow: hidden;
//     z-index: 999;  /* ang moving from 'nav-pop-right-container' because it was having no affect.  also decreased the number from 2000 */
//     .nav-pop-right-container {
//         margin-right: -220px;
//         position: relative;
//         transition-duration: 0.8s;
//         .nav-pop-right-selector {
//             position: absolute;
//             font-size: 16px;
//             top: 0;
//             left: 0;
//             width: 40px;
//             cursor: pointer;
//             .pop-close {
//                 display:none;
//                 color: #fff !important;
//             }       
//             .pop-open {
//                 display:inline;
//                 color: #fff !important;
//             }     
//         } 
//         .nav-pop-right-content {
//             background: $nav-pop-right-content-background;
//             color: $nav-pop-right-content-color;            
//             .title {
//                 background: $nav-pop-right-title-background;
//                 color: $nav-pop-right-title-color;
//             }
//         }          
//         &.show {
//             margin-right: 0px;
//             .nav-pop-right-selector {
//                 .pop-open {
//                     display:none;
//                 }
//                 .pop-close {
//                     display:inline;
//                 }
//             }
//             .nav-pop-right-content {
//                 width: 220px;
//                 margin-left: 40px;
//                 background: $nav-pop-right-content-background;
//                 color: $nav-pop-right-content-color;
//             }            
//         }     
//     }    
// }