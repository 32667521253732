.widget-table {
	cursor:pointer;
	min-width:100%;
	> thead {
		 > tr {
			> th {
				background-color: transparent;
				color: #333;
				padding: 0 5px 0 5px;
				border:none;			
			}
		}
	}
	> thead:first-child {
		 > tr:first-child {
			> th {
				background-color: transparent;
				color: #333;
				padding: 3px 5px 3px 5px;
				border:none;				
			}
		}
	}	
	> tbody {
		> tr {
			border:none;
			> td {
				border:none;
				padding: 10px 5px 10px 5px;
			}
		}
		> tr:nth-child(even) {
			background:darken($content-block-background-color,3%);
		}
	}
	.btn {
		@include remConversion(font-size, .8);
	}		
}