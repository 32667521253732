//
// Colors
// --------------------------------------------------
@include add-to-color-palette-with-shades("default", $default-background-color, $default-text-color, $default-icon-color);
@include add-to-color-palette-with-shades("primary", $primary, $primary-text-color, $primary-icon-color);
@include add-to-color-palette-with-shades("secondary", $secondary, $secondary-text-color, $secondary-icon-color);
@include add-to-color-palette-with-shades("tertiary", $tertiary, $tertiary-text-color, $tertiary-icon-color);
@include add-to-color-palette-with-shades("quaternary", $quaternary, $quaternary-text-color, $quaternary-icon-color);
@include add-to-color-palette-with-shades("success", $success, $success-text-color, $success-icon-color);
@include add-to-color-palette-with-shades("danger", $danger, $danger-text-color, $danger-icon-color);
@include add-to-color-palette-with-shades("warning", $warning, $warning-text-color, $warning-icon-color);
@include add-to-color-palette-with-shades("info", $info, $info-text-color, $info-icon-color);
@include add-to-color-palette-with-shades("dark", $dark, $dark-text-color, $dark-icon-color);
@include add-to-color-palette-with-shades("light", $light, $light-text-color, $light-icon-color);
@include add-to-color-palette("success-muted", $success-muted, $success-muted-text-color, $success-muted-icon-color);
@include add-to-color-palette("danger-muted", $danger-muted, $danger-muted-text-color, $danger-muted-icon-color);
@include add-to-color-palette("white", $white, $white-text-color, $white-icon-color);
@include add-to-color-palette("grey-1000", $palette-grey-1000, $white, $white);
@include add-to-color-palette("grey-900", $palette-grey-900, $white, $white);
@include add-to-color-palette("grey-800", $palette-grey-800, $white, $white);
@include add-to-color-palette("grey-700", $palette-grey-700, $white, $white);
@include add-to-color-palette("grey-600", $palette-grey-600, $default-text-color, $default-text-color);
@include add-to-color-palette("grey-500", $palette-grey-500, $default-text-color, $default-text-color);
@include add-to-color-palette("grey-400", $palette-grey-400, $default-text-color, $default-text-color);
@include add-to-color-palette("grey-300", $palette-grey-300, $default-text-color, $default-text-color);
@include add-to-color-palette("grey-200", $palette-grey-200, $default-text-color, $default-text-color);
@include add-to-color-palette("grey-100", $palette-grey-100, $default-text-color, $default-text-color);
@include add-to-color-palette("grey-50", $palette-grey-50, $default-text-color, $default-text-color);


@include add-to-color-palette("tripple-three-black", #333, #fff, #fff);

//
// Custom Colors
// --------------------------------------------------
@include add-to-color-palette("custom-1", $custom-background-color-1, $custom-text-color-1, $custom-icon-color-1);
@include add-to-color-palette("custom-2", $custom-background-color-2, $custom-text-color-2, $custom-icon-color-2);
@include add-to-color-palette("custom-3", $custom-background-color-3, $custom-text-color-3, $custom-icon-color-3);
@include add-to-color-palette("custom-4", $custom-background-color-4, $custom-text-color-4, $custom-icon-color-4);
@include add-to-color-palette("custom-5", $custom-background-color-5, $custom-text-color-5, $custom-icon-color-5);
@include add-to-color-palette("custom-6", $custom-background-color-6, $custom-text-color-6, $custom-icon-color-6);
@include add-to-color-palette("custom-7", $custom-background-color-7, $custom-text-color-7, $custom-icon-color-7);
@include add-to-color-palette("custom-8", $custom-background-color-8, $custom-text-color-8, $custom-icon-color-8);
@include add-to-color-palette("custom-9", $custom-background-color-9, $custom-text-color-9, $custom-icon-color-9);
@include add-to-color-palette("custom-10", $custom-background-color-10, $custom-text-color-10, $custom-icon-color-10);
@include add-to-color-palette("custom-11", $custom-background-color-11, $custom-text-color-11, $custom-icon-color-12);
@include add-to-color-palette("custom-12", $custom-background-color-12, $custom-text-color-12, $custom-icon-color-12);
@include add-to-color-palette("custom-13", $custom-background-color-13, $custom-text-color-13, $custom-icon-color-13);
@include add-to-color-palette("custom-14", $custom-background-color-14, $custom-text-color-14, $custom-icon-color-14);
@include add-to-color-palette("custom-15", $custom-background-color-15, $custom-text-color-15, $custom-icon-color-15);
@include add-to-color-palette("custom-16", $custom-background-color-16, $custom-text-color-16, $custom-icon-color-16);
@include add-to-color-palette("custom-17", $custom-background-color-17, $custom-text-color-17, $custom-icon-color-17);
@include add-to-color-palette("custom-18", $custom-background-color-18, $custom-text-color-18, $custom-icon-color-18);
@include add-to-color-palette("custom-19", $custom-background-color-19, $custom-text-color-19, $custom-icon-color-19);
@include add-to-color-palette("custom-20", $custom-background-color-20, $custom-text-color-20, $custom-icon-color-20);



.bg-none, .no-bg
{
  background: none !important;
}
.bg-transparent, .transparent-background {
  background:transparent !important;
}

.text-default-normal { // added by ang, for whatever reason, .text-default is setting to the $default-bg-color instead (light grey)
  color: $default-text-color;  
}