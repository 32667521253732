.form-wizard {
	position:relative;
	&:before {
		background: $form-wizard-color;
		height: 2px;
		content: "";
		width: 100%;
		position: absolute;
		bottom: 20px;
		z-index:0;
	}
	&.form-wizard-with-labels {
		@media(min-width:$screen-md){
			margin-bottom:35px;
		}		
	}
	ul {
		padding-left:0px;
		@include flexbox;
		@include justify-content(space-between);
		li {
			list-style:none;
			display: inline;
			font-weight: 700;
			padding: 7px 13px;
			border-radius: 50%;
			line-height: normal;
			position: relative;
			text-align: center;
			z-index: 2;
			transition: all .1s linear 0s;
			font-family:open sans;
			border:2px solid $form-wizard-color;
			background:$form-wizard-inactive-background-color;
			color: $form-wizard-color;
			min-width:43px;
			min-height:43px;
			transition:1s;
			@media(min-width:$screen-md){
				padding: 9px 0px;
			}
			span, small {
				display:none;
				@media(min-width: $screen-md){
					position: absolute;
					width: auto;
					float: left;
					left: 0px;
					top:45px;
					white-space: nowrap;
					font-weight:normal;
					display:block;
					color:#ffffff;
				}
				@extend h4;
			}
			.checkmark {
				display:none;
			}
			&.complete {
				background:$success;
				border-color:lighten($success, 8%);
				color:lighten($form-wizard-color, 20%);
				.checkmark {
					width:43px;
					height:44px;
					border:none;
					border:4px solid darken(#7ac142, 10%);
				}
				i, .fa {
					padding:0px;
				}
				&.complete-checkmark {		
					width:43px;
					height:44px;	
					transition:1s;				
					span {
						top:45px;
					}
					.checkmark {
						display:block;
						position: absolute;
						top: -7px;
						left: -2px;			
					}
				}
			}				
			&.active {
				background:$form-wizard-background;
				border-color:$form-wizard-color;
				color:lighten($form-wizard-color, 20%);
				transition:1s;
			}			
		}
	}
}