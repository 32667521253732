// @include buttonStyle("default", $default-background-color, $default-text-color);
// @include buttonStyle("primary", $primary, $primary-text-color);
// @include buttonStyle("secondary", $secondary, $secondary-text-color);
// @include buttonStyle("tertiary", $tertiary, $tertiary-text-color);
// @include buttonStyle("quaternary", $quaternary, $quaternary-text-color);

// @include buttonStyle("info", $info, $info-text-color);
// @include buttonStyle("success", $success, $success-text-color);
// @include buttonStyle("warning", $warning, $warning-text-color);
// @include buttonStyle("danger", $danger, $danger-text-color);
@include buttonStyle("basic", $basic-background-color, $basic-text-color);

@include buttonClear("", $text-font-color, $text-font-color);
@include buttonClear("info", $info, $info-text-color);
@include buttonClear("success", $success, $success-text-color);
@include buttonClear("warning", $warning, $warning-text-color);
@include buttonClear("danger", $danger, $danger-text-color);
@include buttonClear("default", $default-background-color, $default-text-color);
@include buttonClear("primary", $primary, $primary-text-color);
@include buttonClear("secondary", $secondary, $secondary-text-color);
@include buttonClear("tertiary", $tertiary, $tertiary-text-color);
@include buttonClear("quaternary", $quaternary, $quaternary-text-color);

// ang updating with BS4 styling
.btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    @include hover() {
        color: $body-color;
        text-decoration: none;
      }

    &:focus,
    &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled) {
        cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
    
        &:active,
        &.active {
          @include box-shadow($btn-active-box-shadow);
    
          &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
          }
        }
      }

    .fa:first-of-type, i:first-of-type {
        transition: none;
        margin-right:.3rem;
        // padding:0;
        // position:relative;   
        // min-width:0;
        // width:inherit;        
        // border-radius: 0;
        // background: transparent;
        // &:hover, &:focus, &:active {
        //     background: transparent;
        // }
    }
    &.btn-square {
        border-radius:0px;
    }   
    &.btn-card {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        @extend .top-padding-sm;
        @extend .bottom-padding-sm;
        @extend .top-margin-sm;
    }   
    &.btn-full-width {
        width:100%;
        .fa:first-of-type {
            max-width:25px;
            margin-right:0px !important;
        }        
    }
    @each $breakpoint, $size in $grid-breakpoints {
        @media(min-width: $size) {
            &.btn-full-width-#{$breakpoint} {
                width:100%;
                .fa:first-of-type {
                    max-width:25px;
                    margin-right:0px !important;
                }        
            }
        }    
    }
    &.btn-initial-width {
        width:auto;     
    } 
    @each $breakpoint, $size in $grid-breakpoints {
        @media(min-width: $size) {
            &.btn-initial-width-#{$breakpoint} {
                width:auto;     
            } 
        }    
    }       
    &.btn-left-align {
        text-align:left;
    }  
}


// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
    }


//
// Alternate buttons
//
@each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
    }
  }
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
        }
    }

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-medium;
    color: $link-color;
    text-decoration: $link-decoration;
  
    @include hover() {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
        }
  
    &:focus,
    &.focus {
      text-decoration: $link-hover-decoration;
        }
  
    &:disabled,
    &.disabled {
      color: $btn-link-disabled-color;
      pointer-events: none;
        }
  
    // No need for an active state here
  }  
  
  
//
// Button Sizes
//
.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    }  
.btn-sm, .btn-xs {  /* btn-xs dropped in bs4 */
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }

//
// Block button
//
.btn-block {
    display: block;
    width: 100%;
    // Vertically space out multiple block buttons
    + .btn-block {
      margin-top: $btn-block-spacing-y;
    }
  }
  
// Specificity overrides
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    &.btn-block {
      width: 100%;
    }
  }

// 
// rounded buttons  
//
.btn-rounded {
    height: 36px;
    width: 36px;
    border-radius: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    i:first-of-type {
        margin-right: 0;
        }
    }


// Button sizes
// .btn-xs, .btn-group-xs > .btn {
//     @include remConversion(font-size, multiply-rem($text-font-size, 0.8));
//     padding: $xs-vertical $xs-horizontal;     
//     .fa, .fas, .far, .fal {
//         @include remConversion(font-size, multiply-rem($text-font-size, 0.8));
//     }    
// }
// .btn-sm, .btn-group-sm > .btn {
//     @include remConversion(font-size, multiply-rem($text-font-size, 0.95));
//     padding: $sm-vertical $sm-horizontal;     
//     .fa, .fas, .far, .fal {
//         @include remConversion(font-size, multiply-rem($text-font-size, 0.95));
//     }    
// }
// .btn-lg, .btn-group-lg > .btn {
//     @include remConversion(font-size, multiply-rem($text-font-size, 1.25));
//     padding: $lg-vertical $lg-horizontal;     
//     .fa, .fas, .far, .fal {
//         @include remConversion(font-size, multiply-rem($text-font-size, 1.25));
//     }    
// }
// .btn-xl, .btn-group-xl > .btn {
//     @include remConversion(font-size, multiply-rem($text-font-size, 1.5));
//     padding: $xl-vertical $xl-horizontal;     
//     .fa, .fas, .far, .fal {
//         @include remConversion(font-size, multiply-rem($text-font-size, 1.5));
//     }    
// }

// // Dropdown menu
// .btn-group.open a.dropdown-toggle {
//     -webkit-box-shadow:none;
//     box-shadow: none;
// }
// .dropdown-toggle {        
//     &:focus {
//         box-shadow: none;
//     }
//     &.disabled::after {
//         display:none;
//     }
// }
// .dropdown-toggle +.dropdown-backdrop {
//     z-index:9;
// }

// .dropdown-menu {
//     // @include remConversion(border-radius, $button-border-radius); ang removing, it was conflicting
//     z-index:10;
//     > li > a {
//         clear: both;
//         color: #333;
//         display: block;
//         font-weight: 500;
//         line-height: 1.42857;
//         padding:.4rem .75rem;
//         white-space: nowrap;
//     }        
// }

// Pagination and Pager
.pagination, .pager {
    list-style: outside none none;
    display: inline-block;
    padding-left: 0;
    margin:0px;
}
.pagination > li, .pager > li {
    display: inline-block;
    min-width:34px;
    &.active {    
        > a, > span {
            color: $primary-text-color;
            background-color: $primary;
            border: 1px solid $primary;            
        }                
    }  
    &.disabled {
        @extend .btn.disabled;
        > a, > span {
            @extend .btn.disabled;
            color: #aaa;
        }          
    }
    &:hover:not(.disabled) {
        > a, > span {
            border: 1px solid $primary; 
        }
    }       
}
.pagination > li > a, .pagination > li > span,
.pager > li > a, .pager > li > span {
    display:inline-block;
    padding:5px;
    width:100%;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;    
}

.pagination.pagination-sm > li,
.pager.pager-sm > li {
    min-width:26px;
}
.pagination.pagination-sm > li > a, .pagination.pagination-sm > li > span,
.pager.pager-sm > li > a, .pager.pager-sm > li > span {
    padding:2px;
    font-size:90%;
}

// Pricing / action button
.btn-pricing {
    transition:none;
    border:none;
    min-height: 75px;
    text-align:left;
    padding-left:85px;
    white-space:normal !important;
    .btn-pricing-title {
        margin:0px;
        @include remConversion(font-size, $button-pricing-title-font-size);  
    }   
    .btn-pricing-value-band {
        @include remConversion(border-top-left-radius, $button-border-radius);
        @include remConversion(border-bottom-left-radius, $button-border-radius);
        display: inline-block;
        height: 100%;
        left: 0;
        min-width:75px;
        position: absolute;
        top: 0;
        margin:0px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;        
        .btn-pricing-value {
            border: 3px solid #fff;
            border-radius: 50%;
            color: #fff;
            height: 65px;
            left: 55px;
            margin-left: -50px;
            position: absolute;
            text-align: center;
            width: 65px;
            p {
                margin: 0;
                height: 100%;
                display: flex;
                justify-content: center;
            }  
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                i {
                    padding:0px;
                    margin:0px;
                    border:none;
                    background:transparent;
                    transition:none;
                    color:#fff !important;
                    font-size: 2em;
                }                
            } 
            .btn-pricing-value-from {
                span:first-of-type {
                    margin-top: 8px;
                    margin-left:2px;
                    font-size:0.75em;
                }
                span:nth-of-type(2) {
                    margin-top: 0px;
                }                
            }            
        }        
    }    
}

// Misc
// .btn.disabled {
//     cursor: default;
// }

.btn:not(.btn-no-text) .fa:first-of-type {
    margin-right:5px;
}

// .btn-group-xs >.btn, .btn-xs {
//     padding: .25rem .5rem;
// }

.btn-simple {
    @extend .btn,.btn-default,.btn-sm;
}

// a.btn {
//     &.margin-auto {
//         display:inline-block;
//     }
// }


// INFERNO BUTTONS - added by ang to style TJ stuff within TC
.btn-inferno-primary,
.btn-inferno-secondary,
.btn-inferno-primary-outline,
.btn-inferno-secondary-outline { 
    padding: 0.65rem 1.5rem !important;
    box-shadow:0px 1px 2px #0000001a !important;
    border-radius: 0.5rem !important;
    font-weight: 700 !important;
    font-size: 1rem !important;
    width: auto !important;
    display: inline-block;  
    border-width: 2px;
    }
.btn-inferno-primary:hover, .btn-inferno-primary:active, .btn-inferno-primary:focus {
    background-color: $inferno-primary-hover !important;
    border-color: $inferno-primary-hover !important;
    }
.btn-inferno-secondary:hover, .btn-inferno-secondary:active, .btn-inferno-secondary:focus {
    background-color: $inferno-secondary-hover !important;
    border-color: $inferno-secondary-hover !important;
    }
.btn-inferno-primary-outline {
    border:2px solid $inferno-primary;
    color: $gray-1000 !important;
    background:white;
    } 
.btn-inferno-secondary-outline {
    border:2px solid $inferno-secondary;
    color: $gray-1000 !important;
    background:white;
    } 
.btn-inferno-primary-outline:hover, .btn-inferno-primary-outline:active, .btn-inferno-primary-outline:focus,
.btn-inferno-secondary-outline:hover, .btn-inferno-secondary-outline:active, .btn-inferno-secondary-outline:focus  {
    color: $gray-1000 !important;
    background-color: $gray-200;
    }