// .login footer {
// 	position: relative;
// 	bottom: 0px;
// 	height: 60px;
// 	max-width: 500px;
// 	margin:0 auto;
// 	font-size:$login-font-text-size;
// 	width: 100%;
// 	color:$login-secondary-text-color-mobile;
// 	background:transparent;
// 	@media(min-width: $screen-md){
// 		margin: 0 -45px;
// 		position:absolute;
// 		max-width: 100%;
// 		width:100%;
// 		color:$login-secondary-text-color-desktop;
// 		padding: 0 45px;
// 	}
// 	.version {
// 		text-align:left;
// 		@media(min-width: $screen-md){
// 			padding-top:10px;
// 			margin-left: 30px;
// 			padding:0px;
// 		}
// 	}
// 	.groupgtilink {
// 		background: $footer-gti-background;
// 		display: inline-block;
// 		float: right;
// 		@media(min-width: $screen-md){
// 			right:15px;
// 		}
// 	}
// }