.romeo, .page-operator {
     
    footer {
        border-top: 2px solid $gray-200;
        background: $romeo-footer-bg;
        padding: 2rem 0;
        position: relative;
        bottom: auto;
        width: unset;
        height:auto;
        color: unset;
        margin: unset;

        .footer-top {
            border-bottom:1px solid $gray-200;
            margin-bottom:1.25rem;
            }

        p {
            margin-top: 0;
            margin-bottom:.75rem;
            color: $romeo-footer-text-color;
            }
            
        p.version {
            font-size: .65rem;
            padding-left:0;
            }

        p.copyright {
            font-size: .8rem;
            font-weight:400;
            color:$romeo-footer-text-color;
            text-align: left;
            margin-bottom:0;
            .footer-item {
                padding-left: .65rem;
                padding-right: .65rem;
                position: relative;
                color: $romeo-footer-text-color;
                font-weight: 500;
                display: inline-block;                
                &::before {
                    height: .75rem;
                    width: 1px;
                    background: $romeo-footer-text-color;
                    content: "";
                    position: absolute;
                    top: .2rem;
                    left: 0;
                    }
                &:first-child {
                    padding-left:0;
                    &::before {
                        display:none;
                        }
                    }                
                }
            a.footer-item {
                text-decoration: none;
                &:hover, &:focus {
                    color: $romeo-footer-nav-items-hover;
                    text-decoration: underline;
                    }
                }
            }

        .groupgti {
            margin:0;
            img {
                width:85px;
                }
            a {
                text-decoration: none;
                }
            }
        
        p.langswitch {
            margin-bottom:1.25rem; 
            font-size:.8rem;
            a {
                font-weight:600;
                padding: .3rem .5rem;
                text-decoration: none;
                color: $romeo-footer-text-color;
                &:hover, &:active {
                    color: $primary;
                    text-decoration: underline;
                    }
                &.active {
                    background-color:$primary;                
                    color: color-yiq($primary);
                    border-radius:2px;
                    &:hover, &:focus {
                        color: color-yiq($primary) !important;
                        text-decoration: none;
                        }
                    }
                }
            }

        }
    }

.page-operator {
    footer {
        .container {
            // switching off the fixed-width container for operators.
            max-width:none;
            }
        }
    }