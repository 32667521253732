/*** ../components ***/
@import "../components/dropdown";
@import "../components/block";
@import "../components/forms";
@import "../components/form-wizard";
@import "../components/text-line-center";
@import "../components/profile-column";
@import "../components/badge";
@import "../components/modal";
@import "../components/tables";
@import "../components/nav-popout-right";
@import "../components/search";
@import "../components/avatar";
@import "../components/awards";
@import "../components/circle-bar";
@import "../components/hero";
// @import "../components/flow-view";
@import "../components/flow-view-2";
@import "../components/modal-sidebar";
@import "../components/comment-list";
@import "../components/casebooks";
@import "../components/mock-filter";
@import "../components/status-dots";
@import "../components/dl";
@import "../components/card";
@import "../components/icon";
@import "../components/breadcrumb";
@import "../components/logo";
@import "../components/banner";
@import "../components/scheduler";
@import "../components/input-group";
@import "../components/inputs/input-animation-slide-right";
@import "../components/inputs/input-animation-wave";
@import "../components/inputs/input-text-slide-out";
@import "../components/inputs/input-inline";
@import "../components/inputs/labels";
@import "../components/inputs/pills";
@import "../components/inputs/toggle";
// @import "../components/inputs/slider";
@import "../components/inputs/buttons";
@import "../components/inputs/flip-button";
@import "../components/inputs/checkbox";
@import "../components/inputs/dropzone";
@import "../components/widgets/block";
@import "../components/widgets/general";
@import "../components/widgets/card";
@import "../components/widgets/simple";
@import "../components/widgets/simple-plain";
@import "../components/widgets/simple-stat";
@import "../components/widgets/simple-action";
@import "../components/widgets/simple-action-small";
@import "../components/widgets/simple-action-icon";
@import "../components/widgets/simple-action-center";
@import "../components/widgets/tabs";
@import "../components/widgets/timeline";
@import "../components/widgets/table";
@import "../components/widgets/table-list";
@import "../components/widgets/links";
@import "../components/widgets/profile";
@import "../components/widgets/contacts";
@import "../components/widgets/pricing-simple";
@import "../components/widgets/pricing-slash";
@import "../components/animations/checkmark";
@import "../components/widgets/chat";
@import "../components/widgets/radio-widget";
@import "../components/pathways";
@import "../components/appointment-types";
@import "../components/targetfeedbackinpathways";


// Beamer (3rd party plugin that needs styling) - by Robbie
@import "../components/beamer";