//////////////////////////////////////////////
// Modal Sidebar
// This is a sidebar (currently only availabe on the left-side) which transforms
// into a modal when screen-width falls below a certain amount.
//////////////////////////////////////////////

.modal-sidebar
{
  position: fixed;
  transition: all 0.5s;
  top: 100vh;
  z-index: 1002;
  width: calc(100% - 30px);
  left: 15px;
  display: none;
  overflow: auto;
	max-height: calc(100% - 90px);

	.content-block-container
	{
		max-height: unset;
		overflow-x: hidden;
	}

	.full-width-plus-help
	{
		width: calc(100% - 20px);
		display: inline-block;
	}

  .modal-sidebar-close
  {
    display: inline-block;
    cursor: pointer;

    i
    {
      padding: 0;
    }
  }

  &.activating
  {
    display: inline-block;
  }

  &.active
  {
    top: calc(50% + 40px);
    transform: translateY(-50%);
  }

  @media(min-width: $screen-md)
  {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    overflow: unset;
		max-height: unset;

		.content-block-container
		{
			max-height: calc(100vh - 300px);
			overflow-x: hidden;
		}

    &.activating
    {
      display: inline-block;
      top: auto;
    }

    &.active
    {
      top: auto;
      transform: none;
    }
  }
}

.modal-sidebar-overlay
{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#ffffff, 0.75);
  z-index: 1001;

  @media(min-width: $screen-md)
  {
    display: none;
  }
}
