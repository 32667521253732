$listSizes: 1, 2 ,3 ,4 ,5 ,6 ,7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 50, 100, 150, 200;

@include iconSizes($listSizes, $text-root-font-size, $text-font-size, "icon"); 

@media(min-width: $screen-xs){
    @include iconSizes($listSizes, $text-root-font-size, $text-font-size, "icon-xs"); 
}
@media(min-width: $screen-sm){
    @include iconSizes($listSizes, $text-root-font-size, $text-font-size, "icon-sm");
}
@media(min-width: $screen-md){
    @include iconSizes($listSizes, $text-root-font-size, $text-font-size, "icon-md");
}
@media(min-width: $screen-lg){
    @include iconSizes($listSizes, $text-root-font-size, $text-font-size, "icon-lg");
}