
// .clearfix::after { /* this is from bs4  */
//     display: block;
//     clear: both;
//     content: "";
//     }

// .img-fluid {  /* there must be more img classes somewhere, these should all be grouped together - maybe a mixin to cover all the sizes, break points etc. */
//     max-width: 100%;
//     height: auto;
//     }


/**** HEADER ****/

a.skipnavigation { /* important for accessibility */
    color: #fff;
    position: absolute;
    }

a.btn-toggle-sidebar { /* there must be other sidebar classes, when I find them I'll move this */
    text-decoration: none !important;
    }

/**** TYPOGRAPHY ****/

.strong, b, strong {
    font-weight:600 !important;
    }
.strong-light {
    font-weight:600 !important;
    }
.no-strong {
    font-weight:normal !important;
    }

.content-body h1 {
    margin-bottom:25px;
    font-weight:600;
    font-size: 1.4rem;
    }

.heading-bleed {  /* legacy */
    margin: -10px -10px 10px;
    }
.heading, .panel-heading {
    margin-top: 0;
    padding: 8px;
    }
.heading-lg, .panel-heading-lg {
    font-size: 16px;
    padding: 8px 10px 10px;
    }

.word-break-all {
    word-break:  break-all;
    }
.word-break-word {
    word-break:  break-word;
    }
.white-space-nowrap {
    white-space: nowrap;
    }
.white-space-normal {
    white-space: normal;
    }
.word-wrap-break-word {
    word-wrap: break-word;
}

code {
    border-radius: 0;
    color: #333;
    padding: 0;
    }
    

/**** DISPLAY STUFF ****/

.container-relative { /* used for outer containers which have absolute positioned elements inside*/
    position: relative;
    }

.row-eq-height {  /* not sure this is being used anywhere, it wasn't tested properly - maybe to make the sidebar nav the same height as grids?! */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    }
.row-eq-height-center {
    @extend .row-eq-height;
    justify-content:center;
    }

/**** PRETTY STUFF ****/

.icon-circle, .icon-circle-text {
    border-width: 2px;
    border-style: solid;
    height: 47px;
    width: 47px;
    position: absolute;
    top: -6px;
    left: -15px;
    border-radius: 50px;
    background-color: #fff!important;
    }
.icon-circle-text {
    border-radius: 2px;
    left: 22px;
    top: 4px;
    padding: 1px 8px 2px 12px;
    width: auto;
    height: auto;
    }
.icon-white, div > .icon-white {
    color:#fff !important;
    }

.divider {
    width:1px;
    background: $divider-background-color;
    }


.alert-dismissable, .alert-dismissible {
    .close {
        right:0px;
        }
    }
.alert {
    //z-index:9;
    position:relative;
}
.alert {
    z-index:9;
    position:relative;
}
.pull-top-right.action-buttons {
    display: flex;
    justify-content: flex-end;
    float: none; // changed from initial, which IE doesn't support
    position: relative;
    top: 0px;
    right: 0px;
}

@media(min-width: $screen-md){
    .pull-top-right.action-buttons {
        display:inline;
        float:right;
        position:absolute;
        top:52px;
        right:15px;
    }
}
.opacity-xs {
    opacity:0.9;
}
.opacity-sm {
    opacity:0.7;
}
.opacity-md {
    opacity:0.5;
}
.opacity-lg {
    opacity:0.3;
}
.opacity-xl {
    opacity:0.1;
}

/**** DISPLAYING BLOCKS ****/

.d-block,
.display-block { /* display-block is legacy */
    display:block;
    }
.d-inline {
    display: inline;
    }
.d-inline-block {
    display: inline-block;
    }
.not-visible {
    visibility: hidden;
    }


/**** FORMS ****/

.form-group .input-group.input-no-help {  /* pretty sure these are only being used by TC */
    width:100%;
    }
.form-group .radio.input-no-help {
    width:100%;
    }
.form-control.input-no-help, .form-group.input-no-help .mce-tinymce, .form-group.input-no-help .radio, .form-group.input-no-help .checkbox, .form-group.input-no-help .input-group, .form-group .mce-tinymce.input-no-help {
    width: 100%;
    }


/**** OVERFLOWS ****/

.overflow-hidden {
    overflow: hidden !important;
    }
.overflow-y-hidden {
    overflow-y: hidden !important;
    }
.overflow-x-hidden {
    overflow-x: hidden !important;
    }
.overflow-y-unset {
    overflow-y: unset !important;
    }
.overflow-x-unset {
    overflow-x: unset !important;
    }    
.overflow-auto {
    overflow: auto !important;
    }

/**** OPACITY ****/

@for $i from 1 through $percentages {
  &.opacity-#{$i} {
    opacity: #{$i / 100};
  }
}


/**** COLLAPSE ****/
// March 2023 - styling for new expand/collapse sections   (you'll find some more styling job-posting for link)
.section-collapse {
    border-top:1px solid  $gray-300;
    border-bottom:1px solid  $gray-300;
    .collapse-inner, .collapse { // added a inner div to handly bottom padding.  padding on the .collapse messes with animation
        padding-bottom:1.25rem;  
        }
    h3, legend { // legend??!
        font-weight: 600;
        font-size: $font-size-base;
        margin:0;
        padding:0;
        border:none;
        a, .btn-collapse { // styling under h3 or legend, otherwise it messes with all the other <a>'s in the section
            color:$body-color;
            display: block;  
            padding: 0.75rem 0; // adding the padding here to make the <a> more clickable
            display: flex;
            justify-content: space-between;
            align-items: center;
            &::after {
                display:block;
                content:"\f053";
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                transition: all ease .6s;
                }
            &:not(.collapsed) {
                &::after {
                    transform: rotate(-90deg);
                    }
                }
            &:hover, &:focus {
                text-decoration: none;
                }
            .label { // needed for TC-26021, hopefully we might be able to remove it at some point
                color:#fff !important;  // naughty!!
                font-size: .8rem !important;
                align-self: center;
                font-weight: 500;
                padding: 0.25rem 0.5rem;
                line-height: normal;
                }
            }
        }
    } 
// Turns out 'section-collapse' is a shared component - so I'm undoing some of the styling on certain pages (system settings, swipecard):    
#settings-holder .section-collapse,
#basicSettings .section-collapse,
#action-result-holder-container.section-collapse,
#student-search-advanced-container.section-collapse,
#employer-postings-container.section-collapse,
#audit-job-changes-container.section-collapse,
#pathways-skills-container.section-collapse {
    border:none;
    h3 a, legend a {
        background-color: $primary;
        color:$primary-text-color;
        padding:.5rem;
        margin:0 0 1rem 0;
        }
    }


a.showmore {
    display: block;
    font-weight:600;
    color:var(--primary);
    text-decoration: none !important;
    }
.showmore.collapsed .more {display: inline-block;}
.showmore .more {display: none;}
.showmore.collapsed .less {display: none;}
.showmore .less {display: inline-block;}


/**** LINE HEIGHTS ****/

.line-height-30 {
    line-height:30px;
    }
@each $breakpoint, $size in $grid-breakpoints {
    @media(min-width: $size) {
    .d-sticky-#{$breakpoint} {
        position:sticky !important;
    }
    // .d-top-scroll-header-#{$breakpoint} {
    //     top: calc(#{$header-shrink-size} + 20px);
    // }
    .d-block-#{$breakpoint} {
        display: block !important;
    }
    .d-inline-#{$breakpoint} {
        display: inline !important;
    }
    .d-inline-block-#{$breakpoint} {
        display: inline-block !important;
    }
    .text-center-#{$breakpoint} {
        text-align: center !important;
    }
    .text-left-#{$breakpoint} {
        text-align: left !important;
    }
    .text-right-#{$breakpoint} {
        text-align: right !important;
    }
    .font-weight-normal-#{$breakpoint} {
        font-weight: normal;
    }
  }
    // Robbie: This is the opposite of mobile-first, but trying to do it
    // mobile-first is a real faff*. This class is currently only applied to
    // the body element when a modal-sidebar is being displayed and thus
    // needs the body to be unscrollable. If this needs refactoring, see me.
    @media(max-width: $size){
    .overflow-hidden-#{$breakpoint} {
            overflow: hidden;
        }
  }
}

// CUSTOM WIDTHS + MAX/MIN WIDTHS
    // If an element needs a custom width (or max/min width) create a class here
    // Be sure to also add the custom class to the breakpoints @each loop below
.max-width-400px {
    max-width: 400px;
    }
.max-width-700px {
    max-width: 700px;
    }
.max-width-960px {
    max-width: 960px;
    }
.max-width-1140px {
    max-width: 1140px;
    }
.max-width-60px {
    max-width: 60px;
    }
.width-33p3 {  /** ?? Just wanted to double check this is correct? Not a mistake.  Is there a nice short hand way to represent %? pc? */
    width: 33.33%;
    }
.max-width-100 {
    max-width: 100%;
    }
.width-100 {
    width: 100%;
    }
.height-100 {
	height: 100%;
    }
.height-100vh {
	height: 100vh;
    }
@each $breakpoint, $size in $grid-breakpoints {
    @media(min-width: $size){
        .max-width-400px-#{$breakpoint}
        {
            max-width: 400px;
        }
        .max-width-700px-#{$breakpoint}
        {
            max-width: 700px;
        }
        .max-width-960px-#{$breakpoint}
        {
            max-width: 960px;
        }
        .max-width-1140px-#{$breakpoint}
        {
            max-width: 1140px;
        }
        .max-width-60px-#{$breakpoint}
        {
            max-width: 60px;
        }
        .width-33p3-#{$breakpoint}
        {
            width: 33.33%;
        }
        .max-width-100-#{$breakpoint}
        {
            max-width: 100%;
        }
        .width-100-#{$breakpoint}
        {
            width: 100%;
        }
        .height-100-#{$breakpoint}
        {
            height: 100%;
        }
    }
}

.no-min-height {
    min-height:0px !important;
    }
.no-max-height {
    max-height:0px !important;
    }
.no-min-width {
    min-width:0px !important;
    }
.no-max-width {
    max-width:0px !important;
    }    


// CUSTOM PANES - https://sui-custom-panes.firebaseapp.com/ adding a bg colour for the white logos
.sui-custom-panes .main-banner-brand {
    background-color: $romeo-logo-bg-color;
    }


// STUFF ANG IS REMOVING!

/*.normal {
    ** 'normal' what?!  the selector should be more descriptive.
    ** There is already a class for 'no strong' or 'font-weight-normal' (below) .
    font-weight:normal !important;
    }*/
/*.font-weight-normal { /
    ** already covered with 'no-strong'
    font-weight:normal;
    }

/*.form-control-lg {
    ** there is already a class for this, with a height of 43px, so generally this rule is being ignored anyway. If a shorter input is needed, and form-control-md doesn't cut it, then there should be a proper set of classes.
    height:40px;
    } */

/*.list-style-none {
    ** there already is a class that does this 'list-unstyled' (bs3)
    list-style:none;
    } */

/* text alignments - there are already BS classes for all of these, so the !important is the only difference.
.text-center {
    text-align: center !important;
    }
.text-left {
    text-align: left !important;
    }
.text-right {
    text-align: right !important;
    } */

/* .italic {
    ** I kinda see why this is here, there is no class for setting a bit of text to italic.  BUT ...
    ** If you want to italicize some text it should be tagged in the HTML as <em></em> - this is so the text is labelled as emphasised for accessiblity/screen readers.
    ** please try not to use italic just because it looks pretty ;)
    font-style:italic;
    } */

/* .underline {
    ** same as above, you should be using <u></u> for accessiblity/screen readers.
    ** underline looks yukky and should only be used for links anyway (as that's what people expect, underlined = link).
    text-decoration: underline !important;
    } */

/*hr {
    ** hr should already fill the full width of it's container, if it isn't, something is wrong.
    width:100%;
    }*/

/** super confused with these clearfixes, so I'm deleting and replacing with what BS4 uses.
.clearfix {
    display: inline-block;
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
        }
    }
* html .clearfix {  pretty sure this is/was for old IE browsers
    height: 1%;
    }
.clearfix {
    display: block;
    }

*/


/**** ANG QUERIES ****/

.site-logo-small {
    /* why? what is this for? specific to a client?
    ** please use 'sm' instead of 'small'
    ** not the best description for the selector - 'site-logo-small', I'd maybe expect that to set height and width, not just a max-width.
    ** a better approach would be: '.site-logo-max-height-**' with different sizes and maybe breakpoints */
    max-height:150px;
    }

.img-detail-view {
    /* why? what is this for? specific to a client?!
    ** not the best description 'image-detail-view' means nothing to me, and doesn't match the styles given */
    max-height:100px;
    max-width:100%;
    }
.img-100 {
    /* similar to above, why and what?
    ** not the best description, it should be more descriptive and with more options - i.e. img-sq-100, img-sq-200, img-sq-300 etc*/
    height:100px;
    width:100px;
    }
.img-max-75 {
    max-height: 75px;
    height:auto;
    width:auto;
}

/* these 2 classes do the same - should the second one actually be set to 'min-height:auto' ?  (there is a 'no-min-height') */
.height-auto {
    height: auto !important;
    }
.min-height-auto {
    height:auto !important;
    }

/* I'm confused by this one - by default <a> links are not underlined (but do change colour) and then underline on hover,
   so what are the classes below used for?
   and actually, the naming makes no sense - it's not 'no-underline-link', it's 'no-underline-on-hover' (it's only effecting hovers, not the <a>)
*/
.no-underline-link:hover, .no-underline-link:active, .no-underline-link:focus, .no-underline-link:visited  {
    text-decoration: none;
    }
.underline-on-hover {
    text-decoration: none !important;
    &:hover {
        text-decoration: underline !important;
        }
    }


@media(min-width: $screen-xs){
    @include float("float","xs");
}
@media(min-width: $screen-sm){
    @include float("float","sm");
}
@media(min-width: $screen-md){
    @include float("float","md");
}
@media(min-width: $screen-lg){
    @include float("float","lg");
}
    
