// Color contrast
@mixin color-yiq($color, $prop: 'color') {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
  
    $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;
  
    @if ($yiq >= $yiq-contrasted-threshold) {
      #{$prop}: $yiq-text-dark;
    } @else {
      #{$prop}: $yiq-text-light;
    }
}

// Color contrast (without porperty)
// $yiq-text-dark: #212529;
// $yiq-text-light: #FFF;
// $yiq-contrasted-threshold:  150;

@function color-yiq-r($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $dark;
  } @else {
    @return $light;
  }
}

// Color level
$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;
$theme-color-interval: 8%;

@function ccsv-theme-color-level($color, $level: 0) {
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);
  @return mix($color-base, $color, $level * $theme-color-interval);
}