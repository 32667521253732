@mixin iconSizes($numbers, $baseSize, $font-size, $class){
	@each $item in $numbers {
		$baseSizeStripped: strip-px($baseSize) * strip-px($font-size);
		$font-size-pixels: $baseSizeStripped + $item;

		.#{$class}-#{$item}x {
			font-size: $font-size-pixels * 2px !important;
			font-size: ($font-size-pixels / strip-px($baseSize)) * 2rem !important;
		}
	}
}