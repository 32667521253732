//
// Colors mixin
// --------------------------------------------------
$color-palette: (
  'background':(),
  'text':(),
  'icon':()
);

$color-background-palette: map-get($color-palette, background);
$color-text-palette: map-get($color-palette, text);
$color-icon-palette: map-get($color-palette, icon);

@mixin add-to-color-palette($name, $background, $text, $icon) {
    $color-background-palette: append($color-background-palette, ($name $background));
    $color-text-palette: append($color-text-palette, ($name $text));
    $color-icon-palette: append($color-icon-palette, ($name $icon));
    @include colors($name, $background, $text, $icon);
    //@include shades($name, $background, $text, $icon); ANG REMOVING!  there is a 'add-to-color-WITH-shades' below, so in theory this line shouldn't be needed
}

@mixin add-to-color-palette-with-shades($name, $background, $text, $icon) {
    $color-background-palette: append($color-background-palette, ($name $background));
    $color-text-palette: append($color-text-palette, ($name $text));
    $color-icon-palette: append($color-icon-palette, ($name $icon));
    @include colors($name, $background, $text, $icon);
    @include shades($name, $background, $text, $icon);
}

// Robbie: Add shades of existing colors
@mixin shades($name, $bg, $text, $icon)
{
  @include colors($name + "-darkest", darken($bg, 15%), darken($text, 15%), darken($icon, 15%));
  @include colors($name + "-darker", darken($bg, 10%), darken($text, 10%), darken($icon, 10%));
  @include colors($name + "-dark", darken($bg, 5%), darken($text, 5%), darken($icon, 5%));
  @include colors($name + "-light", lighten($bg, 5%), lighten($text, 5%), lighten($icon, 5%));
  @include colors($name + "-lighter", lighten($bg, 10%), lighten($text, 10%), lighten($icon, 10%));
  @include colors($name + "-lightest", lighten($bg, 15%), lighten($text, 15%), lighten($icon, 15%));
}

@mixin colors($name, $background, $text, $icon){
	.text-#{$name}{
        color:$background !important;  /** ang adding important to over write BS4 **/
        &.strict {
            color:$background !important;
        }
    }
    .bg-#{$name} {
        background: $background !important; /**;  ang adding important to over write BS4 **/
        color: $text !important; /** ang adding important to over write table.a (problem where <a> with bg-info in tables was picking up the dark <a> colour   **/
		> i {
            color:inherit; // From Robbie: This used to be $icon, but this made <i> elements the same colour as their background, which didn't make sense.
        }  
        a, a:active, a:visited, a:focus, a:hover { // ang adding these a's - otherwise it was making all links within bg's black.
            color:inherit;
        }       
        &.strict {
            background:$background !important;
            color: $text !important;
            > i {
                color:$icon !important;
            }                
        }    
    }
}
