.widget-simple-stat {
	@extend .content-block;
	i {
		float: right;
		text-align: center;
		padding: 0;
		font-size: 26px;
		margin-top:7px;
		&.primary-color, .widget-primary-color {
			color:$widget-primary-background !important;
		}	
		&.secondary-color, .widget-secondary-color {
			color:$widget-secondary-background !important;
		}		
		&.tertiary-color, .widget-tertiary-color {
			color:$widget-tertiary-background !important;
		}		
		&.quaternary-color, .widget-quaternary-color {
			color:$widget-quaternary-background !important;
		}		
	}
	h2 {
		padding-top: 0px;
		margin-top: 0px;
		margin-bottom:0px;
		font-family:$font-family-base;
		border:none;
		&.primary-color, .widget-primary-color {
			color:$widget-primary-background !important;
		}	
		&.secondary-color, .widget-secondary-color {
			color:$widget-secondary-background !important;
		}		
		&.tertiary-color, .widget-tertiary-color {
			color:$widget-tertiary-background !important;
		}		
		&.quaternary-color, .widget-quaternary-color {
			color:$widget-quaternary-background !important;
		}										
	}	
	h3 {
		text-transform:uppercase;
		color: lighten($text-font-color, 30%);
		margin-bottom:20px;
		margin-top:5px;
		padding-top:0px;
		font-family:$font-family-base;
		font-size:0.9rem;
		font-weight:600;	
	}
	.progress {
		height: 5px;
		font-weight: 600;
		border-radius: 0px;	
		background:darken($content-background,3%);
		background-image:none;	
		border-color:darken($content-background,3%);
		box-shadow:none;
		.progress-bar {
			background-image:none;
			border-radius:0px;	
			&.bg-primary, .widget-primary-background {
				background:$widget-primary-background !important;
			}	
			&.bg-secondary, .widget-secondary-background {
				background:$widget-secondary-background !important;
			}		
			&.bg-tertiary, .widget-tertiary-background {
				background:$widget-tertiary-background !important;
			}		
			&.bg-quaternary, .widget-quaternary-background {
				background:$widget-quaternary-background !important;
			}						
		}		
	}
	span {
		text-transform:uppercase;
		color: lighten($text-font-color, 30%);
	}	
}