h1, .h1 {
	@include remConversion(font-size, $heading-primary-font-size);
	font-family: $heading-primary-font, "Arial","Open Sans";
	margin: $heading-primary-margin;	
	text-transform: $heading-primary-font-transform;
	font-weight:bold;
	small .small {
		@include remConversion(font-size, multiply-rem($heading-primary-font-size, $heading-small-percentage));
	}	
}
h2, .h2 {
	@include remConversion(font-size, $heading-secondary-font-size);
	font-family: $heading-secondary-font, "Arial","Open Sans";
	margin: $heading-secondary-margin;	
	text-transform: $heading-secondary-font-transform;
	font-weight:bold;
	small .small {
		@include remConversion(font-size, multiply-rem($heading-secondary-font-size, $heading-small-percentage));
	}
}
h3, .h3 {
	@include remConversion(font-size,$heading-tertiary-font-size);
	font-family: $heading-tertiary-font, "Arial","Open Sans";
	margin: $heading-tertiary-margin;	
	text-transform: $heading-tertiary-font-transform;
	font-weight:bold;
	small .small {
		@include remConversion(font-size, multiply-rem($heading-tertiary-font-size, $heading-small-percentage));
	}	
}
h4, .h4 {
	@include remConversion(font-size,$heading-quaternary-font-size);
	font-family: $heading-quaternary-font, "Arial","Open Sans";
	margin: $heading-quaternary-margin;	
	text-transform: $heading-quaternary-font-transform;	
	font-weight:bold;
	small .small {
		@include remConversion(font-size, multiply-rem($heading-quaternary-font-size, $heading-small-percentage));
	}	
}
h5, .h5 {
	@include remConversion(font-size,$heading-quinary-font-size);
	font-family: $heading-quinary-font, "Arial","Open Sans";
	margin: $heading-quinary-margin;	
	text-transform: $heading-quinary-font-transform;	
	font-weight:bold;
	small .small {
		@include remConversion(font-size, multiply-rem($heading-quinary-font-size, $heading-small-percentage));
	}		
}
h6, .h6 {
	@include remConversion(font-size,$heading-senary-font-size);
	font-family: $heading-senary-font, "Arial","Open Sans";
	margin: $heading-senary-margin;	
	text-transform: $heading-senary-font-transform;		
	small .small {
		@include remConversion(font-size, multiply-rem($heading-senary-font-size, $heading-small-percentage));
	}	
}