.hero {
    margin-top: -#{$content-padding-top-desktop};

    ul.bottom-nav {

        li {
            float: none;

            a {
                border-radius: 0;
                background-color: #b3c4d8;
                color: #141c3a;
                border: none;
                display: inline-block;
                text-decoration:none;
                
                &:hover, &:focus {
                    background-color: #b3c4d8;
                }
            }
            
            &.active {
                a {
                    background-color: #f5f7fa;
                    color: #141c3a;
                    border: none;
                }
            }
        }

        &::before, &::after {
            content: none;
        }
    }
}