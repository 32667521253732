.widget-contacts {
	.widget-contacts-row {
		.widget-contacts-image {
			img {
				width:100%;
				@include remConversion(height, 3.75);
				border-radius: 50%;
				@include remConversion(height, 3.75);
			}
		}
		.widget-contacts-details {
			h3 {
				margin-bottom:0;
				@include remConversion(padding-bottom, 0.5);
			}
			p {
				@include remConversion(margin-bottom, 0.5);
			}
		}
	}
}