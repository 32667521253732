// Top filter container :: Default
.search-filter-container {
    background-color: transparent;
    @include remConversion(font-size, $search-filter-container-text-size);
    .search-filter-viewport {
        display:flex;
        flex-wrap:wrap;
        &.collapse:not(.in) {
            display:none;
        }
    }
    .search-filter-viewport-static {
        width:100%;
        display:flex;
        flex-wrap:wrap;
        .search-filter-viewport-column {
            border:none !important;
            @media(min-width: $screen-sm) {
                max-width:50%;
            }
        }
    }
    .search-filter-viewport-column {
        flex:1;
        flex-basis:100%;
        @media(min-width: $screen-sm) {
            flex-basis:50%;
            padding:0 15px 0 15px;
            border-right: 1px solid darken($search-filter-container-background, 10%);
            &:nth-child(2n+1) {
                padding:0 15px 0 0;
            }
            &:nth-child(2n) {
                padding:0 0 0 15px;
                border-right:none;
            }
            &:last-child {
                border-right:none;
                padding-right:0px;
            }
        }
        .input-daterange input {
            -webkit-appearance: none;
        }
        .scrollable, .scrollable-sm, .scrollable-md, .scrollable-lg {
            max-height:none;
            overflow: initial;
        }
        .view-more {
            background: $search-filter-container-viewmore-background-color;
            color: $search-filter-container-viewmore-text-color;
            @include remConversion(padding, 0.3);
            @include remConversion(padding-left, 0.8);
            @include remConversion(padding-right, 0.8);
            display:inline-block;
            @include remConversion(font-size, 0.7);
        }
        @media(min-width: $screen-md) {
            flex-basis:25%;
            &:nth-child(2n+1), &:nth-child(2n) {
                padding:0 15px 0 15px;
                border-right: 1px solid darken($search-filter-container-background, 10%);
            }
            &:nth-child(4n+1) {
                padding:0 15px 0 0;
            }
            &:nth-child(4n) {
                padding:0 0 0 15px;
                border-right:none;
            }
            &:last-child {
                border-right:none;
                padding-right:0px;
            }
            .scrollable-sm {
                overflow-y: auto;
                @include remConversion(max-height, 12);
            }
            .scrollable, .scrollable-md {
                overflow-y: auto;
                @include remConversion(max-height, 20);
            }
            .scrollable-lg {
                overflow-y: auto;
                @include remConversion(max-height, 25);
            }
        }
        @media(min-width: $screen-lg) {
            flex-basis:20%;
            &:nth-child(4n+1), &:nth-child(4n) {
                padding:0 15px 0 15px;
                border-right: 1px solid darken($search-filter-container-background, 10%);
            }
            &:nth-child(5n+1) {
                padding:0 15px 0 0;
            }
            &:nth-child(5n) {
                padding:0 0 0 15px;
                border-right:none;
            }
            &:last-child {
                border-right:none;
                padding-right:0px;
            }
        }
        h2, .label-header {
            font-size: $md-size;
            font-weight:300;
            margin: 0px;
            @include remConversion(margin-bottom, 0.5);
            line-height:1.5;
        }
        p {
            @include remConversion(margin-bottom, 0.5);
        }
        ul {
            li {
                @include remConversion(margin-bottom, 0.5);
            }
        }
    }
    .search-filter-selected {
        ul.pills {
            min-height:0px;
        }
    }
    .checkbox, .radio {
        margin-top:0px;
        margin-bottom:2px;
        label {
            @include remConversion(font-size, $search-filter-container-text-size);
            input {
                @include remConversion(margin-right, 0.4);
                position: relative;
                top:4px;
                margin-top:0px;
            }
        }
    }
    &.card {
        > .card-header {
            background-color: $search-filter-container-background;
            @include remConversion(padding, 1);
            padding-bottom: 0px;
            border: none;
        }
        > .card-body {
            background-color: $search-filter-container-background;
            @include remConversion(padding, 1);
            .card-body {
                @include remConversion(padding, 1);
            }
            // BS3
            .nav-tabs {
                border-bottom: none;;
                .nav-item {
                    margin-bottom:-1px;
                    z-index:1;
                    &.hidden {
                        display:none;
                        &.visited {
                            display:block !important;
                        }
                    }
                    .nav-link {
                        background: transparent;
                        border: 1px solid transparent;
                        border-top: 3px solid transparent;
                        color: $text-font-color;
                        display:block;
                        @include remConversion(padding, 1);
                        @include remConversion(padding-top, 0.5);
                        @include remConversion(padding-bottom, 0.5);
                        font-weight: normal;
                        @include remConversion(font-size, 0.8);
                        cursor: pointer;
                        margin:0px;
                        .close-tab {
                            @include remConversion(padding-left, 0.5);
                        }
                    }
                    &.active {
                        .nav-link {
                            background-color: #fff;
                            @include remConversion(border-radius, 0);
                            border-color: #ddd #ddd #fff;
                            border-top: 3px solid $search-filter-container-tab-border-color;
                        }
                    }
                }
                li.flexMenu-viewMore {
                    width:100%;
                    margin-bottom: 0px;
                    z-index: 1;
                    > a {
                        border: 1px solid transparent;
                        background-color: #fff;
                        @include remConversion(border-radius, 0);
                        border-color: #ddd #ddd #fff;
                        border-top: 3px solid $search-filter-container-tab-border-color;
                        border-bottom: 1px solid $search-filter-container-tab-border-color;
                        color: $text-font-color;
                        display:block;
                        @include remConversion(padding, 1);
                        @include remConversion(padding-top, 0.5);
                        @include remConversion(padding-bottom, 0.5);
                        font-weight: normal;
                        @include remConversion(font-size, 0.8);
                        cursor: pointer;
                        margin:0px;
                        .fa {
                            padding-right:0px;
                        }
                    }
                    .flexMenu-popup {
                        position: relative !important;
                        color: $search-filter-container-tab-mobile-menu-text-color;
                        background-color: $search-filter-container-tab-mobile-menu-background-color;
                        box-shadow: none;
                        padding:0px;
                        .nav-item {
                            padding:0px;
                            .nav-link {
                                background-color: $search-filter-container-tab-mobile-menu-background-color;
                                border: none;
                                @include remConversion(padding, 0.8);
                                color: $search-filter-container-tab-mobile-menu-text-color;
                                .close-tab {
                                    float:right;
                                    @include remConversion(padding, 0);
                                }
                            }
                            &.active {
                                .nav-link {
                                    background-color: $search-filter-container-tab-mobile-menu-background-active-color;
                                    color: $search-filter-container-tab-mobile-menu-text-active-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .card-footer {
            background-color: $search-filter-container-background;
            padding: 1rem 1rem 1.5rem 1rem;
            min-height: 0px;
            border: none;
            justify-content: flex-start;
            .search-filter-container-collapse {
                display:flex;
                &.show-label {
                    span {
                        font-weight: normal;
                    }
                    i.collapsed + span{
                        display: none;
                    }
                    i.opened + span {
                        display:block;
                    }
                    &.collapsed {
                        i.collapsed + span {
                            display: block;
                        }
                        i.opened + span {
                            display:none;
                        }
                    }
                }
                background: $search-filter-container-collapse-background-color;
                color:$search-filter-container-collapse-text-color;
                position: absolute;
                bottom: -12px;
                right: 15px;
                @include remConversion(padding, 0.5);
                i.collapsed {
                    display: none;
                }
                i.opened {
                    display:block;
                }
                span.label {
                    display:none;
                    @include remConversion(font-size, multiply-rem($text-font-size, 0.8));
                    padding-left:5px;
                }
                &.collapsed {
                    i.collapsed {
                        display: block;
                    }
                    i.opened {
                        display:none;
                    }
                }
                &:hover, &:active, &:focus {
                    text-decoration: none;
                }
            }
        }
    }
    &.search-filter-container-side:not(.search-filter-container-fullwidth) {
        .search-filter-viewport-column {
            flex-basis:100%;
            max-width: 100%;
            padding-left: 0px;
            padding-right:0px;
            border:none;
            .label-header {
                @include remConversion(font-size, 1);
            }
        }

    }
}

.search-results-pagination-container, .search-results-page-total-container {
    .search-results-pagination-list, .search-results-page-total-list {
        margin:0;
        padding:0;
        display:inline-flex;
        list-style: none;
        .search-results-pagination-item, .search-results-page-total-item {
            &:last-child {
                a {
                    margin-right:0px;
                }                
            }
            a {
                padding:5px 10px;
                font-size: 90%;
                margin:0 4px 0px 0;
                position:relative;
                overflow:hidden;
                min-width: 31px;
                display:block;
                border: 2px solid $search-results-pagination-item-border-color;
                background:$search-results-pagination-item-background-color;
                color:$search-results-pagination-item-text-color;
                text-decoration: none;
                &:hover, &:focus, &:active {
                    background-color: $search-results-pagination-item-hover-background-color;
                    border: 2px solid $search-results-pagination-item-hover-border-color;
                    color: $search-results-pagination-item-hover-text-color;
                }
            }            
            &.selected {
                a {
                    background-color: $search-results-pagination-item-active-background-color;
                    border: 2px solid $search-results-pagination-item-active-border-color;
                    color: $search-results-pagination-item-active-text-color;
                }
            }
            &.directional {
                a {
                    border: 2px solid darken($search-results-pagination-item-active-border-color, 5%);
                }
            }
            &.disabled, :disabled {
                a {
                    background-color: #ccc;
                    border-color: #ccc;
                    cursor: not-allowed;
                    &:hover, &:focus, &:active {
                        color: $search-results-pagination-item-text-color;
                    }
                }
            }
        }
        &.round {
            .search-results-pagination-item, .search-results-page-total-item  {
                a {
                    @include remConversion(border-radius,$search-results-pagination-item-border-radius);
                }
            }
        }
    } 
}

.hideQuickArrows {
    .next-in-content, .previous-in-content {
        display:none;
    }
}

.more-option
{
  display: none;
}
.more-container
{
  position: absolute;
}
