
$link-bg: #F4F5FA;  //gray-50

// UNAUTH :: getting started (after registration- post job or placement
//
.target-connect.unauthorised.page-employer-getstarted {
    .content-body {
        background-color: $link-bg;
        padding: 0;
        }
    .row.justify-content-center {
        max-width: 1400px;
        margin: 0 auto;
        }
    .post-a-job,
    .post-a-placement { // big btns/links
        display: block;
        border-width: 2px !important;
        border-radius: 12px;
        padding: 1.5rem;
        &:hover, &:focus {
            border-color: $romeo-secondary !important;
            text-decoration: none;
            .text-primary {
                color: $romeo-secondary !important;
                }
            }
        }
    .getting-started-image {
        background-image: url('https://images1.groupgti.com/targetconnect/tc-link/tcl-getstarted-bg.png');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
        min-height: 600px;
        } 
    .placement-header {
        height: 400px;
        .getting-started-image {
            max-height: 400px;
            min-height: auto;
            }
        }
    .placement-btns {
        background:#fff;;
        }       
    } 

//
// EMPLOYER :: post a job - re-jigging outer layout and header
//
.target-connect.unauthorised .section-job-posting,
.unauthorised.target-connect.job-edit-flow:not(.job-preview),
.romeo.target-connect.job-edit-flow:not(.job-preview) {
    padding: 0 !important;
    margin-top:0;
    background: transparent !important;
    .content-body {
        // hacky hacky to get the job-posting header/stepper to be full width
        max-width: none;
        padding:0;
        margin-top:3px;
        .post-job-form,
        .post-job-header-inner {
            max-width: 1140px;
            margin: 0 auto;
            padding:20px 15px;
            }
        .post-job-header-inner {
            padding:32px 15px;
            }
        .post-job-header {
            background-color: $link-bg;
            }    
        }        
    .page-operator {
        // switching off fixed width for operators
        .post-job-form {
            max-width: none;
            padding-left:0;
            padding-right:0
            }
        }
    }

//
// EMPLOYER :: register and post a job - form updates
//
// Link 1.5 updates (January 2023)    
.page-employer-registration,
.unauthorised .section-job-posting,
.romeo .section-job-posting {
    legend {
        color:$romeo-secondary;
        font-size:1.5rem;
        font-weight:700;
        border-top:1px solid $gray-300;
        border-bottom:none;
        padding:2.2rem 0 1.9rem 0;
        margin:0;
        }
    fieldset {
        margin-top:1.2rem;
        &:first-child legend,
        &:first-of-type legend {
            border-top:none;
            padding-top:0;
            }    
        }   
    .form-group {
        margin-bottom:2rem;
        label, p.label {
            font-size: 1rem;
            color:$gray-1000;
            padding-top:0; // needed to overwrite .col-form-label (which is on tc forms)
            padding-bottom:0;
            // adding below for <p>
            font-weight: 600;
            display: inline-block;
            margin-bottom: 0.5rem;            
            }
        p.label {
            padding:0; 
            }
        .form-control:not([disabled]) {
            background: #F4F5FA; /* grey/50 */
            border: 1px solid $gray-300;
            border-radius: 4px;
            color:$gray-1000;
            @media(min-width: $screen-md) {
                &:not(.date) {
                    width:60%;
                    }
                }
            option {
                background:#fff;
                }
            }
        .form-control[type="radio"], 
        textarea.form-control:not(.date) {
            width:100%;
            }
        .form-check:first-of-type {
            margin-top:.5rem;
            }
        .form-check:not(:last-child) {
            margin-bottom:.5rem;
            }
        .form-check-label,
        .form-check label,
        label.radio-inline {
            font-size: .9rem;
            margin-bottom:0;
            font-weight: 500;
            }
        // fixing custom fields where fields are in a .col but not a .row (causing indent/padding issues)
        &:not(.row) .col-12 {  
            padding:0;
            }
        .help-popover {
            float:none !important;
            padding: 0 0 0 0;
            display: inline-block;
            margin: 7px 0 0 5px;
            }
        .form-check {
            .field-description {
                // tidying up 'How do you want to apply' check boxes, with desc underneath
                margin-top:0;
                margin-bottom:.5rem
                }
            }
        }
    .field-description, .form-help-text {
        color:$gray-700 !important; 
        margin-top:.5rem;
        line-height: 1.5;
        font-size: .85rem;
        }
    .logo-upload-zone {
        height: 125px;
        width:60%;
        margin-top:.5rem;
        color: $gray-500;/*gray500*/
        border:1px dashed #C7CBD1;/*gray300 - doesn't match up with variables*/
        background: #F4F5FA; /*gray50*/
        border-radius: 4px; 
        .text-info {
            color:$gray-1000 !important;
            }
        }
    .btn-primary, .btn-secondary {
        padding: 0.75rem 1.5rem 0.75rem 1.5rem !important;
        box-shadow: none !important;
        border-radius: 4px !important;
        font-weight: 700;
        font-size: 1rem !important;
        width: auto !important;
        display: inline-block;
        &.btn-primary {
            color: #fff !important;
            background-color:$romeo-primary;
            border-color: $romeo-primary;
            }
        &.btn-secondary {
            color: #fff !important;
            background-color:$romeo-secondary;
            border-color: $romeo-secondary;
            }
        }
    .list-group-inline .list-group-item {
        // multi-select options;
        background: #F4F5FA !important;  //gray-50;
        border-radius: 2rem;
        padding: 0.5rem .75rem !important;
        margin: 0.5rem 0.5rem 0.5rem 0;
        font-weight: 600;
        font-size: .8rem;
        text-decoration: none;
        a {
            color: $romeo-primary;
            &:hover, &:active, &:focus {
                color:$secondary;
                }
            .fa-times {
                padding-left:.5rem;
                }
            }
        }
    }

// 
// EMPLOYER : job posting > job preview
//
.romeo.target-connect.job-edit-flow.job-preview .content-body {
    margin:5rem auto;
    }

//
// OPERATOR : reverting new link theme for operators only TC-25497
//
.page-operator .section-job-posting {
    .post-job-header {
        background-color: $link-bg;
        padding: 32px 15px;
        }
    .post-job-form {
        .col-12.col-lg-8 {
            // putting back the full width for operators - NOTE: this is undone on Link (via custom css)!
            flex: 0 0 100%;
            max-width: 100%;
            }
        }
    fieldset {
        padding:1.5rem;
        &:nth-of-type(even) {background:$link-bg}
        &:nth-of-type(odd) {background: #fff }
        }
    legend {
        float:left; // needed to stop the default legend positioning
        margin:0 0 .5rem 0;
        color:$primary;
        border-bottom:none;
        font-size:1.1rem;
        }
    p.label {
        color: $body-color;
        display: block;
        font-size: .9rem;
        font-weight: 600;
        padding: 15px 0;
        text-align: left;
        margin: 0;
        }
    .form-control {
        font-size:.85rem;
        }
    .field-description, .form-help-text {
        color: #5c6670 !important;
        margin-top: 0.5rem;
        line-height: 1.5;
        font-size: .85rem;
        }
    .alert-inferno {
        display:none !important;
        }
    .alert, .alert-info {
        background: $info-muted;
        border: 1px solid $gray-300;
        font-size: .8rem;
        padding: .75rem 0.5rem;
        }
    .form-group {
        .form-check-label, label.radio-inline, .form-check label, label.radio-inline {
            font-size: .9rem;
            margin-bottom: 0;
            font-weight: 500;
            }
        .form-check {
            .field-description {
                margin-top:0;
                margin-bottom:.5rem
                }
            }
        }
    .post-job-buttons {
        margin-top:1.25rem;
        }
    }

//
// EMPLOYER : stepper (progress bar)
//
.stepper {
    display: flex;
    align-items: center;
    padding-bottom:1.7rem;
    .step {
        height:34px;
        width:34px;
        background-color: #C7CBD1; /* grey-300*/
        border-radius: 100%;
        color:#17191C; /* grey-1000*/
        font-weight:600;
        font-size: .9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &.step-active {
            background-color: #54E2DA;
            }
        &.step-current {
            .step-text {
                font-weight: 700;
                }
            }
        .step-text {
            position: absolute;
            bottom:-1.6rem;
            font-weight: 400;
            white-space: nowrap;
            }
        } 
    .step-line {
        background-color: #C7CBD1; /* grey-300*/
        height:6px;
        width:80px;
        border-radius: 40px;
        margin:0 1rem;
        &.step-line-active {
            background-color: #54E2DA;
            }
        .step-line-half {
            display: block;
            height:6px;
            width:40px;
            border-radius: 40px;
            background-color: #54E2DA;
            }
        }   
    }
  

//
// EMPLOYER :: packages
//
body.job-advertising-form,
body.target-connect.unauthorised.job-edit-flow, 
body.romeo.job-edit-flow {
    background: $link-bg !important;
    .main-content-header { // hiding the breadcrumb header (for employer only)
        display: none;
        }
    .section-job-posting {
        margin-top: 20px;
        background: transparent;
        }
    }
body.target-connect.unauthorised.job-advertising-form {
    .page-employer-packages { // ang fix for packages page before login
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        a {
            color:$inferno-primary;
            }
        }
    }
.link-packages {
    &.row.row-cols-1.row-cols-lg-3 {
        margin-top: 3rem;
        }
    .package, .package-custom, .package-bespoke, .network-list {
        .card-header {
            padding: 1.75rem 1.5rem 0 1.5rem;
            border: none;
            }
        .card-body {
            padding: 0 1.5rem 1.5rem 1.5rem;
            }
        .card-footer {
            padding: 0 1.25rem 1.75rem 1.25rem;
            border:none;
            .btn {
                display:block;
                }
            }
        .package-title {
            font-size: 1.8rem;
            font-weight: 700;
            color: $gray-1000;
            margin-bottom: 1rem !important;
            padding: 0 !important;
            }
        .package-cost {
            color: #6F79B4;
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 1rem;
            }
        .package-desc {
            color: $gray-700;
            font-size: 1rem;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 1.25rem;
            display: block;
            // min-height: 110px;
            }
        .package-included {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 1.52rem;
            text-decoration: none;
            span {
                display: block;
                color: #8263FF;
                font-weight: 600;
                font-size: 1rem; 
                }
            > span:last-child {
                display: block;
                flex-grow: 1;
                border-bottom: 1px solid #EAEBF4;
                margin-left: 0.8rem;
                }   
            &.section-collapse {  // TC-26307
                border-top:0;
                border-bottom:0;
                }
            }
        ul.package-bullets {
            line-height: 1.5;
            color: $gray-700;
            margin-bottom: 0;
            margin-left: 1.65em;
            li {
                margin-bottom: 0.75rem;
                .fa-li {
                    color: #28A744;
                    top: 0.25em;
                    }
                ul {
                    margin-top: 0.5rem;
                    padding-left: 1rem;
                    list-style-type: disc;
                    li {
                        margin-bottom: 0.25rem;
                        }
                    }
                }
            }
        }
    .package-terms {
        flex: 0 0 100%;
        max-width: 100%;
        }

    // build a bespoke audience section
    .package-bespoke, .network-list {
        margin-bottom:5rem;
        .package-desc {
            min-height: auto;
            margin-bottom:2rem;
            }
        .package-included { 
            margin-bottom:2rem;           
            > span:nth-child(2) {
                order:+1;
                margin-left: .8rem; 
                }
            .more, .less {
                color:$romeo-primary;
                span {
                    color:$romeo-primary;
                    }
                i {
                    margin-right:.3rem;
                    }  
                }
            }
        .package-selected {
            color: #334095;
            font-weight: 500;
            margin-bottom: 0.1rem;
            }
        .package-total {
            color: $gray-1000;
            font-weight: 500;
            margin-bottom: 0.4rem;
            font-size: 1rem;
            span {
                font-weight: 600;
                font-size: 1.3rem;
                }
            }
        .package-vat {
            color: $gray-700;
            }    
        .btn.btn-inferno-primary-outline {
            width: 100% !important;
            }
        // list of check boxes!
        ul.uni-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -0.625rem 2rem -0.625rem;
            li {
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 0.625rem;
                padding-left: 0.625rem;
                margin-bottom: 1.25rem;
                @media(min-width: $screen-md) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    }
                @media(min-width: $screen-lg) {
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                    }
                }
            }
        .custom-control {
            padding-left: 0;
            border: 1px solid #EAEBF4;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            &:has(input):hover,
            &:has(input:checked), 
            &:has(input:focus) {
                border-color: #54E2DA;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
                }
            label {
                cursor: pointer;
                font-weight: 600;
                color: $gray-1000;
                font-size: .9rem;
                width: 100%;
                padding: 1rem 5rem 1rem 1rem;
                &::before{
                    top: calc(50% - 8px);
                    right: 1rem;
                    left: auto;
                    }
                &::after {
                    top: calc(50% - 8px);
                    right: 1rem;
                    left: auto;
                    }
                }
            }
            .custom-control-input:checked~.custom-control-label::before {
                color: #fff;
                border-color: #54E2DA;
                background-color: #54E2DA;
            }
        // total - it's a shared component, so removing all the styling for this bespoke section only
        #totalCosting {
            margin:0;
            border:none;
            padding:0;
            box-shadow: none;
            .card-body {
                padding:0;
                }
            .text-right {
                text-align: left !important;
                }
            .top-border-solid-strong {
                border:none;
                margin-bottom: 0;
                padding-bottom: 0;
                }   
            #_otherJobsPrice, #_totalPriceContainer {
                display:none;
                }
            #_taxDetails {
                padding-left:0 !important; 
                }
            }

        .section-collapse {  // packages > bespoke audience > expand/collapse the uni's.  This was previously in helpers, but I want to expand on this section-collapse styling across the operator ui
            .more {display:none !important; align-items: center;}
            .less {display:flex !important; align-items: center;}
            &.collapsed {
                .more {display:flex !important}
                .less {display:none !important}
                }
            }  
            
        }
        
        // list of uni's in modal - super annoying that I'm having to repeat these styles, but hey ho
        .network-list {
            margin-bottom:0;
            ul.uni-list li span {
                font-weight: 600;
                color: $gray-1000;
                font-size: .9rem;
                padding: 1rem 5rem 1rem 1rem;
                border: 1px solid #EAEBF4;
                border-radius: 8px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                }
            }
    }

.page-employer-registration, .section-job-posting {
    .job-summary { // should make this only on lg screen
        position: sticky;
        top:2rem;
    }
    .alert-inferno {  //don't forget the perks
        background:#EDFCFB;
        color:$gray-700;
        border-radius: 4px;
        padding:1rem;
        margin:-.5rem 0 2rem 0;
        p {
            margin-bottom:0; 
            }
        strong:first-of-type {
            display: block;
            margin-bottom:.5rem;
            color:$gray-1000;
            }
        .far {
            color:#41BFB8;
            margin-top: 0.15rem;
            }
        }
    }


// 'Take me back' pullout when coming to link from a uni
.nav-pop-right {
    z-index: 100;
    .nav-pop-right-content {
        background:white;
        border:1px solid #e7e8ed;
        }
    }

// Choose your audience page - this page is hacky hack chilli page (chilli markup and inline styles). 
// targeting only the first card, which 'should' have the client logo in it! 
._chilli .row .col-md-4:first-child .card-header._chilli-pricingcard__header {
    background-color: $romeo-link-clientlogo-bg-color;
    }

// operator - hiding bespoke/managed services, T&Cs and marketing (logos etc) for the operator view 
body.page-operator #job-application-settings .link-packages .package-custom,
body.page-operator #job-application-settings .link-packages .package-terms,
body.page-operator #job-application-settings + .row.align-items-center.mt-5 {
    display:none; 
    }
body.page-operator .link-packages .package .card-inferno.selected { //TC-25398
    border:2px solid $inferno-primary;
    }
    