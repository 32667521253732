.target-connect {

    .page-registration {
        font-family: "Poppins", sans-serif;
        h1, h2, h3, h4, h5, h6, p {
            font-family: "Poppins", sans-serif;
            }
        p {
            font-size: 1.025rem;
            color: $gray-1000;
            }
        .card .card-header.bg-primary {
            background-color: $romeo-primary !important;  // ang adding here so it picked up the student colours, rather than default operator   
            }
        }

    &.unauthorised {
        font-family: "Poppins", sans-serif;
        h1, h2, h3, h4, h5, h6, p {
            font-family: "Poppins", sans-serif;
            }
        .btn {
            font-size: .9rem; // needed to overwrite the operator buttons
            }
        .card .card-header.bg-primary {
            background-color: $romeo-primary !important;  // ang adding here so it picked up the student colours, rather than default operator   
            }
        .top-search-bar {
            background: transparent;
            padding: 0;
            min-height: auto;
            }
        .user-details  {
            display:none;
            }
        .unauthroised-body {
            // min-height:calc(100vh - 60px);
            padding:0;
            }
        &.employer-registration-flow { // fix for TC-24628
            .main-content-header {
                .container {
                    background: #fff;
                    margin-top: 1rem;
                    padding-top: 1rem;
                    }
                .breadcrumb {
                    background:none;
                    }
                }
            }
        }    
    &.unauthorised {
        &:not(.section-reset .employer-registration-form) {
            background: $login-background-mobile;
            @media (min-width: $screen-md) {
                background: $login-background-desktop;
                }
            }
        .content-body {
            background:transparent;
            padding:30px 15px 15px 15px;
            min-height: calc(100vh - 114px);
            height:auto;
        }
        &.section-reset
        // &.employer-registration-form 
        {
            background: $login-content-bg;
        }
        header {
            background: $login-header-bg;
            position: relative;
            .user-details {
                display: none;
            }    
            .site-logo {
                width:auto;
                height: 60px;
                margin: 0.25rem 0.25rem;
            }   
        }  
        .footer-container {
            background: $login-footer-bg;
            border-top:1px solid #d7d7d7;
            footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:.5rem;
                border-top:none;
                }
            p {
                font-size: .8rem;
                margin-bottom:0;
            }
            .groupgtilink img {
                width:85px;
                }
        }               
        .content-block.block-login {
            margin-top:20px;
        }   
        
    // pulling out employer login styling so it's easier to spot!
    &.employer-registration-flow,
    &.job-manage-flow,
    &.job-edit-flow {
        background:#fff !important;
        header {
            border-bottom:1px solid $gray-300;
            padding:0.5rem;
            }
        }

    }	
}
