// BS4
// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    @include font-size($badge-font-size);
    font-weight: $badge-font-weight;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    @include border-radius($badge-border-radius);
    @include transition($badge-transition);
  
    @at-root a#{&} {
      @include hover-focus() {
        text-decoration: none;
      }
    }
  
    // Empty badges collapse automatically
    &:empty {
      display: none;
    }
  }
  
  // Quick fix for badges in buttons
  .btn .badge {
    position: relative;
    top: -1px;
  }
  
  // Pill badges
  //
  // Make them extra rounded with a modifier to replace v3's badges.
  
  .badge-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
  }
  
  // Colors
  //
  // Contextual variations (linked badges get darker on :hover).
  
  @each $color, $value in $theme-colors {
    .badge-#{$color} {
      @include badge-variant($value);
    }
  }
  



// OLD STUF
// .badge, .panel-secondary .badge, .panel-info .badge {
//     @include remConversion(font-size, $badge-font-size);  
//     @include remConversion(border-radius, $badge-border-radius);      
//     display: inline-block;
//     font-weight: 700;
//     line-height: 1;
//     color: $badge-text-color;
//     background-color: $badge-backgound-color;
//     text-align: center;
//     white-space: nowrap;
//     vertical-align: baseline;
//     padding: 2px 4px 3px 5px;
// }
// .btn .badge {
//     top:0px;
// }

// .badge {
//     &.notification {
//         position: absolute;
//         padding: 5px 7px;
//         top: -7px;
//         border-radius: 20px;        
//     }
// }