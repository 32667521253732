
.alert-inferno {
    border-radius: 12px;
    padding: 1rem;
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: $gray-1000;
    background-color: $gray-50;
    &.alert-info {
        background-color: $info-muted;
        border-color: $info;
        }
    &.alert-danger {
        background-color: $danger-muted;
        border-color: $danger;
        }
    &.alert-success {
        background-color: $success-muted;
        border-color: $success;
        }
    &.alert-warning {
        background-color: $warning-muted;
        border-color: $warning;
        }
    }