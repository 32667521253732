$listSizes: 1, 2 ,3 ,4 ,5 ,6 ,7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 50, 75, 100;

@include textSizes($listSizes, $text-root-font-size, $text-font-size, "text", "");

@media(min-width: $screen-xs){
    @include textSizes($listSizes, $text-root-font-size, $text-font-size, "text", "xs");
    @include textAlign("text-align","xs");
    @include textStrong("text-strong","600","xs");
}
@media(min-width: $screen-sm){
    @include textSizes($listSizes, $text-root-font-size, $text-font-size, "text", "sm");
    @include textAlign("text-align","sm");
    @include textStrong("text-strong","600","sm");
}
@media(min-width: $screen-md){
    @include textSizes($listSizes, $text-root-font-size, $text-font-size, "text", "md");
    @include textAlign("text-align","md");
    @include textStrong("text-strong","600","md");
}
@media(min-width: $screen-lg){
    @include textSizes($listSizes, $text-root-font-size, $text-font-size, "text", "lg");
    @include textAlign("text-align","lg");
    @include textStrong("text-strong","600","lg");
}