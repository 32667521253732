
.page-operator.romeo a {
    text-decoration: none;
    }

/** Crazy HOT FIX for pathways **/
.pathways-ui-page .fd-collapse .collapse.in {
    display: none!important;
    }
.pathways-ui-page .fd-collapse .collapse.in:not(.show) {
    display: block!important;
    }

/** random vlibrary stuff **/
a:hover {
   /*ang removing as it's not clear enough what's a link
    text-decoration: none;  /** this should be $link-hover-decoration in variables, but it's not working :( **/
    }
p {
    margin-bottom: $paragraph-margin-bottom;
    }
.small, small {
    font-size: 90%;
    }
.lead {
    font-size: 16px;
    }
a.skipnavigation {
    background-color: rgba(0,0,0,.8);
    color:#fff;
    text-decoration: underline;
    display: block;
    padding: 3px 5px;
    z-index: 1000;
    top: 10px;
    left: -400px;
    }
a.skipnavigation:focus {
    left: 5px;
    }
a.arrowed:before {
    content: "\f105";
    font-family: FontAwesome;
    padding-right: 4px;
    display: inline-block;
    }

/**********************************/
/** HACKY HACKS FOR QUICK FIXES **/
/**********************************/

.page-employeruser #toggle-sidebar {
    display: none;  /* fix to hide stop organisations  organisations */
    }
.page-employeruser #sidebar-header {
    margin:0;
    }

.btn {
    // background:#ccc;  /* quick fix for random buttons that don't have btn-primary, btn-secondary etc - found .btn-square-secondary on casebooks */
    }
.btn-square-secondary { //being used  on casebooks, add new case
    background-color: $secondary;
    color:$secondary-text-color !important;
    }
.btn-square-tertiary { //being used  on casebooks, add new case
    background-color: $gray-300;
    color:$gray-900 !important;
    }
.btn-square-success {
    background:#ccc;
    }
.btn-outline-default {
    // without this the colour is $grey-300 and too light
    color:$gray-900;
    }  
.card-header .btn, .heading .btn,
.card-header .btn:hover, .heading .btn:hover,
.btn-link {
    background: transparent !important;  /* quick fix to underdo the above!  buttons within a card header shouldn't have a bg colour */
    }
// TC-25026 : fixing invisible buttons when card-header doesn't have a bg-colour.   
.card-header:not(.bg-primary, .bg-secondary, .bg-danger, .bg-success, .bg-warning, .bg-tertiary, .bg-quaternary, .bg-info, .bg-dark) .btn {
        color: $text-font-color !important;
    }    

// annoying fix for btns with both classes: .btn-## overwrites .btn-clear-#  
.btn-info.btn-clear-info {
    color:$info !important;
    }


.main-content {
    background: #fff;  /* only needed as a quick fix for clients with dark body backgrounds - it was breaking 'work as student > pathways' */
    }

.username-containedropdown-toggle .caret { /** user dropdown in header, adding in the arrow **/
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    }
.dataTables_wrapper.dt-bootstrap { /* for students appointment page, 'choose your slot' */
    flex-grow: 1 !important;
    }


/****************/
/** NAVIGATION **/
/****************/

// ang removing for new navigation 

// @media (min-width: 768px) {
//     .navbar-collapse.collapse {
//         display: block!important;
//         height: auto!important;
//         overflow: visible!important;
//         }
//     }

// .navbar {
//     padding:0;
//     justify-content: flex-start;
//     }
// .navbar-nav {
//     display:block;
//     float: left;
//     margin: 0;
//     }
// .nav>li {
//     position:relative;
//     a {
//         position: relative;
//         display: block;
//         text-decoration: none !important;
//         .fa.fa-chevron-circle-down {
//             display:none;
//             }
//         }
//     .dropdown-menu>li>a {
//         font-size: 0.8rem;
//         padding: 10px 10px;
//         }
//     }
// @media (min-width: 768px) {
//     .navbar-nav>li {
//         float: left;
//         }
//     }
// .navbar-nav .dropdown-menu {
//     position: absolute; 
//     }
// .open>.dropdown-menu {
//     display: block;  /** BS4 has changed to 'show' **/
//     }
// .navbar-nav .flexMenu-viewMore .dropdown-menu {
//     position: relative; /* fix for items under the 'more' menu */
//     }


// @media (min-width: 768px) {
//     .navbar-toggle {
//         display: none;
//         }    
//     }
// .dropdown-menu>li>span { /** from vlibrary **/
//     display: block;
//     font-weight: 700;
//     white-space: nowrap;
//     margin-top: 4px;
//     border-top: 1px solid #fff;
//     padding:10px 10px 0 10px;
//     }
// .navbar-default .navbar-nav .show .dropdown-menu>li span:hover,
// .navbar-default .navbar-nav .open .dropdown-menu>li span:hover {
//     background-color: transparent !important; /** crazy override **/
//     color:inherit !important;
//     }


/****************/
/** BREADCRUMB **/
/****************/

// .breadcrumb {
//     background: transparent;
//     padding: 5px 0 0;
//     margin: 0px;
//     }
// .breadcrumb>li {
//     display: inline-block;
//     }
// .breadcrumb>li a.nav-link {
//     padding:0;
//     display: inline-block;
//     }
// .breadcrumb>li+li:before {
//     padding: 0 5px;
//     color: #ccc;
//     content: "/\00a0";
//     }
// .breadcrumb>li .dropdown.show {
//     display:inline-block !important;  /* this is too fix an issue on the 'work with student' page where you can see 'recently viewed users' as a dropdown */
//     }

/****************/
/**** CARDS *****/
/****************/

.card .card-header {
    // background: #fff !important;  Ang removing!  As it was causing issues when a bg class was used
    // color: #333;
    }
/*nasty hack for full student profile*/
#student-profile-container .card {   
    box-shadow: 1px 1px 4px 0px #0000004d;
    }
#student-profile-container .card .card-header {
    // background:#fff !important; removing as it messed up the student profile on the student side 
    }

.page-operator .card .card-header.bg-primary {
    background-color: $primary;
    color: $primary-text-color
    }
.page-operator .card .card-header.bg-secondary {
    background-color: $secondary;
    color: $secondary-text-color
    }

/****************/
/**** TABLES ****/
/****************/

// .table-condensed>tbody>tr>td, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>td, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>thead>tr>th {
//     padding: 5px;
//     }
// .table thead th .quicksearch a.dropdown-item, .table thead th .dropdown-menu a { /* invisible text in dropdowns TC-24417 */
//     color: $text-font-color;
//     }


/****************/
/***** TABS *****/
/****************/

.nav-tabs {
    margin-bottom: 15px;
    border-bottom: 2px solid $gray-700;
    }
.nav-tabs>li>.tab-nolink, .nav-tabs>li>a,
.nav-tabs>li>.btn-tab {
    background-color: $gray-300;
    border:1px solid $gray-300;
    color:$gray-1000;
    font-weight:600;
    border-radius: 4px 4px 0 0;
    padding: .5rem 1rem;
    margin-right: 3px;
    outline: 0;
    display: block;
    transition: all .3s ease;
    }    
.nav-tabs li.active .btn-tab, .nav-tabs li .btn-tab:hover, .nav-tabs li .btn-tab:focus,
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    background-color: $gray-700;
    color: #fff;
    border: 1px solid $gray-700;
    }
.widget-tabs .nav-tabs>li>.tab-nolink, .content-block .content-block-tabs .nav-tabs>li>.tab-nolink, .widget-simple-plain .content-block-tabs .nav-tabs>li>.tab-nolink, .widget-simple-stat .content-block-tabs .nav-tabs>li>.tab-nolink, .widget-tabs .nav-tabs>li>a, .content-block .content-block-tabs .nav-tabs>li>a, .widget-simple-plain .content-block-tabs .nav-tabs>li>a, .widget-simple-stat .content-block-tabs .nav-tabs>li>a {
    background: #fff;
    border: none;
    font-weight: normal;
    padding: 0px 12px 10px;
    color: #333;
    text-transform: capitalize;
    }
.widget-tabs .nav-tabs>li>a:focus, .content-block .content-block-tabs .nav-tabs>li>a:focus, .widget-simple-plain .content-block-tabs .nav-tabs>li>a:focus, .widget-simple-stat .content-block-tabs .nav-tabs>li>a:focus, .widget-tabs .nav-tabs>li>a:hover, .content-block .content-block-tabs .nav-tabs>li>a:hover, .widget-simple-plain .content-block-tabs .nav-tabs>li>a:hover, .widget-simple-stat .content-block-tabs .nav-tabs>li>a:hover {
    text-decoration: none;
    border-bottom: 3px solid #fff;
    transition: none;
    }

/****************/
/**** FORMS ****/
/****************/

.form-control {
    font-size: $input-font-size;
    }
.table>thead>tr>th, fieldset h2, h2.legend, label, legend {
    font-weight: 600;
    }
.form-inline .form-group, .form-inline .form-control  {
    margin-left:5px;
    }
.form-inline label {
    justify-content: left;
    padding-left:5px; /** adding this for modal forms - the label doesn't align with the input underneath*/
    }
fieldset h2, h2.legend, legend {
    border-bottom: 1px solid #d0d0d0;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    }
fieldset.form-group legend {
    // this is for when we're grouping radio/check inputs with a fieldset and legend - making the legend look like a normal label
    border-bottom: 0;
    margin: 0 0 0.5rem 0;
    padding: 0;
    font-size: 100%;
    }
.help-block {
    display: block;
    margin-top: 5px;
    }
.form-group input[type="file"] {
    height: 42px !important;
    }

// ANG deleting as it's causing issues for new BS4 input-group code
// /**input groups**/
// .input-group {
//     position: relative;
//     display: table;
//     border-collapse: separate;
//     }
// .input-group .form-control {
//     position: relative;
//     z-index: 2;
//     float: left;
//     width: 100%;
//     margin-bottom: 0;
//     }    
// .form-group .input-group.input-no-help {
//     width: 100%;
//     }
.input-group-addon {
    display: flex;
    align-items: center;
    // most of below should be using variables!
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: $input-font-size;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    }
.input-group-btn  { /* needed until we replace with .input-group-prepend, .input-group-append */
    display: flex;
    }
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    }
.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:first-child>.btn-group:not(:first-child)>.btn, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    }
.input-group-addon:first-child {
    border-right: 0;
    }
.input-group-addon:last-child {
    border-left: 0;
    }
.input-group-prepend .btn-outline-primary, .input-group-prepend .btn-outline-secondary {
    border-width: 1px;  // used for translation fields. border width is 2px for inferno
    }
.input-group .input-group-append a.btn {
    line-height: 1.8;
    }



// .input-group-addon, .input-group-btn {
//     width: 1%;
//     white-space: nowrap;
//     vertical-align: middle;
//     }
// .input-group .form-control, .input-group-addon, .input-group-btn,
// .input-group-btn.show {
//     display: table-cell !important;  /* needs !important for input-group-btn dropdown TC-24297 */
//     }

// .input-group .input-group-btn .btn {
//     padding: 6px 10px;
//     }
// .form-group .input-group input[type="file"] {
//     width: auto;  /* override to stop the 'upload' button wrapping onto a new line */
//     }

// needed for dropdowns with buttons instead of <a>s
.dropdown-menu > li > button.btn {
    height:auto !important;
    }
.dropdown.btn-group .dropdown-menu > li > button.btn:hover,
.dropdown-menu > li > button.btn:hover {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background: $dropdown-link-hover-bg !important;
    }

.btn-group.show {  
    // needed to overwrite .show, otherwise the dropdowns jump around (see student > search jobs > job details 
    display:inline-flex !important;
    }


/** custom-control-label **/
.custom-control-label {
    padding-top:2px;  /** quick fix to get the label aligned - used on student > appointments > show only referrals toggle */
    }
.small .custom-control-label {
    padding-top:5px;  /** quick fix to get the label aligned - used on student > appointments > show only available toggle */
    }

/** checkboxes radio/checkbox-inline removed in BS4 **/    
.form-inline .radio, .form-inline .checkbox {  /** this is due to the help icon being out of alignement on radio/checkboxs **/
    display: flex;
    justify-content: space-between;
    }
.checkbox label, .radio label, .form-check label {
    margin:0;
    font-weight: normal; /* attempting to target check/radio labels */
    }
.checkbox, .radio {
    position:relative; /* adding because of misaligned inputs  - picked up on CV books */
    }
.checkbox-inline, .radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
    }
.checkbox-inline, .radio-inline {
    position: relative;
    display: inline-block !important;
    padding-left: 20px /*removing the !important because it was forcing the first check/radio out of alignment */;
    margin-bottom: 0 !important;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
    }
label.radio-inline, label.checkbox-inline {
    margin-right: 0.4rem;
    padding-top: 0 !important;
    }
.checkbox-inline+.checkbox-inline, .radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px;
    }
.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
    display:flex;
    flex-wrap: wrap; /*adding to match .row*/
    }
.checkbox-inline+.checkbox-inline, .radio-inline+.radio-inline {
    margin-left: 10px;
    }

/** form-group-sm (removed in BS4) */
.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
    }
.form-group-sm .form-control, .input-group-sm>.form-control, .input-group-sm>.input-group-addon, .input-group-sm>.input-group-btn>.btn, .input-sm,
.control-label-sm, .form-group-sm label, .radio-sm label {
    font-size: 0.8rem;
    }
.form-group-sm .form-control, .input-group-sm>.form-control, .input-group-sm>.input-group-addon, .input-group-sm>.input-group-btn>.btn, .input-sm {
    height: 29px !important;
    padding: 5px 6px;
    }    

.form-group-sm select.form-control {
    padding: 4px 3px;
    }

.form-horizontal .form-control-static {
    padding-top: 5px;
    padding-bottom: 0;
    vertical-align: middle;
    min-height: auto;
    }

/** errors **/
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: #C61C2D !important
    }
.has-error .form-control {
    border-color: #C61C2D!important;
    }

/* Disabled */
.checkbox-inline.disabled,
.checkbox.disabled label,
.form-control[disabled],
.form-control[readonly],
.form-control[disabled]::placeholder,
.form-control[readonly]::placeholder,
.radio-inline.disabled,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .form-control,
fieldset[disabled] .radio label,
fieldset[disabled] .radio-inline {
    color: #aaa
}


/*************************/ 
/*** SIDEBAR / FACETS ****/
/*************************/

.sidebar {
    max-width: 16.666667%;
    padding-bottom:10px;
    }
.filters-collapse .heading a {
    text-decoration: initial;
    display: block;
    }
.filters-collapse .heading a:focus, .filters-collapse .heading a:hover, .filters-collapse .heading a:active {
    text-decoration: underline;
    color:#fff;
    }
.sidebar.filters .form-group {
    display:block;  /* stopping the forms from being horizontal **/
    }
.sidebar.filters .form-group label {
    margin-bottom:0.1rem;
    }
.sidebar.filters .form-control, .sidebar.filters .list-group-item {
    font-size: .8rem;
    }
.sidebar h2.heading {
    margin-top:15px;
    background-color: $primary;
    color: $primary-text-color;
    }
.page-operator .row.row-eq-height {  /* this is an attempt to fix the sidebar within tab content (i.e. event management).  Making specific to operator as it was throwing out student  */
    margin-left:0;
    margin-right:0;
    flex-wrap: nowrap; /** trying to fix the sidebar toggle **/
    }
.filters.form-group .checkbox .amount, .filters.form-group .checkbox-inline .amount {
    font-size: 0.65rem;
    color: #828282;
    }

/** sidebar toggling **/
.content, .sidebar {
    transition: all .5s ease;
    }
.sidebar .btn-toggle-sidebar {
    font-size: 20px;
    margin-top: -10px;
    margin-right: -10px;
    line-height: 1.7;
    padding-left: 7px;
    width: 20px
    }
.sidebar .btn-toggle-sidebar:before { content: "\f104"; font-family: FontAwesome}
.sidebar .toggled .btn-toggle-sidebar:before {content: "\f105"; font-family: FontAwesome}   

/** backdrop for mobile **/
.toggle-fade.in {
    display: block
    } 
@media(min-width:992px) {
    .toggle-fade,
    .toggle-fade.in {
        display: none
        }
    }
@media (min-width: 992px) {
    .toggled .content {
        margin-left: -16%;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        }
    }



/****************/
/**** MODAL *****/
/****************/

.modal-title {
    color:$primary;
    }
.modal-footer {
    //trying to organise the buttons in a modal footer (#session-schedule-cancel and #bookingCancelButton from appointments)
    button[data-dismiss="modal"], a[data-dismiss="modal"],
    button#bookingCancelButton,
    button#session-schedule-cancel {
        margin-right:auto;
        }
    }



// SEPT 2022 - ang working on and tidying up modals

// @media (min-width: 576px) {
//     .modal-dialog {
//         max-width:600px; /*hiding this, as it's throwing out lg dialogs - ANG, PUTTING BACK!!*/
//         margin: 5.75rem auto;
//         }
//     }

// @media (min-width: 992px) {
//     .modal-lg {
//         max-width: 900px;
//         }
//     }

// .modal .modal-header {
//     flex-direction: row-reverse;
//     .modal-title {
//         margin:0;
//         padding:0;
//         }
//     }
// .modal-backdrop.in {
//     opacity: .5 !important;
//     }

/** popover **/
.popover-header {
    margin-top:0;
    }


/****************/
/*** COLLAPSE ***/
/****************/

.collapse {
    display: none;
    }
.collapse.in {
    display: block;
    }
// .collapse.in:not(.show) {
//     display: none;
//     }
.show {
    display: block !important;
    }
.d-inline-block.show {
    display: inline-block !important;
    }
.collapsible-icon .fa-collapsible:before {
    content: "\f0d7";
    }
.collapsible-icon.collapsed .fa-collapsible:before {
    content: "\f0d9"
    }
.collapsible-icon-left.collapsed .fa-collapsible:before {
    content: "\f0da"
    }


/****************/
/*** WIDGETS ***/
/****************/

.widget-block { /** from vlibrary **/
    width: auto;
    margin: 0 auto 30px;
    padding: 15px;
    background: #fff;
    border: 1px solid #d9d9d9;
    color: #333;
    }


/****************/
/*** POPOVERS ***/
/****************/

.popover-content {
    padding: 9px 14px;
    }
.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0,0,0,.25);
    }
.popover>.arrow, .popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    }
.popover.left>.arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff;
    }


/**************************************/
/** CONTENT BLOCKS / PANELS / WELLS ***/
/**************************************/

.content-block, .panel, .panel-group .panel {
    margin: 0 auto 15px 0;
    background-color: #fff; /* needed for popovers on appointments */
    position: relative; /*adding for icons/circles/badges etc that sit within headings e.g. TC-24413 */
    }
.panel, .panel-group .panel {
    padding: 0 !important;
    }
.panel-title {
    color: inherit; /*needed for a few places (maintenance > appointments > custom fields.  Ahh, messed up by being in a fieldset */
    margin-bottom:0;  // removing,  <panel-heading><h4> now has mb from reboot
    }
.content-block-footer, .panel-footer {
    background-color: #EEE;
    border-radius: 0;
    padding: 8px;
    }
.heading a {
    color: #fff;
    }
.heading a:hover, .heading a:active, .heading a:focus {
    color: #fff;
    text-decoration: underline;
    }
.content-block .heading-bleed, .content-block .heading-bleed:first-child, .heading-bleed {
    margin: -15px -15px 10px;
    }
.content-block-footer {
    margin: 0 -15px -15px -15px;
    }
.panel-heading h2, h2.heading, h2.panel-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.1;
    padding:8px;
    }
.panel-heading h2 { /* Ok!  Adding padding back, but using the class below to try and fix the issues!  Was seeing too many headings with no padding.  Removing padding as it was causing double padding - organisation profile > view employer activity history > user logins (with edit button) */
    padding:8px;
    }
.panel-heading > h2, .panel-heading > h3 {
    padding: 0 !important;
    }
.panel-body {
    padding: 15px;
    }    
/* buttons in block headings */
.heading .btn, .panel-title .btn {
    font-weight: 600;
    background: rgba(255,255,255,.15);
    border: none;
    border-radius: 0;
    border-left: 1px solid #fff;
    margin-top: -8px;
    margin-right: -8px;
    padding: 6px 10px 8px 32px;
    }
.well {
    min-height: 20px;
    padding: 1.25rem;
    margin-bottom: 20px;
    background-color: $gray-50;
    border-radius: 0.5rem;
    }


/****************/
/** UTILITIES ***/
/****************/

.bottom-margin-xxs {
    margin-bottom: 2px!important;
    }
.pull-left {
    float: left!important;
    }
.pull-right {
    float: right!important;
    }
.pull-top-right {
    float: right;
    margin-bottom: 10px;
    margin-top: -10px;
    }
@media (min-width: 768px) {
    .pull-top-right {
        position: absolute;
        top: 45px;
        right: 15px;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 99; // needed to put the buttons ontop of the 'general testing should not be done .... ' blah blah message
        }
    .container-relative .pull-top-right,
    .row .pull-top-right {
        top: 0
        }
    .container-relative .pull-top-right {
        right: 0
        }
    }
.pull-bottom-full,
.pull-bottom-right {
    bottom: 0;
    right: 0;
    position: absolute
    }
.pull-bottom-full {
    left: 0
    }
@media only screen and (min-width :320px) {
    .full-width-xxs {width: 100% !important}
    .pull-right-xxs {float: right !important}
    .pull-left-xxs {float: left !important}
    }    
@media only screen and (min-width :480px) {
    .full-width-xs {width: 100% !important}
    .pull-right-xs {float: right !important}
    .pull-left-xs {float: left !important}
    }
@media only screen and (min-width :768px) {
    .full-width-sm {width: 100% !important}
    .pull-right-sm {float: right !important}
    .pull-left-sm {float: left !important}
    }    
@media only screen and (min-width :992px) {
    .full-width-md {width: 100% !important}
    .pull-right-md {float: right !important}
    .pull-left-md {float: left !important}
    }    
@media only screen and (min-width :1200px) {
    .full-width-xl {width: 100% !important}
    .pull-right-xl {float: right !important}
    .pull-left-xl {float: left !important}
    }    
.pull-center {
    left: 50%
    }


/* clear fix from bs3 and vlibrary */
.btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
    display: table;
    content: " ";
    }    
.btn-group-vertical>.btn-group:after, .btn-toolbar:after, .clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .form-horizontal .form-group:after, .nav:after, .navbar-collapse:after, .navbar-header:after, .navbar:after, .pager:after, .panel-body:after, .row:after {
    clear: both;
    }

.heading-dark, .label-dark {/** quick fix  label-dark should change to .badge **/
    background-color: #666;
    border-color: #5B5B5B;
    color: #fff
    }

/* crazy circles from vlibrary */
.icon-circle .fa {
    position: absolute;
    top: 9px;
    left: 4px;
    font-size: 1.7rem;  /* different places have different font-sizes (fa-1x, fa-2x) - trying to make consistent */
    text-align: center;
    width: 1.25em;
    }
.icon-circle-sm .fa {  /* aahh!  hadn't spotted some circles are sm! */
    font-size: 1.5rem;
    left:6px;
    }

.heading-primary .icon-circle, .heading-primary .icon-circle-text, .panel-danger .icon-circle, .panel-primary .icon-circle-text {
    color: $primary;
    }
.heading-secondary .icon-circle, .heading-secondary .icon-circle-text, .panel-secondary .icon-circle, .panel-secondary .icon-circle-text {
    color: $secondary;
    }
.heading-success .icon-circle, .heading-success .icon-circle-text, .panel-success .icon-circle, .panel-success .icon-circle-text {
    color: $success;
    }
.heading-info .icon-circle, .heading-info .icon-circle-text, .panel-info .icon-circle, .panel-info .icon-circle-text {
    color: $info;
    }
.heading-warning .icon-circle, .heading-warning .icon-circle-text, .panel-warning .icon-circle, .panel-warning .icon-circle-text {
    color: $warning;
    }
.heading-danger .icon-circle, .heading-danger .icon-circle-text, .panel-danger .icon-circle, .panel-danger .icon-circle-text {
    color: $danger;
    }
.content-block-primary .heading, .content-block.bg-primary, .heading-primary, .label-primary, .panel-primary .panel-heading, .progress-bar-primary, .thick-border-primary {
    background-color: $primary;
    border-color: $primary;
    color:$primary-text-color
    }
.content-block-secondary .heading, .content-block.bg-secondary, .heading-secondary, .label-secondary, .panel-secondary .panel-heading, .progress-bar-secondary, .thick-border-secondary {
    background-color: $secondary;
    border-color: $secondary;
    color:$secondary-text-color
    }
.content-block-success .heading, .content-block.bg-success, .heading-success, .label-success, .panel-success .panel-heading, .progress-bar-success, .thick-border-success {
    background-color: $success;
    border-color: $success;
    color:$success-text-color
    }
.content-block-danger .heading, .content-block.bg-danger, .heading-danger, .label-danger, .panel-danger .panel-heading, .progress-bar-danger, .thick-border-danger {
    background-color: $danger;
    border-color: $danger;
    color:$danger-text-color;
    }
.content-block-warning .heading, .content-block.bg-warning, .heading-warning, .label-warning, .panel-warning .panel-heading, .progress-bar-warning, .thick-border-warning {
    background-color: $warning;
    border-color: $warning;
    color:$warning-text-color;
    }
.content-block-info .heading, .content-block.bg-info, .heading-info, .label-info, .panel-info .panel-heading, .progress-bar-info, .thick-border-info {
    background-color: $info;
    border-color: $info;
    color:$info-text-color;
    }
.alert-success,.bg-success-muted,.content-block.bg-success-muted,.table>tbody>tr.success>td,.table>tbody>tr.success>th,.table>tbody>tr>td.success,.table>tbody>tr>th.success,.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,.table>tfoot>tr>td.success,.table>tfoot>tr>th.success,.table>thead>tr.success>td,.table>thead>tr.success>th,.table>thead>tr>td.success,.table>thead>tr>th.success {
    background-color: $success-muted;
    border-color: $success-muted;
    color:darken($success, 13%);
    a {
        color:darken($success, 13%);
        }
    }
.alert-warning,.bg-warning-muted,.content-block.bg-warning-muted,.table>tbody>tr.warning>td,.table>tbody>tr.warning>th,.table>tbody>tr>td.warning,.table>tbody>tr>th.warning,.table>tfoot>tr.warning>td,.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,.table>tfoot>tr>th.warning,.table>thead>tr.warning>td,.table>thead>tr.warning>th,.table>thead>tr>td.warning,.table>thead>tr>th.warning {
    background-color: $warning-muted;
    border-color: $warning-muted;
    color:darken($warning, 13%);
    a {
        color:darken($warning, 13%);
        }
    }
.alert-info, .bg-info-muted, .content-block.bg-info-muted, .table>tbody>tr.info>td, .table>tbody>tr.info>th, .table>tbody>tr>td.info, .table>tbody>tr>th.info, .table>tfoot>tr.info>td, .table>tfoot>tr.info>th, .table>tfoot>tr>td.info, .table>tfoot>tr>th.info, .table>thead>tr.info>td, .table>thead>tr.info>th, .table>thead>tr>td.info, .table>thead>tr>th.info {
    background-color: $info-muted;
    border-color: $info-muted;
    color:darken($info, 13%);
    a {
        color:darken($info, 13%);
        }
    }
.alert-danger,.bg-danger-muted,.content-block.bg-danger-muted,.table>tbody>tr.danger>td,.table>tbody>tr.danger>th,.table>tbody>tr>td.danger,.table>tbody>tr>th.danger,.table>tfoot>tr.danger>td,.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,.table>tfoot>tr>th.danger,.table>thead>tr.danger>td,.table>thead>tr.danger>th,.table>thead>tr>td.danger,.table>thead>tr>th.danger {
    background-color: $danger-muted;
    border-color: $danger-muted;
    color:darken($danger, 15%);
    a {
        color:darken($danger, 13%);
        }
    }
.bg-danger, .bg-danger-muted, .bg-dark, .bg-dark-muted, .bg-info, .bg-info-muted, .bg-light, .bg-light-muted, .bg-primary, .bg-primary-muted, .bg-secondary, .bg-secondary-muted, .bg-success, .bg-success-muted, .bg-warning, .bg-warning-muted {
    padding: 3px 10px;  /* eeeek!  I don't want to put this back, but I need too :( */
    }
.label-default {
    background-color: #777;
    }


.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    }
@media (min-width: 768px) {
    .dl-horizontal dd {
        margin-left: 180px;
    }
    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        }
    }
.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
    }
.label {
    padding: .25rem .5rem;
    font-size: 85%;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 0.5em;
    margin-right: .25rem;
    }
.label.label-finished {
    color:#fff;
    }


/******************************************************/
/***** LIST GROUPS / DISPLAY INFO (from vlibrary) *****/
/******************************************************/

.list-group-item.row {
    display:flex;  /* list-group-item has display:block, which is trumping the row flex */
    }
.list-group-item+.list-group-item { /*crazy hack, BS4 sets it too 0px, not sure why */
    border-top-width: 1px;
    }
.list-group-item-heading { /* from bs3 */
    margin-top: 0;
    margin-bottom: 5px;
    }
.list-group-inline .list-group-item {
    background: #dad9d9 !important;
    border-radius: 5px; 
    display: inline-block;
    float: left;
    padding: 2px 5px !important;
    margin: 2px 5px 2px 0;
    // a {  NEW styling that I'm un-doing! 
    //     background:$success !important;
    //     border-radius: $button-border-radius; 
    //     display: inline-block;
    //     padding: .25rem .5rem .2rem .5rem !important;
    //     margin: 0 .25rem .25rem 0 !important;
    //     font-weight: 500;
    //     color: $success-text-color;
    //     .fa-times  {
    //         font-size: .75rem;
    //         margin-left: 0.2rem;
    //         }
    //     &:hover, &:active, &:focus {
    //         background:$success;
    //         color:$success-text-color;
    //         color:$success-text-color;
    //         text-decoration: none;
    //         }
    //     }
    }
.display-info .display-info-item {
    padding: 0;
    margin: 0 0 5px 0;
    }
.list-group-basic .list-group-item {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
    padding: 0;
    }
a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
    }
.display-group, .display-info-border {
    border-bottom: 1px solid #E5E5E5;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
    }
.display-group.row {
    margin-left: -15px;
    margin-right: -15px;
    }
.display-info-border-dotted {
    border-bottom: 1px dotted #E5E5E5
    }
.display-info-border-strong {
    border-bottom: 2px solid #DDD
    }
.display-info-sm-border {
    padding-top: 4px;
    padding-bottom: 4px
    }
.display-group label, /*.form-horizontal .form-group:last-child,*/ .help-block {
    margin-bottom: 0;
    }
.display-group .form-control-static {
    padding-bottom: 0;
    padding-top: 0;
    min-height: 0
    }
.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px;
    }
.list-hover li:hover, .row-hover:hover {
    background-color: #EEE;
}


/****************/
/***** GRID *****/
/****************/

.col-xs-offset-12 {
    margin-left: 100%;
    }
.col-xs-offset-11 {
    margin-left: 91.66666667%;
    }
.col-xs-offset-10 {
    margin-left: 83.33333333%;
    }
.col-xs-offset-9 {
    margin-left: 75%; 
    }
.col-xs-offset-8 {
    margin-left: 66.66666667%;
    }
.col-xs-offset-7 {
    margin-left: 58.33333333%;
    }
.col-xs-offset-6 {
    margin-left: 50%;
    }
.col-xs-offset-5 {
    margin-left: 41.66666667%;
    }
.col-xs-offset-4 {
    margin-left: 33.33333333%;
    }
.col-xs-offset-3 {
    margin-left: 25%;
    }
.col-xs-offset-2 {
    margin-left: 16.66666667%;
    }
.col-xs-offset-1 {
    margin-left: 8.33333333%;
    }
.col-xs-offset-0 {
    margin-left: 0%;
    }
@media (min-width: 768px) {
    .col-sm-offset-12 {
        margin-left: 100%;
        }
    .col-sm-offset-11 {
        margin-left: 91.66666667%;
        }
    .col-sm-offset-10 {
        margin-left: 83.33333333%;
        }
    .col-sm-offset-9 {
        margin-left: 75%;
        }
    .col-sm-offset-8 {
        margin-left: 66.66666667%;
        }
    .col-sm-offset-7 {
        margin-left: 58.33333333%;
        }
    .col-sm-offset-6 {
        margin-left: 50%;
        }
    .col-sm-offset-5 {
        margin-left: 41.66666667%;
        }
    .col-sm-offset-4 {
        margin-left: 33.33333333%;
        }
    .col-sm-offset-3 {
        margin-left: 25%;
        }
    .col-sm-offset-2 {
        margin-left: 16.66666667%;
        }
    .col-sm-offset-1 {
        margin-left: 8.33333333%;
        }
    .col-sm-offset-0 {
        margin-left: 0%;
        }
    }
@media (min-width: 992px) {
    .col-md-offset-12 {
        margin-left: 100%;
        }    
    .col-md-offset-11 {
        margin-left: 91.66666667%;
        }    
    .col-md-offset-10 {
        margin-left: 83.33333333%;
        }    
    .col-md-offset-9 {
        margin-left: 75%;
        }    
    .col-md-offset-8 {
        margin-left: 66.66666667%;
        }    
    .col-md-offset-7 {
        margin-left: 58.33333333%;
        }    
    .col-md-offset-6 {
        margin-left: 50%;
        }    
    .col-md-offset-5 {
        margin-left: 41.66666667%;
        }    
    .col-md-offset-4 {
        margin-left: 33.33333333%;
        }    
    .col-md-offset-3 {
        margin-left: 25%;
        }    
    .col-md-offset-2 {
        margin-left: 16.66666667%;
        }    
    .col-md-offset-1 {
        margin-left: 8.33333333%;
        }    
    .col-md-offset-0 {
        margin-left: 0%;
        }
    }
@media (min-width: 1200px) {
    .col-lg-offset-12 {
        margin-left: 100%;
        }
    .col-lg-offset-11 {
        margin-left: 91.66666667%;
        }
    .col-lg-offset-10 {
        margin-left: 83.33333333%;
        }
    .col-lg-offset-9 {
        margin-left: 75%;
        }
    .col-lg-offset-8 {
        margin-left: 66.66666667%;
        }
    .col-lg-offset-7 {
        margin-left: 58.33333333%;
        }
    .col-lg-offset-6 {
        margin-left: 50%;
        }
    .col-lg-offset-5 {
        margin-left: 41.66666667%;
        }
    .col-lg-offset-4 {
        margin-left: 33.33333333%;
        }
    .col-lg-offset-3 {
        margin-left: 25%;
        }
    .col-lg-offset-2 {
        margin-left: 16.66666667%;
        }
    .col-lg-offset-1 {
        margin-left: 8.33333333%;
        }
    .col-lg-offset-0 {
        margin-left: 0%;
        }
    }

/***********************/
/** DISPLAY UTILITIES **/
/***********************/

// .media is being used for job list (student) and alerts (operator>merge student)- big 'i' icon with text along side - needs switching to flex */
.media {
    // needed here because .content-block.media is overwriting with display:block
    display: flex !important;
    }
.media-left, .media>.pull-left {
    padding-right: 10px;
    }
// .media-body, .media-left, .media-right { 
//     display: table-cell;
//     vertical-align: top;
//     }
// .media-middle {
//     vertical-align: middle;
//     }

.hidden {
    display: none!important;
    }
.hide {
    display: none!important;
    }
.fade.in {
    opacity: 1;
    }
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
    }
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
    }
@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
        }
    table.visible-xs {
        display: table !important;
        }
    tr.visible-xs {
        display: table-row !important;
        }
    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
        }
    }
@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
        }
    }
@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
        }
    }
@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
        }
    }
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
        }
    table.visible-sm {
        display: table !important;
        }
    tr.visible-sm {
        display: table-row !important;
        }
    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
        }
    }
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
        display: block !important;
        }
    }
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important;
        }
    }
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline-block {
        display: inline-block !important;
        }
    }
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
        }
    table.visible-md {
        display: table !important;
        }
    tr.visible-md {
        display: table-row !important;
        }
    th.visible-md,
    td.visible-md {
        display: table-cell !important;
        }
    }
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
        }
    }
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
        }
    }
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important;
        }
    }
@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
        }
    table.visible-lg {
        display: table !important;
        }
    tr.visible-lg {
        display: table-row !important;
        }
    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
        }
    }
@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
        }
    }
@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
        }
    }
@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important;
        }
    }
@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
        }
    }
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
        }
    }
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
        }
    }
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
        }
    }
.visible-print {
    display: none !important;
    }
@media print {
    .visible-print {
        display: block !important;
        }
    table.visible-print {
        display: table !important;
        }
    tr.visible-print {
        display: table-row !important;
        }
    th.visible-print,
    td.visible-print {
        display: table-cell !important;
        }
    }
.visible-print-block {
    display: none !important;
    }
@media print {
    .visible-print-block {
        display: block !important;
        }
    }
.visible-print-inline {
    display: none !important;
    }
@media print {
    .visible-print-inline {
        display: inline !important;
        }
    }
.visible-print-inline-block {
    display: none !important;
    }
@media print {
    .visible-print-inline-block {
        display: inline-block !important;
        }
    }
@media print {
    .hidden-print {
        display: none !important;
        }
    }

.label:empty {
    display: none !important;
    }


/////  
/// page specific stuff 
/// 

#apptActions {  // run appointments page - horrible bg-primary box with action buttons
    background:$gray-200 !important;
    border:none;
    padding:.8rem 1rem 1rem 1rem;
    h2 {
        color:$gray-800;
        }
    }


// FONT AWESOME SHAKE ANIMATION (copied this from v6)
.fa-shake {
    animation: fa-shake 1s;
    }  
@keyframes fa-shake {
    0% {transform: rotate(-15deg)}
    4% {transform: rotate(15deg)}
    8%,24% {transform: rotate(-18deg)}
    12%,28% {transform: rotate(18deg)}
    16% {transform: rotate(-22deg)}
    20% {transform: rotate(22deg)}
    32% {transform: rotate(-12deg)}
    36% {transform: rotate(12deg)}
    40%,to {transform: rotate(0deg)}
  }