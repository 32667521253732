.dashboard-widget-pane { 
    margin-bottom:30px;
    }

.widget-simple {
	min-height:75px;
	position:relative;
	overflow:hidden;
	text-align:right;
	padding:0px 20px;
	cursor:pointer;
	// margin-bottom:15px;
    display:block;
    z-index:1;
    height:100%;
    @media print {
        border:$content-block-border;
    }
    @media(min-width:$screen-md){
    	// margin-bottom:30px;
    }
	&> i {
		position:absolute;
		bottom:-10px;
		left:-10px;
		@include remConversion(font-size, 6);
        z-index:-1;
	}
	h2 {
		@include remConversion(font-size, 1.3);
		font-family:$font-family-base;
		margin-top:15px;
		margin-bottom:20px;
        text-transform: capitalize;
        font-weight:300;
	}
	.widget-simple-count {
		@include remConversion(font-size, 3);
	}
    &:focus, &:hover, &:active  {
        text-decoration: none;
    }
	@media(min-width:$screen-md){
		min-height:100px;
		&> i {
			position:absolute;
			bottom:-10px;
			left:-10px;
		}
	}
    .widget-simple-hover {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index:1;
        padding: 0px 20px;
        background:$widget-primary-background;
		color: $primary-text-color;
        a {
            text-decoration: none;
            z-index:2;
        }
        h2 {
            color: $primary-text-color;
            z-index:2;
            i {
                position:relative;
                font-size: 1em;
                top: 0;
                left: 0;
                color: $primary-text-color !important;
            }
        }
		&> i {
			color: lighten($widget-primary-background, 5%);
            z-index:-1;
		}
        &:hover {
            -webkit-transition: all 0.5s ease;
            transition: all 0.75s ease;
            opacity: 1;
            cursor: pointer;
        }
    }
	&.bg-primary, .widget-primary-background {
		background:$widget-primary-background !important;
		color: $primary-text-color !important;
		&> i {
			color: lighten($widget-primary-background, 5%) !important;
		}
        .widget-simple-hover {
            background:darken($widget-primary-background, 20%) !important;
            h2 {
                color: $primary-text-color !important;
                i {
                    color: $primary-text-color !important;
                }
            }
            &> i {
                color: lighten($widget-primary-background, 5%) !important;
            }
        }
	}
	&.bg-secondary, .widget-secondary-background {
		background:$widget-secondary-background !important;
		color: $primary-text-color !important;
		&> i {
			color: lighten($widget-secondary-background, 5%) !important;
		}
        .widget-simple-hover {
            background:darken($widget-secondary-background, 20%) !important;
            h2 {
                color: $secondary-text-color !important;
                i {
                    color: $secondary-text-color !important;
                }
            }
            &> i {
                color: lighten($widget-secondary-background, 5%) !important;
            }
        }
	}
	&.bg-tertiary, .widget-tertiary-background {
		background:$widget-tertiary-background !important;
		color: $tertiary-text-color !important;
		&> i {
			color: lighten($widget-tertiary-background, 5%) !important;
		}
        .widget-simple-hover {
            background:darken($widget-tertiary-background, 20%) !important;
            h2 {
                color: $tertiary-text-color !important;
                i {
                    color: $tertiary-text-color !important;
                }
            }
            &> i {
                color: lighten($widget-tertiary-background, 5%) !important;
            }
        }
	}
	&.bg-quaternary, .widget-quaternary-background {
		background:$widget-quaternary-background !important;
		color: $quaternary-text-color !important;
		&> i {
			color: lighten($widget-quaternary-background, 5%) !important;
		}
        .widget-simple-hover {
            background:darken($widget-quaternary-background, 20%) !important;
            h2 {
                color: $quaternary-text-color !important;
                i {
                    color: $quaternary-text-color !important;
                }
            }
            &> i {
                color: lighten($widget-quaternary-background, 5%) !important;
            }
        }
	}
}
