

// Add values here for border colors that you want to exist
$theme-colors: ( 
  "primary": $romeo-primary,
  "secondary": $romeo-secondary,
  "tertiary": $romeo-tertiary,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "info": $info         
);

@mixin variabliseThemeColours {
  // $theme-colors
  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
    --#{$color}-opacity-50: #{rgba($value, .5)};
    --#{$color}-darken-7_5: #{darken($value, 7.5%)};
    --#{$color}-darken-10: #{darken($value, 10%)};
    --#{$color}-darken-12_5: #{darken($value, 12.5%)};
    --#{$color}-darken-15: #{darken($value, 15%)};
    --#{$color}-darken-20: #{darken($value, 20%)};
    --#{$color}-darken-25: #{darken($value, 25%)};
    --#{$color}-lighten-10: #{lighten($value, 10%)};
    --#{$color}-lighten-15: #{lighten($value, 15%)};
    --#{$color}-lighten-20: #{lighten($value, 20%)};
    --#{$color}-lighten-25: #{lighten($value, 25%)}; 
    --#{$color}-variant-border-darken-10: #{darken(ccsv-theme-color-level($value, $alert-border-level), 10%)};
    --#{$color}-variant-border: #{ccsv-theme-color-level($value, $alert-border-level)};
    --#{$color}-variant-border-darken-5: #{darken(ccsv-theme-color-level($value, $alert-border-level), 5%)};
    --#{$color}-variant-color: #{ccsv-theme-color-level($value, $alert-color-level)};
    --#{$color}-variant-color-darken-10: #{darken(ccsv-theme-color-level($value, $alert-color-level), 10%)};
    --#{$color}-variant-background: #{ccsv-theme-color-level($value, $alert-bg-level)};
    --#{$color}-variant-background-darken-5: #{darken(ccsv-theme-color-level($value, $alert-bg-level), 5%)};
    --#{$color}-contrast: #{color-yiq-r($value)};
    --#{$color}-contrast-25: #{rgba($value, .25)};
    --#{$color}-contrast-50: #{rgba($value, .5)};
    --#{$color}-focus: #{rgba(mix(color-yiq-r($value), $value, 15%), .5)};
  }    
};

@mixin variabliseLinks {
    // Links
    --link-color: #{$romeo-link-color};
    --link-hover-color: #{$romeo-link-hover-color};
    --link-decoration: #{$romeo-link-decoration};
    --link-hover-decoration: #{$romeo-link-hover-decoration};
}

@mixin variabliseTypography {
    // Typography
    --font-family-base: #{inspect($text-font)};
    --font-weight-lighter: #{$romeo-font-weight-lighter};
    --font-weight-light: #{$romeo-font-weight-light};
    --font-weight-normal: #{$romeo-font-weight-normal};
    --font-weight-semibold: #{$romeo-font-weight-semibold};
    --font-weight-bold: #{$romeo-font-weight-bold};
    --font-weight-bolder: #{$romeo-font-weight-bolder};  
}

@mixin variabliseGeneral {
    // General
    --body-bg: #{$romeo-body-bg};
    --body-color: #{$romeo-body-color};  
    --grid-gutter-width: #{$romeo-grid-gutter-width};  
}

// Generate variables
body {
  @include variabliseThemeColours();
  @include variabliseLinks();
  @include variabliseTypography();
  @include variabliseGeneral();
}