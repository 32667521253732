.btn {
    .fa:first-of-type {
        padding: 0;
        position: relative;
        min-width: 0;
        width: inherit;
        transition: none;
        border-radius: 0;
        background: transparent;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            background: transparent;
        }
    }

    &.btn-link {
        padding: 0px;
    }
}

.dropdown-menu button.btn {
    text-align: left;
}

.panel,
.panel-group .panel {
    padding: 0;
}

.wrapper-with-sidebar.content {
    padding-left: 0px;
}

.form-group.input-with-help .form-control,
.form-group.input-with-help .radio,
.form-group.input-with-help .checkbox,
.form-group.input-with-help .input-group,
.form-control,
.form-group .radio,
.form-group .checkbox,
.form-group .input-group {
    width: 92%;
    width: calc(100% - 20px);
}

// fix for file uploads, misaligned 'attach' button and help icon  :  TC-27522
.romeo .form-group.input-with-help .input-group {
    float:left;
    .form-control {
        width:auto;
        }
    }


.help-popover {
    padding: 0 5px; /**increasing for UX **/
    font-size: 1rem;
    color: $info;

    &:hover {
        color: darken($info, 20%);
    }
}

a._content-block-toggle {
    color: $primary;
}

.heading,
.panel-heading {
    background-color: $primary;
    border-color: $primary;
    color: $primary-text-color;
}

.sidebar {
    h2.heading {
        font-size: 1rem;
    }
    h3.heading {
        font-size: .8rem;
        margin-bottom: 10px;
    }
    .heading a {
        /*color:$table-sidebar-heading-text-color;  removing the important, it was breaking filter headings on the sidebar */
    }
}

// head>tr>th {
//     background-color: $secondary; 
// }

// .table>caption+thead>tr:first-child>td,
// .table>caption+thead>tr:first-child>th,
// .table>colgroup+thead>tr:first-child>td,
// .table>colgroup+thead>tr:first-child>th,
// .table>thead:first-child>tr:first-child>td,
// .table>thead:first-child>tr:first-child>th,
// .table>thead>tr>th {
//     border: none;
// }

.sidebar {
    z-index: 0;
}

.toggled .sidebar {
    z-index: 101;
}

.toggle-fade {
    z-index: 100;
}

.nav-tabs {
    border-bottom: 2px solid $primary;
}

.nav-tabs li.active .btn-tab,
.nav-tabs li .btn-tab:hover,
.nav-tabs li .btn-tab:focus,
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    background-color: $primary;
    color: $primary-text-color;
    border: 1px solid $primary;
}

.panel-default .panel-heading {
    border-bottom: none;

    a {
        text-decoration: none;
    }
}

.widget-tabs-chevron,
.content-block-tabs-chevron {
    @include flexbox;
    @include justify-content(space-between);
    @include flex-wrap(wrap);

    &:after {
        content: none;
    }

    h2 {
        @include flex-grow(1);
        cursor: move;
    }

    ul {
        @include flexbox;
        @include justify-content(flex-end);
    }

    ul.converted {
        display: none;
    }

    .open {
        ul.converted {
            display: block;
        }
    }

    .fa {
        order: 3;
    }
}

.toggled .sidebar.col-md-2 {
    left: 0;
    margin-right: 15px;
}

// .breadcrumb {
//     display: none;
// }

// @media(min-width:$screen-md) {
//     .toggled.content-body.wrapper-with-sidebar .sidebar.col-md-2 {
//         left: -15.667%;
//     }

//     .breadcrumb {
//         display: block;
//     }
// }

.toggled.content-body.wrapper-with-sidebar .sidebar.col-md-2 {
    left: 0;
}

.wrapper-with-sidebar.content {
    overflow: hidden;
}

.content-body.wrapper-with-sidebar {
    overflow: auto;
}

.toggled.content-body.wrapper-with-sidebar .sidebar.col-md-2 {
    left: 0;
}

// @media(min-width:$screen-md) {
//     .toggled.content-body.wrapper-with-sidebar .sidebar.col-md-2 {
//         left: -15.7%;
//     }

//     // .dropdown-menu>li>span, .navbar .dropdown-menu>li>a {
//     //     color:$navigation-secondary-text-color;
//     //     background:$desktop-navigation-secondary-background;
//     //     margin:0;
//     // }    
// }

.toggled .sidebar,
.sidebar {
    background: $sidebar-background;
}

.form-group.input-no-help .mce-tinymce,
.form-group.input-no-help .radio,
.form-group.input-no-help .checkbox,
.form-group.input-no-help .input-group,
.formio-form .formio-choices .form-control {
    width: 100%;
    //height: 34px;
}

.targetconnect-login .form-control.input-no-help {
    height: 40px;
    padding-left: 15px;
    width: 100%;
}

// @keyframes autofill {
//     to {
//         color: $login-secondary-text-color-mobile !important;
//         background: transparent;

//         @media(min-width:$screen-md) {
//             color: $login-secondary-text-color-desktop !important;
//         }
//     }
// }

// input:-webkit-autofill {
//     animation-name: autofill;
//     animation-fill-mode: both;
//     padding-left: 15px;
//     width: 100%;
// }

.show-more {
    padding-top: 20px;
}

._field-errors {
    clear: both;
}

/** WIDGETS **/
.widget {
    position: relative;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
    margin-bottom: 20px;
}

.widget.widget-shadow {
    box-shadow: 1px 1px 3px -1px #000;
}

.widget.widget-simple-summary {
    text-align: right;
    padding: 5px 20px;
}

.widget.widget-simple-summary .widget-simple-summary-icon {
    z-index: -10;
    position: absolute;
    bottom: -15px;
    left: -10px;
    font-size: 10rem;
    opacity: 0.1;
}

.widget.widget-simple-summary widget-simple-summary-title {
    font-size: 2em;
}

.widget.widget-simple-summary .widget-simple-summary-information {
    font-size: 2.8em;
}

.widget.widget-simple-summary .widget-simple-summary-hover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.widget.widget-simple-summary .widget-simple-summary-hover .widget-simple-summary-hover-title {
    font-size: 2em;
    padding: 0px;
    margin: 0px;
}

.widget.widget-simple-summary .widget-simple-summary-hover .widget-simple-summary-hover-icon {
    width: 100%;
    font-size: 1.5em;
}

.widget.widget-simple-summary .widget-simple-summary-hover:hover {
    -webkit-transition: all 0.5s ease;
    transition: all 0.75s ease;
    opacity: 1;
    cursor: pointer;
}

// .dropdown-menu>li>span, .navbar .dropdown-menu>li>a {
//     color:$navigation-secondary-text-color;
// }

// ANG removing!!  Not really sure what it's there for
// .bg-primary .badge,
// .btn-secondary .badge,
// .heading-primary .icon-circle,
// .heading-primary .icon-circle-text,
// .panel-default .badge,
// .panel-default .icon-circle,
// .panel-default .icon-circle-text,
// .panel-primary .badge,
// .panel-primary .icon-circle,
// .panel-primary .icon-circle-text,
// .text-primary,
// fieldset h2,
// h2.legend,
// legend {
//     color: $primary;
// }

.display-group:last-child,
.display-info-border:last-child {
    border-bottom: none;
    padding-bottom: 8px;
}

.heading .btn,
.panel-title .btn {
    background: transparent !important;
    color: $primary-text-color !important;
}

@media(max-width: $screen-md) {
    .typeahead.dropdown-menu {
        min-width: 308px !important;
        right: -10px;
        left: auto !important;
        position: absolute;
    }
}

.sidebar .typeahead.dropdown-menu {
    right: auto !important;
    overflow: hidden;
}

.dhx_cal_navline .dhx_cal_date {
    text-align: center !important;
}

@media(min-width: $screen-md) {
    .dhx_cal_navline .dhx_cal_date {
        // hack for dhx calendar, needs removing when we come up with better soltuion.
        padding-left: 8px !important;
        text-align: left !important;
        font-size: 16px !important;
    }
}

@media(min-width:$screen-md) {
    .dhx_cal_navline .dhx_cal_date {
        padding-left: 0px !important;
        text-align: center !important;
        font-size: 22px !important;
    }
}

.sidebar .heading {
    font-weight: normal;
}

.filters-collapse .heading a {
    text-decoration: initial;
}

// .login {
//     .body {
//         .side-section .side-section-body .side-section-body-container {
//             .leap-pills {
//                 // TC custom for /leap/events.html on login page {
//                 list-style: none;

//                 li {
//                     padding: 0;

//                     .btn.pill {
//                         @include flexbox;
//                         @include justify-content(space-between);
//                         @include align-items(center);
//                         padding: 10px 15px !important;
//                         margin: -1px 0 -1px 0 !important;
//                         background: $login-pill-background-color;

//                         span.fa {
//                             width: auto;
//                             order: 4;
//                             transition: 0.5s;
//                         }

//                         &:hover,
//                         &:focus {
//                             background: transparent;
//                             text-decoration: none;

//                             span.fa {
//                                 transform: scale(4.5);
//                                 transition: 0.5s;
//                                 overflow: hidden;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

.dropdown.btn-group {
    .dropdown-menu {
        li {
            .btn {
                background: transparent !important;
                height: auto;
            }
        }
    }
}

.search-results-list .list-group-item a h3,
.search-results-list a.list-group-item,
.standard-list .list-group-item a h3,
.standard-list a.list-group-item {
    color: $text-font-color;
    font-size: $text-font-size;
}

// .panel-primary span.badge {  ANG removing!!  Not really sure what it's there for
//     color: $badge-color;
// }

.view-event-buttons a:hover,
.view-event-buttons a:focus,
.view-event-buttons a {
    text-decoration: none;
}

.datepicker.datepicker-dropdown {
    z-index: 10011 !important;
    /* ang updating from z-index:1060, see TC-17814  (not even sure why this is here in the first place - the popup adds it's own inline z-index) */
}

.cv-box {
    display: inline-block;
    padding: 15px;
    width: 100%;

    @media(min-width: $screen-md) {
        margin-right: 15px;
    }

    .widget-simple-action:last-child {
        margin-bottom: 0px;
    }
}

/** INLINE MULTIPLE DATE SELECTS **/
.form-group select.date.form-control {
    // width: 80px;
    width: auto;
    display: inline-block;
}

.romeo #sidebar-content .form-group select.date.form-control {
    width: 63px; // for the my bookings>events tab > sidebar
}

.bannerTJ {
    margin: -20px;
    background: url(https://targetjobs.co.uk/sites/targetjobs.co.uk/files/public/management-business-admin-dark.jpg) 0 -150px;
    padding: 15px 25px;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    color: #fff;

    img {
        height: 117px;
        order: 2;
        align-content: flex-end;
        align-self: center;
        justify-content: center;
    }

    >a {
        order: 2;
        color: #fff;
    }

    h2 {
        margin-top: 25px;
        margin-bottom: 0px;
        font-style: italic;
        font-weight: normal;
        flex: 1;
        color: #fff;

        a {
            color: #fff;
            text-decoration: none;
        }
    }
}

.bannerTJ h2 a {

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }
}

.bannerTJ a {
    color: #fff;
    text-decoration: none;
}

.bannerTJ h2 span {
    display: block;
    font-style: normal;
    font-weight: bold;
    color: #fff;
}

// a.calEventDesc.cal-user-name {  ang removing as part of the schedular upgrade
//     color: black
// }

.btn-zendesk {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}

.form-horizontal .form-group:last-child {
    margin-bottom: 15px;
}

#nav-anchor-activity {
    .widget-container.widget-container-image.logo-container {
        img {
            max-height: 200px;
        }
    }
}

/** PDF-PRINT **/
body {
    &.pdf-print {
        background: $white;
        font-weight: 400;

        a,
        a:hover {
            color: $text-font-color;
            text-decoration: underline;
        }

        header {
            &.pdf-print-header {
                height: auto !important;
                background: $romeo-main-header-bg;

                @if $romeo-main-header-bg==#fff {
                    padding: 0px;
                    margin: 0 0 30px 0;
                }

                @else {
                    padding: 20px;
                    color: $romeo-main-header-icon-color;
                    margin: 0;

                    a,
                    a:hover {
                        color: $romeo-main-header-icon-color;
                    }
                }

                position: relative;
                top:0;
                left:0;
                transition: none;
                z-index:0;
                display: inline-block;

                .pdf-print-header-logo {
                    height: 110px;
                    display: inline-block;

                    .site-logo {
                        max-height: 50px;
                        transition: none;
                        margin: 0;
                        padding: 0;
                    }
                }

                .pdf-print-header-user-details,
                .pdf-print-header-event-details {
                    text-align: right;
                    display: inline-block;

                    p {
                        margin-bottom: 2px;

                        label {
                            font-weight: 600;
                            margin-bottom: 0;
                            padding-right: 5px;
                        }

                        &:not(.pdf-print-header-user-details-additional)+.pdf-print-header-user-details-additional,
                        &:not(.pdf-print-header-event-details-additional)+.pdf-print-header-event-details-additional {
                            margin-top: 5px;
                        }
                    }

                    .pdf-print-header-user-details-name,
                    .pdf-print-header-event-details-title {
                        font-weight: bold;
                        font-size: 24px;
                        text-transform: uppercase;
                    }

                    .pdf-print-header-user-details-additional,
                    .pdf-print-header-event-details-additional {
                        font-size: 90%;
                    }
                }
            }

            &.event-details {
                padding: 20px;
            }
        }

        main {
            &.pdf-print-main {
                background: #eee;
                padding: 20px;

                @if $romeo-main-header-bg==#fff {
                    border-top: 10px solid $romeo-primary;
                }

                h1 {
                    text-align: center;
                    margin: 0 0 20px 0;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #888;
                    font-size: 2rem;

                    +div:not(.pdf-print-main-content) {
                        margin-top: -20px;
                        margin-bottom: 5px;
                    }
                }

                dl {
                    dt {
                        font-size: 16px;
                    }

                    dd {
                        span.label {
                            font-size: 90%;
                            font-weight: 500;
                            padding: 10px 20px;
                            display: inline-block;
                            border-radius: 20px;
                            margin-top: 5px;
                        }

                        p {
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .pdf-print-main-content {
                    background: $white;
                    border: 0 solid #eceaea;
                    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
                    padding: 20px;
                }

                &.event-details {

                    table {
                        width: 100%;

                        th:not(:first-child),
                        td:not(:first-child) {
                            padding: 0 15px 3px;
                        }

                        .pdf-print-name {
                            width: 170px;
                        }

                        .pdf-print-date {
                            min-width: 155px;
                        }

                        .pdf-print-time {
                            min-width: 130px;
                        }

                        .pdf-print-attendance {
                            border-bottom: 1px solid #999;
                        }
                    }
                    h2 {
                        border-bottom: 2px solid #000; 
                        padding-bottom: 0.3rem;

                        &:nth-of-type(2) {
                            margin-top: 25px;
                        }
                    }
                }
            }
        }

        footer {
            &.pdf-print-footer {
                position: relative;
                bottom: 0;
                height: auto;
                padding: 0;
                margin: 0;
                background: transparent;
            }
        }

        // don't show on print
        @media print {

            // @page {
            //     size: A4 landscape;
            //   }

            .pdf-print-hide,
            .pdf-print-hide * {
                display: none !important;
            }

            .pdf-print-header.event-details {
                margin-bottom: 30px !important;
            }

            .pdf-print-main.event-details {

                small {
                    text-align: center;
                    display: block;
                }

                .pdf-print-main-content {
                    padding: 0 !important;

                    .card-body {
                        padding: 0 !important;

                        .print-pdf-attendance {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}


/* pv = profile view page Career Eeadiness lozenge */
.pv-career-readiness-label {
    display: block;
    z-index: 2;
    margin-bottom: 0.7rem;

    .pv-label {
        font-weight: 500;
    }

    a {
        font-size: 11px;
        color: #333;
        width: 100%;
    }

    @media (min-width: 768px) {
        font-size: 0.7rem;
        cursor: pointer;
    }

    @media (min-width: 992px) {
        // position: absolute;
        // top: 2rem;
        position: relative;
        top:1rem;
        margin-left: 10px;
        display: inline-flex;
        margin-bottom: 0;

        .pv-label {
            margin: 0 auto;
            display: block;
            text-align: center;
        }

        a {
            display: block;
            margin: 0 auto;
        }

        .pv-label-container {
            width: 65%;
            display: inline-block;
        }

        .popover-content {
            min-width: 16rem;
        }
    }
}

.pv-career-readiness-label+.breadcrumb {
    @media (min-width: 992px) {
        position: relative;
        top: -0.7rem;
    }
}

/* pv = profile view page main section */
.pv-section {

    // margin-top: 2.5rem !important;

    // @media (min-width: 768px) {
    //     margin-top: 1rem !important;
    // }
    // god knows what's going on with this profile view page!  It's just a huge mess

    .ev-column-1, .ev-column-2 {
        margin-top: -2rem;
        }

    // ios fix as it creates a link tag and this gets rid of the underline and blue colour text
    a[href^="tel"] {
        color: inherit;
        text-decoration: none;
    }

    .action-buttons {

        &.pull-top-right {
            @media (min-width: 768px) {
                top: -85px;
            }
        }

        // AB only to be used if there is a second button in this container
        a:nth-of-type(2) {
            margin-left: .3125rem;
        }
    }

    .pv-label-pill {
        margin-top: 0.2rem;
    }

    .pv-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4rem;
        white-space: normal;
        text-align: left;
    }

    .pv-splash {
        padding-left: 1.3rem !important;
    }

    .pv-logo-icon {
        max-width: 1.7rem;
    }

    .pv-table-responsive {
        padding: 1rem;

        td {
            vertical-align: top;
        }

        .pv-short-description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 1.2857125rem;
            max-height: 2.8rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-top: 0.3rem;
            max-width: 19rem;

            // only for Edge as padding slighly different
            @supports (-ms-ime-align: auto) {
                padding-top: 0.25rem;
                line-height: 1.3rem;
            }
        }
    }

    .contact-details {
        ul {
            li {
                -ms-flex-align: start !important;
                align-items: flex-start !important;
                -ms-flex-direction: row !important;
                flex-direction: row !important;
                display: flex;

                i {
                    margin-top: 0.2rem;
                    min-width: 1.4rem;
                }

                span:not(.sr-only) {
                    display: inline-block;
                    width: 17rem;
                }

                a {
                    color: #333 !important;

                    &.pv-no-underline {
                        cursor: text;
                    }
                }

                .pv-no-underline {
                    text-decoration: none !important;

                    &:hover {
                        text-decoration: none !important;
                    }
                }
            }
        }
    }

    .pv-skills-and-interests {
        li {
            -ms-flex-align: start !important;
            align-items: flex-start !important;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            span.d-block {
                min-width: 10rem;
                max-width: 10rem;
            }
        }
    }

    .breadcrumb {
        @media (min-width: 992px) {
            position: relative;
            top: -1.5rem;
        }
    }

    @media (min-width: 768px) {

        .pv-skills-and-interests>li>span:first-of-type {
            min-width: 12rem;
            display: inline-block;
        }
    }
}

// overrides for operator side which could include later on more than this specific page
// as we have no romeo here it fixes
.page-operator {

    .pv-section.content-body {

        padding-top: 1rem !important;

        // generic to all pages
        h1 {
            margin-bottom: 3rem !important;
            margin-top: 0;
            font-size: 1.8rem;
            font-weight: 600;

            @media (min-width: 992px) {
                position: inherit;
                top: inherit;
            }
        }

        @media (min-width: 992px) {
            .pv-career-readiness-label {
                top:2rem;
            }
        }
        
        // reset for operator side
        .action-buttons {
            &.pull-top-right {
                @media (min-width: 768px) {
                    top: 52px;
                }
                @media (min-width: 992px) {
                    top: 85px;
                }

            }
        }
    }

    .pv-section {
        .pv-table-responsive {

            td:nth-child(1) {
                width: 12rem;
            }

            // disable this just for the operator
            .pv-short-description {
                overflow: visible;
                text-overflow: unset;
                line-height: inherit;
                max-height: inherit;
                -webkit-line-clamp: inherit;
                -webkit-box-orient: inherit;
                max-width: none;
            }

            // dont want the Upload Date to wrap
            .pv-short-description+td {
                white-space: nowrap;
            }
        }
    }
}


/* pe- = profile edit page */
/* https://seiyria.com/bootstrap-slider/ */

.pe-career-readiness-container {
    width: 80%;
    min-height: 11rem;

    .pe-career-readiness-slider {

        @media (min-width: 768px) {
            .pe-label {
                display: block !important;
            }
        }

        .slider-horizontal {
            width: 260px;
            top: 0.1rem;

            @media (min-width: 768px) {
                left: -1rem;
            }

            .pe-career-readiness-slider-label {
                width: 4rem;
            }

            .slider-handle {
                width: 2.2rem;
                height: 2.2rem;
                position: relative;
                top: -0.6rem;
                background-image: linear-gradient(to bottom, $primary 0, $primary 100%);
            }

            .slider-tick {
                display: none;
            }

            .slider-selection {
                background-image: linear-gradient(to bottom, $primary 0, $primary 100%);
                height: 0.4rem !important;
            }

            .slider-track-high {
                height: 0.4rem !important;
                background-image: linear-gradient(to bottom, lightgrey 0, lightgrey 0);
            }

            &.slider-disabled {

                .slider-selection,
                .slider-handle {
                    background-image: linear-gradient(to bottom, lightgrey 0, lightgrey 100%);
                }

                .slider-track {
                    background-image: linear-gradient(to bottom, #fff 0, #fff 100%);
                }
            }
        }
    }

    #pe-career-readiness-statement {
        max-width: 20rem;
    }
}

.page-employeruser.romeo {
    .em-search-events-saved-search {
        top: -65px !important;

        &+ul {
            top: -1.8rem;
        }
    }
}

.job-management-list.romeo {
    .em-search-events-saved-search {
        top: inherit !important;

        &+ul {
            top: 2.2rem;
        }
    }
}

.pe-tj-signup-container {

    background: #fff !important;
    color:#17191c;
    font-family:'Poppins', sans-serif !important;

    h3, h4, p {
        font-family:'Poppins', sans-serif !important;
        }
    .pe-tj-logo {
        height:52px;
        @media(min-width:$screen-md) {
            height:72px;
            }
        }    
    h3 {
        font-weight:700;        
        font-size: 1.2rem;
        line-height: 1.75rem;
        @media(min-width:$screen-md) {
            font-size:1.5rem;
            line-height:2.02rem;
            }
        }
    h4 {
        font-weight:700;
        font-size:1rem;
        line-height:1.5rem;
        @media(min-width:$screen-md) {
            font-size:1.2rem;
            line-height:1.75rem;
            }
        }
    .lead {
        font-weight:500;
        font-size:0.9rem;
        @media(min-width:$screen-md) {
            font-size:1rem;
            }
        }
    .text-grad {
        color: $inferno-secondary;
        }
    .pe-tj-fancyblock {
        border:6px solid #EDE8FF;
        border-radius:2rem;
        box-shadow: 0px 18px 40px rgba(84, 40, 251, 0.15);
        }

    .checkbox-button, .btn-tj {
        background-color: #4E21FF !important;
        border-color: #4e21ff !important;
        padding:.5rem 1.5rem .5rem 1.5rem !important;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px;
        font-weight: 700;
        color:#fff;
        width: auto !important;
        display: inline-block;
        &:hover, &:focus {
            background: #3716be !important;
            color:#fff;
            }
        label {
            padding:0;
            }
        strong {
            padding-left: 1.8rem;
            }
        input[type=checkbox] {
            border: 1px solid #4e21ff;
            margin-left:0;
            }
        input[type=checkbox]:checked {
            background: #ffffff;
            border: 1px solid #4e21ff;
            color:#4e21ff
            }
        input[type=checkbox]:focus,input[type=checkbox]:active {
            box-shadow:none;
            }
        }
        
    .checkbox label { // aligning the checkbox and label
        padding-top: 0;
        }
        
    &.card { // this is what displays on the view page
        border:4px solid #EDE8FF;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.36);
        img {
            height:40px;
            }
        .card-body {
            color: #17191c;  // needed to overwrite romeo card-body
            }
        }
    
    }

// TC-21143 max-width stopping image from showing so overriding just for this instance
.media-middle {
    img {
        max-width: none;
    }
}



/* DISPLAYING FORM INFORMATION - BEING USED ON COHORTS */

.form-display {
    .form-display-item {
        border-bottom: 1px solid #dbdedb;
        padding: 1rem 0;
        &:first-child {
            padding-top:0;
            }
        &:last-child {
            border-bottom:none;
            }
        p {
            margin-bottom: 0;
            &:first-child {
                font-weight:500;
                margin-bottom:.4rem
                }
            }
        }
    }


//////////////
/// CHATBOT
//////////////

.chatbot {
    position: fixed;
    inset: auto 1rem 1rem auto;
    z-index: 9999;
    .chatbot-wrapper {
        position: relative;
        .chatbot-cta {
            cursor: pointer;
            appearance: none;
            background-color: #212a3f;
            line-height: 1rem;
            font-size: 1rem;
            color: #fff;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            border-radius: 100%;    
        .chatbot-cta__icon {
            width: 2.5rem;
            height: 2.5rem;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 250ms;        
            }
        .chatbot-cta__label {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
            }
            &:hover {
                .chatbot-cta__icon {
                    transform: scale(105%);
                    }
                }
            }
        .chatbot-popup {
            background-color: #e3e5e8;
            border: 2px solid #e3e5e8;
            border-radius: 12px;
            overflow: hidden;
            width: calc(100vw - 32px);
            height: calc(100vh - 32px);
            width: calc(100dvw - 32px);
            height: calc(100dvh - 160px);
            background-color: white;
            position: absolute;
            visibility: hidden;
            pointer-events: none;
            bottom: 4.5rem;
            right: 0px;
            z-index: -1;
            opacity: 0;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
            transform: translateY(1rem);
            @media (min-width: 576px) { 
                width: 400px;
                height: calc(100dvh - 160px);       
                max-height: 600px;
                }
            &.open {
                z-index: 10;
                opacity: 100;
                pointer-events: auto;
                visibility: visible;
                transform: translateY(0rem);
                }
            }
        }
    }



///////////////////////////////////////////////////////////////////////
/// TARGETFEEDBACK - hack to fix an annoying unstyled popup  TC-28120
//////////////////////////////////////////////////////////////////////

.columbo-ui-notification.notification-popup.clickable {
    display: none;
    }