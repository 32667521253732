.form-group.input-animation-wave {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	vertical-align: top;
	overflow: hidden;	
    .form-control {
        position: relative;
        display: block;
        float: right;
        border: none;
        border-radius: 0;
        font-weight: 400; 
		padding: 0.5em 0em 0.25em;
		width: 100%;
		background: transparent;
		-webkit-appearance: none;
		height: 55px;		
		box-shadow: none;
		&:focus {
			outline: none;
			~ svg,
			.form-group-selected svg {
				stroke: #333;
				-webkit-transform: translate3d(-66.6%, 0, 0);
				transform: translate3d(-66.6%, 0, 0);
			}
			+ label {
				color: #333;
				-webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
				transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);	
			}												
		}		
	}
	label {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;		
		position: absolute;
		left: 0;
		display: block;
		width: 100%;
		text-align: left;
        padding: 1.6em 0;
		pointer-events: none;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
		transition: transform 0.2s 0.15s, color 1s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;		
	}    
	svg {
		position: absolute;
		top: 0;
		left: 0;
		fill: none;
		stroke: #92989e;
		pointer-events: none;
		-webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
		transition: transform 0.7s, stroke 0.7s;
		-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
		transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);		
	}	
	&.form-group-selected {
		label {
			color: $primary;
			-webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
			transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);			
		}	
		svg {
			stroke: #333;
			-webkit-transform: translate3d(-66.6%, 0, 0);
			transform: translate3d(-66.6%, 0, 0);			
		}	
	}
}