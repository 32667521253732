@include spacing("xss", $xxs-size, $xxs-vertical, $xxs-horizontal);
@include spacing("xs", $xs-size, $xs-vertical, $xs-horizontal);
@include spacing("sm", $sm-size, $sm-vertical, $sm-horizontal);
@include spacingNoTitle($reg-size, $reg-vertical, $reg-horizontal);
@include spacing("reg", $reg-size, $reg-vertical, $reg-horizontal);
@include spacing("md", $md-size, $md-vertical, $md-horizontal);
@include spacing("lg", $lg-size, $lg-vertical, $lg-horizontal);
@include spacing("xl", $xl-size, $xl-vertical, $xl-horizontal);
@include spacing("xl", $xl-size, $xl-vertical, $xl-horizontal);
@include spacing("xxl", $xxl-size, $xxl-vertical, $xxl-horizontal);
@include spacing("xxxl", $xxxl-size, $xxxl-vertical, $xxxl-horizontal);
@include spacing("xxxxl", $xxxxl-size, $xxxxl-vertical, $xxxxl-horizontal);
@include spacing("card", $card-size, $card-vertical, $card-horizontal);
@include spacing("xxxxxxl", $xxxxxxl-size, $xxxxxxl-vertical, $xxxxxxl-horizontal);
@include spacing("xxxxxxxl", $xxxxxxxl-size, $xxxxxxxl-vertical, $xxxxxxxl-horizontal);
@include spacing("xxxxxxxxl", $xxxxxxxxl-size, $xxxxxxxxl-vertical, $xxxxxxxxl-horizontal);
@include spacing("bootstrap-spacing", $bootstrap-size, $bootstrap-vertical, $bootstrap-horizontal);
@include spacing("bootstrap-spacing2x", $bootstrap-size2x, $bootstrap-vertical2x, $bootstrap-horizontal2x);

.no-margin {margin: 0px !important;}
.minus-margins {margin-right:-15px; margin-bottom:-15px; margin-left:-15px;} /* why haven't I put top??! sure there must of been a reason */
.margin-auto {margin-left:auto !important; margin-right:auto !important; display:block;}

.no-top-margin {margin-top: 0px !important;}
.no-right-margin {margin-right: 0px!important;}
.no-bottom-margin, .content-block p:last-child, .list-group-item p:last-child {margin-bottom: 0px !important;}
.no-left-margin {margin-left: 0px !important;}

.no-padding {padding: 0px !important;}
.no-top-padding {padding-top: 0px !important;}
.no-right-padding {padding-right: 0px !important;}
.no-bottom-padding {padding-bottom: 0px !important;}
.no-left-padding {padding-left: 0px !important;}

.minus-margin-card {margin: -.75rem 0 -.75rem -1.25rem};

@each $breakpoint, $size in $grid-breakpoints {
    @media(min-width: $size) {
        @include spacing("xss-#{$breakpoint}", $xxs-size, $xxs-vertical, $xxs-horizontal);
        @include spacing("xs-#{$breakpoint}", $xs-size, $xs-vertical, $xs-horizontal);
        @include spacing("sm-#{$breakpoint}", $sm-size, $sm-vertical, $sm-horizontal);
        @include spacingNoTitle($reg-size, $reg-vertical, $reg-horizontal);        
        @include spacing("md-#{$breakpoint}", $md-size, $md-vertical, $md-horizontal);
        @include spacing("reg-#{$breakpoint}", $reg-size, $reg-vertical, $reg-horizontal);
        @include spacing("lg-#{$breakpoint}", $lg-size, $lg-vertical, $lg-horizontal);
        @include spacing("xl-#{$breakpoint}", $xl-size, $xl-vertical, $xl-horizontal);
        @include spacing("xl-#{$breakpoint}", $xl-size, $xl-vertical, $xl-horizontal);
        @include spacing("xxl-#{$breakpoint}", $xxl-size, $xxl-vertical, $xxl-horizontal);
        @include spacing("xxxl-#{$breakpoint}", $xxxl-size, $xxxl-vertical, $xxxl-horizontal);
        @include spacing("xxxxl-#{$breakpoint}", $xxxl-size, $xxxl-vertical, $xxxl-horizontal);
        @include spacing("xxxxxl-#{$breakpoint}", $xxxl-size, $xxxl-vertical, $xxxl-horizontal);
        @include spacing("card-#{$breakpoint}", $card-size, $card-vertical, $card-horizontal);
        @include spacing("xxxxxxl-#{$breakpoint}", $xxxl-size, $xxxl-vertical, $xxxl-horizontal);
        @include spacing("bootstrap-spacing-#{$breakpoint}", $bootstrap-size, $bootstrap-vertical, $bootstrap-horizontal);   
        @include spacing("bootstrap-spacing2x-#{$breakpoint}", $bootstrap-size2x, $bootstrap-vertical2x, $bootstrap-horizontal2x);   
        .no-margin-#{$breakpoint} {margin: 0px !important;}
        .minus-margins-#{$breakpoint} {margin-right:-15px; margin-bottom:-15px; margin-left:-15px;} /* why haven't I put top??! sure there must of been a reason */
        .margin-auto-#{$breakpoint} {margin-left:auto !important; margin-right:auto !important; display:block;}
        .no-top-margin-#{$breakpoint} {margin-top: 0px !important;}
        .no-right-margin-#{$breakpoint} {margin-right: 0px!important;}
        .no-bottom-margin-#{$breakpoint}, .content-block p:last-child, .list-group-item p:last-child {margin-bottom: 0px !important;}
        .no-left-margin-#{$breakpoint} {margin-left: 0px !important;}
        .no-padding-#{$breakpoint} {padding: 0px !important;}
        .no-top-padding-#{$breakpoint} {padding-top: 0px !important;}
        .no-right-padding-#{$breakpoint} {padding-right: 0px !important;}
        .no-bottom-padding-#{$breakpoint} {padding-bottom: 0px !important;}
        .no-left-padding-#{$breakpoint} {padding-left: 0px !important;}
        .no-padding-#{$breakpoint} {padding:0;}
        .no-margin-#{$breakpoint} {margin:0;}
    }
}
