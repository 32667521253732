.romeo {

    font-family: "Poppins",sans-serif,"Open Sans","Arial","Open Sans";
    font-weight:400;
    line-height: 1.5;
    font-size:0.9rem;
    //background-color:var(--body-bg, $romeo-body-bg) !important; // ang removing, looks like IE doesn't like it.  Needs investigation
    background-color:#fff !important;
    background-image: none !important;

    // Making sure everything is Poppins
    h1, h2, h3, h4, h5, h6, p {
      font-family: "Poppins",sans-serif,"Open Sans","Arial","Open Sans";
      }
    .small, small {
      font-size: 90%;
      }

    // Links
    a {
      color:var(--link-color, $romeo-link-color);
      text-decoration: var(--link-decoration, $romeo-link-decoration);
      &:focus, &:hover, &:active {
        color:var(--link-hover-color, $romeo-link-hover-color);
        text-decoration: var(--link-hover-decoration, $romeo-link-hover-decoration);
      }
      &:focus-visible {
        outline: 1px auto #000;
        outline-offset: 4px;
      }
    }

    // random overrides for text-decoration :: Will temp removed a.arrowed
    h2 a,
    .dropdown-menu>li>a,
    #sidebar-content a, // scheme list 
    .query-link-box a, //event details, 'ask a question'
    a.alert-link, // appointments 'referral' alert
    .nav-tabs a // tabs (specifically 'my cvs'
    {
      text-decoration: none;
    }
    
    // fix for legacy img-responsive 
    .img-responsive {
      max-width: 100%;
      height: auto;
      }

    // content-block headings, overrides
    .content-block,
    .panel {
      .heading,
      .panel-heading {
        font-size:1rem;
        padding: 0.85rem 0.9rem;  
        border:none; // removing, not needed anyway, but heading-secondary was getting a primary border from a new romeo class
        margin: -15px -15px 25px -15px;  // adding more bottom margin
        text-transform: none;  // removing any capitalizing from old vlibrary
      }
      .panel-heading {
        margin:0 0 25px 0; // panel headings don't need minus margins
      }
      .heading h2,
      .panel-heading h2 {
        padding:0; // otherwise it adds double padding
        border:none;
      }
    }
    // widget and content-block overrides
    .widget-block, .widget-pricing-simple, .widget-pricing-slash, .widget-simple, .widget-simple-plain, .widget-simple-stat {
      border-radius: 0 !important;
    }

    // modals
    .modal {
      .modal-content, .modal-header {
        border-radius: 0;
        border: none;
      }
    }

    // quick fix for search tables on mobile (due to row-eq-height which adds display:flex
    @include media-breakpoint-down(md) {
      #search-main {
        width:100%;
      }
    }

    hr {
      border-top: $hr-border-width solid $hr-border-color;
      }

    .content-body {  // in TC, using this instead of the foundation 'container' - this 'may' cause issues, lets wait and see
        padding: 20px 15px;
        background:$white;
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;    
        position:relative; // adding here, as it was being picked up from the other main.css before
        min-height: calc(100vh - 90px);
        .overlap-main-header {
          margin-top:-2rem;
        }    
    }

    //rows and cols - making them sexy flexy (bootstrap 4!)
    // .row {
    //     display: flex;
    //     flex-wrap: wrap; 
    // }

    // Robbie: Putting this here temporarily to sort out the display of Recommended Organisations section
    .logos .item {
        flex: 1 1 auto;
        margin: 0 30px;
    }

    // top buttons (aagghh!)
    .pull-top-right {
      float: none;
      margin-bottom: 10px;
      margin-top: -10px;
      }
    @include media-breakpoint-up(md) {
        .pull-top-right { // ang quick fix!
          position: absolute;
          top: -65px;
          right: 15px;
          margin-top: 0;
          margin-bottom: 0;
          z-index:auto; // adding here to overwrite operator migration
        }
    }

    // TC :: Custom
    a._content-block-toggle {
      color: $romeo-primary;   
    }
    .heading, .panel-heading {
        // ANG removing - these styles are conflicting with all the other heading colours (secondary, light etc)
        // background-color: $romeo-primary;
        // border-color: $romeo-primary;
        // color: color-yiq-r($romeo-primary);
        a {
          color: color-yiq-r($romeo-primary);
        }
    }
    head>tr>th {
      background-color: $romeo-secondary;
    }    
    .nav-tabs {
        border-bottom: 2px solid $romeo-primary;
    }
    .nav-tabs li .btn-tab {
      border-radius: 0;
    }
    .nav-tabs li.active .btn-tab, .nav-tabs li .btn-tab:hover, .nav-tabs li .btn-tab:focus,
    .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
        background-color: $romeo-primary;
        color: color-yiq-r($romeo-primary);
        border: 1px solid $romeo-primary;
    }     
    .widget-block, .widget-simple-plain {
      h2, h3 {
        color: color-yiq-r($romeo-secondary);
      }
      .widget-tabs {
        h2, h3 {
          color: $default-text-color;
        }
      }
    } 


  // Custom content at the top of general page (i.e. appointments and events search).  I've made it specific, just incase
  .well.custom-content { 
      background:white;
      box-shadow: $romeo-box-shadow;
      margin-top: -2rem;
      border-radius: 0;
      border: none;
  }
  .page-tabs {
    .well.custom-content {
      margin-bottom: 70px;
    }
  }
  .wrapper-with-sidebar {
    .well.custom-content {
      margin-top:-2rem;
      margin-bottom:40px;
    }
    @include media-breakpoint-up(lg) {
      .well.custom-content {
        margin-top: -2rem;
      }
    }
  }


    // TC :: Student :: Job details
    .leap-link-display .form-control-static {
        word-break: break-all;
    }

    // TC Dropdown menus
    .dropdown-menu {
      border-radius: 0;
    }
    // stupid bloody dropdowns in responsive!!!  See TC-19221 for more details.  vlibrary set dropdown-menu to 'static', undoing this.
    .table-responsive .dropdown-menu {
      position: relative;
    }
    @include media-breakpoint-up(md) {
      .table-responsive .dropdown-menu {
        position: absolute;
      }
    }

    // TC :: Student :: My CVS
    #cv-tab-list .content-block .row .col-xs-12 h2 {
      padding-bottom: 1rem;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item {
      width: 100%;
      flex: 2;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item:last-of-type {
      flex: 1;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item:nth-of-type(2) .widget-simple-plain {
      border-left: 2px solid #ebebeb;
      border-right: 2px solid #ebebeb;
      border-top: 2px solid #ebebeb;
      border-radius: 0 !important;
      cursor: default;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item:nth-of-type(2) .widget-simple-plain h3 {
      border-bottom: 0.0625rem solid #d9d9d9;
      padding-bottom: 0.3125rem;
      text-transform: capitalize;
      color: $sub-heading-text-color;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item:nth-of-type(2) .widget-simple-plain i {
      display: none;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item:nth-of-type(2) .widget-simple-plain .widget-simple-plain-container {
      height: auto;
      clear: both;
      font-size: 1rem;
      cursor: default;
    }
    #cv-tab-list .content-block .d-flex-md .flex-item:nth-of-type(2) .widget-simple-plain .widget-simple-plain-container span {
      font-weight: normal;
      font-size: 0.9rem !important;
    }
    #cv-tab-list [data-cv-id] .widget-simple-action-small {
      padding: 0;
      border: none;
      margin: 0;
    }
    #cv-tab-list [data-cv-id] .widget-simple-action-small a {
      display: flex;
      justify-content: flex-end;
      text-decoration: none;
      align-items: center;
    }
    #cv-tab-list [data-cv-id] .widget-simple-action-small a h2 {
      font-weight: 500;
      font-size: 1rem;
      padding: 0;
    }
    #cv-tab-list [data-cv-id] .widget-simple-action-small a:after {
      font-family: Font Awesome\ 5 Pro;
      content: "";
      color: $romeo-secondary;
      position: relative;
      font-size: 2.2em;
      font-weight: 300;
      padding-left: 10px;
    }
    #cv-tab-list [data-cv-id] .widget-simple-action-small a:hover h2 {
      color: $romeo-secondary;
    }
    #cv-tab-list [data-cv-id] .widget-simple-action-small i {
      display: none;
    }    

    // accessibility - skip links
    a.skipnavigation {
      color: $white;
      background-color: rgba(0,0,0,.8);
      text-decoration: underline;
      display: block;
      padding: .5rem .75rem;
      z-index: 1000;
      top: 10px;
      left: -10000px;
      &:focus {
        left:10px;
      }
    }


  // booking calendar, fix for black text on dark backgrounds
  .fc-event-container {
    a.fc-day-grid-event {
      color:#fff;
      }
    }

  // Sweet alert modal
  .sweet-alert button.confirm {
    background-color: $romeo-primary !important;
    color:color-yiq($romeo-primary) !important;
    }


} // end of romeo



// book availability stuff  (KPMG and custom user - book event > events calendar)
#multibook-precontainer {
  border: none;
  box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.4);
  margin-top: -1rem;
  margin-bottom: 2rem;
      #multibook-container {
          .col-sm-8.col-md-9.col-lg-10.flex-container,
          .col-sm-4.col-md-3.col-lg-2 { // eeek!  crazy hacks
              width:100%;
          }
          .col-sm-4.col-md-3.col-lg-2 {
              margin: .1875rem .05rem !important;
          }                
          a {
              font-size: 1rem;
              text-decoration:none;
          }
          .list-group-basic {
              margin-top: 0.5rem;
              margin-bottom: 1rem;
          }                
          a.list-group-item, .list-group-item  { // select time slots
              font-size:0.8rem;                        
              color:$default-text-color;
              background: #fff;
              &.active {
                  color:#fff;
                  background:$romeo-primary;
              }
              &.disabled {
                  color:#b7b7b7;
              }
              &:hover {
                  text-decoration: underline;
              }
              .scheduler-date:after {
                  content:":";
                  padding-left:0.625rem;
              }
          }
          .panel-footer {
              .btn-default {
                  font-size: 0.75rem;
                  padding: 0;
                  background:transparent !important;
              }
              .btn-default.pull-right {
                  margin-top: 3px;
              }
          }
      }
      .alert.alert-info,
      .alert.alert-warning {
          display:inline-block;
          font-size:0.8rem;
          margin-left:.4375rem !important;
          .badge {
              color:#fff;
              margin-right:5px;
          }
      }
      .alert.alert-warning {
          display:block;
      }
      .col-sm-4.col-md-3.col-lg-2, // select time slots button
      .col-sm-3 { // confirm button
          .btn-primary { 
              float: right;
              font-weight: 600;
              font-size: 1rem;
              border: 1px solid #ffffff75;
          }
          .btn-default {
              background: none !important;
              color: #fff !important;
              font-size: 0.8rem;
              padding: 1rem 0;
          }
      }
      .panel-heading {
          font-size:0.8rem;
          a {
              font-size:0.8rem;
          }
          .col-sm-3, .col-sm-6 {
              padding:0;
          }
      }          
  }



@media screen and (-webkit-min-device-pixel-ratio:0) and (min-width: 1024px) { 

  ::i-block-chrome, .row.safari-fix  {
    display:table!important;
  }
 ::i-block-chrome, .row.safari-fix [class*="col-"] {
    display:table-cell!important;
    float:none!important;
   width:auto!important;
   padding-left:2%!important;
   padding-right:2%!important;
  }

} /* end safari rounding bug hack */